import React from 'react'
import { useSelector } from 'react-redux'
import { State } from 'store'
import { AccessPermission } from 'settings/schema'

const RESELLER_UI_PERMISSIONS: AccessPermission[] = ['access_orders', 'export_orders', 'edit_orders']

export function usePermission() {
    const conviousAdmin = useSelector((state: State) => state.auth.user?.isAdmin ?? false)
    const permissions = useSelector((state: State) => state.auth.user?.permissions ?? [])
    const accounts = useSelector((state: State) => state.auth.user?.accounts ?? [])
    const isReseller = useSelector((state: State) => Boolean(state.auth.user?.resellerId))
    const accountSlug = useSelector((state: State) => state.preferences.activeAccount)

    const hasPermission = React.useCallback(
        (permission: string, overrideAccountSlug?: string): boolean => {
            const slug = overrideAccountSlug ?? accountSlug

            if (conviousAdmin) {
                return true
            }

            if (permission === 'convious_admin' && !conviousAdmin) {
                return false
            }

            if (permission === 'access_account') {
                return accounts.some((a) => a.slug === slug)
            }

            if (isReseller && !RESELLER_UI_PERMISSIONS.includes(permission as AccessPermission)) {
                return false
            }

            const accountPermissions = permissions.find((p) => p.account === slug)
            const permissionsList = accountPermissions?.permissions ?? []
            return permissionsList.includes('partner_admin') || permissionsList.includes(permission)
        },
        [conviousAdmin, accountSlug, accounts, isReseller, permissions],
    )

    return { hasPermission }
}

export function useAdminPermission() {
    const conviousAdmin = useSelector((state: State) => state.auth.user?.isAdmin ?? false)
    return { isAdmin: conviousAdmin }
}
