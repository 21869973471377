import { getCmsSlug } from 'admin/articleService'
import { AuthenticatedHttpService } from 'http/httpService'
import { DetailedError } from 'utils/errorHandler'

export interface BookingFeeTitle {
    text: Record<string, string>
    key?: string
}

export interface BookingFee {
    uuid: string
    enabled: boolean
    title: BookingFeeTitle
    amount: number
    chargeType: 'flat' | 'percentage'
    type: 'product' | 'order'
    articleUuids: string[]
    taxConfigurationUuid: string | null
    conviousCommissionEnabled: boolean
    articleCount?: number
}

export type NewBookingFee = Omit<BookingFee, 'uuid'>

const appendTitleKey = (bookingFee: NewBookingFee) => {
    const firstLocale = Object.keys(bookingFee.title.text)[0]
    bookingFee.title.key = `fee.${getCmsSlug(bookingFee.title.text[firstLocale])}_title`
    return bookingFee
}

export type BookingFeesResponse = BookingFee[]

export class BookingFeesService {
    constructor(private httpService: AuthenticatedHttpService, public backofficeEndpoint: string) {}

    private bookingFeesUrl(accountSlug: string) {
        return `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/booking_fees/`
    }

    async getBookingFees(accountSlug: string): Promise<BookingFeesResponse | null> {
        const response = await this.httpService.fetch(this.bookingFeesUrl(accountSlug))
        if (response.status === 404) {
            return null
        }
        if (!response.ok) {
            throw new Error('Unable to retrieve booking fees')
        }
        return await response.json()
    }

    async createBookingFee(accountSlug: string, bookingFee: NewBookingFee): Promise<BookingFee> {
        const response = await this.httpService.fetch(this.bookingFeesUrl(accountSlug), {
            method: 'POST',
            body: JSON.stringify(appendTitleKey(bookingFee)),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const responseJson = await response.json()
        if (!response.ok) {
            throw new DetailedError('Unable to create booking fee', responseJson.error)
        }
        return responseJson
    }

    async updateBookingFee(accountSlug: string, bookingFee: BookingFee): Promise<BookingFee> {
        const response = await this.httpService.fetch(`${this.bookingFeesUrl(accountSlug)}${bookingFee.uuid}/`, {
            method: 'PUT',
            body: JSON.stringify(bookingFee),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const responseJson = await response.json()
        if (!response.ok) {
            throw new DetailedError('Unable to update booking fee', responseJson.error)
        }
        return await responseJson
    }

    async deleteBookingFee(accountSlug: string, bookingFeeUuid: string): Promise<void> {
        const response = await this.httpService.fetch(`${this.bookingFeesUrl(accountSlug)}${bookingFeeUuid}/`, {
            method: 'DELETE',
        })
        if (!response.ok) {
            throw new Error('Unable to delete booking fee')
        }
    }
}
