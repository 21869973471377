import React from 'react'
import { snakeCase } from 'lodash'
import { IOrderItems } from 'orders/schema'
import ItemSection from './components/itemSection'
import SectionDataGrid from './components/sectionDataGrid'
import { useGetApfConfigQuery } from 'orders/reduxQueries'
import APFInput from './components/apfInput'
import { ReplaceMessagesFunc } from 'messagesContext'
import './styles.scss'

const ItemAPFSection = ({
    item,
    isItemSelected,
    accountSlug,
    replaceMessages,
    hideMessage,
    isItemCanceledRefunded,
}: {
    item: IOrderItems
    isItemSelected: boolean
    accountSlug: string
    replaceMessages: ReplaceMessagesFunc
    hideMessage: (id: string) => void
    isItemCanceledRefunded: boolean
}) => {
    const { data } = useGetApfConfigQuery({ accountSlug })
    const [editable, setEditable] = React.useState(true)

    const getDataFields = () => {
        return Object.entries(item.afterPaymentForm).map(([key, value]) => {
            const fieldName = snakeCase(key)
            const field = data?.fieldsConfig.find((field) => field.name === fieldName)
            if (!field) {
                return {
                    name: fieldName,
                    value,
                }
            }

            const translation = field.translations['en']
            const name = translation ? translation.label : fieldName
            const placeholder = translation ? translation.placeholder : ''

            return {
                name: name,
                value: (
                    <APFInput
                        item={item}
                        accountSlug={accountSlug}
                        fieldName={fieldName}
                        placeholder={placeholder}
                        type={field.type}
                        value={value}
                        replaceMessages={replaceMessages}
                        hideMessage={hideMessage}
                        editable={editable}
                        setEditable={setEditable}
                    />
                ),
            }
        })
    }

    return (
        <ItemSection
            sectionName="Personal details"
            isItemSelected={isItemSelected}
            isItemCanceledRefunded={isItemCanceledRefunded}
            expanded={false}
        >
            <SectionDataGrid dataItems={getDataFields()} />
        </ItemSection>
    )
}

export default ItemAPFSection
