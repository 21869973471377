import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ArticleConfigurationService } from 'products/articleConfigurationService'
import { ArticleService as AdminArticleService } from 'admin/articleService'
import { History, Location } from 'history'
import ExceptionForm from './exceptionForm'
import ExceptionEdit from './exceptionEdit'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { MessageKind } from 'uiComponents/messages'
import { usePermission } from 'admin/hocs'
import { match as RouteMatch } from 'react-router-dom'

interface ProductPricingPageParams {
    accountSlug: string
    id: string
    copy_from: string
}

interface ExceptionDetailPageProps {
    history: History
    location: Location
    articleConfigurationService: ArticleConfigurationService
    articleService: AdminArticleService
    match: RouteMatch<ProductPricingPageParams>
    navigation: Navigation
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    className?: string
}

const ExceptionDetailPage: React.FC<ExceptionDetailPageProps> = (props) => {
    const {
        history,
        match,
        navigation,
        articleConfigurationService,
        articleService,
        setActiveSection,
        replaceTopMessages,
        removeAllMessages,
        className,
    } = props
    const { hasPermission } = usePermission()
    const accountSlug = match.params.accountSlug
    const overrideId = match.params.id
    const prototypeId = navigation.query().copy_from

    return (
        <div
            style={{
                padding: '2.8em 3.2em',
                position: 'relative',
                maxWidth: '75rem',
            }}
        >
            <Switch>
                <Route
                    path="/account/:accountSlug/products/validity/exceptions/duplicate"
                    render={() => (
                        <ExceptionEdit
                            className={className}
                            key="new"
                            id={prototypeId}
                            prototype={true}
                            accountSlug={accountSlug}
                            history={history}
                            articleService={articleService}
                            articleConfigurationService={articleConfigurationService}
                            setActiveSection={setActiveSection}
                            replaceTopMessages={replaceTopMessages}
                            removeAllMessages={removeAllMessages}
                            hasPermission={hasPermission}
                        />
                    )}
                />
                <Route
                    path="/account/:accountSlug/products/validity/exceptions/create"
                    render={() => (
                        <ExceptionForm
                            key="new"
                            className={className}
                            history={history}
                            accountSlug={accountSlug}
                            articleService={articleService}
                            articleConfigurationService={articleConfigurationService}
                            setActiveSection={setActiveSection}
                            replaceTopMessages={replaceTopMessages}
                            removeAllMessages={removeAllMessages}
                            hasPermission={hasPermission}
                        />
                    )}
                />
                <Route
                    path="/account/:accountSlug/products/validity/exceptions/:id"
                    render={() => (
                        <ExceptionEdit
                            className={className}
                            key={overrideId}
                            id={overrideId}
                            accountSlug={accountSlug}
                            history={history}
                            articleService={articleService}
                            articleConfigurationService={articleConfigurationService}
                            setActiveSection={setActiveSection}
                            replaceTopMessages={replaceTopMessages}
                            removeAllMessages={removeAllMessages}
                            hasPermission={hasPermission}
                        />
                    )}
                />
            </Switch>
        </div>
    )
}

export default withNavigation(ExceptionDetailPage)
