import * as React from 'react'
import { useState } from 'react'
import { Order } from '../schema'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus/'
import Permission from 'admin/permissionRequired'
import Feature from 'features/feature'
import { ModalDialog } from 'uiComponents/popups/modal'
import RefundDialog from 'orders/transactions/refunds/refundDialog'
import { OrdersService } from '../ordersService'
import { delay } from 'utils'
import { MessageKind } from 'uiComponents/messages'
import { LoggingService } from 'http/loggingService'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { withFeatures } from 'features'
import { generateOnRefundErrorMessage } from 'orders/utils/orderRefundErrorMessage'

interface ActionsProps {
  order: Order
  accountSlug: string
  ordersService: OrdersService
  loggingService: LoggingService
  rowIndex: number
  rowCount: number
  reloadOrderList: () => void
  replaceMessages: (id: string, status: MessageKind, text: string) => void
  hideMessage: (id: string) => void
  hasFeature: (feature: string, slug: string) => boolean
}

const ActionsMenu: React.FC<ActionsProps> = (props) => {
  const [cancellation, setCancellation] = useState(false)
  const [showEmailResendDialog, setShowEmailResendDialog] = useState(false)
  const [showRefundDialog, setShowRefundDialog] = useState(false)
  const [refundDialogContentHeight, setRefundDialogContentHeight] = useState(0)
  const [loading, setLoading] = useState(false)

  const onRefund = async (cancellation: boolean, e?: Error) => {
    setShowRefundDialog(false)
    if (e) {
      props.replaceMessages(
        'unknown_error',
        'error',
        generateOnRefundErrorMessage(e, cancellation, props.order.id),
      )
    } else {
      props.reloadOrderList()
      const message = cancellation
        ? `Order ${props.order.id} cancellation request was successful`
        : `Order ${props.order.id} refund request was successfully placed and is being processed`
      props.replaceMessages('refunded_successfully', 'success', message)
      await delay(5000)
      props.hideMessage('refunded_successfully')
    }
  }

  const onRefundDialogHeightChange = (height: number) => {
    setRefundDialogContentHeight(height)
  }

  const resendEmail = async () => {
    try {
      setLoading(true)
      await props.ordersService.resendEmail(props.order.id)
      setShowEmailResendDialog(false)
      setLoading(false)
      props.replaceMessages(
        'resent_successfully',
        'success',
        `Email to ${props.order.customer.email} sent successfully`,
      )
      await delay(5000)
      props.hideMessage('resent_successfully')
    } catch {
      setShowEmailResendDialog(false)
      setLoading(false)
      props.replaceMessages('unknown_error', 'error', 'Oops! We could not send the email. Please try again.')
    }
  }

  const downloadTicket = (e: React.MouseEvent<any>) => {
    const eventData = {
      category: 'orders_transactions',
      order: props.order.id,
    }
    props.loggingService.logAction('order_list_ticket_download', eventData)
  }

  const openRefundDialog = (cancellation: boolean = false) => {
    props.hideMessage('unknown_error')
    setCancellation(cancellation)
    setShowRefundDialog(true)
  }

  const closeRefundDialog = () => {
    setShowRefundDialog(false)
  }

  const getBottomOffset = () => {
    if (props.rowCount === 1) {
      return '-2em'
    }
    const numberFromBottom = props.rowCount - props.rowIndex
    if (numberFromBottom === 1) {
      return '-0.7em'
    }
    if (numberFromBottom < 4) {
      return '-3.4em'
    }
    return ''
  }

  const { accountSlug, order, ordersService } = props

  const nonRefundableStatuses = ['refunded', 'refunding', 'pending']
  const showRefundOption =
    !nonRefundableStatuses.includes(order.paymentStatus) &&
    order.canRefund &&
    order.total > 0 &&
    order.paymentProvider !== 'Settled externally'
  const showCancelOption =
    order.orderStatus !== 'cancelled' &&
    order.paymentStatus !== 'refunded' &&
    order.paymentStatus !== 'refunding'
  const canGetTickets = order.orderStatus !== 'cancelled'

  return (
    <OnClickMenu
      className="actions-button"
      title="Actions"
      kind="action"
      secondary
      customFromTop="2.6em"
      width="11.3em"
      alignRight
      customFromBottom={getBottomOffset()}
    >
      {showRefundDialog && (
        <ModalDialog
          onDismiss={closeRefundDialog}
          interactive
          fromTop="10%"
          dynamicHeight={refundDialogContentHeight}
        >
          <RefundDialog
            accountSlug={accountSlug}
            orderNumber={order.id}
            cancellation={cancellation}
            onRefund={onRefund}
            cancelRefund={closeRefundDialog}
            refunding={loading}
            onHeightChange={onRefundDialogHeightChange}
            ordersService={ordersService}
            replaceMessages={props.replaceMessages}
          />
        </ModalDialog>
      )}
      {showEmailResendDialog && (
        <ConfirmationDialog
          title="Resend order confirmation?"
          text={`To: ${order.customer.email}`}
          buttonText="Resend"
          onCancel={() => setShowEmailResendDialog(false)}
          onConfirm={resendEmail}
          loading={loading}
        />
      )}
      <Feature name="OrderRefunds" accountSlug={accountSlug}>
        <Permission name="refund_orders" accountSlug={accountSlug}>
          {showRefundOption && (
            <OnClickMenuItem onClick={() => openRefundDialog()} className="refund-button">
              Refund
            </OnClickMenuItem>
          )}
        </Permission>
      </Feature>
      <Permission name="refund_orders" accountSlug={accountSlug}>
        {showCancelOption && (
          <OnClickMenuItem onClick={() => openRefundDialog(true)} className="cancel-button">
            Cancel {order.total > 0 ? '(no refund)' : ''}
          </OnClickMenuItem>
        )}
      </Permission>
      {canGetTickets && (
        <>
          {!!order.customer?.email && (
            <OnClickMenuItem onClick={() => setShowEmailResendDialog(true)}>
              Resend email
            </OnClickMenuItem>
          )}
          <OnClickMenuItem>
            <a
              href={order.ticketDownloadUrl}
              onClick={downloadTicket}
              target={order.unfilledApf ? '_blank' : ''}
              rel="noreferrer"
            >
              Download ticket
            </a>
          </OnClickMenuItem>
        </>
      )}
    </OnClickMenu>
  )
}

export default withFeatures(ActionsMenu)
