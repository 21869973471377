import React from 'react'
import './tab.scss'

interface TabProps {
    children?: React.ReactNode
    onClick?: () => void
    className?: string
}

export const Tab = ({ children, onClick, className }: TabProps) => (
    <div className={`tab ${className}`.trim()} onClick={onClick}>
        {children}
    </div>
)

export default Tab
