import * as React from 'react'
import { Switch, Route } from 'react-router-dom'
import { History, Location } from 'history'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import PersonalSection from './personalSection'
import PasswordSection from './passwordSection'
import { Profile } from 'settings/schema'
import { AccountSettingsService } from 'settings/settingsService'
import { UpdatedAccountDetails, PersonalSettingsParams } from 'settings/schema'
import { Container, ContainerHeading, ContainerBody, HeadingSectionName } from 'uiComponents/settingsContainer'
import { MessageKind } from 'uiComponents/messages'
import { User } from 'auth/state'
import { withFeatures } from 'features'

interface SettingsContainerState {
    welcome: boolean
    activeSection: string
}

interface SettingsContainerProps {
    accountSlug: string
    user: User | null
    accountSettingsService: AccountSettingsService
    history: History
    navigation: Navigation
    match: RouteMatch<PersonalSettingsParams>
    profile: Profile
    onSave: (newDetails: UpdatedAccountDetails, password: string) => void
    resendEmail: () => void
    location: Location
    welcomePage: boolean
    loading: boolean
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

class AccountSettingsContainer extends React.Component<SettingsContainerProps, SettingsContainerState> {
    constructor(props: SettingsContainerProps) {
        super(props)
        this.state = {
            welcome: this.props.welcomePage,
            activeSection: '',
        }
    }

    sections = ['personal', 'password']

    onSectionChange = (section: string) => {
        if (this.state.activeSection === section) {
            return
        }
    }

    setActiveSection = (section: string) => {
        this.setState({ activeSection: section })
    }

    render() {
        const { welcome, activeSection } = this.state
        const { profile, onSave, resendEmail, location } = this.props
        const resellerPages = !!this.props.user?.resellerId && this.props.user.accounts.length > 1

        return (
            <Container id="personal-settings">
                <ContainerHeading>
                    {!welcome && (
                        <>
                            <HeadingSectionName
                                className={activeSection === 'personal' ? 'active' : ''}
                                onClick={() => this.onSectionChange('personal')}
                                to={`${resellerPages ? '/resellers' : ''}/personal_settings/personal`}
                            >
                                Personal
                            </HeadingSectionName>
                            <HeadingSectionName
                                className={activeSection === 'password' ? 'active' : ''}
                                onClick={() => this.onSectionChange('password')}
                                to={`${resellerPages ? '/resellers' : ''}/personal_settings/password`}
                            >
                                Password
                            </HeadingSectionName>
                        </>
                    )}
                    {welcome && (
                        <HeadingSectionName className="active" to="" disabled>
                            Welcome
                        </HeadingSectionName>
                    )}
                </ContainerHeading>
                <ContainerBody>
                    {!welcome && (
                        <Switch location={location}>
                            <Route
                                path="/personal_settings/personal"
                                render={() => (
                                    <PersonalSection
                                        profile={profile}
                                        onSave={onSave}
                                        resendEmail={resendEmail}
                                        welcome={welcome}
                                        setActiveSection={this.setActiveSection}
                                        loading={this.props.loading}
                                    />
                                )}
                            />
                            <Route
                                path="/personal_settings/password"
                                render={() => (
                                    <PasswordSection
                                        accountSettingsService={this.props.accountSettingsService}
                                        profile={profile}
                                        setActiveSection={this.setActiveSection}
                                        replaceMessages={this.props.replaceMessages}
                                        removeAllMessages={this.props.removeAllMessages}
                                    />
                                )}
                            />
                            <Route
                                path="/resellers/personal_settings/personal"
                                render={() => (
                                    <PersonalSection
                                        profile={profile}
                                        onSave={onSave}
                                        resendEmail={resendEmail}
                                        welcome={welcome}
                                        setActiveSection={this.setActiveSection}
                                        loading={this.props.loading}
                                    />
                                )}
                            />
                            <Route
                                path="/resellers/personal_settings/password"
                                render={() => (
                                    <PasswordSection
                                        accountSettingsService={this.props.accountSettingsService}
                                        profile={profile}
                                        setActiveSection={this.setActiveSection}
                                        replaceMessages={this.props.replaceMessages}
                                        removeAllMessages={this.props.removeAllMessages}
                                    />
                                )}
                            />
                        </Switch>
                    )}
                    {welcome && (
                        <PersonalSection
                            profile={profile}
                            onSave={onSave}
                            resendEmail={resendEmail}
                            welcome={welcome}
                            setActiveSection={this.setActiveSection}
                            loading={this.props.loading}
                        />
                    )}
                </ContainerBody>
            </Container>
        )
    }
}

export default withFeatures(withNavigation(AccountSettingsContainer))
