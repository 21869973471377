import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { Row } from 'uiComponents/flex'
import { Tab } from './tab'
import TabContent from './tabContent'

export interface TabsProps {
    titles: string[]
    children: React.ReactNode[]
    titleClassNames?: string[]
    setActive?: (i: number) => void
    activeTabIndex?: number
}

export const TabRow = styled(Row)`
    width: 30em;
`

export function Tabs({ titles, children, titleClassNames, setActive, activeTabIndex }: TabsProps) {
    const [activeIndex, setActiveIndex] = useState<number>(0)
    useEffect(() => {
        if ((!!activeTabIndex || activeTabIndex === 0) && activeTabIndex !== activeIndex) {
            setActiveIndex(activeTabIndex)
        }
    }, [activeTabIndex])

    return (
        <>
            <TabRow className="tabs">
                {titles.map((t, i) => (
                    <Tab
                        key={i}
                        className={classNames((titleClassNames && titleClassNames[i]) || '', {
                            active: activeIndex === i,
                        })}
                        onClick={() => {
                            setActiveIndex(i)
                            if (!!setActive) {
                                setActive(i)
                            }
                        }}
                    >
                        {t}
                    </Tab>
                ))}
            </TabRow>
            {children.map((ch, i) => (
                <TabContent key={i} className={classNames({ hidden: activeIndex !== i })}>
                    {ch}
                </TabContent>
            ))}
        </>
    )
}

const TabsContainer = styled.div.attrs((props) => ({
    className: classNames('tabs-container', props.className),
}))<{ light?: boolean }>`
    margin: 0 auto;
    width: fit-content;
    display: flex;
    background: ${(props) => (props.light ? props.theme.colors.white : props.theme.colors.background)};
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 0.25rem;
`
const TabElement = styled.div.attrs((props) => ({
    className: classNames('tab-element', props.className),
}))<{
    current: boolean
    disabled?: boolean
    fixedWidth?: string
}>`
    ${(props) => (props.fixedWidth ? `width: ${props.fixedWidth};` : '')}
    display: flex;
    justify-content: center;
    padding: 0.7rem 1.6rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: ${(props) => (props.current || props.disabled ? '' : 'pointer')};
    color: ${(props) => (props.current ? props.theme.colors.boyBlue : props.theme.colors.textLight)};
    &:not(:last-child) {
        border-right: 1px solid ${(props) => props.theme.colors.border};
    }
`

interface NavigationTab {
    name: string
    onClick: () => void
    current: boolean
    disabled?: boolean
}

interface NavigationTabsProps {
    tabs: NavigationTab[]
    light?: boolean
    fixedWidth?: string
}

export function NavigationTabs(props: NavigationTabsProps) {
    return (
        <TabsContainer light={props.light}>
            {props.tabs.map((t, i) => (
                <TabElement
                    key={i}
                    onClick={t.current || t.disabled ? () => {} : t.onClick}
                    current={t.current}
                    disabled={t.disabled}
                    fixedWidth={props.fixedWidth}
                >
                    {t.name}
                </TabElement>
            ))}
        </TabsContainer>
    )
}
