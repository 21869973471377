import { IconProp } from '@fortawesome/fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetPartnerFundListQuery } from 'admin/reduxQuery'
import { FundBalance, PartnerFundParams } from 'admin/types'
import React, { useMemo } from 'react'
import styled from 'styled-typed'
import Currency from 'uiComponents/money/currency'
import { Body } from 'uiComponents/typography'
import { ReactTable, TableColumn } from '../../uiComponents/table/reactTable'
import FundAction from './fundActions'
import FundLog from './fundLog'
import FundStatus from './status'
import TooltipWrapper from '../../uiComponents/tooltip/tooltipWrapper'

const ClosedIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.aluminiumShades[10]};
    font-size: 1.2rem;
    width: unset !important;
`

const OpenedIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.aluminiumShades[30]};
    font-size: 1.2rem;
    width: unset !important;
`

const TableStyles = styled.div`
    margin-top: 2rem;
    & .body .tr {
        height: 4rem;
        border-top: 1px solid ${(props) => props.theme.colors.border};
    }
`

interface FundTransferRow extends Omit<PartnerFundParams, 'balance' | 'pendingBalance' | 'onHoldBalance'> {
    balance?: FundBalance
    onHoldBalance?: FundBalance
    pendingBalance?: FundBalance
}

const FundTransfer = () => {
    const { data, isFetching } = useGetPartnerFundListQuery({})
    const modifiedData = useMemo(() => {
        if (!data || !Array.isArray(data)) {
            return []
        }
        const partners = [] as FundTransferRow[]

        data.forEach((partner) => {
            let availableCurrencies = [
                ...(partner.balance ?? [])?.map((balance) => balance.currency),
                ...(partner.pendingBalance ?? [])?.map((balance) => balance.currency),
                ...(partner.onHoldBalance ?? [])?.map((balance) => balance.currency),
            ] as string[]

            const uniqueCurrencies = [...new Set(availableCurrencies)]

            if (uniqueCurrencies.length === 0) {
                const { balance, onHoldBalance, pendingBalance, ...withoutBalances } = partner

                partners.push(withoutBalances as any)
            } else {
                uniqueCurrencies.forEach((currency) => {
                    const balance = (partner.balance ?? [])?.find((balance) => balance.currency === currency)
                    const onHoldBalance = (partner.onHoldBalance ?? [])?.find(
                        (balance) => balance.currency === currency,
                    )
                    const pendingBalance = (partner.pendingBalance ?? [])?.find(
                        (balance) => balance.currency === currency,
                    )

                    partners.push({
                        ...partner,
                        balance,
                        onHoldBalance,
                        pendingBalance,
                    })
                })
            }
        })

        return partners
    }, [data])

    const columns = React.useMemo(() => {
        return [
            {
                Header: () => null,
                id: 'expander',
                accessor: 'expander',
                width: '2.5rem',
                disableSortBy: true,
                disableHideColumn: true,
                Cell: ({ row }) => (
                    <div {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? (
                            <OpenedIcon icon={faAngleUp as IconProp} />
                        ) : (
                            <ClosedIcon icon={faAngleDown as IconProp} />
                        )}
                    </div>
                ),
            },
            {
                Header: 'Partner',
                accessor: 'adyenAccountHolderCode',
                Cell: ({ value, row: { original } }) => {
                    const accountName = original.accountName

                    return (
                        <TooltipWrapper text={value ?? accountName}>
                            {!!value && <div>{value}</div>}
                            <div>{accountName}</div>
                        </TooltipWrapper>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                width: '10rem',
                Cell: ({ value }) => <FundStatus state={value} />,
            },
            {
                Header: 'Balance',
                accessor: 'balance.value',
                width: '10rem',
                Cell: ({ value, row: { original } }) => (
                    <Currency amount={value} currency={original.balance?.currency} />
                ),
            },
            {
                Header: 'Pending Balance',
                accessor: 'pendingBalance.value',
                width: '10rem',
                Cell: ({ value, row: { original } }) => (
                    <Body color="light" style={{ fontWeight: 200 }}>
                        <Currency amount={value} currency={original.balance?.currency} />
                    </Body>
                ),
            },
            {
                accessor: 'action',
                disableSortBy: true,
                align: 'right',
                width: '20rem',
                minWidth: '20rem',
                Cell: ({ row: { original } }) => (
                    <FundAction
                        accountSlug={original.accountSlug}
                        accountName={original.accountName}
                        balance={original.balance}
                        pendingBalance={original.pendingBalance}
                        status={original.status}
                    />
                ),
            },
        ] as TableColumn<FundTransferRow>[]
    }, [])

    return (
        <TableStyles>
            <ReactTable sort expanded={FundLog} loading={isFetching} columns={columns} data={modifiedData} />
        </TableStyles>
    )
}

export default FundTransfer
