import React from 'react'
import styled from 'styled-typed'
import { Combobox, ComboboxProps } from '.'

const ParentStyling = styled.div`
    & .combobox-select-item.has-child {
        margin-left: 5px;
        background-color: ${(props) => props.theme.colors.background};
        font-weight: bold;

        & .combobox-select-item-checkbox,
        & svg {
            display: none;
        }
    }
`

export const ComboboxWithParentStyling = (props: ComboboxProps) => (
    <ParentStyling>
        <Combobox {...props} />
    </ParentStyling>
)
