import * as React from 'react'
import { Navigation } from 'navigation'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { StatsService } from 'http/statsService'
import MarketingEmailsSection from './marketingEmailsSection'
import { PageTitle } from 'uiComponents/typography'
import { DateRange, dateRangeFromQuery, dateRangeToQuery } from 'dateRanges'
import {
    ChartWrapperWithDateRange as WithDateRangeNotice,
    ReportsToolsWrapper,
    ReportUpdateInfo,
} from '../helpers'
import TransactionEmailsTable from './transactionEmailsTable'
import { EmailsData } from 'reports/schema'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { emailNameMap } from 'emailTemplates/schema'
import Disclaimer from 'reports/disclaimer'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import { useMessages } from 'messagesContext'

interface EmailsReportParams {
    accountSlug: string
}

interface EmailsReportProps {
    accountSlug: string
    statsService: StatsService
    navigation: Navigation
    match: RouteMatch<EmailsReportParams>
}

const EmailsReport: React.FC<EmailsReportProps> = (props) => {
    const { accountSlug, statsService, navigation } = props
    const query = navigation.query()
    const dateRange = dateRangeFromQuery(query)
    const { replaceMessages } = useMessages()

    React.useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const onDateRangeChanged = (range: DateRange) => {
        navigation.addQueryWithReplace(dateRangeToQuery(range))
    }

    const mapEmailName = (dataSeries: EmailsData[]) => {
        const emailNames = {
            '{CART_PAID}': emailNameMap['CART_PAID'],
            '{CART_PAID_INPUT_REQUIRED}': emailNameMap['CART_PAID_INPUT_REQUIRED'],
            '{CART_RESERVATION}': emailNameMap['CART_RESERVATION'],
            '{CART_RESERVATION_INPUT_REQUIRED}': emailNameMap['CART_RESERVATION_INPUT_REQUIRED'],
            '{CART_REFUND}': emailNameMap['CART_REFUND'],
            '{CART_REMINDER}': emailNameMap['CART_REMINDER'],
            'Cart Reminder': emailNameMap['CART_REMINDER'],
            '{CART_REMINDER_II}': emailNameMap['CART_REMINDER_II'],
            '{CART_REMINDER_RTP}': emailNameMap['CART_REMINDER_RTP'],
            '{CART_PAID_INPUT_REQUIRED_REMINDER}': emailNameMap['CART_PAID_INPUT_REQUIRED_REMINDER'],
            '{CART_PRE_EVEN_NOTIFICATION}': emailNameMap['CART_PRE_EVEN_NOTIFICATION'],
            '{CART_POST_EVENT_NOTIFICATION}': emailNameMap['CART_POST_EVENT_NOTIFICATION'],
        }
        return dataSeries.map((ds) => {
            const mappedName = emailNames[ds.emailName]
            const emailName =
                mappedName ??
                ds.emailName
                    .replace(/[{}]/g, '')
                    .split(/[_ ]/g)
                    .map((w) => w[0] + w.toLowerCase().slice(1))
                    .join(' ')
            return { ...ds, emailName }
        })
    }

    return (
        <div id="reports-emails-page">
            <PageTitle data-userpilot="emails-header-tooltip">Emails Report</PageTitle>
            <ReportsToolsWrapper style={{ marginBottom: '0rem' }}>
                <ReportUpdateInfo metric="Data" text="updated every 1 hour." />
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    userpilot="emails-date-range"
                    firstAvailableDay={new Date(2019, 0, 1)}
                />
            </ReportsToolsWrapper>
            <MarketingEmailsSection
                statsService={statsService}
                accountSlug={accountSlug}
                dateRange={dateRange}
                replaceTopMessages={replaceMessages}
                mapEmailName={mapEmailName}
            />
            <div style={{ marginTop: '3em' }}>
                <WithDateRangeNotice dateRange={dateRange} userpilot="emails-transactional-table">
                    <TransactionEmailsTable
                        title="Transactional Emails"
                        statsService={statsService}
                        accountSlug={accountSlug}
                        dateRange={dateRange}
                        replaceTopMessages={replaceMessages}
                        mapEmailName={mapEmailName}
                    />
                </WithDateRangeNotice>
            </div>
            <BaseKnowledgeLink link="https://support.convious.com/help/email-report" />
            <Disclaimer />
        </div>
    )
}

export default withNavigation(EmailsReport)
