import React from 'react'
import { delay } from 'utils'
import styled from 'styled-typed'
import { Container } from 'uiComponents/pageElements'
import { PageTitle } from 'uiComponents/typography'
import { TableLoader } from 'uiComponents/loaders'
import { ValidationMessage } from 'uiComponents/form/formElements'
import { ActionButton } from 'uiComponents/buttons'
import { History } from 'history'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { ListItem } from './listItem'
import { TextInput } from 'uiComponents/input'
import { TicketTemplatesService, Template, TemplateBody, TemplateType } from 'ticketTemplates/ticketTemplatesService'
import { TopButtonWrapper } from 'uiComponents/settingsContainer'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import { useMessages } from 'messagesContext'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from 'hocs'

const ListContainer = styled.div`
    background: ${(props) => props.theme.colors.white};
    border-radius: 6px;
    min-height: 7.5em;
    box-shadow: ${CONTAINER_BOX_SHADOW};
`
const WhiteContainer = styled.div`
    background: ${(props) => props.theme.colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25em 1.75em;
    border-bottom: 2px solid ${(props) => props.theme.colors.background};

    &:last-child {
        border: none;
    }
`
const TemplateName = styled.div`
    font-weight: bold;
    margin-bottom: 0.9em;
`

export const ContainerHeader = styled.div`
    background-color: ${(props) => props.theme.colors.tableRow};
    padding: 0.4em 1.875rem;
    margin-bottom: 0.9em;
`

interface TicketTemplatesOverviewPageProps {
    history: History
    ticketTemplatesService: TicketTemplatesService
}

function TicketTemplatesOverviewPage(props: TicketTemplatesOverviewPageProps) {
    const { accountSlug } = useParams<BaseRouteParams>()
    const [templateList, setTemplateList] = React.useState<Template[]>([])
    const [showPreview, setShowPreview] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = React.useState<boolean>(false)
    const [addingModeOn, setAddingModeOn] = React.useState<boolean>(false)
    const [newTemplateName, setNewTemplateName] = React.useState<string>('')
    const [newTemplateNameError, setNewTemplateNameError] = React.useState<string>('')
    const [templateTobeDeleted, setTemplateTobeDeleted] = React.useState<string | null>(null)
    const [templateType, setTemplateType] = React.useState<TemplateType>('TICKET')
    const [path, setPath] = React.useState<'ticket_templates' | 'gift_card_templates'>('ticket_templates')
    const { replaceMessages, removeAllMessages } = useMessages()
    
    async function getData() {
        try {
            setLoading(true)
            const templType = props.history.location.pathname.includes('gift_card_templates') ? 'VOUCHER' : 'TICKET'
            setTemplateType(templType)
            setPath(templType === 'VOUCHER' ? 'gift_card_templates' : 'ticket_templates')
            const templates = await props.ticketTemplatesService.getTemplatesList(accountSlug, templType)
            setTemplateList(templates)
        } catch {
            replaceMessages(
                'server_error',
                'error',
                'Oops! Could not get the templates list. Please try again later.',
            )
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        getData()
    }, [accountSlug, props.history.location.pathname])

    React.useEffect(() => {
        if (!!newTemplateName.replace(/ /g, '')) {
            setNewTemplateNameError('')
        }
        if (newTemplateName.length > 100) {
            setNewTemplateNameError('The name is too long.')
        }
    }, [newTemplateName])

    React.useEffect(() => {
        if (!addingModeOn) {
            setNewTemplateName('')
            setNewTemplateNameError('')
        }
    }, [addingModeOn])

    const onPreview = () => {
        setShowPreview(true)
    }

    const DEFAULT_PAYLOAD: TemplateBody = {
        name: 'Test template config',
        image: '',
        useBannerImage: false,
        printingRequired: false,
        showVat: false,
        showTapQrCode: true,
        additionalInfo: null,
        hidePrice: 'NEVER',
        showBarcode: true,
        showBarcodeNumber: true,
        showFullName: false,
        showOrderNo: true,
        showEmail: true,
        showOptions: false,
        templateType: templateType,
    }

    const createTemplate = async () => {
        if (!newTemplateName.replace(/ /g, '')) {
            setNewTemplateNameError('Please enter a valid name for your template')
        } else {
            try {
                const config = await props.ticketTemplatesService.createTemplate(accountSlug, {
                    ...DEFAULT_PAYLOAD,
                    name: newTemplateName,
                })
                props.history.push(`/account/${accountSlug}/engage/${path}/${config.id}`)
            } catch {
                replaceMessages(
                    'server_error',
                    'error',
                    'Oops! Could not save the template. Please try again later.',
                )
            }
        }
    }
    const updateTemplateName = async (id: string, v: string) => {
        try {
            const config = await props.ticketTemplatesService.updateTemplateName(accountSlug, id, v)
            const newTemplates = templateList.map((t) => (t.id === id ? config : t))
            setTemplateList(newTemplates)
        } catch {
            replaceMessages(
                'server_error',
                'error',
                'Oops! Could not rename the template. Please try again later.',
            )
        }
    }

    const deleteTemplate = async () => {
        if (!templateTobeDeleted) {
            return
        }
        try {
            await props.ticketTemplatesService.deleteTemplate(accountSlug, templateTobeDeleted)
            props.history.replace(`/account/${accountSlug}/engage/${path}/`)
            setShowDeleteConfirmationDialog(false)
            getData()
            replaceMessages('success', 'success', 'The template was successfully deleted.')
            await delay(3000)
            removeAllMessages()
        } catch {
            replaceMessages(
                'server_error',
                'error',
                'Oops! Could not delete the template. Please try again later.',
            )
        }
    }

    return (
        <Container id="ticket-template-overview">
            {showPreview && <></>}
            <PageTitle>{templateType === 'VOUCHER' ? 'Gift card' : 'Ticket'} templates</PageTitle>
            {showDeleteConfirmationDialog && (
                <ConfirmationDialog
                    title="Are you sure you want to delete this template?"
                    text=""
                    buttonText="Delete"
                    destructive
                    onCancel={() => setShowDeleteConfirmationDialog(false)}
                    onConfirm={deleteTemplate}
                />
            )}
            {!addingModeOn && (
                <TopButtonWrapper>
                    <ActionButton id="newTemplate" size="medium" onClick={(e) => setAddingModeOn(true)} kind="action">
                        + Add new
                    </ActionButton>
                </TopButtonWrapper>
            )}
            {addingModeOn && (
                <WhiteContainer>
                    <div style={{ width: '24em', display: 'flex', alignItems: 'center' }}>
                        <TextInput
                            placeholder="Template Name"
                            id="ticketTemplateName"
                            value={newTemplateName}
                            onChange={(e) => setNewTemplateName(e.target.value)}
                            status={!!newTemplateNameError ? 'error' : 'normal'}
                            block
                        />
                        <ValidationMessage className={!!newTemplateNameError ? 'validation-message-visible' : ''}>
                            {newTemplateNameError}
                        </ValidationMessage>
                    </div>
                    <div>
                        <ActionButton
                            kind="action"
                            secondary
                            onClick={() => setAddingModeOn(false)}
                            style={{ marginRight: '1.5em' }}
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton id="saveTemplate" onClick={createTemplate} style={{ marginRight: '2em' }}>
                            Save
                        </ActionButton>
                    </div>
                </WhiteContainer>
            )}
            <ListContainer>
                {loading && <TableLoader style={{ paddingTop: '2em' }} />}
                {!loading && (
                    <>
                        {templateList.length === 0 && (
                            <WhiteContainer>
                                <TemplateName>No templates found</TemplateName>
                            </WhiteContainer>
                        )}
                        {templateList.map((tmp) => (
                            <ListItem
                                key={tmp.id}
                                template={tmp}
                                onPreview={onPreview}
                                onNameEdit={(v: string) => updateTemplateName(tmp.id, v)}
                                onDelete={() => {
                                    setShowDeleteConfirmationDialog(true)
                                    setTemplateTobeDeleted(tmp.id)
                                }}
                                accountSlug={accountSlug}
                                history={props.history}
                                path={path}
                            />
                        ))}
                    </>
                )}
            </ListContainer>
            {templateType === 'VOUCHER' && (
                <BaseKnowledgeLink link="https://support.convious.com/help/how-to-set-up-gift-card-templates" />
            )}
        </Container>
    )
}

export default TicketTemplatesOverviewPage
