import * as React from 'react'
import styled from 'styled-typed'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { State } from 'store'
import { User } from 'auth/state'
import { PageTitle, Link } from 'uiComponents/typography'
import { TextInput } from 'uiComponents/input/textInput'
import {
    Container,
    ContainerHeading,
    ContainerBody,
    HeadingSectionName,
    Section,
    SectionForm,
    InputRow,
    InputColumn,
    PageActions,
} from 'uiComponents/settingsContainer'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { isValidUrl } from 'utils/formFieldChecks'
import { startOfToday } from 'date-fns'
import { ActionButton } from 'uiComponents/buttons'
import { AccountServiceContext, ProductsListServiceContext, ArticleServiceContext } from 'admin/context'
import { PricingServiceContext } from 'products/pricing/context'
import { ComponentsServiceContext } from 'engageTools/studio/context'
import { UserServiceContext } from 'auth/context'
import { createAccount } from './onboarding'
import { defaultOnboardingTemplateFor } from './onboardingTemplate'
import { PageLoader } from 'uiComponents/loaders'
import { CopySnippetElement } from 'snippet/copySnippetElement'
import { userDataRetrieved } from 'auth/actions'
import { SingleSelect } from 'uiComponents/input'
import { MarketType } from 'settings/accountSettings/accountInfo'
import { activeAccountChanged } from 'preferences/actions'
import { useMessages } from 'messagesContext'

interface OnboardingPageProps {
    user: User | null
    userDataRetrieved: typeof userDataRetrieved
    activeAccountChanged: typeof activeAccountChanged
    checkoutDemoEndpoint: string
}

function slugify(str: string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz_____'
    const p = new RegExp(a.split('').join('|'), 'g')

    return str
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '_')
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/&/g, '_and_')
        .replace(/[^\w\-]+/g, '')
        .replace(/__+/g, '_')
        .replace(/^_+/, '')
        .replace(/_+$/, '')
}

function isEmail(email: string) {
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

const DemoLink = styled(Link)`
    display: block;
    margin-bottom: 1em;
`

function OnboardingPageComponent(props: OnboardingPageProps) {
    const accountService = React.useContext(AccountServiceContext)
    const productsListService = React.useContext(ProductsListServiceContext)
    const articleService = React.useContext(ArticleServiceContext)
    const pricingService = React.useContext(PricingServiceContext)
    const componentsService = React.useContext(ComponentsServiceContext)
    const userService = React.useContext(UserServiceContext)
    const { addMessage, removeAllMessages } = useMessages()
    const [accountName, setAccountName] = React.useState('')
    const [accountNameError, setAccountNameError] = React.useState<string | null>(null)
    const [websiteUrl, setWebsiteUrl] = React.useState('')
    const [websiteUrlError, setWebsiteUrlError] = React.useState<string | null>(null)
    const [adyenAccountName, setAdyenAccountName] = React.useState('')
    const [adyenAccountNameError, setAdyenAccountNameError] = React.useState<string | null>(null)
    const [adyenCountryCode, setAdyenCountryCode] = React.useState('')
    const [adyenCountryCodeError, setAdyenCountryCodeError] = React.useState<string | null>(null)
    const [supportAgentEmail, setSupportAgentEmail] = React.useState('')
    const [supportAgentEmailError, setSupportAgentEmailError] = React.useState<string | null>(null)
    const [marketType, setMarketType] = React.useState<MarketType>('Europe')
    const [acountCreating, setAccountCreating] = React.useState(false)
    const [createdAccountSlug, setCreatedAccountSlug] = React.useState<string | null>(null)

    const fullWebsiteUrl = /^https?:\/\//i.test(websiteUrl) ? websiteUrl : 'https://' + websiteUrl

    function reset() {
        setAccountName('')
        setWebsiteUrl('')
        setAdyenAccountName('')
        setAdyenCountryCode('')
        setSupportAgentEmail('')
        setMarketType('Europe')
        setAccountNameError(null)
        setWebsiteUrlError(null)
        setAdyenAccountNameError(null)
        setAdyenCountryCodeError(null)
        setSupportAgentEmailError(null)
        setCreatedAccountSlug(null)
    }

    function validate() {
        setAccountNameError(null)
        setWebsiteUrlError(null)
        setAdyenAccountNameError(null)
        setAdyenCountryCodeError(null)
        setSupportAgentEmailError(null)

        let result = true
        if (!accountName) {
            setAccountNameError('This field is required')
            result = false
        }
        if (!websiteUrl) {
            setWebsiteUrlError('This field is required')
            result = false
        } else if (!isValidUrl(fullWebsiteUrl)) {
            setWebsiteUrlError('This field must be a valid URL')
            result = false
        }
        if (!adyenAccountName) {
            setAdyenAccountNameError('This field is required')
            result = false
        }
        if (!adyenCountryCode) {
            setAdyenCountryCodeError('This field is required')
            result = false
        }
        if (!supportAgentEmail) {
            setSupportAgentEmailError('This field is required')
            result = false
        } else if (!isEmail(supportAgentEmail)) {
            setSupportAgentEmailError('This field must be a valid email')
            result = false
        }

        return result
    }

    const onSave = async (evt: React.FormEvent<HTMLElement>) => {
        evt.preventDefault()
        removeAllMessages()
        if (!validate()) {
            return
        }

        const today = startOfToday()
        const endOfNextYear = new Date(today.getFullYear() + 1, 11, 31)
        const accountSlug = slugify(accountName)
        const onboardingTemplate = defaultOnboardingTemplateFor(
            accountSlug,
            accountName,
            fullWebsiteUrl,
            adyenAccountName,
            adyenCountryCode,
            supportAgentEmail,
            marketType,
            today,
            endOfNextYear,
        )

        try {
            setAccountCreating(true)
            const accountInfo = await accountService.getAccount(accountSlug)
            if (accountInfo) {
                addMessage('account_already_exists', 'error', `Account slug "${accountSlug}" already exists`)
                return
            }

            await createAccount(
                accountService,
                productsListService,
                articleService,
                pricingService,
                componentsService,
                onboardingTemplate,
            )

            const userDetails = await userService.getLoggedInUser()
            props.userDataRetrieved(userDetails.currentUser)
            setCreatedAccountSlug(accountSlug)
            props.activeAccountChanged(accountSlug)
        } catch {
            addMessage('unknown_error', 'error', 'Oops! An unknown error occured. Please try again later.')
        } finally {
            setAccountCreating(false)
        }
    }

    const createdAccount =
        createdAccountSlug && props.user ? props.user.accounts.find((a) => a.slug === createdAccountSlug) : null
    return (
        <>
            <PageTitle>New Account</PageTitle>
            {acountCreating && <PageLoader />}
            {!acountCreating && (
                <>
                    {!createdAccount && (
                        <Container>
                            <ContainerHeading>
                                <HeadingSectionName className="active" to="" disabled>
                                    Account
                                </HeadingSectionName>
                            </ContainerHeading>
                            <ContainerBody>
                                <Section>
                                    <SectionForm noValidate onSubmit={onSave}>
                                        <div>
                                            <InputRow>
                                                <InputColumn>
                                                    <FormItem htmlFor="accountName">
                                                        <FormItemName>Partner name</FormItemName>
                                                        <TextInput
                                                            id="accountName"
                                                            name="accountName"
                                                            type="text"
                                                            value={accountName}
                                                            onChange={(e) => setAccountName(e.target.value)}
                                                            block
                                                        />
                                                    </FormItem>
                                                    <ValidationMessage
                                                        className={accountNameError ? 'validation-message-visible' : ''}
                                                    >
                                                        {accountNameError || ''}
                                                    </ValidationMessage>
                                                </InputColumn>
                                            </InputRow>
                                            <InputRow>
                                                <InputColumn>
                                                    <FormItem htmlFor="accountSlug">
                                                        <FormItemName>Account slug</FormItemName>
                                                        <TextInput
                                                            id="accountSlug"
                                                            name="accountSlug"
                                                            type="text"
                                                            value={slugify(accountName)}
                                                            block
                                                            disabled
                                                        />
                                                        <ValidationMessage />
                                                    </FormItem>
                                                </InputColumn>
                                            </InputRow>
                                            <InputRow>
                                                <InputColumn>
                                                    <FormItem htmlFor="websiteUrl">
                                                        <FormItemName>Website url</FormItemName>
                                                        <TextInput
                                                            id="websiteUrl"
                                                            name="websiteUrl"
                                                            type="text"
                                                            value={websiteUrl}
                                                            onChange={(e) => setWebsiteUrl(e.target.value)}
                                                            block
                                                        />
                                                    </FormItem>
                                                    <ValidationMessage
                                                        className={websiteUrlError ? 'validation-message-visible' : ''}
                                                    >
                                                        {websiteUrlError || ''}
                                                    </ValidationMessage>
                                                </InputColumn>
                                            </InputRow>
                                            <InputRow>
                                                <InputColumn>
                                                    <FormItem htmlFor="adyenAccountName">
                                                        <FormItemName>Adyen account name</FormItemName>
                                                        <TextInput
                                                            id="adyenAccountName"
                                                            name="adyenAccountName"
                                                            type="text"
                                                            value={adyenAccountName}
                                                            onChange={(e) => setAdyenAccountName(e.target.value)}
                                                            block
                                                        />
                                                    </FormItem>
                                                    <ValidationMessage
                                                        className={
                                                            adyenAccountNameError ? 'validation-message-visible' : ''
                                                        }
                                                    >
                                                        {adyenAccountNameError || ''}
                                                    </ValidationMessage>
                                                </InputColumn>
                                            </InputRow>
                                            <InputRow>
                                                <InputColumn>
                                                    <FormItem htmlFor="adyenCountryCode">
                                                        <FormItemName>Adyen country code</FormItemName>
                                                        <TextInput
                                                            id="adyenCountryCode"
                                                            name="adyenCountryCode"
                                                            type="text"
                                                            value={adyenCountryCode}
                                                            onChange={(e) => setAdyenCountryCode(e.target.value)}
                                                            block
                                                        />
                                                    </FormItem>
                                                    <ValidationMessage
                                                        className={
                                                            adyenCountryCodeError ? 'validation-message-visible' : ''
                                                        }
                                                    >
                                                        {adyenCountryCodeError || ''}
                                                    </ValidationMessage>
                                                </InputColumn>
                                            </InputRow>
                                            <InputRow>
                                                <InputColumn>
                                                    <FormItem htmlFor="supportAgentEmail">
                                                        <FormItemName>Support agent email</FormItemName>
                                                        <TextInput
                                                            id="supportAgentEmail"
                                                            name="supportAgentEmail"
                                                            type="text"
                                                            value={supportAgentEmail}
                                                            onChange={(e) => setSupportAgentEmail(e.target.value)}
                                                            block
                                                        />
                                                    </FormItem>
                                                    <ValidationMessage
                                                        className={
                                                            supportAgentEmailError ? 'validation-message-visible' : ''
                                                        }
                                                    >
                                                        {supportAgentEmailError || ''}
                                                    </ValidationMessage>
                                                </InputColumn>
                                            </InputRow>
                                            <InputRow>
                                                <InputColumn>
                                                    <FormItem htmlFor="marketType">
                                                        <FormItemName>Market Type</FormItemName>
                                                        <SingleSelect
                                                            id="marketType"
                                                            name="marketType"
                                                            options={[
                                                                { value: 'Europe', name: 'Europe' },
                                                                { value: 'USA', name: 'USA' },
                                                            ]}
                                                            selected={marketType}
                                                            onSelect={(value: MarketType) => setMarketType(value)}
                                                        />
                                                    </FormItem>
                                                </InputColumn>
                                            </InputRow>
                                        </div>
                                        <PageActions>
                                            <ActionButton type="submit">Create</ActionButton>
                                        </PageActions>
                                    </SectionForm>
                                </Section>
                            </ContainerBody>
                        </Container>
                    )}
                    {createdAccount && (
                        <>
                            <CopySnippetElement account={createdAccount} />
                            <p>Link to checkout:</p>
                            <br />
                            <DemoLink
                                target="__blank"
                                href={`${props.checkoutDemoEndpoint}/index.html?q=${createdAccount.slug}`}
                            >
                                Demo
                            </DemoLink>
                            <br />
                            <ActionButton onClick={reset}>Create another</ActionButton>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export const OnboardingPage = connect(
    (state: State) => ({ user: state.auth.user }),
    (dispatch) =>
        bindActionCreators(
            {
                userDataRetrieved,
                activeAccountChanged,
            },
            dispatch,
        ),
)(OnboardingPageComponent)
