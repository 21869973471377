import React, { useEffect } from 'react'
import { PageTitle } from 'uiComponents/typography'
import { resetState } from './redux'
import { FeatureProps, withFeatures } from 'features'
import OrdersTable from './ordersTable'
import OrderFilters from './filters/searchAndDate'
import { useDispatch } from 'react-redux'
import OrderTableEventHandler from './orderTableEventHandler'
import { useHistory } from 'react-router-dom'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import './index.css'

interface OrdersProps extends FeatureProps {}

const Orders: React.FC<OrdersProps> = ({ children }) => {
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(resetState())
            history.replace({ search: '' })
        }
    }, [])

    return (
        <div id="orders__elem">
            <PageTitle>Orders</PageTitle>
            <OrderTableEventHandler>
                <OrderFilters />
                <OrdersTable />
            </OrderTableEventHandler>
            {children}
            <BaseKnowledgeLink link="https://support.convious.com/help/how-to-manage-orders" />
        </div>
    )
}

export default withFeatures(Orders)
