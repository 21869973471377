import React, { useEffect, useRef } from 'react'
import { embedDashboard } from '@superset-ui/embedded-sdk'
import { useParams } from 'react-router-dom'
import styled from 'styled-typed'
import { getRequired } from 'config'
import { useGetSupersetReport } from './hooks'
import { useGetGuestTokenMutation } from 'reports/supersetQuery'
import { BaseRouteParams } from 'hocs'
import { Headline } from '../../uiComponents/typography'
import { Card } from '../../uiComponents/cards'

const IframeStyles = styled.div`
    width: 100%;
    height: calc(100vh - 100px);

    & > iframe {
        height: 100%;
        width: 100%;
        border: none;
    }
`

const Superset = () => {
    const ref = useRef(null)
    const [fetchGuestToken] = useGetGuestTokenMutation()
    const { dashboardId, accountSlug } = useParams<{ dashboardId: string } & BaseRouteParams>()
    const report = useGetSupersetReport(dashboardId)

    useEffect(() => {
        if (report) {
            embedDashboard({
                id: report.dashboardId,
                supersetDomain: getRequired('superset-endpoint'),
                mountPoint: ref.current as any,
                fetchGuestToken: () => {
                    return fetchGuestToken({ dashboardId: report.dashboardId, accountSlug })
                        .unwrap()
                        .then((response) => response.token)
                },
                dashboardUiConfig: {
                    hideTitle: true,
                    hideChartControls: false,
                    hideTab: false,
                    filters: {
                        visible: true,
                        expanded: true,
                    },
                },
            })
        }
    }, [report, accountSlug])

    return (
        <div>
            <Headline size={1}>{report?.title ?? 'Report'}</Headline>
            <Card style={{ margin: '2rem 0 5rem 0', padding: 0, overflow: 'hidden' }}>
                <IframeStyles ref={ref} style={report?.height ? { height: report.height } : undefined} />
            </Card>
        </div>
    )
}

export default Superset
