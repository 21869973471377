import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Account } from 'auth/state'

interface RequiresToChangePasswordProps {
    children?: React.ReactNode | undefined
    requiresPasswordChange: boolean
}

export function RequiresToChangePassword({ requiresPasswordChange, children }: RequiresToChangePasswordProps) {
    if (requiresPasswordChange && location.pathname.indexOf('/new_user/welcome') < 0) {
        return <Route render={() => <Redirect to="/new_user/welcome" />} />
    }

    return <>{children}</>
}

export function redirectUser(
    selfSignUp: boolean,
    activeAccount: string | undefined,
    isReseller: boolean,
    accounts: Account[],
) {
    if (!activeAccount) {
        return <Redirect to="/login" />
    }
    if (selfSignUp) {
        return <Redirect to={`/account/${activeAccount}/engage/tools/home/`} />
    }
    if (isReseller && accounts.length > 1) {
        return <Redirect to="/resellers/home" />
    }
    if (isReseller) {
        return <Redirect to={`/account/${activeAccount}/orders/transaction/`} />
    }
    return <Redirect to={`/account/${activeAccount}/dashboard/overview/`} />
}
