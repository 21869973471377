import {
    CancelRefundFooterProps,
    CancelRefundHeaderProps,
    OrderRefundDetails,
    SelectedForRefund,
    TicketsForRefund,
} from '../types'
import { User } from 'auth/state'
import { RefundPaypalDetails } from 'orders/paypalDisputes/schema'
import { DateFormats, formatISOString } from 'utils/dates'
import {
    CancelRefundTableData,
    CancelRefundTableSection,
    ICancelRefundTableRow,
} from '../components/cancelRefundTable/types'
import { RefundFeeType } from 'settings/accountSettings/contract/managementService'
import { BookingFeeRowData, getBookingFeesRowsData } from '../utils'

interface headerPropsMapperOptions {
    accountSlug: string
    user: User | null
    isCancellation: boolean
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    order?: OrderRefundDetails
    paypalRefund?: RefundPaypalDetails
    onClose: () => void
}

export const mapHeaderData = ({
    accountSlug,
    user,
    isCancellation,
    formatCurrencyString,
    order,
    paypalRefund,
    onClose,
}: headerPropsMapperOptions): CancelRefundHeaderProps => {
    return {
        title: isCancellation ? 'Cancel items' : 'Refund items',
        orderNumber: order?.number ?? 'XXXXXXXX',
        isAdminUser: user?.isAdmin ?? false,
        customerEmail: order?.customer?.email ?? '',
        visitDate: order?.visitDate ? formatISOString(order.visitDate, DateFormats.EXTRA_LONG_DATE) : undefined,
        paypalRefundData: paypalRefund
            ? {
                  id: paypalRefund.id,
                  accountName: paypalRefund.accountName,
                  location: paypalRefund.location,
                  reason: paypalRefund.reason,
                  refundAmount: formatCurrencyString(paypalRefund.refundAmount.toFixed(2), accountSlug),
              }
            : undefined,
        onClose,
    }
}

interface TableDataMapperOptions {
    tickets: TicketsForRefund[]
    selectedTickets: SelectedForRefund[]
    totalAmount: number
    partnerRefundFeeAmount: number
    partnerRefundFeeOff: boolean
    partnerRefundFeeSelected: boolean
    showPartialRedeemWarning: boolean
    partnerRefundFee?: string
    partnerRefundFeeType?: RefundFeeType | null
    bookingFeesEnabled: boolean
    bookingFeesSelected: string[]
}

export const prepareTableData = ({
    tickets,
    selectedTickets,
    totalAmount,
    partnerRefundFeeAmount,
    partnerRefundFeeOff,
    partnerRefundFeeSelected,
    showPartialRedeemWarning,
    partnerRefundFee,
    partnerRefundFeeType,
    bookingFeesEnabled,
    bookingFeesSelected,
}: TableDataMapperOptions): CancelRefundTableData => {
    const sections: CancelRefundTableSection[] = []
    const ticketRows: ICancelRefundTableRow[] = []
    const bookingFeeRowsData: BookingFeeRowData[] = []

    for (const ticket of tickets) {
        for (const item of ticket.items) {
            const ticketEntity = item.ticketEntity
            const barcodeString = item.barcodeList.join(', ') || '-'
            const isSelected = selectedTickets.some((selected) => selected.ticketUuids.includes(ticketEntity.ids[0]))

            ticketRows.push({
                id: ticketEntity.ids[0],
                rowType: 'ticket',
                name: ticket.product,
                description: item.barcodeList.length > 1 ? 'Hover to view barcodes' : barcodeString,
                descriptionInfo: barcodeString,
                amount: Number(item.price),
                selected: isSelected,
                orderItemId: ticket.orderItemId,
                disabled: !item.itemRefundable
            })

            getBookingFeesRowsData(bookingFeeRowsData, ticketEntity, isSelected, ticket.product)
        }
    }

    const ticketsSection = {
        id: 'itemsSection',
        rows: ticketRows,
        borderBottom: true,
    }

    sections.push(ticketsSection)

    if (!partnerRefundFeeOff) {
        const refundFeeSection = {
            id: 'refundFeeSection',
            rows: [
                {
                    id: '',
                    rowType: 'refundFee',
                    name: 'Visitor refund fee',
                    description:
                        partnerRefundFeeType === 'percentage' ? `${partnerRefundFee}% on products total` : 'Flat rate',
                    amount: partnerRefundFeeAmount,
                    selected: partnerRefundFeeSelected,
                    orderItemId: '',
                    infoTip: 'To change this, go to Settings > Account.',
                },
            ],
        }

        sections.push(refundFeeSection)
    }

    if (bookingFeesEnabled && bookingFeeRowsData.length > 0) {
        const bookingFeeSection = {
            id: 'bookingFeeSection',
            rows: bookingFeeRowsData.map((b) => ({
                id: b.id,
                rowType: 'bookingFee',
                name: b.name,
                description: `${b.selectedTicketsUuids.length}/${b.ticketsUuids.length} products selected`,
                amount: b.amount,
                selected: bookingFeesSelected.includes(b.id),
                orderItemId: '',
                infoTip: b.selectedProductsNames.map((name) => `* ${name}`).join('\n'),
            })),
            borderTop: true,
        }

        sections.push(bookingFeeSection)
    }

    const totalAmountSection = {
        id: 'totalSection',
        rows: [
            {
                id: '',
                rowType: 'total',
                name: 'Total',
                description: showPartialRedeemWarning ? '(includes redeemed tickets)' : '',
                amount: totalAmount,
                selected: false,
                orderItemId: '',
            },
        ],
    }
    sections.push(totalAmountSection)

    return {
        sections,
    }
}

interface FooterPropsMapperOptions {
    accountSlug: string
    confirmMode: boolean
    isCancellation: boolean
    fullRefundOnly: boolean
    refunding: boolean
    onCancel: () => void
    onNext: () => void
    totalAmount: number
    noTicketsSelected: boolean
    refundDisallowed: boolean
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    customerEmail?: string
}

export const mapFooterData = ({
    accountSlug,
    confirmMode,
    isCancellation,
    fullRefundOnly,
    refunding,
    onCancel,
    onNext,
    totalAmount,
    noTicketsSelected,
    refundDisallowed,
    formatCurrencyString,
    customerEmail = '',
}: FooterPropsMapperOptions): CancelRefundFooterProps => {
    const confirmMessage = (() => {
        if (confirmMode) {
            return 'Please double-check above items for any mistakes, and confirm when ready.'
        } else {
            if (fullRefundOnly) {
                return `Only full ${isCancellation ? 'cancellation' : 'refund'} is allowed.`
            } else {
                return `Uncheck items if you want to make a partial ${isCancellation ? 'cancellation' : 'refund'}`
            }
        }
    })()

    const confirmMessageImportant = (() => {
        if (confirmMode) {
            return `${isCancellation ? 'Cancellations' : 'Refunds'} cannot be undone.`
        } else {
            return ''
        }
    })()

    const email = (() => {
        if (!customerEmail) {
            return ''
        }

        if (customerEmail.length > 40) {
            return `to ${customerEmail.slice(0, 40)}...`
        } else {
            return `to ${customerEmail}`
        }
    })()

    const nextButtonCaption = confirmMode
        ? isCancellation
            ? 'Cancel items'
            : `Refund ${formatCurrencyString(Number(totalAmount).toFixed(2), accountSlug)} ${email}`
        : 'Review'

    return {
        confirmMessage,
        confirmMessageImportant,
        cancelButtonCaption: confirmMode ? 'Back to editing' : 'Cancel',
        onCancel,
        nextButtonCaption,
        onNext,
        nextButtonDisabled: refundDisallowed || noTicketsSelected || refunding,
    }
}
