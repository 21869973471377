import * as React from 'react'
import { Navigation } from 'navigation'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { StatsService } from 'http/statsService'
import { createTimeseriesBarChart, TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { PageTitle } from 'uiComponents/typography'
import { dateRangeToQuery, DateRange } from 'dateRanges'
import WebisteTrafficChart from './websiteTrafficChart'
import {
    ChartWrapperWithDateRange as WithDateRangeNotice,
    getDataFromQuery,
    areDateRangeDatesEqual,
    ReportsToolsWrapper,
    ReportUpdateInfo,
} from '../helpers'
import TrafficByGroupCharts from './trafficByGroupCharts'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import ReportsFilter from 'reports/reportsFilter'
import { AVAILABLE_FILTERS, STANDALONE_FILTERS, METADATA_CATEGORIES } from 'reports/constants'
import { FilterCategory } from 'uiComponents/filter/schema'
import { ChartContainer } from 'uiComponents/charts/styleComponents'
import { InventoryService } from 'inventory/inventoryService'
import Disclaimer from 'reports/disclaimer'
import { CharonIncidentWarning } from '../utils/charonIncidentWarning'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import { useMessages } from 'messagesContext'

interface TrafficReportProps {
    accountSlug: string
    statsService: StatsService
    navigation: Navigation
    match: RouteMatch<any>
    inventoryService: InventoryService
}

const TrafficReport: React.FC<TrafficReportProps> = (props) => {
    const { accountSlug, statsService, navigation, inventoryService } = props
    const { dateRange } = getDataFromQuery(navigation.query())
    const [transactionsChart, setTransactionsChart] = React.useState<TimeseriesBarChartSource>(
        createTimeseriesBarChart(dateRange),
    )
    const { filters } = getDataFromQuery(navigation.query())
    const appliedFilters = filters.filter(
        (f) => AVAILABLE_FILTERS['traffic'].indexOf(f.attribute as FilterCategory) > -1,
    )
    const { replaceMessages, hideMessage } = useMessages()

    React.useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const onDateRangeChanged = (range: DateRange) => {
        const { dateRange: currentDateRange } = getDataFromQuery(navigation.query())
        if (!areDateRangeDatesEqual(currentDateRange, range)) {
            navigation.addQueryWithReplace(dateRangeToQuery(range))
            setTransactionsChart(createTimeseriesBarChart(range))
        }
    }

    return (
        <div id="reports-traffic-page">
            <PageTitle data-userpilot="traffic-header-tooltip">Traffic Report</PageTitle>
            <CharonIncidentWarning dateRange={dateRange}>
                Due to a technical issue, the traffic metrics from 20th to 23rd of February, 2024 will be excluded
                from our reports. We apologize for any inconvenience.
            </CharonIncidentWarning>
            <ReportUpdateInfo metric="Data" text="updated every 15 minutes." />
            <ReportsToolsWrapper>
                <ReportsFilter
                    accountSlug={accountSlug}
                    statsService={statsService}
                    inventoryService={inventoryService}
                    applicableFilters={AVAILABLE_FILTERS['traffic']}
                    standaloneFilters={STANDALONE_FILTERS['traffic']}
                    metadataCategories={METADATA_CATEGORIES['traffic']}
                    appliedFilters={appliedFilters}
                    replaceMessages={replaceMessages}
                    hideMessage={hideMessage}
                    dateRange={dateRange}
                    userpilot="traffic-filter"
                />
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    userpilot="traffic-date-range"
                    firstAvailableDay={new Date(2019, 0, 1)}
                />
            </ReportsToolsWrapper>
            <ChartContainer>
                <WithDateRangeNotice dateRange={dateRange} userpilot="traffic-total-canvas">
                    <WebisteTrafficChart
                        dateRange={dateRange}
                        statsService={statsService}
                        accountSlug={accountSlug}
                        chart={transactionsChart}
                        replaceTopMessages={replaceMessages}
                        filters={appliedFilters}
                    />
                </WithDateRangeNotice>
            </ChartContainer>
            <TrafficByGroupCharts
                statsService={statsService}
                accountSlug={accountSlug}
                dateRange={dateRange}
                replaceTopMessages={replaceMessages}
                filters={appliedFilters}
            />
            <BaseKnowledgeLink  link="https://support.convious.com/help/traffic-report" />
            <Disclaimer />
        </div>
    )
}

export default withNavigation(TrafficReport)
