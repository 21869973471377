import React from 'react'
import classNames from 'classnames'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faGripVertical } from '@fortawesome/pro-solid-svg-icons'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import ActionButton from 'uiComponents/buttons'
import { Checkbox } from 'uiComponents/input/checkbox'
import './index.scss'
import { useDispatch } from 'react-redux'
import { updateTableColumns } from 'orders/orders/redux'
import { OrderTableEventHandlerContext } from 'orders/orders/orderTableEventHandler'
import { ClickAwayListener } from '@mui/base'
import { Popover } from '@mui/material'
import { useAppSelector } from 'store/hooks'
import { ORDERS_TABLE_COLUMNS, ORDERS_TABLE_COLUMNS_CONFIG, sanitizeColumns } from './config'
import { useHasFeatureFunc } from 'utils/useHasFeature'
import { Body } from 'uiComponents/typography'

export interface ColumnConfig {
    name: string
    key: string
    selected: boolean
    sectionKey: number
    features?: string[]
}

const ColumnsConfigurator = ({ accountSlug }: { accountSlug: string }) => {
    const dispatch = useDispatch()
    const hasFeature = useHasFeatureFunc()
    const [tabIndex, setTabIndex] = React.useState(0)
    const { eventEmmitter } = React.useContext(OrderTableEventHandlerContext)
    const tableColumns = sanitizeColumns(
        useAppSelector((state) => state.orderTable.tableColumns),
        hasFeature,
    )
    const toggleHideAllColumns = () => eventEmmitter.emit('toggleHideAllColumns', null)
    const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLButtonElement | null>(null)
    const [columns, setColumns] = React.useState(tableColumns)

    const previousAccountSlug = React.useRef(accountSlug).current
    React.useEffect(() => {
        if (accountSlug !== previousAccountSlug) {
            const newColumns = sanitizeColumns(ORDERS_TABLE_COLUMNS, hasFeature)
            setColumns(newColumns)
            dispatch(updateTableColumns(newColumns))
        }
    }, [accountSlug, hasFeature])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPopoverAnchor(event.currentTarget)
    }

    const handleClose = () => {
        setPopoverAnchor(null)
    }

    const handleTabsChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue)
    }

    const onRowSelected = (column: { name: string; key: string; selected: boolean }) => {
        const newColumns = columns.map((col) => {
            if (col.key === column.key) {
                return { ...col, selected: !col.selected }
            }
            return col
        })
        setColumns(newColumns)
    }

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result

        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
            return
        }

        const newColumns = Array.from(columns)
        const [removed] = newColumns.splice(source.index, 1)
        newColumns.splice(destination.index, 0, removed)

        setColumns(newColumns)
    }

    const onApply = () => {
        dispatch(updateTableColumns(columns))
        toggleHideAllColumns()
        handleClose()
    }

    const renderRow = (column: ColumnConfig, index: number) => {
        return (
            <Draggable key={column.key} draggableId={column.key} index={index}>
                {(provided) => (
                    <div
                        key={column.key}
                        className={classNames({ 'column-config-row': true })}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <div className="left">
                            <Checkbox checked={column.selected} onChange={() => onRowSelected(column)} />
                            <Body>{column.name}</Body>
                        </div>
                        <FontAwesomeIcon className="drag-icon" icon={faGripVertical as IconProp} />
                    </div>
                )}
            </Draggable>
        )
    }

    const renderPanel = (
        section: { section: string; columns: { name: string; key: string }[] },
        sectionIndex: number,
    ) => {
        return (
            <div
                id={`simple-tabpanel-${sectionIndex}`}
                key={section.section}
                className="tab-panel"
                hidden={tabIndex !== sectionIndex}
            >
                <DragDropContext onDragEnd={onDragEnd} key={section.section}>
                    <Droppable droppableId={`droppable-${sectionIndex}`} type="droppable-columns">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {columns
                                    .filter((column) => column.sectionKey === sectionIndex)
                                    .map((column) => renderRow(column, columns.indexOf(column)))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        )
    }

    const everyColumnSelected = columns.every((col) => col.selected)
    const selectAll = () => {
        setColumns(
            columns.map((col) => ({
                ...col,
                selected: true,
            })),
        )
    }
    const clearAll = () => {
        setColumns(
            columns.map((col) => ({
                ...col,
                selected: false,
            })),
        )
    }

    return (
        <div>
            <ActionButton onClick={handleClick} size="medium" secondary kind="settings" className="action-button">
                <FontAwesomeIcon icon={faCog as IconProp} className="cog-icon" />
                Adjust view
            </ActionButton>
            <Popover
                id="column-configurator-popover"
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <div className="popup-container">
                        <Tabs value={tabIndex} onChange={handleTabsChange} centered>
                            {ORDERS_TABLE_COLUMNS_CONFIG.map((section) => (
                                <Tab label={section.section} key={section.section} />
                            ))}
                        </Tabs>
                        <div className="content">
                            {ORDERS_TABLE_COLUMNS_CONFIG.map((section, sectionIndex) =>
                                renderPanel(section, sectionIndex),
                            )}
                        </div>
                        <div className="footer">
                            <ActionButton onClick={onApply} size="medium" kind="action">
                                Apply
                            </ActionButton>
                            {everyColumnSelected ? (
                                <ActionButton
                                    onClick={clearAll}
                                    size="medium"
                                    secondary
                                    kind="action"
                                    className="clear-button"
                                >
                                    Clear all
                                </ActionButton>
                            ) : (
                                <ActionButton
                                    onClick={selectAll}
                                    size="medium"
                                    secondary
                                    kind="action"
                                    className="clear-button"
                                >
                                    Select all
                                </ActionButton>
                            )}
                        </div>
                    </div>
                </ClickAwayListener>
            </Popover>
        </div>
    )
}

export default ColumnsConfigurator
