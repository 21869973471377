import React from 'react'
import { withFeatures } from 'features'
import { formatFlatOptions } from 'uiComponents/filter/filterHelpers'
import { MetaData, FilterCategory } from 'uiComponents/filter/schema'
import { useGetAccountLocationsQuery } from 'orders/reduxQueries'
import Filter from 'uiComponents/filter'
import { useAppSelector } from 'store/hooks'
import { getActiveAccount } from 'preferences/selectors'

interface CapacityReportFiltersProps {
    hasFeature: (feature: string, accountSlug: string) => boolean
}

export const applicableFilters: FilterCategory[] = ['location_id']

const CapacityReportFilters: React.FC<CapacityReportFiltersProps> = (props) => {
    const accountSlug = useAppSelector(getActiveAccount)
    if (!accountSlug) throw new Error('No account selected')

    const { data: locationData, isLoading } = useGetAccountLocationsQuery({ accountSlug })

    const data = React.useMemo((): MetaData[] => {
        const locationCategory = {
            category: 'location_id' as FilterCategory,
            options: formatFlatOptions(
                'location_id',
                (locationData ?? []).map((l) => ({
                    name: l.name,
                    slug: l.uuid,
                })),
            ),
        }

        return [locationCategory]
    }, [locationData])

    return (
        <Filter
            accountSlug={accountSlug}
            loading={isLoading}
            applicableFilters={applicableFilters}
            metaData={data}
            standaloneFilters={[]}
            singleSelectCategories={['location_id']}
            compact
        />
    )
}

export default withFeatures(CapacityReportFilters)
