import * as React from 'react'
import styled from 'styled-components'
import { State } from 'store'
import { useSelector } from 'react-redux'
import { Sorting, Pagination } from 'uiComponents/table'
import { DateRange } from 'dateRanges'
import DetailsTable from './detailsTable'
import { OrdersService } from 'orders/ordersService'
import { cancelOutOffsetBeforeConvertionToUTC } from 'utils'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { OrderDetails, OrderDetailsPage, defaultPageData } from 'orders/schema'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation, renderSearch } from 'navigation'
import { History } from 'history'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { PaginationSection } from 'uiComponents/table/pagination'
import { UserField, UpdateTicketValidToDetails } from 'orders/schema'
import { DateRangeType } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import PageActionsSection from './pageActionsSection'
import { NavigationBack } from 'uiComponents/navigation/navigationBack'
import { UpdateDialog } from 'orders/details/updateDialog'
import { format } from 'date-fns'
import { useGetFeatureValue } from 'utils/useHasFeature'
import { SupersetReportFeatureFlag } from 'reports/superset/hooks'
import { useMessages } from 'messagesContext'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import { PageTitle } from 'uiComponents/typography'

interface OrdersDetailsPageProps {
    search?: string
    dateRange: DateRange
    onDateRangeChanged: (dr: DateRange) => void
    dateRangeType: DateRangeType
    onDateRangeTypeChange: (type: DateRangeType) => void
    sort: Sorting
    onSortChanged: (s: Sorting) => void
    pagination: Pagination
    onPaginationChanged: (p: Pagination) => void
    accountSlug: string
    ordersService: OrdersService
    loginService: LoginService
    loggingService: LoggingService
    navigation: Navigation
    match: RouteMatch<{}>
    history: History
    backofficeEndpoint: string
}

const DatePickerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 1rem 0 2rem 0;
`

const OrdersDetailsPage: React.FC<OrdersDetailsPageProps> = (props) => {
    const [ordersPageData, setOrdersPageData] = React.useState<OrderDetailsPage>(defaultPageData)
    const [selectedBarcodes, setSelectedBarcodes] = React.useState<string[]>([])
    const [allBarcodesSelected, setAllBarcodesSelected] = React.useState<boolean>(false)
    const [updateValidToDetails, setUpdateValidToDetails] = React.useState<UpdateTicketValidToDetails | null>(null)
    const [showRedeemDialog,] = React.useState<boolean>(false)
    const [showUpdateDialog, setShowUpdateDialog] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const accounts = useSelector((state: State) => (state.auth.user ? state.auth.user.accounts : []))
    const { removeAllMessages, replaceMessages, hideMessage } = useMessages()
    const supersetReportsFeatures = useGetFeatureValue('superset-reports')
    const salesReport: SupersetReportFeatureFlag = supersetReportsFeatures.find(
        (feature: SupersetReportFeatureFlag) => feature.featureFlag === 'superset-report-sales',
    )

    const {
        sort,
        onSortChanged,
        dateRange,
        search,
        accountSlug,
        ordersService,
        loginService,
        loggingService,
        pagination,
        onPaginationChanged,
    } = props

    const query = props.navigation.query()
    const {
        searchType,
        filter,
        backTo
    } = query

    const loadOrdersList = React.useCallback(async () => {
        setLoading(true)
        await getOrdersList()
    }, [
        accountSlug,
        dateRange.from,
        dateRange.to,
        props.dateRangeType,
        search,
        sort.prop,
        sort.direction,
        pagination.page,
        pagination.pageSize,
        searchType,
        filter,
    ])

    const getOrdersList = React.useCallback(async () => {
        try {
            const data = await ordersService.getOrderDetailsList(
                accountSlug,
                dateRange.from,
                dateRange.to,
                props.dateRangeType,
                search,
                sort.prop,
                sort.direction,
                pagination.page,
                pagination.pageSize,
                searchType === 'extended' ? 'extended' : 'simple',
                filter,
            )

            setOrdersPageData(data.barcodes)
            setSelectedBarcodes([])
            setAllBarcodesSelected(false)
            setLoading(false)
            updateSelectAllToggle(data.barcodes.entries, [])
        } catch {
            replaceMessages(
                'server_error',
                'error',
                'Oops! Orders table could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }, [
        accountSlug,
        dateRange.from,
        dateRange.to,
        props.dateRangeType,
        search,
        sort.prop,
        sort.direction,
        pagination.page,
        pagination.pageSize,
        searchType,
        filter,
        replaceMessages,
        ordersService,
    ])

    const updateSelectAllToggle = (entries: OrderDetails[], selectedBarcodes: string[]) => {
        const allSelected = checkAllBarcodesSelected(selectedBarcodes, entries)
        setAllBarcodesSelected(allSelected)
    }

    const checkAllBarcodesSelected = (selectedBarcodes: string[], entries: OrderDetails[]) => {
        const allBarcodes = getAllBarcodesList(entries)
        return selectedBarcodes.length > 0 && selectedBarcodes.length === allBarcodes.length
    }

    const getAllBarcodesList = (entries: OrderDetails[]) => {
        return entries
            ? entries
                  .filter((b) => !!b.barcode && b.status !== 'refunded' && b.status !== 'refunding')
                  .map((b) => b.barcode)
            : []
    }

    const toggleSelectAllBarcodes = () => {
        const updatedList = allBarcodesSelected ? [] : getAllBarcodesList(ordersPageData.entries)
        setAllBarcodesSelected(!allBarcodesSelected)
        setSelectedBarcodes(updatedList)
    }

    const onSelectBarcode = (event: React.ChangeEvent<HTMLInputElement>) => {
        hideMessage('invalid_visit-date')
        const barcode = event.target.name
        let updatedSelection = [...selectedBarcodes]
        if (updatedSelection.includes(barcode)) {
            updatedSelection = updatedSelection.filter((b) => b !== barcode)
        } else {
            updatedSelection.push(barcode)
        }
        const allSelected = checkAllBarcodesSelected(updatedSelection, ordersPageData.entries)
        setSelectedBarcodes(updatedSelection)
        setAllBarcodesSelected(allSelected)
    }

    const onRedeemComplete = async () => {
        await loadOrdersList()
        setSelectedBarcodes([])
        setAllBarcodesSelected(false)
    }

    const updateApfItem = async (barcode: string, userField: UserField, value: string) => {
        if (userField.isRequired && !value) {
            replaceMessages('required_user_field', 'error', `The field ${userField.label} is required`)
            return
        }
        setLoading(true)
        try {
            await ordersService.updateApfField(accountSlug, barcode, userField.name, value)
            await getOrdersList()
        } catch {
            replaceMessages(
                'user_field',
                'error',
                'Oops! There was a problem with saving the new user field value. Please try again.',
            )
        }
        setLoading(false)
    }

    const saveNewValidToDate = (orderId: string, orderItemId: string, date: Date, bundleOrderItemId: string) => {
        if (!showUpdateDialog) {
            setShowUpdateDialog(true)
            setUpdateValidToDetails({ bundleOrderItemId, orderId, orderItemId, date })
        }
    }

    const onConfirmUpdate = async () => {
        if (!updateValidToDetails) {
            return
        }
        try {
            setLoading(true)
            await ordersService.updateTicketValidToDate({
                account: accountSlug,
                orderItemId: updateValidToDetails.orderItemId,
                validTo: format(updateValidToDetails.date, 'yyyy-MM-dd'),
            })
            await getOrdersList()
        } catch {
            replaceMessages(
                'valid_to',
                'error',
                'Oops! There was a problem with saving new ticket valid to date. Please try again.',
            )
        } finally {
            setLoading(false)
            setShowUpdateDialog(false)
            setUpdateValidToDetails(null)
        }
    }

    const closeDialog = async () => {
        setLoading(true)
        await getOrdersList()
        setShowUpdateDialog(false)
        setUpdateValidToDetails(null)
        setLoading(false)
    }

    const backToTransactions = () => {
        const query = props.navigation.query()
        query.sortBy = query.prevSortBy
        query.sortDirection = query.prevSortDirection
        query.q = query.prevQ
        query.page = query.prevPage
        delete query.prevSortBy
        delete query.prevSortDirection
        delete query.prevQ
        delete query.prevPage
        delete query.backTo
        const search = renderSearch(query)
        props.history.push(`/account/${accountSlug}/orders/transactions${search}`)
    }

    React.useEffect(() => {
        loadOrdersList()
    }, [loadOrdersList])

    const dateFrom = cancelOutOffsetBeforeConvertionToUTC(dateRange.from).toISOString()
    const dateTo = cancelOutOffsetBeforeConvertionToUTC(dateRange.to).toISOString()

    const exportQuery = {
        search,
        widget_slug: accountSlug,
        date_from: dateFrom,
        date_to: dateTo,
        date_range_type: props.dateRangeType,
        sort_by: sort.prop,
        sort_direction: sort.direction,
        filters: filter,
    }

    const orderDetailsEntries = ordersPageData.entries
    const activeAccount = accounts.find((x) => x.slug === accountSlug)
    const withTimeSlots = activeAccount ? activeAccount.timeSlotsEnabled : false

    return (
        <>
            {showUpdateDialog && updateValidToDetails && (
                <UpdateDialog
                    accountSlug={accountSlug}
                    updateValidToDetails={updateValidToDetails}
                    ordersService={ordersService}
                    onCancel={closeDialog}
                    onConfirm={onConfirmUpdate}
                    replaceMessages={replaceMessages}
                    loading={loading}
                />
            )}
            <PageTitle>Orders details</PageTitle>
            <DatePickerWrapper>
                <DateRangePicker
                    range={dateRange}
                    onChange={props.onDateRangeChanged}
                    dateRangeType={props.dateRangeType}
                    onDateRangeTypeChange={props.onDateRangeTypeChange}
                    allowFutureDateSelection
                />
            </DatePickerWrapper>
            <PageActionsSection
                search={search}
                dateRange={dateRange}
                accountSlug={accountSlug}
                loginService={loginService}
                loggingService={loggingService}
                ordersService={ordersService}
                replaceMessages={replaceMessages}
                hideMessage={hideMessage}
                onRedeemComplete={onRedeemComplete}
                selectedBarcodes={selectedBarcodes}
                ordersPageData={ordersPageData}
                exportQuery={exportQuery}
                showRedeemDialog={showRedeemDialog}
                loading={loading}
                refreshTableData={getOrdersList}
                backofficeEndpoint={props.backofficeEndpoint}
            />
            {backTo && (
                <NavigationBack onClick={backToTransactions} text="Back to transactions" topOffset="-2.5em" />
            )}
            <DetailsTable
                orderDetails={orderDetailsEntries}
                userFieldConfig={ordersPageData.userFieldConfig}
                loading={loading}
                sort={sort}
                onSortChanged={onSortChanged}
                ordersService={ordersService}
                loggingService={loggingService}
                accountSlug={accountSlug}
                reloadOrderList={getOrdersList}
                replaceMessages={replaceMessages}
                removeAllMessages={removeAllMessages}
                allBarcodesSelected={allBarcodesSelected}
                toggleSelectAllBarcodes={toggleSelectAllBarcodes}
                onSelectBarcode={onSelectBarcode}
                selectedBarcodes={selectedBarcodes}
                updateApfItem={updateApfItem}
                saveNewValidToDate={saveNewValidToDate}
                withTimeSlots={withTimeSlots}
            />
            <PaginationSection
                pagination={pagination}
                onPaginationChanged={onPaginationChanged}
                totalItemsCount={ordersPageData.totalCount}
                style={{ marginBottom: 0 }}
            />
            <BaseKnowledgeLink link={`/account/${accountSlug}/reports/superset/${salesReport.dashboardId}/`} />
        </>
    )
}

export default withNavigation(OrdersDetailsPage)
