import React, { useState } from 'react'
import styled from 'styled-typed'
import { User } from 'auth/state'
import { ActionButton } from 'uiComponents/buttons'
import { Dispute, ModalType, disputeReasonMap } from './schema'
import { ModalDialogTitle } from 'uiComponents/popups/modal'
import { InfoRow } from 'orders/transactions/refunds/refundDialogTable'
import Money from 'uiComponents/money'
import { TextArea } from 'uiComponents/input'
import { ValidationMessage } from 'uiComponents/form/formElements'
import { UploadInput } from 'uiComponents/input/upload'
import { MessageKind } from 'uiComponents/messages'
import { PaypalService } from 'orders/paypalService'
import { ChartDataLoader } from 'uiComponents/loaders'
import { ImageUploadResponse } from 'http/imagesService'
import Permission from 'admin/permissionRequired'
import { DateFormats, formatISOString } from 'utils/dates'

const Container = styled.div`
    width: 32em;
`
export const AccountNameAndLocation = styled.strong`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`
const MessageContainer = styled.div`
    font-size: 0.75em;
    background: ${(props) => props.theme.colors.background};
    margin-bottom: 1em;
    padding: 1em;
    margin-left: 0.75em;
    position: relative;

    strong {
        text-transform: capitalize;
    }

    &:before {
        content: '';
        background: ${(props) => props.theme.colors.background};
        height: 3em;
        width: 3em;
        position: absolute;
        left: -0.85em;
        top: -2em;
        transform: rotate(55deg);
        z-index: -1;
    }
`
const MessageDate = styled.div`
    margin-top: 1em;
    font-weight: lighter;
`
const ButtonsSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5em;
`
const PastMessagesSection = styled.div`
    margin-top: 1.5em;
`
const PaypalMessageSection = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`
const NewMessageSection = styled(PaypalMessageSection)`
    padding-left: 0.75em;
`
const SectionTitle = styled.div`
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    align-self: flex-start;
    font-weight: 500;
`
const PlainTextArea = styled(TextArea)`
    width: 100%;
`
const ErrorMessage = styled(ValidationMessage)`
    font-size: 0.8em;
    top: 0.2em;
    align-self: flex-start;
`
const UploadedFileName = styled.div`
    position: relative;
    font-size: 0.8em;
    top: -5.5em;
    left: 0.5em;
    align-self: flex-start;
`

interface DisputesDialogProps {
    accountSlug: string
    user: User | null
    dialogType: ModalType
    dispute: Dispute
    paypalService: PaypalService
    loading: boolean
    onOpenModal: (id: string, type: ModalType) => void
    onSendMessage: (id: string, message: string, receiver: 'paypal' | 'buyer', file?: File) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

export function DisputesDialog(props: DisputesDialogProps) {
    const [message, setMessage] = useState<string>('')
    const [file, setFile] = useState<File | null>(null)
    const [showError, setShowError] = useState<boolean>(false)

    const onFinishTyping = (value: string) => {
        !!value.trim() ? setShowError(false) : setShowError(true)
    }

    const onSendMessage = (receiver: 'paypal' | 'buyer') => {
        if (receiver === 'buyer' && !!message.trim()) {
            props.onSendMessage(props.dispute.id, message, receiver)
        } else if (receiver === 'paypal' && !!message.trim() && !!file) {
            props.onSendMessage(props.dispute.id, message, receiver, file)
        } else {
            setShowError(true)
        }
    }

    const onUpload = (uploadedFile: File) => {
        setFile(uploadedFile)
        return new Promise((resolve) => resolve({ url: '' })) as Promise<ImageUploadResponse>
    }

    const title =
        props.dialogType === 'reject'
            ? 'Reject dispute'
            : props.dialogType === 'message'
            ? 'Send a message to the buyer'
            : 'Case information'

    return (
        <Container>
            {props.loading && <ChartDataLoader />}
            <ModalDialogTitle>{title}</ModalDialogTitle>
            <InfoRow>
                <span>Case ID:</span>
                <strong>{props.dispute.disputeId}</strong>
            </InfoRow>
            <InfoRow>
                <span>Order ID:</span>
                <strong>{props.dispute.order.number}</strong>
            </InfoRow>
            {props.user?.isAdmin && (
                <InfoRow>
                    <span>Partner:</span>
                    <AccountNameAndLocation>
                        <span>{props.dispute.account.name}</span>
                        <span>{props.dispute.order.location}</span>
                    </AccountNameAndLocation>
                </InfoRow>
            )}
            <InfoRow>
                <span>Reason:</span>
                <strong>{disputeReasonMap[props.dispute.reason]}</strong>
            </InfoRow>
            <InfoRow>
                <span>Requested refund:</span>
                <strong>
                    <Money amount={props.dispute.amount} accountSlug={props.dispute.account.slug} />
                </strong>
            </InfoRow>
            {props.dialogType !== 'reject' && (
                <PastMessagesSection>
                    {props.dispute.messages.map((m, i) => (
                        <div style={{ overflow: 'hidden' }} key={i}>
                            <MessageContainer>
                                <div>
                                    <strong>{m.postedBy}</strong>: {m.content}
                                </div>
                                <MessageDate>
                                    {formatISOString(m.postedAt, DateFormats.EXTRA_LONG_DATE)} at{' '}
                                    {formatISOString(m.postedAt, DateFormats.LONG_TIME)}
                                </MessageDate>
                            </MessageContainer>
                        </div>
                    ))}
                </PastMessagesSection>
            )}
            {props.dialogType === 'reject' && (
                <PaypalMessageSection>
                    <SectionTitle>Provide dispute details to PayPal</SectionTitle>
                    <PlainTextArea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onFinishTyping={onFinishTyping}
                        maxLength={2000}
                        showCharsLeft
                        status={showError && !message.trim() ? 'error' : 'normal'}
                        placeholder="Do not include confidencial information"
                    />
                    <ErrorMessage
                        className={showError && !message.trim() ? 'validation-message-visible' : ''}
                        style={{ top: '-1em' }}
                    >
                        Message cannot be left blank
                    </ErrorMessage>
                    <UploadInput
                        type="file"
                        acceptedTypesMsg="JPG, PNG, PDF or GIF"
                        acceptedTypes="image/jpg, image/jpeg, image/png, image/gif, application/pdf"
                        onUpload={onUpload}
                        handleUploadSuccessResponse={() => {}}
                        style={{ margin: '0.5em 0 5em 0', height: '4.5em' }}
                        uploadArea
                        inputId="upload"
                        fileSizeLimit={10}
                        status={showError && !file ? 'error' : 'normal'}
                        replaceTopMessages={props.replaceMessages}
                        removeAllMessages={props.removeAllMessages}
                    />
                    {!!file && <UploadedFileName>{file.name}</UploadedFileName>}
                    <ErrorMessage
                        className={showError && !file ? 'validation-message-visible' : ''}
                        style={{ top: '-5.5em' }}
                    >
                        No file uploaded
                    </ErrorMessage>
                    <ActionButton size="large" onClick={() => onSendMessage('paypal')}>
                        Submit
                    </ActionButton>
                </PaypalMessageSection>
            )}
            {props.dialogType === 'message' && (
                <NewMessageSection>
                    <PlainTextArea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onFinishTyping={onFinishTyping}
                        maxLength={2000}
                        showCharsLeft
                        status={showError ? 'error' : 'normal'}
                        placeholder="Leave a message regarding requested refund"
                    />
                    <ErrorMessage className={showError ? 'validation-message-visible' : ''}>
                        Message cannot be left blank
                    </ErrorMessage>
                    <ActionButton size="large" onClick={() => onSendMessage('buyer')} style={{ marginTop: '1.5em' }}>
                        Send Message
                    </ActionButton>
                </NewMessageSection>
            )}
            <Permission name="partner_admin" accountSlug={props.accountSlug}>
                {props.dialogType === 'details' && props.dispute.status !== 'RESOLVED' && (
                    <ButtonsSection>
                        {props.dispute.reason !== 'UNAUTHORISED' && (
                            <ActionButton
                                size="large"
                                kind="action"
                                secondary
                                onClick={() => props.onOpenModal(props.dispute.id, 'message')}
                            >
                                Send message
                            </ActionButton>
                        )}
                        <ActionButton
                            size="large"
                            kind="action"
                            secondary
                            onClick={() => props.onOpenModal(props.dispute.id, 'refund')}
                            style={{ marginLeft: '1em' }}
                        >
                            Issue refund
                        </ActionButton>
                        {props.dispute.reason === 'UNAUTHORISED' && (
                            <ActionButton
                                size="large"
                                kind="action"
                                secondary
                                onClick={() => props.onOpenModal(props.dispute.id, 'reject')}
                                style={{ marginLeft: '1em' }}
                            >
                                Reject dispute
                            </ActionButton>
                        )}
                    </ButtonsSection>
                )}
            </Permission>
        </Container>
    )
}
