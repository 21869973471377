import * as React from 'react'
import styled from 'styled-typed'
import { ActionButton, ButtonKind } from 'uiComponents/buttons'
import { Col, Row } from 'uiComponents/flex'
import Svg from 'svg'
import { useMessages } from 'messagesContext'

export type MessageKind = 'error' | 'success' | 'warn' | 'unknown' | 'info'

const Icon = styled(Svg)`
    height: 3em;
`

interface PageTopMessageProps {
    children: React.ReactNode
    showMessage: boolean
    onDismiss?: () => void
}

interface ContainerProps {
    kind: MessageKind
    paddingRight?: boolean
}

export const HeaderMessageContainer = styled.div`
    position: relative;
    z-index: 199;
`

export const MessageContainer = styled.div`
    position: absolute;
    top: 0.5em;
    left: 0;
    z-index: 99;
    width: 100%;
    > .message-visible {
        width: 80%;
        margin: auto;
    }
`

const Container = styled.div<ContainerProps>`
    font-size: 0.75em;
    padding-right: ${(props) => (props.paddingRight ? '2em' : '')};
    position: fixed;
    top: 2em;
    left: 50%;
    transform: translateX(-50%);
    max-width: 960px;
    background-color: #fff;
    border: 1px solid ${(props) => props.theme.colors.status[props.kind]};
    ${(props) => (props.kind === 'unknown' ? `border: 1px solid ${props.theme.colors.textLight};` : null)}
    border-radius: 0.625em;
    box-shadow: 0 1px 15px 0 rgba(35, 48, 57, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in, visibility 0.3s 0.3s;

    &.message-visible {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
    }
`

const RelativeContainer = styled(Container)`
    position: relative;
    left: 0%;
    top: 0;
    transform: translateX(0%);
    max-width: unset;
`

const ContainerBackground = styled(Container)<ContainerProps>`
    height: 100%;
    width: 100%;
    max-width: unset;
    top: 0em;
    border: none;
    background-color: ${(props) => props.theme.colors.status[props.kind]};
    ${(props) => (props.kind === 'unknown' ? `background-color: ${props.theme.colors.tableRow};` : null)}
    z-index: -1;

    &.message-visible {
        opacity: 0.1;
        transition: 0.3s ease-in;
    }
`
const Content = styled.div`
    height: 100%;
    max-width: 100%;
    padding: 0.6em 1em 0.6em 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.107em;
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &.message-visible {
        opacity: 1;
    }
`
const CloseButton = styled.div`
    position: absolute;
    right: 0.5em;
    top: 0.1em;
    opacity: 0.5;
    font-size: 1.5em;
    font-weight: 300;
    cursor: pointer;
    transition: opacity 0.2s ease-in;

    &:hover {
        opacity: 1;
    }
`

export function PageTopMessage({
    showMessage,
    kind,
    onDismiss,
    children,
    style,
}: PageTopMessageProps & ContainerProps & React.InputHTMLAttributes<HTMLElement>) {
    return (
        <Container
            kind={kind}
            className={showMessage ? 'message-visible' : ''}
            style={style}
            paddingRight={!!onDismiss}
        >
            <ContainerBackground kind={kind} className={showMessage ? 'message-visible' : ''} />
            <CloseButton onClick={onDismiss}>&times;</CloseButton>
            <Content className={showMessage ? 'message-visible' : ''}>
                <div style={{ width: '100%' }}>{children}</div>
            </Content>
        </Container>
    )
}

export function RelativeMessage({
    showMessage,
    kind,
    onDismiss,
    children,
    style,
}: PageTopMessageProps & ContainerProps & React.InputHTMLAttributes<HTMLElement>) {
    return (
        <RelativeContainer kind={kind} className={showMessage ? 'message-visible' : ''} style={style}>
            <ContainerBackground kind={kind} className={showMessage ? 'message-visible' : ''} />
            {!!onDismiss && <CloseButton onClick={onDismiss}>&times;</CloseButton>}
            <Content className={showMessage ? 'message-visible' : ''}>
                <div style={{ width: '100%' }}>{children}</div>
            </Content>
        </RelativeContainer>
    )
}

interface MessageButtonType {
    kind: ButtonKind
    href?: string
    buttonText: string
    postButtonText?: string
    onClick?: () => void
    secondary?: boolean
}

export type MessageButton = MessageButtonType | null

export interface Message {
    id: string
    status: MessageKind
    text: string
    visible: boolean
    actionButton?: MessageButton
    type?: 'inline' | 'snackbar'
    tag?: 'EngageTools' | 'general'
    header?: string
    data?: any
}

interface MessageProps {
    className?: string
    style?: React.CSSProperties
    stacked?: boolean
}

export function Messages({ className, style, stacked }: MessageProps) {
    const { messages, hideMessage } = useMessages()

    return (
        <HeaderMessageContainer className={className} style={style}>
            {messages.map((m, i) => (
                <PageTopMessage
                    key={m.id}
                    kind={m.status}
                    showMessage={m.visible}
                    onDismiss={() => hideMessage(m.id)}
                    style={stacked ? { top: `${4 * i + 1}em` } : {}}
                >
                    {m.text}
                    {m.actionButton && (
                        <>
                            <ActionButton
                                kind={m.actionButton.kind}
                                style={{ margin: '.3em .7em 0 .7em' }}
                                size="small"
                                onClick={m.actionButton.onClick}
                                secondary={!!m.actionButton.secondary}
                            >
                                {m.actionButton.buttonText}
                            </ActionButton>
                            {m.actionButton.postButtonText}
                        </>
                    )}
                </PageTopMessage>
            ))}
        </HeaderMessageContainer>
    )
}

const WideMessage = styled(RelativeMessage)`
    padding: 3em;
`

const TitleText = styled.div`
    text-transform: uppercase;
    text-align: left;
`

interface MessageContainerProps {
    children: React.ReactNode
    title?: string
    iconSrc?: SVGAElement
    onDismiss?: () => void
    status: MessageKind
}

export function StatusMessageContainer({ children, title, onDismiss, status, iconSrc }: MessageContainerProps) {
    return (
        <WideMessage kind={status} showMessage={true} onDismiss={onDismiss} style={{ padding: '1em' }}>
            {title && <TitleText>{title}</TitleText>}
            <Row style={{ textAlign: 'left', padding: '1.7em 2em 0 2em' }}>
                <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                    {children}
                </Col>
                {iconSrc && (
                    <Col span={6} style={{ textAlign: 'center' }}>
                        <Icon src={iconSrc} />
                    </Col>
                )}
            </Row>
        </WideMessage>
    )
}
