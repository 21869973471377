import React, { useState, useEffect, useContext } from 'react'
import { State } from 'store'
import { connect } from 'react-redux'
import { format, startOfToday } from 'date-fns'
import { match as RouteMatch } from 'react-router-dom'
import { History, Location } from 'history'
import { delay } from 'utils'
import { usePrevious } from 'reactUtils'
import { Navigation } from 'navigation'
import { withNavigation } from 'hocs'
import { HorizontalLine } from 'uiComponents/pageElements'
import { PageLoader } from 'uiComponents/loaders'
import Infotip from 'uiComponents/infotip'
import { Account } from 'auth/state'
import { MessageKind } from 'uiComponents/messages'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { ActionButtonA } from 'uiComponents/buttons'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { PageActions, ProductContainerBody } from 'uiComponents/settingsContainer'
import { ValidityException, PricingType } from 'products/articleConfigurationService'
import { ValidationMessage } from 'products/pricing/articlePricingService'
import { PricingServiceContext } from 'products/pricing/context'
import { ArticleConfigurationServiceContext } from 'products/context'
import { ArticleServiceContext } from 'admin/context'
import ValidityDetailsForm from './articleDetailForm'
import ExceptionsTable from './exceptions/exceptionsList'
import {
    getEndDate,
    getStartDate,
    transformPricesToCalendarData,
    Price,
    generateUnavailableDates,
} from 'products/utils'
import { ProductListWithPath, iterateProductLists } from 'products/crud/common'
import { AppServices } from 'middleware'
import { FormWrapper } from 'uiComponents/form/form'
import { ArticleDetailFormSchema } from './articleDetailFormValidation'
import { formatISOString, parseDate } from 'utils/dates'

interface ArticleDetailsPageParams {
    accountSlug: string
    id: string
}

interface ArticleDetailsPageProps {
    history: History
    location: Location
    match: RouteMatch<ArticleDetailsPageParams>
    navigation: Navigation
    hasFeature: (feature: string, slug: string) => boolean
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hideTopMessage: (id: string) => void
    className?: string
    viewMode: 'flat' | 'nested' | ''
    accounts: Account[]
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
}

export interface ArticleState {
    name: string
    validFrom: string
    validTo: string
    showFrom: string
    showTo: string
    enabled: boolean
    exceptions: ValidityException[]
    pricingType: PricingType
}

const initialArticleState: ArticleState = {
    name: '',
    validFrom: '',
    validTo: '',
    showFrom: '',
    showTo: '',
    enabled: false,
    exceptions: [],
    pricingType: 'upsell',
}

function ArticleDetailsPage(props: ArticleDetailsPageProps) {
    const accountSlug = props.match.params.accountSlug
    const productId = props.match.params.id
    const [article, setArticle] = useState<ArticleState>(initialArticleState)
    const [loading, setLoading] = useState<boolean>(true)
    const [calendarLoading, setCalendarLoading] = useState<boolean>(false)
    const [backendError, setBackendError] = useState<boolean>(false)
    const [endDateInvalid, setEndDateInvalid] = useState<boolean>(false)
    const [visibleEndDateInvalid, setVisibleEndDateInvalid] = useState<boolean>(false)
    const now = startOfToday()
    const [dateFrom, setDateFrom] = useState<Date>(now)
    const [dateTo, setDateTo] = useState<Date>(getEndDate(now))
    const [validationMessages, setValidationMessages] = useState<ValidationMessage[]>([])
    const [calendarData, setCalendarData] = useState<Price[] | null>(null)
    const [pricingSettings, setPricingSettings] = useState<any[]>([])
    const [categoryIds, setCategoryIds] = useState<string[]>([])
    const [allCategories, setAllCategories] = useState<ProductListWithPath[]>([])
    const [selectedCat, setSelectedCat] = useState<string>('')

    const articleService = useContext(ArticleServiceContext)
    const articleConfigurationService = useContext(ArticleConfigurationServiceContext)
    const pricingService = useContext(PricingServiceContext)

    useEffect(() => {
        async function getCategories() {
            try {
                const product = await articleService.fetchArticle(accountSlug, productId)
                setCategoryIds(product.productsListIds || [])
                setSelectedCat(product.productsListIds ? product.productsListIds[0] : '')
                const categories = await articleService.getProductLists(accountSlug)
                const flattened = iterateProductLists(categories, '', [])
                setAllCategories(flattened)
            } catch {
                props.replaceTopMessages(
                    'server_error',
                    'error',
                    'Oops! Failed fetching product categories. Please try again later.',
                )
                setBackendError(true)
            }
        }
        async function getValidityDetails() {
            props.setActiveSection('productsList')
            try {
                const a = await articleConfigurationService.getArticleValidity(accountSlug, productId)
                setArticle({
                    name: a.name,
                    validFrom: a.validFrom,
                    validTo: a.validTo,
                    showFrom: a.showFrom,
                    showTo: a.showTo,
                    enabled: a.enabled,
                    exceptions: a.validityOverrides,
                    pricingType: a.pricingType,
                })
            } catch {
                props.replaceTopMessages(
                    'server_error',
                    'error',
                    'Oops! Failed fetching product validity. Please try again later.',
                )
                setBackendError(true)
            } finally {
                setLoading(false)
            }
        }
        async function getPricingSettings() {
            try {
                const productPricingDetails = await pricingService.getProductPricing([productId])
                setPricingSettings(productPricingDetails)
            } catch {
                props.replaceTopMessages('server_error', 'error', 'Oops! An unknown error occured.')
                setBackendError(true)
            } finally {
                setLoading(false)
            }
        }
        getCategories()
        getValidityDetails()
        getPricingSettings()
        checkEndDateValidity()
    }, [])

    function checkEndDateValidity() {
        if (article.validFrom && article.validTo && new Date(article.validTo) < new Date(article.validFrom)) {
            setEndDateInvalid(true)
        } else {
            setEndDateInvalid(false)
            if (pricingSettings.length > 0 && !!article.validFrom) {
                fetchData()
            }
        }
    }

    const prevCat = usePrevious(selectedCat)
    const prevValidFrom = usePrevious(article.validFrom)
    const prevValidTo = usePrevious(article.validTo)
    const prevDateFrom = usePrevious(dateFrom)
    const prevDateTo = usePrevious(dateTo)
    useEffect(() => {
        if (
            prevCat !== selectedCat ||
            prevValidFrom !== article.validFrom ||
            prevValidTo !== article.validTo ||
            prevDateFrom !== dateFrom ||
            prevDateTo !== dateTo
        ) {
            checkEndDateValidity()
        }
    }, [article.validFrom, article.validTo, dateFrom, dateTo, selectedCat])

    useEffect(() => {
        const displayValidationMessages = () => {
            let messagesText = ''
            validationMessages.forEach((msg) => {
                messagesText = messagesText ? messagesText + '; ' + msg.message : msg.message
            })
            props.replaceTopMessages('calendar_warning', 'warn', messagesText)
        }
        if (validationMessages.length > 0) {
            displayValidationMessages()
        } else {
            props.hideTopMessage('calendar_warning')
        }
    }, [validationMessages])

    const prevAccountSlug = usePrevious(accountSlug)
    useEffect(() => {
        if (!!prevAccountSlug && prevAccountSlug !== accountSlug) {
            props.history.push(`/account/${props.match.params.accountSlug}/products/home/${props.viewMode}`)
        }
    }, [accountSlug])

    useEffect(() => {
        function checkVisibleEndDateValidity() {
            if (article.showFrom && article.showTo && new Date(article.showTo) < new Date(article.showFrom)) {
                setVisibleEndDateInvalid(true)
            } else {
                setVisibleEndDateInvalid(false)
            }
        }
        checkVisibleEndDateValidity()
    }, [article.showFrom, article.showTo])

    const fetchData = async () => {
        setCalendarLoading(true)

        try {
            const originalPrice = pricingSettings[0].originalPrice
            const minAcceptedPrice = pricingSettings[0].minAcceptedPrice
            const averageTargetPrice = pricingSettings[0].avgTargetPrice
            const stepSize = pricingSettings[0].stepSize
            const charmPricing = pricingSettings[0].charmPricing

            const productData = {
                id: productId,
                priceSettings: {
                    productId: productId,
                    account: accountSlug,
                    originalPrice: originalPrice,
                    avgTargetPrice: averageTargetPrice,
                    minAcceptedPrice: minAcceptedPrice,
                    stepSize: stepSize,
                    charmPricing: charmPricing,
                },
                override: {},
            }
            const availabilities = await articleConfigurationService.getAvailability(
                accountSlug,
                productId,
                selectedCat || null,
                dateFrom,
                dateTo,
                article.validFrom,
                article.validTo,
            )
            const dates = Object.keys(availabilities)
            let someDateAvailable = false
            dates.forEach((key) => {
                if (availabilities[key].available) {
                    someDateAvailable = true
                    return
                }
            })

            let calendarPrices: Price[] = []
            if (!someDateAvailable) {
                calendarPrices = generateUnavailableDates(dateFrom, dateTo)
            } else {
                const data = await AppServices.articlePricingService.getPricesForCalendar(
                    accountSlug,
                    selectedCat || null,
                    productData,
                    format(dateFrom, 'yyyy-MM-dd'),
                    format(dateTo, 'yyyy-MM-dd'),
                )
                setValidationMessages(data.validationMessages)

                calendarPrices = transformPricesToCalendarData(
                    data,
                    availabilities,
                    accountSlug,
                    props.formatCurrencyString,
                )
            }
            setCalendarData(calendarPrices)
            setCalendarLoading(false)
        } catch {
            props.replaceTopMessages('server_error', 'error', 'There was a problem fetching the calendar prices.')
            await delay(3000)
            props.hideTopMessage('server_error')
            setCalendarLoading(false)
        }
    }

    async function onSettingsSave(value: ArticleState) {
        props.hideTopMessage('server_error')
        if (
            !value.validFrom ||
            !value.validTo ||
            !value.showFrom ||
            !value.showTo ||
            endDateInvalid ||
            visibleEndDateInvalid
        ) {
            return
        }
        try {
            const validFrom = formatISOString(value.validFrom, 'yyyy-MM-dd')
            const validTo = formatISOString(value.validTo, 'yyyy-MM-dd')
            const showFrom = formatISOString(value.showFrom, 'yyyy-MM-dd')
            const showTo = formatISOString(value.showTo, 'yyyy-MM-dd')
            await articleConfigurationService.updateArticleValidityAndStatus(
                accountSlug,
                productId,
                validFrom,
                validTo,
                showFrom,
                showTo,
                article.enabled,
            )
            props.replaceTopMessages(
                'validity_settings_save_success',
                'success',
                'Validity date range was successfully saved..',
            )
            await delay(3000)
            props.hideTopMessage('validity_settings_save_success')
        } catch (error) {
            props.replaceTopMessages('server_error', 'error', error.message)
            await delay(3000)
            props.hideTopMessage('server_error')
        }
    }

    function onMonthChange(month: Date) {
        setDateFrom(getStartDate(month))
        setDateTo(getEndDate(month))
    }

    return (
        <ProductContainerBody className={props.className} id="validity-product-detail-page">
            {!backendError && article.name && (
                <>
                    <FormWrapper
                        formId="product-validity-detail-form"
                        enableReinitialize
                        initialValues={{
                            name: article.name,
                            validFrom: article.validFrom ? parseDate(article.validFrom) : null,
                            validTo: article.validTo ? parseDate(article.validTo) : null,
                            showFrom: article.showFrom ? parseDate(article.showFrom) : null,
                            showTo: article.showTo ? parseDate(article.showTo) : null,
                        }}
                        onSubmit={(values) => {
                            onSettingsSave(values)
                        }}
                        validationSchema={ArticleDetailFormSchema}
                    >
                        <ValidityDetailsForm
                            history={props.history}
                            match={props.match}
                            accountSlug={accountSlug}
                            replaceTopMessages={props.replaceTopMessages}
                            removeAllMessages={props.removeAllMessages}
                            hideTopMessage={props.hideTopMessage}
                            onMonthChange={onMonthChange}
                            now={now}
                            categoryIds={categoryIds}
                            allCategories={allCategories}
                            showCalendar={
                                pricingSettings.length > 0 &&
                                article.pricingType !== 'upsell' &&
                                article.pricingType !== 'nyop'
                            }
                            calendarData={calendarData}
                            calendarLoading={calendarLoading}
                            viewMode={props.viewMode}
                            selectedCat={selectedCat}
                            onCategoryChange={setSelectedCat}
                        />
                    </FormWrapper>
                    <HorizontalLine />
                    <div style={{ display: 'flex', marginBottom: '1em', fontSize: '.85em' }}>
                        Product validity exceptions
                        <Infotip pointer="left" maxWidth="18em">
                            If you have overwritten your default product validity with exceptions they will be displayed
                            here
                        </Infotip>
                    </div>
                    {article.exceptions.length > 0 && (
                        <ExceptionsTable
                            articleConfigurationService={articleConfigurationService}
                            articleService={articleService}
                            accountSlug={accountSlug}
                            productId={productId}
                            setActiveSection={props.setActiveSection}
                            replaceTopMessages={props.replaceTopMessages}
                        />
                    )}
                    {article.exceptions.length === 0 && (
                        <>
                            <NoResultsRow text="This product's validity settings have no exceptions" />
                            <PageActions>
                                <ActionButtonA
                                    id="newException"
                                    size="large"
                                    href={`/account/${accountSlug}/products/validity/exceptions/create`}
                                >
                                    Add exception
                                </ActionButtonA>
                            </PageActions>
                        </>
                    )}
                </>
            )}
            {loading && <PageLoader />}
        </ProductContainerBody>
    )
}

const mapStateToProps = (state: State) => {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}

export default withCurrency(withNavigation(connect(mapStateToProps)(ArticleDetailsPage)))
