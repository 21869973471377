import React, { useState, useEffect } from 'react'
import { useAccountChange } from 'reactUtils'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Location, History } from 'history'
import { CRMService } from 'crm/crmService'
import ProfileDetailsPage from './detailsPage'
import ProfileTimelinePage from './timelinePage'
import { Headline } from 'uiComponents/typography'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { Container, ContainerBody, ContainerHeading, HeadingSectionName } from 'uiComponents/settingsContainer'
import { NavigationBack } from 'uiComponents/navigation/navigationBack'
import { CustomerDetails, Activity, GroupedActivities } from 'crm/schema'
import { formatISOString } from 'utils/dates'
import { useMessages } from 'messagesContext'

const dummyDetails: CustomerDetails = {
    cookieId: '',
    activity: [],
    profile: null,
}

interface CRMHomeProps {
    history: History
    location: Location
    accountSlug: string
    crmService: CRMService
    navigation: Navigation
    match: RouteMatch<{ cookieId: string }>
}

function ProfileDetailsHome(props: CRMHomeProps) {
    const [activeSection, setActiveSection] = useState<'home' | 'timeline'>('home')
    const [loading, setLoading] = useState<boolean>(true)
    const [details, setDetails] = useState<CustomerDetails>(dummyDetails)
    const [groupedActivities, setGroupedActivities] = useState<GroupedActivities[]>([])
    const { replaceMessages } = useMessages()
    
    async function getData() {
        try {
            setLoading(true)
            const data = await props.crmService.getProfileDetails(props.accountSlug, props.match.params.cookieId)
            const activityGroups = getGroupedActivities(data.activity)
            setGroupedActivities(activityGroups)
            setDetails(data)
        } catch {
            replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useAccountChange(props.accountSlug, () => props.history.push(`/account/${props.accountSlug}/crm/profiles/list`))

    const sortActivities = (a: Activity, b: Activity) => {
        if (a.activityStartTime < b.activityStartTime) {
            return 1
        }
        if (a.activityStartTime > b.activityStartTime) {
            return -1
        }
        return 0
    }

    const getGroupedActivities = (activityList: Activity[]) => {
        activityList.sort(sortActivities)
        const groups: { [key: string]: Activity[] }[] = []
        activityList.forEach((a) => {
            const month = formatISOString(a.activityStartTime, 'MMMM yyyy')
            if (groups[month]) {
                groups[month].push(a)
            } else {
                groups[month] = [a]
            }
        })
        return groups
    }

    const backToList = () => {
        props.history.push(`/account/${props.accountSlug}/crm/profiles/list${location.search}`)
    }

    return (
        <div style={{ position: 'relative' }}>
            <Headline size={1} style={{ marginBottom: '1.5em' }}>
                Profiles
            </Headline>
            <NavigationBack onClick={backToList} text="Back to list" topOffset="-2.5em" />
            <Container>
                <ContainerHeading>
                    <HeadingSectionName
                        className={activeSection === 'home' ? 'active' : ''}
                        onClick={() => setActiveSection('home')}
                        to={`/account/${props.accountSlug}/crm/profiles/details/${props.match.params.cookieId}/home${location.search}`}
                    >
                        User info
                    </HeadingSectionName>
                    <HeadingSectionName
                        className={activeSection === 'timeline' ? 'active' : ''}
                        onClick={() => setActiveSection('timeline')}
                        to={`/account/${props.accountSlug}/crm/profiles/details/${props.match.params.cookieId}/timeline${location.search}`}
                    >
                        Timeline
                    </HeadingSectionName>
                </ContainerHeading>
                <Switch location={props.location}>
                    <Route
                        path="/account/:accountSlug/crm/profiles/details/:cookieId/home"
                        render={() => (
                            <ProfileDetailsPage
                                accountSlug={props.accountSlug}
                                loading={loading}
                                details={details}
                                onSectionChange={() => setActiveSection('home')}
                            />
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/crm/profiles/details/:cookieId/timeline"
                        render={() => (
                            <ContainerBody>
                                <ProfileTimelinePage
                                    loading={loading}
                                    activities={details.activity.sort(sortActivities)}
                                    groupedActivities={groupedActivities}
                                    accountSlug={props.accountSlug}
                                    onSectionChange={() => setActiveSection('timeline')}
                                />
                            </ContainerBody>
                        )}
                    />
                    <Route
                        render={() => (
                            <Redirect
                                to={`/account/${props.accountSlug}/crm/profiles/details/${props.match.params.cookieId}/home`}
                            />
                        )}
                    />
                </Switch>
            </Container>
        </div>
    )
}

export default withNavigation(ProfileDetailsHome)
