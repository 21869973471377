import * as React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import styled from 'styled-typed'
import { format } from 'date-fns'
import { withFeatures, useHasFeature } from 'features'
import { usePermission } from 'admin/hocs'
import { withNavigation } from 'hocs'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { Navigation } from 'navigation'
import { OrdersService } from 'orders/ordersService'
import { LoggingService } from 'http/loggingService'
import Money from 'uiComponents/money'
import { Cell, DataRow } from 'uiComponents/table'
import { MessageKind } from 'uiComponents/messages'
import { Checkbox } from 'uiComponents/input'
import { Text, StatusType } from 'uiComponents/typography'
import { MaskedData } from 'uiComponents/maskedData'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { UserField, statusDescriptors, ConfigUserField, OrderDetails, OrderItemOptions } from 'orders/schema'
import { Link, getPaymentDate } from 'orders/transactions/transactionRow'
import { getDiscountCodeString, DiscountCodesElement, OptionsElement, getOptionsTotal } from 'orders/helpers'
import UserInputField from './userInputField'
import { getLowestValidToDay } from 'orders/utils/getLowestValidToDay'
import { DateFormats, parseDate } from 'utils/dates'

const BarcodeStatus = styled.div`
    white-space: nowrap;
    color: ${(props) => props.theme.colors.status.warn};

    &.redeemed {
        color: ${(props) => props.theme.colors.status.success};
    }
`

interface OrderSummaryProps {
    detailsItem: OrderDetails
    userFieldList: ConfigUserField[]
    accountSlug: string
    loggingService: LoggingService
    ordersService: OrdersService
    reloadOrderList: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    onSelectBarcode: (event: React.ChangeEvent<HTMLInputElement>) => void
    selectedBarcodes: string[]
    updateApfItem: (barcode: string, userField: UserField, value: string) => void
    saveNewValidToDate: (orderId: string, orderItemId: string, date: Date, bundleOrderItemId: string) => void
    withTimeSlots: boolean
    showResellerColumn: boolean
    navigation: Navigation
    match: RouteMatch<{}>
    loading: boolean
    hasFeature: (feature: string, accountSlug: string) => boolean
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    getCurrency: (accountSlug: string) => Currency
}

function DetailsRow(props: OrderSummaryProps) {
    const {
        detailsItem,
        accountSlug,
        onSelectBarcode,
        selectedBarcodes,
        updateApfItem,
        withTimeSlots,
        showResellerColumn,
        userFieldList,
        loading,
        formatCurrencyString,
    } = props
    const { hasPermission } = usePermission()
    const getOptionsString = (options: OrderItemOptions[]) => {
        const stringList = options.map((o) => `${o.name} (${formatCurrencyString(getOptionsTotal([o]), accountSlug)})`)
        return stringList.join(', ')
    }

    const redeemStatus = detailsItem.redeemed
        ? format(detailsItem.redeemed, `${DateFormats.EXTRA_SHORT_DATE} - ${DateFormats.SHORT_TIME}`)
        : 'Not redeemed'
    const searchResult = !!props.navigation.query().q
    const extendOrderItemValidityFeatureFlag = useHasFeature('extendOrderItemValidity')
    const validityCanBeEdited =
        extendOrderItemValidityFeatureFlag &&
        hasPermission('edit_orders', props.accountSlug) &&
        detailsItem.status !== 'refunded' &&
        detailsItem.status !== 'refunding' &&
        !detailsItem.visitDate

    return (
        <DataRow className="details-row" narrow>
            <Cell align="center">
                {!!detailsItem.barcode &&
                    detailsItem.status !== 'refunded' &&
                    detailsItem.status !== 'refunding' &&
                    detailsItem.status !== 'cancelled' && (
                        <Checkbox
                            name={detailsItem.barcode}
                            checked={selectedBarcodes.indexOf(detailsItem.barcode) > -1}
                            onChange={onSelectBarcode}
                        />
                    )}
            </Cell>
            <Cell className="details-barcode-redeem-status">
                <BarcodeStatus className={detailsItem.redeemed ? 'redeemed' : ''}>{redeemStatus}</BarcodeStatus>
            </Cell>
            <Cell className="details-barcode-status">
                <Text status={statusDescriptors[detailsItem.status]?.style as StatusType}>
                    {statusDescriptors[detailsItem.status]?.text}
                </Text>
            </Cell>
            <Cell className="details-printed">
                {detailsItem.printed ? format(detailsItem.printed, DateFormats.LONG_DATE) : '-'}
            </Cell>
            <Cell className="details-order-id">
                <Link>
                    <UnstyledLink to={`/account/${props.accountSlug}/orders/transactions/?q=${detailsItem.orderId}`}>
                        {detailsItem.orderId}
                    </UnstyledLink>
                </Link>
            </Cell>
            <Cell className="details-email productName" title={detailsItem.orderEmail}>
                <span>
                    <MaskedData value={detailsItem.orderEmail} mask={!searchResult || loading} />
                </span>
            </Cell>
            <Cell className="details-product productName" title={detailsItem.product}>
                <span>{detailsItem.product}</span>
            </Cell>
            <Cell className="details-barcode-number" title={detailsItem.barcode || 'Not yet issued'}>
                <span>{detailsItem.barcode || 'Not yet issued'}</span>
            </Cell>
            <Cell className="details-amount">
                {detailsItem.bundle && 'bundle'}
                {!detailsItem.bundle && <Money amount={detailsItem.amountPaid.toFixed(2)} accountSlug={accountSlug} />}
            </Cell>
            <Cell className="options" title={getOptionsString(detailsItem.options)}>
                <span>
                    <OptionsElement options={detailsItem.options} accountSlug={props.accountSlug} />
                </span>
            </Cell>
            <Cell className="details-discount-code" title={getDiscountCodeString(detailsItem.discountCode)}>
                <span>
                    <DiscountCodesElement discountsString={detailsItem.discountCode} />
                </span>
            </Cell>
            <Cell className="details-payment-date">{getPaymentDate(detailsItem)}</Cell>
            <Cell className="details-visit-date">
                {detailsItem.visitDate ? format(detailsItem.visitDate, DateFormats.LONG_DATE) : 'Undated'}
            </Cell>
            {withTimeSlots && <Cell className="details-visit-time">{detailsItem.visitTime || 'Any time'}</Cell>}
            <Cell className="details-valid-from">
                {detailsItem.validFrom ? format(detailsItem.validFrom, DateFormats.LONG_DATE) : '-'}
            </Cell>
            <Cell className="details-valid-to">
                {detailsItem.validTo && (
                    <DatePicker
                        date={detailsItem.validTo ? parseDate(detailsItem.validTo) : null}
                        inlineEdit
                        modalDatePicker
                        lowerBoundary={getLowestValidToDay({
                            validFrom: detailsItem.validFrom,
                            validTo: detailsItem.validTo,
                        })}
                        onInlineEditAccept={(newDate) =>
                            props.saveNewValidToDate(
                                detailsItem.orderId,
                                detailsItem.orderItemId,
                                newDate,
                                detailsItem.bundleOrderItemId,
                            )
                        }
                        disabled={!validityCanBeEdited}
                        inlineEditDateFormat={DateFormats.LONG_DATE}
                    />
                )}
                {!detailsItem.validTo && '-'}
            </Cell>
            {showResellerColumn && (
                <Cell className="details-reseller productName" title={detailsItem.resellerName || ''}>
                    <span>{detailsItem.resellerName || '-'}</span>
                </Cell>
            )}
            <Cell className="details-marketing-opt-in">{detailsItem.marketingOptIn ? 'Yes' : 'No'}</Cell>
            {userFieldList.map((f, i) => (
                <UserInputField
                    key={f.name + detailsItem.barcode + i}
                    configField={f}
                    item={detailsItem}
                    updateApfItem={updateApfItem}
                    ordersService={props.ordersService}
                    replaceMessages={props.replaceMessages}
                    removeAllMessages={props.removeAllMessages}
                    accountSlug={accountSlug}
                    maskData={!searchResult}
                />
            ))}
            <Cell />
        </DataRow>
    )
}

export default withFeatures(withCurrency(withNavigation(DetailsRow)))
