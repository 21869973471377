import React from 'react'
import { Switch, Route, Redirect, useParams, useHistory, useLocation } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { BaseRouteParams, WithNavigationProps } from 'hocs'
import {
    Container,
    ContainerHeading,
    FullWidthContainerBody,
    HeadingSectionName,
    TopButtonWrapper,
} from 'uiComponents/settingsContainer'
import { ActionButtonA } from 'uiComponents/buttons'
import { PageTitle } from 'uiComponents/typography'
import DiscountRulesList from 'products/discountRules/table/list'
import DiscountRuleDetails from 'products/discountRules/form'
import MetadataContextState from './metadataContext'
import DiscountRulesContextState from './table/tableContext'
import RulesFormContextState from './form/formContext'
import { useMessages } from 'messagesContext'

interface DiscountRulesContainerProps extends WithNavigationProps<BaseRouteParams> {
}

function DiscountRulesContainer(props: DiscountRulesContainerProps) {
    const { replaceMessages } = useMessages()
    const { accountSlug } = useParams<BaseRouteParams>()
    const history = useHistory()
    const location = useLocation()
    
    const getPageTitle = () => {
        if (location.pathname.includes('/details/new')) {
            return 'Add new rule'
        } else if (location.pathname.includes('/details/')) {
            return 'Edit rule'
        } else {
            return 'Discount rules'
        }
    }

    return (
        <div style={{ position: 'relative' }}>
            <PageTitle>{getPageTitle()}</PageTitle>
            <TopButtonWrapper style={{ minHeight: '2em' }}>
                {location.pathname.includes('/home') && (
                    <ActionButtonA
                        href={`/account/${accountSlug}/products/discount_rules/details/new`}
                        kind="action"
                    >
                        + Add new
                    </ActionButtonA>
                )}
            </TopButtonWrapper>
            <Container>
                <ContainerHeading style={{ paddingLeft: '0.3em' }}>
                    <HeadingSectionName
                        className="active"
                        to={`/account/${accountSlug}/products/discount_rules/home`}
                    >
                        Discount Rules
                    </HeadingSectionName>
                </ContainerHeading>
                <MetadataContextState
                    match={props.match}
                    navigation={props.navigation}
                    history={history}
                    replaceMessages={replaceMessages}
                >
                    <Switch location={location}>
                        <Route
                            path="/account/:accountSlug/products/discount_rules/home"
                            render={() => (
                                <FullWidthContainerBody>
                                    <DiscountRulesContextState>
                                        <DiscountRulesList />
                                    </DiscountRulesContextState>
                                </FullWidthContainerBody>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/products/discount_rules/details/:ruleId"
                            render={() => (
                                <FullWidthContainerBody>
                                    <RulesFormContextState>
                                        <DiscountRuleDetails />
                                    </RulesFormContextState>
                                </FullWidthContainerBody>
                            )}
                        />
                        <Route
                            render={() => (
                                <Redirect to={`/account/${accountSlug}/products/discount_rules/home`} />
                            )}
                        />
                    </Switch>
                </MetadataContextState>
            </Container>
        </div>
    )
}

export default withNavigation(DiscountRulesContainer)
