import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { FeatureProps, withFeatures } from 'features'
import { usePermission } from 'admin/hocs'
import { State } from 'store'
import { Account, User } from 'auth/state'
import { Button } from '@mui/material'

interface AddOrderProps extends WithNavigationProps<BaseRouteParams>, FeatureProps {}

const AddOrder: FC<AddOrderProps> = ({ hasFeature, match }) => {
    const { accountSlug } = match.params
    const { hasPermission } = usePermission()
    const accounts = useSelector<State, Account[]>((state) => (state.auth.user ? state.auth.user.accounts : []))
    const user = useSelector<State, User | null>((state) => state.auth.user)

    const checkResellerHasRequiredPermissions = () => {
        const accountPermissions = user?.permissions.filter((a) => a.account === accountSlug)
        const permissionsList =
            accountPermissions && accountPermissions.length > 0 ? accountPermissions[0].permissions : []
        return permissionsList.indexOf('edit_orders') > -1 && permissionsList.indexOf('view_pricing_settings') > -1
    }

    const activeAccount = accounts.find((x) => x.slug === accountSlug) || accounts[0]
    const userIsReseller = !!user?.resellerId
    const showInlineCOToResellers =
        userIsReseller &&
        hasFeature('ResellersFeature', accountSlug) &&
        activeAccount.activeResellerContract &&
        checkResellerHasRequiredPermissions()
    const showInlineCOToRegularUsers =
        hasFeature('CPInlineCheckout', accountSlug) &&
        hasPermission('edit_orders', accountSlug) &&
        hasPermission('view_pricing_settings', accountSlug)
    const inlineCheckout = showInlineCOToResellers || showInlineCOToRegularUsers
    const hideNewOrderButton = userIsReseller && !showInlineCOToResellers

    const getLinkToCheckout = (account: Account) => {
        if (!!account.url) {
            const checkoutUrlParts = account.url.split('/')
            if (checkoutUrlParts[0].indexOf('http') < 0) {
                return `http://${account.url}#convious-control-panel`
            }
        }
        return `${account.url}#convious-control-panel`
    }

    const checkoutLink = getLinkToCheckout(activeAccount)
    const resellerWithMultipleAccounts = !!user && accounts.length > 1

    const inlineCheckoutLink =
        userIsReseller && resellerWithMultipleAccounts
            ? `/resellers/account/${accountSlug}/new_order/`
            : `/account/${accountSlug}/orders/reseller/new_order`

    if (hideNewOrderButton) {
        return <></>
    }

    return (
        <>
            {inlineCheckout ? (
                <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    data-testid="orders-add-order-button"
                    className="action-button"
                >
                    <UnstyledLink to={inlineCheckoutLink}>Add Order</UnstyledLink>
                </Button>
            ) : (
                <Button
                    size="medium"
                    href={checkoutLink}
                    target="_blank"
                    color="primary"
                    variant="contained"
                    className="action-button"
                >
                    Add Order
                </Button>
            )}
        </>
    )
}

export default withFeatures(withNavigation(AddOrder))
