import * as React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { PageTitle } from 'uiComponents/typography'
import { StatsService } from 'http/statsService'
import { dateRangeToQuery, DateRange } from 'dateRanges'
import TimeseriesChart from 'reports/discounts/timeseriesChart'
import { Row, Col } from 'uiComponents/flex'
import { chartRowsGap } from 'reports/helpers'
import SingleDataItems from 'reports/discounts/singleDataItems'
import { createTimeseriesBarChart, TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { areDateRangeDatesEqual, ReportsToolsWrapper, ReportUpdateInfo } from 'reports/helpers'
import { dateRangeFromQuery } from 'dateRanges'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { withFeatures } from 'features'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import VerticalBartChart from 'reports/discounts/verticalBarChart'
import PopularCodesChart from 'reports/discounts/comparisonBarChart'
import SearchField from 'uiComponents/search/searchWithButton'
import { Filters } from 'uiComponents/filter/schema'
import Disclaimer from 'reports/disclaimer'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import { useMessages } from 'messagesContext'

interface DiscountsReportsPageProps {
    navigation: Navigation
    match: RouteMatch<any>
    statsService: StatsService
    accountSlug: string
    backofficeEndpoint: string
    loginService: LoginService
    loggingService: LoggingService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function DiscountsReportsPage(props: DiscountsReportsPageProps) {
    const dateRange = dateRangeFromQuery(props.navigation.query())
    const [chart, setChart] = React.useState<TimeseriesBarChartSource>(createTimeseriesBarChart(dateRange))
    const { replaceMessages, hideMessage } = useMessages()
    
    React.useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const onDateRangeChanged = (range: DateRange) => {
        if (!areDateRangeDatesEqual(dateRange, range)) {
            props.navigation.addQueryWithReplace(dateRangeToQuery(range))
            setChart(createTimeseriesBarChart(range))
        }
    }

    const query = props.navigation.query()
    const codesFilters: Filters[] = query.q ? [{ attribute: 'discount_names', values: [query.q] }] : []

    return (
        <div id="reports-discounts-page" style={{ marginBottom: '7em' }}>
            <PageTitle data-userpilot="sales-header-tooltip">Discount Codes Report</PageTitle>
            <ReportUpdateInfo metric="Data" text="updated every 1 hour." />
            <ReportsToolsWrapper>
                <SearchField placeholder="Filter by discount code" hideExtendedSearch style={{ width: '18.5em' }} />
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    firstAvailableDay={new Date(2019, 0, 1)}
                />
            </ReportsToolsWrapper>
            <SingleDataItems
                filters={codesFilters}
                accountSlug={props.accountSlug}
                dateRange={dateRange}
                replaceMessages={replaceMessages}
            />
            <Row style={{ marginTop: chartRowsGap }}>
                <Col span={6}>
                    <PopularCodesChart
                        accountSlug={props.accountSlug}
                        dateRange={dateRange}
                        replaceMessages={replaceMessages}
                    />
                </Col>
                <Col span={6}>
                    <VerticalBartChart
                        filters={codesFilters}
                        accountSlug={props.accountSlug}
                        dateRange={dateRange}
                        replaceMessages={replaceMessages}
                    />
                </Col>
            </Row>
            <TimeseriesChart
                filters={codesFilters}
                accountSlug={props.accountSlug}
                dateRange={dateRange}
                chart={chart}
                backofficeEndpoint={props.backofficeEndpoint}
                loginService={props.loginService}
                loggingService={props.loggingService}
                replaceTopMessages={replaceMessages}
                hideMessage={hideMessage}
                groupByLocation
            />
            <BaseKnowledgeLink link="https://support.convious.com/help/how-to-use-your-discount-code-report" />
            <Disclaimer />
        </div>
    )
}

export default withFeatures(withNavigation(DiscountsReportsPage))
