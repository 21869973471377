import * as React from 'react'
import ForecastSection from 'reports/forecast'
import { StatsService } from 'http/statsService'
import { PageTitle, PageHeading } from 'uiComponents/typography'
import { ReportUpdateInfo } from 'reports/helpers'
import { useMessages } from 'messagesContext'

interface ForecastPageProps {
    statsService: StatsService
    accountSlug: string
}

function ForecastPage(props: ForecastPageProps ) {
    const { replaceMessages } = useMessages()
    
    return (
        <>
            <PageTitle>Forecast Report</PageTitle>
            <PageHeading fullWidth>
                <div>
                    Please note that in our forecasting procedure, we take into account both dated single-visit tickets
                    and reservations to predict the daily number of visitors. A reliable forecast necessitates a minimum
                    of 50 visitors per day. A short-term forecast (7 days) is generated with a minimum of 28 days of
                    data, while a long-term forecast (4 weeks) requires at least 3 months of visitor data.
                </div>
            </PageHeading>
            <div style={{ display: 'flex' }}>
                <ReportUpdateInfo metric="Visitors forecast" text="updated once a day." />
                <ReportUpdateInfo metric="Ticket sales data" text="updated every 20 minutes." />
            </div>
            <ForecastSection
                statsService={props.statsService}
                accountSlug={props.accountSlug}
                replaceMessages={replaceMessages}
            />
        </>
    )
}

export default ForecastPage
