import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faLaptop, faMobileAndroidAlt, faPhoneLaptop } from '@fortawesome/pro-solid-svg-icons'
import { usePermission } from 'admin/hocs'
import { useFetchAudiences } from 'crm/audiences/hooks/useFetchAudiences'
import { DateRange, dateRangeToQuery } from 'dateRanges'
import Filters from 'engageTools/filters'
import { ROUTE_PATHS } from 'engageTools/routePaths'
import { ComponentsService, ConfigurationVersions, ProductName } from 'engageTools/studio/componentsService'
import { withFeatures } from 'features'
import { History, Location } from 'history'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import React, { useEffect, useState } from 'react'
import { match as RouteMatch, Redirect, Route, Switch } from 'react-router-dom'
import { getDataFromQuery } from 'reports/helpers'
import styled from 'styled-typed'
import { TapPlacementService } from 'tapPlacements/service'
import { PromotionService } from 'tapPromotions/types'
import { Card } from 'uiComponents/cards'
import { FilterCategory } from 'uiComponents/filter/schema'
import { Col } from 'uiComponents/flex'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { Container, ContainerHeading, HeadingSectionName } from 'uiComponents/settingsContainer'
import { PageTitle } from 'uiComponents/typography'
import DeleteConfirmation from './deleteConfirmation'
import DuplicatesModalRoute from './duplicateModal'
import PlacementTable from './placements'
import Promotions from './promotions'
import Table from './table'
import { useMessages } from 'messagesContext'

export const targetDevices = [
    {
        id: 'any',
        icon: faPhoneLaptop as IconProp,
    },
    {
        id: 'desktop',
        icon: faLaptop as IconProp,
    },
    {
        id: 'mobile',
        icon: faMobileAndroidAlt as IconProp,
    },
    {
        id: 'total',
        icon: faPhoneLaptop as IconProp,
    },
]

export const BORDER_RADIUS = '0.375em'

const FilterContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-bottom: 0.5em;
`

export const ContainerHeader = styled.div`
    background-color: ${(props) => props.theme.colors.tableRow};
    padding: 0.4em 1.875rem;
    margin-bottom: 0.9em;
`

export const CampaignContainer = styled(Card)`
    background: ${(props) => props.theme.colors.white};
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    position: relative;
    border: none;
    &:not(:nth-child(3)) {
        border-bottom: 2px solid ${(props) => props.theme.colors.tableRow};
    }
    &:first-child {
        border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0;
        > ${ContainerHeader} {
            border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0;
        }
    }
    &:last-child {
        border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
    }
`

export const DeviceInfo = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.7em;
`
export const DeviceName = styled.span`
    text-transform: capitalize;
`

export const EditSection = styled(Col)`
    display: flex;
    align-self: baseline;
    align-items: center;
    justify-content: flex-end;
`

interface CampaignsPageProps {
    location: Location
    history: History
    navigation: Navigation
    hasFeature: (feature: string, accountSlug: string) => boolean
    match: RouteMatch<{}>
    accountSlug: string
    componentsService: ComponentsService
    placementService: TapPlacementService
    promotionService: PromotionService
}

function CampaignsPage(props: CampaignsPageProps) {
    const { hasPermission } = usePermission()
    const [activeSection, setActiveSection] = useState<ProductName | ''>('')
    const [dateRangeApplied, setDateRangeApplied] = useState<boolean>(false)
    const [tools, setTools] = useState<{
        [key: string]: ConfigurationVersions[]
    } | null>(null)
    const { dateRange } = getDataFromQuery(props.navigation.query())
    const { fetch: fetchAudiences } = useFetchAudiences()
    const { hideMessage, removeAllMessages, replaceMessages } = useMessages()
    
    useEffect(() => {
        fetchAudiences()
    }, [fetchAudiences])

    const HEADER_ITEMS = () => {
        const items = [
            {
                id: 'checkout-tab',
                className: 'checkout',
                path: `/account/${props.accountSlug}/engage/tools/home/checkout${location.search}`,
                name: 'Checkouts',
            },
            {
                id: 'trigger-button-tab',
                className: 'trigger_button',
                path: `/account/${props.accountSlug}/engage/tools/home/trigger_button${location.search}`,
                name: 'Trigger Buttons',
            },
            {
                id: 'modal-tab',
                className: 'modal_window',
                path: `/account/${props.accountSlug}/engage/tools/home/pop_up${location.search}`,
                name: 'Pop Ups',
            },
            {
                id: 'wonderbar-tab',
                className: 'wonderbar',
                path: `/account/${props.accountSlug}/engage/tools/home/wonderbar${location.search}`,
                name: 'Wonderbars',
            },
            {
                id: 'placements-tab',
                className: 'placements',
                path: `/account/${props.accountSlug}/engage/tools/home/placements${location.search}`,
                name: 'App Placements',
            },
        ]

        if (props.hasFeature('TapPromotions', props.accountSlug)) {
            items.push({
                id: 'promotions-tab',
                className: 'promotions',
                path: `/account/${props.accountSlug}/engage/tools/home/promotions${location.search}`,
                name: 'App Promotions',
            })
        }

        return items
    }

    useEffect(() => {
        let section: ProductName = 'checkout'
        if (location.pathname.indexOf('trigger_button') > -1) {
            section = 'trigger_button'
        } else if (location.pathname.indexOf('pop_up') > -1) {
            section = 'modal_window'
        } else if (location.pathname.indexOf('wonderbar') > -1) {
            section = 'wonderbar'
        } else if (location.pathname.indexOf('placements') > -1) {
            section = 'placements'
        } else if (location.pathname.indexOf('promotions') > -1) {
            section = 'promotions'
        }
        setActiveSection(section)
    }, [location.pathname])

    const mergeTools = (type: ProductName, configurations: ConfigurationVersions[]) => {
        const allTools = { ...tools }
        allTools[type] = configurations
        setTools(allTools)
    }

    const onDateRangeChanged = (range: DateRange) => {
        props.navigation.addQueryWithReplace(dateRangeToQuery(range))
    }

    const getFiltersToApply = () => {
        const filters: FilterCategory[] = ['tool_status']
        const placements = activeSection === 'placements'
        const showPlacementsAudiences = props.hasFeature('CRMAudiencesPage', props.accountSlug)
        if (!placements) {
            filters.push('device_type')
        }
        if (placements && showPlacementsAudiences) {
            filters.push('audience')
        }
        return filters
    }

    const canEditCampaigns = hasPermission('edit_wonderbar', props.accountSlug)
    const filtersToApply = getFiltersToApply()

    return (
        <div id="engage-tools-page" style={{ position: 'relative', maxWidth: '1200px' }}>
            <PageTitle>Engage Tools</PageTitle>
            <FilterContainer>
                <Filters
                    accountSlug={props.accountSlug}
                    dateRange={dateRange}
                    applicableFilters={filtersToApply}
                    standaloneFilters={filtersToApply}
                    replaceMessages={replaceMessages}
                    hideMessage={hideMessage}
                />
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    userpilot="engage-tools-date-range"
                    firstAvailableDay={new Date(2019, 0, 1)}
                    dateRangeToggler
                    onEnableDateRange={setDateRangeApplied}
                    dateRangeOn={dateRangeApplied}
                    allowFutureDateSelection
                />
            </FilterContainer>
            {activeSection && (
                <Container>
                    <ContainerHeading style={{ paddingLeft: '1.5em' }}>
                        {HEADER_ITEMS().map((headerItem) => (
                            <HeadingSectionName
                                key={headerItem.id}
                                id={headerItem.id}
                                className={activeSection === headerItem.className ? 'active' : ''}
                                to={headerItem.path}
                            >
                                {headerItem.name}
                            </HeadingSectionName>
                        ))}
                    </ContainerHeading>
                    <Switch location={props.location}>
                        <Route
                            path="/account/:accountSlug/engage/tools/home/promotions"
                            render={() => (
                                <Promotions
                                    accountSlug={props.accountSlug}
                                    history={props.history}
                                    navigation={props.navigation}
                                    promotionService={props.promotionService}
                                    replaceMessages={replaceMessages}
                                    removeAllMessages={removeAllMessages}
                                />
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/tools/home/placements"
                            render={() => (
                                <PlacementTable
                                    accountSlug={props.accountSlug}
                                    history={props.history}
                                    placementService={props.placementService}
                                    replaceMessages={replaceMessages}
                                    removeAllMessages={removeAllMessages}
                                    navigation={props.navigation}
                                    componentsService={props.componentsService}
                                    canEditCampaigns={canEditCampaigns}
                                    dateRangeApplied={dateRangeApplied}
                                />
                            )}
                        />

                        <Route path={ROUTE_PATHS.engageToolList}>
                            <Table
                                accountSlug={props.accountSlug}
                                componentsService={props.componentsService}
                                tools={(tools && tools[activeSection]) || []}
                                setTools={(t: ConfigurationVersions[]) => mergeTools(activeSection, t)}
                                replaceMessages={replaceMessages}
                                removeAllMessages={removeAllMessages}
                                type={activeSection}
                                history={props.history}
                                navigation={props.navigation}
                                canEditCampaigns={canEditCampaigns}
                                dateRangeApplied={dateRangeApplied}
                                placementService={props.placementService}
                            >
                                <Switch>
                                    <Route
                                        path={ROUTE_PATHS.duplicateModal}
                                        render={({
                                            match: {
                                                params: { id },
                                            },
                                        }) => <DuplicatesModalRoute type={activeSection} id={id as string} />}
                                    />
                                    <Route
                                        path={ROUTE_PATHS.deleteModal}
                                        render={({
                                            match: {
                                                params: { id },
                                            },
                                        }) => <DeleteConfirmation type={activeSection} id={id as string} />}
                                    />
                                </Switch>
                            </Table>
                        </Route>

                        <Route
                            render={() => <Redirect to={`/account/${props.accountSlug}/engage/tools/home/checkout`} />}
                        />
                    </Switch>
                </Container>
            )}
        </div>
    )
}

export default withFeatures(withNavigation(CampaignsPage))
