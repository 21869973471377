import { Reducer, combineReducers } from '@reduxjs/toolkit'
import { adminApi } from 'admin/reduxQuery'

import { authReducer } from 'auth/authReducer'
import { connectRouter } from 'connected-react-router'
import { reducer as audienceReducer, reducerPath as audienceReducerPath } from 'crm/audiences/reduxQuery'
import { crmReducer } from 'crm/state'
import { reducer as engageToolsReducer, reducerPath as engageToolsPath } from 'engageTools/reduxApi'
import { featureReducer } from 'features/featureReducer'
import { History } from 'history'
import { imageApi, statsApi } from 'http/reduxQuery'

import orderReducer from 'orders/orders/redux'
import { reducer as orderApiReducer, reducerPath as ordersApiPath } from 'orders/reduxQueries'
import { userPrefsReducer } from 'preferences/reducer'
import { pricingApi } from 'products/pricing/reduxQueries'
import { reducer as articleReducer, reducerPath as articlePath } from 'products/reduxApi'
import {
    reducer as pushNotificationsReducer,
    reducerPath as pusNotificationsReducerPath,
} from 'pushNotifications/reduxQuery'
import { profileReducer } from 'settings/profileReducer'
import { reducer as integrationReducer, reducerPath as integrationPath } from 'settings/reduxQuery'
import sideBarReducer from 'sidebar/reducer'
import { messagesReducer } from 'uiComponents/messages/reducer'
import { popupsReducer } from 'uiComponents/popups/popupsReducer'
import { reducer as parkMapReducer, reducerPath as parkMapReducerPath } from 'venue/parkMap/reduxQuery'
import venueReducer from 'venue/redux'
import { reducer as codesReducer, reducerPath as codesReducerPath } from 'venue/reduxQuery'
import { supersetApi } from 'reports/supersetQuery'
import contractsReducer, { name as contracts } from 'settings/accountSettings/contract/redux'
import {
    reducer as taxConfigurationsReducer,
    reducerPath as taxConfigurationsApiPath,
} from 'settings/accountSettings/taxConfigurations/rtkQuery'
import {
    reducer as bookingFeesReducer,
    reducerPath as bookingFeesApiPath,
} from 'settings/accountSettings/bookingFees/reduxQuery'
import capacityReportReducer from 'venue/capacityReport/state/reduxQuery'

const sortReducers = <T extends Record<string, Reducer>>(reducers: T): T => {
    return Object.keys(reducers)
        .sort()
        .reduce((sortedReducers, key) => {
            sortedReducers[key] = reducers[key]
            return sortedReducers
        }, {}) as T
}

export const allReducers = (history: History) => {
    const reducers = {
        auth: authReducer,
        features: featureReducer,
        preferences: userPrefsReducer,
        profile: profileReducer,
        popups: popupsReducer,
        messages: messagesReducer,
        crm: crmReducer,
        sidebar: sideBarReducer,
        [contracts]: contractsReducer,
        [articlePath]: articleReducer,
        [engageToolsPath]: engageToolsReducer,
        [ordersApiPath]: orderApiReducer,
        [taxConfigurationsApiPath]: taxConfigurationsReducer,
        [bookingFeesApiPath]: bookingFeesReducer,
        orderTable: orderReducer,
        [codesReducerPath]: codesReducer,
        venue: venueReducer,
        [integrationPath]: integrationReducer,
        router: connectRouter(history),
        [parkMapReducerPath]: parkMapReducer,
        [pricingApi.reducerPath]: pricingApi.reducer,
        [statsApi.reducerPath]: statsApi.reducer,
        [imageApi.reducerPath]: imageApi.reducer,
        [adminApi.reducerPath]: adminApi.reducer,
        [audienceReducerPath]: audienceReducer,
        [pusNotificationsReducerPath]: pushNotificationsReducer,
        [supersetApi.reducerPath]: supersetApi.reducer,
        capacityReport: capacityReportReducer,
    }

    const sortedReducers = sortReducers(reducers)

    return combineReducers(sortedReducers)
}
