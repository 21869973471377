import React from 'react'
import { getListOrderData, getSelectedOrderDetails, getSelectedOrdersList } from 'orders/orders/redux'
import { useMemo } from 'react'
import Permission from 'admin/permissionRequired'
import { BaseRouteParams, WithNavigationProps, withNavigation } from 'hocs'
import BulkCancelRefundButton from './bulk/button'
import SingleCancelRefundButton from './single/button'
import { useAppSelector } from 'store/hooks'
import { ReplaceMessagesFunc } from 'messagesContext'

interface CancelRefundProps extends WithNavigationProps<BaseRouteParams> {
    replaceMessages: ReplaceMessagesFunc
}

const CancelRefund: React.FC<CancelRefundProps> = ({ match, replaceMessages }) => {
    const { accountSlug } = match.params
    const selectedOrderRowsIds = useAppSelector(getSelectedOrdersList)
    const currentData = useAppSelector(getListOrderData)?.data
    const selectedOrder = useAppSelector((state) => state.orderTable.selectedOrder)
    const selectedOrderItems = useAppSelector(getSelectedOrderDetails)
    const selectedItemsState = useAppSelector((state) => state.orderTable.selectedItems)

    const selectedRows = useMemo(() => {
        const ordersData = [...(currentData?.results ?? [])]
        if (selectedOrder && ordersData.length === 0) {
            ordersData.push(selectedOrder)
        }
        const rows = ordersData.filter((order) => selectedOrderRowsIds.includes(order.id))

        if (rows.length === 0 && selectedOrderItems.length > 0) {
            const orderIdFromSelectedItem = Object.keys(selectedItemsState).find((orderId) =>
                selectedItemsState[orderId].hasOwnProperty(selectedOrderItems[0].id),
            )
            const orderFromSelectedItem = ordersData.find((order) => order.id === orderIdFromSelectedItem)
            return orderFromSelectedItem ? [orderFromSelectedItem] : []
        }

        return rows
    }, [currentData, selectedOrderRowsIds, selectedOrder, selectedOrderItems])

    return (
        <>
            {selectedRows.length > 1 && (
                <>
                    <BulkCancelRefundButton
                        orders={selectedRows}
                        isCancellation={true}
                        replaceMessages={replaceMessages}
                    />
                    <BulkCancelRefundButton
                        orders={selectedRows}
                        replaceMessages={replaceMessages}
                    />
                </>
            )}
            <Permission name="refund_orders" accountSlug={accountSlug}>
                {(selectedRows.length === 1 || (selectedOrderItems.length > 0 && selectedRows.length <= 1)) && (
                    <>
                        <SingleCancelRefundButton
                            order={selectedRows[0]}
                            isCancellation={true}
                            replaceMessages={replaceMessages}
                        />
                        <SingleCancelRefundButton
                            order={selectedRows[0]}
                            replaceMessages={replaceMessages}
                        />
                    </>
                )}
            </Permission>
        </>
    )
}

export default withNavigation(CancelRefund)
