import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { startOfToday } from 'date-fns'
import { PlainLoader } from 'uiComponents/loaders'
import { withNavigation, WithNavigationProps } from 'hocs'
import { SingleSelect, SingleSelectFieldFormik, SingleSelectOption } from 'uiComponents/input'
import { useHistory } from 'react-router-dom'
import { ActionButton } from 'uiComponents/buttons/'
import { ButtonWrapper, TextWithHorizontalLine } from 'uiComponents/pageElements'
import { FeatureProps, withFeatures } from 'features'
import { CurrencyProps, withCurrency } from 'uiComponents/money/moneyHoc'
import { AvailabilityDates } from 'products/articleConfigurationService'
import { ArticleConfigurationServiceContext } from 'products/context'
import { delay } from 'utils'
import {
    generateUnavailableDates,
    getEndDate,
    getStartDate,
    Price,
    transformPricesToCalendarData,
} from 'products/utils'
import CalendarBlock from 'products/components/calendarBlock'
import { DEFAULT_STEP_SIZE, handleFailure, isAnyDateAvailable, priceStepSizeOptions } from '../common'
import { ProductListWithPath } from 'products/crud/common'
import { AppServices } from 'middleware'
import { useGetGlobalAccountSettingsQuery } from '../reduxQueries'
import { useGetAllCategoriesForSelect } from 'products/redux'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'
import FieldWrapper from 'uiComponents/input/fieldWrapper'
import { Field, FormikHelpers, FormikProps, withFormik } from 'formik'
import { Alert, ToggleButton } from '@mui/material'
import { ToggleButtonGroup } from 'formik-mui'
import { VariationHighSolid, VariationLowSolid, VariationMedSolid } from '@convious/icons'
import SlidersIcon from '@convious/icons/src/icons/solid/slidersSolid.svg'
import { PricingFormState, Rounding } from './types'
import { LoadingButton } from '@mui/lab'
import { getMinMaxValues } from './utils'
import { compose } from 'redux'
import { ValidationMessage } from '../articlePricingService'
import { CreatePricingSettingsError, PricingService, ProductData } from '../pricingService'
import { ExpandablePanel } from 'uiComponents/panels/expandablePanel'
import { usePermission } from 'admin/hocs'
import validationSchema from './validations'
import './pricingForm.scss'
import Infotip from 'uiComponents/infotip'
import { SwitchButtonFormik } from 'uiComponents/input/switch/switchButtonFormik'
import { isLocalStorageAvailable } from 'utils/storage'
import debounce from 'lodash/debounce'
import { useHasFeature } from 'utils/useHasFeature'
import { formatISOString } from 'utils/dates'
import { MessageProps } from 'messagesContext'

type PricingFormParams = {
    accountSlug: string
    id: string
}

type PricingFormProps = {
    productName: string | null
    pricingData: ProductData | null
    pricingService: PricingService
    allCategories: ProductListWithPath[]
    categoryIds: string[] | null
    viewMode: 'flat' | 'nested' | ''
    updateAfterSave: () => void
    replaceMessages: MessageProps['replaceMessages']
    hideMessage: MessageProps['hideMessage']
}

type EnhancedPricingFormProps = PricingFormProps &
    FeatureProps &
    CurrencyProps &
    WithNavigationProps<PricingFormParams> &
    FormikProps<PricingFormState>

const PricingForm: FC<EnhancedPricingFormProps> = ({
    pricingData,
    categoryIds,
    viewMode,
    match,
    isSubmitting,
    values,
    validateForm,
    setValues,
    handleSubmit,
    hideMessage,
    replaceMessages,
    formatCurrencyString,
    getCurrency,
}) => {
    const { accountSlug, id: productId } = match.params
    const history = useHistory()
    const articleConfigurationService = useContext(ArticleConfigurationServiceContext)
    const now = startOfToday()
    const [calendarData, setCalendarData] = useState<Price[] | null>(null)
    const [dateFrom, setDateFrom] = useState<Date>(now)
    const [dateTo, setDateTo] = useState<Date>(getEndDate(now))
    const [loading, setLoading] = useState<boolean>(false)
    const [validationMessages, setValidationMessages] = useState<ValidationMessage[]>([])
    const [showCalendar, setShowCalendar] = useState<boolean>(false)
    const [currentCat, setCurrentCat] = useState<string>(!!categoryIds?.length ? categoryIds[0] : '')
    const [isEqualPriceInfoMessageShown, setIsEqualPriceInfoMessageShown] = useState<boolean>(false)
    const { data: globalAccountSettings } = useGetGlobalAccountSettingsQuery({ accountSlug })
    const categories = useGetAllCategoriesForSelect()
    const [isOptionalDetailsShown, setIsOptionalDetailsShown] = useState<boolean | undefined>(true)
    const currencySymbol = getCurrency(accountSlug).symbol
    const isDynamicPricingEnabled = useHasFeature({ featureName: 'is_dynamic_pricing_enabled' })
    const { hasPermission } = usePermission()

    useEffect(() => {
        const displayInfoMessage = async () => {
            replaceMessages(
                'calendar_info',
                'success',
                'By changing the price settings you will be able to see the pregenerated prices in the calendar.',
            )
            await delay(9000)
            hideMessage('calendar_info')
        }

        if (isLocalStorageAvailable() && window.localStorage.getItem('CalendarExplanationShown') !== 'true') {
            displayInfoMessage()
            window.localStorage.setItem('CalendarExplanationShown', 'true')
        }
    }, [])

    useEffect(() => {
        const fetchIfValid = async () => {
            const errors = await validateForm()

            if (Object.keys(errors).length > 0) {
                if (values.gatePrice && values.gatePrice < values.maxPrice) {
                    setIsOptionalDetailsShown(true)
                }

                return
            }

            debouncedFetchData()
        }
        fetchIfValid()
    }, [
        dateFrom,
        dateTo,
        values.rounding,
        values.charmPricing,
        values.minPrice,
        values.maxPrice,
        values.gatePrice,
        values.regularPrice,
        values.dynamicPricing,
        currentCat,
        globalAccountSettings,
    ])

    useEffect(() => {
        const displayValidationMessages = () => {
            let messagesText = ''
            validationMessages.forEach((msg) => {
                messagesText = messagesText ? messagesText + '; ' + msg.message : msg.message
            })
            replaceMessages('calendar_warning', 'warn', messagesText)
        }
        if (validationMessages?.length > 0) {
            displayValidationMessages()
        } else {
            hideMessage('calendar_warning')
        }
    }, [validationMessages])

    useEffect(() => {
        const { dynamicPricing, minPrice, maxPrice } = values
        setIsEqualPriceInfoMessageShown(dynamicPricing && minPrice === maxPrice)
    }, [values])

    const fetchData = async () => {
        const { regularPrice, minPrice, maxPrice, charmPricing, rounding, gatePrice } = values
        setLoading(true)

        const dates = await fetchAvailabilities()
        if (!dates || (!maxPrice && maxPrice !== 0)) {
            return setLoading(false)
        }

        const productData = {
            id: productId,
            priceSettings: {
                productId: productId,
                account: accountSlug,
                originalPrice: maxPrice !== undefined ? String(maxPrice) : null,
                avgTargetPrice: regularPrice !== undefined ? String(regularPrice) : null,
                minAcceptedPrice: minPrice !== undefined ? String(minPrice) : null,
                gatePrice: !!gatePrice ? String(gatePrice) : null,
                stepSize: rounding,
                charmPricing: charmPricing,
            },
            override: {},
        }
        if (!dates) {
            return setLoading(false)
        }
        try {
            let calendarPrices: Price[]
            if (!isAnyDateAvailable(dates)) {
                calendarPrices = generateUnavailableDates(dateFrom, dateTo)
            } else {
                const data = await AppServices.articlePricingService.getPricesForCalendar(
                    accountSlug,
                    currentCat || null,
                    productData,
                    formatISOString(dateFrom, 'yyyy-MM-dd'),
                    formatISOString(dateTo, 'yyyy-MM-dd'),
                    globalAccountSettings ? globalAccountSettings.settings : undefined,
                )
                setValidationMessages(data.validationMessages)

                calendarPrices = transformPricesToCalendarData(data, dates, accountSlug, formatCurrencyString!)
            }
            setCalendarData(calendarPrices)
            setLoading(false)
        } catch (e) {
            console.error(e)
            replaceMessages('server_error', 'error', 'There was a problem fetching the calendar prices.')
            await delay(3000)
            hideMessage('server_error')
            setLoading(false)
        }
    }

    const debouncedFetchData = useCallback(debounce(fetchData, 1000), [fetchData])

    const fetchAvailabilities = useCallback(async (): Promise<AvailabilityDates | null> => {
        try {
            const availabilityData = await articleConfigurationService.getAvailability(
                accountSlug,
                productId,
                currentCat || null,
                dateFrom,
                dateTo,
            )
            setShowCalendar(!!availabilityData)
            if (!availabilityData) {
                return null
            }
            return availabilityData
        } catch {
            replaceMessages('server_error', 'error', 'There was a problem fetching the availabilities.')
            return null
        }
    }, [values, dateFrom, dateTo, currentCat, accountSlug])

    const categoryOptions: SingleSelectOption[] = useMemo(() => {
        return categories?.filter((cat) => categoryIds?.includes(cat.value)) || []
    }, [categories])

    const onDynamicPricingChange = useCallback(
        (_, dynamicPricing: boolean) => {
            if (dynamicPricing) {
                if (pricingData) {
                    if (pricingData.priceVariation === 'custom') {
                        return setValues({
                            ...values,
                            dynamicPricing,
                            minPrice: parseFloat(pricingData.minAcceptedPrice),
                            maxPrice: parseFloat(pricingData.originalPrice),
                            priceVariation: pricingData.priceVariation,
                        })
                    } else {
                        const [minPrice, maxPrice] = getMinMaxValues({ ...values, dynamicPricing })
                        return setValues({ ...values, dynamicPricing, minPrice, maxPrice })
                    }
                } else {
                    const [minPrice, maxPrice] = getMinMaxValues({ ...values, dynamicPricing })
                    return setValues({ ...values, dynamicPricing, minPrice, maxPrice })
                }
            }
            setValues({ ...values, dynamicPricing, minPrice: values.regularPrice, maxPrice: values.regularPrice })
        },
        [values, setValues],
    )

    const onRegularPriceChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const regularPrice = parseFloat(e.target.value)
            const [minPrice, maxPrice] = getMinMaxValues({ ...values, regularPrice })

            setValues({ ...values, regularPrice, minPrice, maxPrice })
        },
        [values, setValues],
    )

    const onPriceVariationChange = useCallback(
        (priceVariation: PricingFormState['priceVariation']) => {
            if (!priceVariation) {
                return
            }

            if (priceVariation === 'custom' && pricingData) {
                setValues({
                    ...values,
                    priceVariation,
                    minPrice: parseFloat(pricingData.minAcceptedPrice),
                    maxPrice: parseFloat(pricingData.originalPrice),
                })
            } else {
                const [minPrice, maxPrice] = getMinMaxValues({ ...values, priceVariation })
                setValues({ ...values, priceVariation, minPrice, maxPrice })
            }
        },
        [values, setValues],
    )

    const onRoundingChange = useCallback(
        (rounding: Rounding) => {
            if (values.priceVariation === 'custom') {
                return setValues({ ...values, rounding })
            }

            const [minPrice, maxPrice] = getMinMaxValues({ ...values, rounding })

            setValues({ ...values, rounding, minPrice, maxPrice })
        },
        [values, setValues],
    )

    const onCharmPricingChange = useCallback(
        (_, charmPricing: boolean) => {
            if (values.priceVariation === 'custom') {
                return setValues({ ...values, charmPricing })
            }

            const [minPrice, maxPrice] = getMinMaxValues({ ...values, charmPricing })

            setValues({ ...values, charmPricing, minPrice, maxPrice })
        },
        [values, setValues],
    )

    return (
        <form onSubmit={handleSubmit} className="product-pricing-detail-form">
            <div className="product-pricing-detail-form__left">
                <TextInputFieldFormik name="productName" label="Product name" disabled />
                <SwitchButtonFormik
                    name="dynamicPricing"
                    label={
                        <>
                            Dynamic pricing
                            {!isDynamicPricingEnabled && (
                                <Infotip maxWidth="300px" pointer="left">
                                    Module not enabled. Contact Customer Success to activate Dynamic Pricing.
                                </Infotip>
                            )}
                        </>
                    }
                    labelProps={{ labelPlacement: 'start' }}
                    onChange={onDynamicPricingChange}
                    disabled={!isDynamicPricingEnabled}
                />
                <TextInputFieldFormik
                    name="regularPrice"
                    type="number"
                    label={
                        <>
                            {values.dynamicPricing ? 'Regular price' : 'Price'}
                            <Infotip maxWidth="300px" pointer="left">
                                {values.dynamicPricing
                                    ? 'The standard ticket price without discounts or surcharges.'
                                    : 'Default price that visitors will pay.'}
                            </Infotip>
                        </>
                    }
                    width="148px"
                    prefix={currencySymbol}
                    onChange={onRegularPriceChange}
                />
                {values.dynamicPricing && (
                    <>
                        <FieldWrapper label="Price variation" status="normal" id="price-variation">
                            <Field
                                component={ToggleButtonGroup}
                                className="iconToggles"
                                name="priceVariation"
                                type="checkbox"
                            >
                                <ToggleButton value="low" size="small" onChange={() => onPriceVariationChange('low')}>
                                    <VariationLowSolid width="20px" height="20px" />
                                    Low ±5%
                                </ToggleButton>
                                <ToggleButton
                                    value="medium"
                                    size="small"
                                    onChange={() => onPriceVariationChange('medium')}
                                >
                                    <VariationMedSolid width="20px" height="20px" />
                                    Medium ±12%
                                </ToggleButton>
                                <ToggleButton value="high" size="small" onChange={() => onPriceVariationChange('high')}>
                                    <VariationHighSolid width="20px" height="20px" />
                                    High ±20%
                                </ToggleButton>
                                <ToggleButton
                                    value="custom"
                                    size="small"
                                    onChange={() => onPriceVariationChange('custom')}
                                >
                                    <SlidersIcon width="20px" height="20px" />
                                    Custom
                                </ToggleButton>
                            </Field>
                        </FieldWrapper>

                        <div className="product-pricing-detail-form__variation-values">
                            <TextInputFieldFormik
                                name="minPrice"
                                type="number"
                                label={
                                    <>
                                        Min price
                                        <Infotip maxWidth="300px" pointer="left">
                                            Minimum price is the lowest price after the highest discounts or during
                                            sales promotions. The final price will never be lower than this minimum
                                        </Infotip>
                                    </>
                                }
                                width="148px"
                                prefix={currencySymbol}
                                disabled={values.priceVariation !== 'custom'}
                            />
                            <TextInputFieldFormik
                                name="maxPrice"
                                type="number"
                                label={
                                    <>
                                        Max price
                                        <Infotip maxWidth="300px" pointer="left">
                                            Maximum price is the highest price with the biggest surcharges during the
                                            busiest days. The final price won&apos;t be higher than this maximum, unless
                                            you use the &apos;Gate price on visit day&apos; option.
                                        </Infotip>
                                    </>
                                }
                                width="148px"
                                prefix={currencySymbol}
                                disabled={values.priceVariation !== 'custom'}
                            />
                        </div>
                    </>
                )}

                <ExpandablePanel
                    expandedSummary="Hide optional details"
                    collapsedSummary="Show optional details"
                    isExpanded={isOptionalDetailsShown}
                >
                    {values.dynamicPricing && (
                        <div className="product-pricing-detail-form__price-settings">
                            <SingleSelectFieldFormik
                                options={priceStepSizeOptions}
                                onSelect={onRoundingChange}
                                name="rounding"
                                label={
                                    <>
                                        Price rounding
                                        <Infotip maxWidth="300px" pointer="left">
                                            This will define the steps used to round your prices. For example, a step
                                            size of 0.05 cents allows you to round 11.82 to 11.80 or 11.97 to 12.00.
                                            This allows unifying prices and increasing their appeal.
                                        </Infotip>
                                    </>
                                }
                                width="242px"
                            />
                            <div className="product-pricing-detail-form__charm-pricing">
                                <SwitchButtonFormik
                                    name="charmPricing"
                                    label={
                                        <>
                                            Charm pricing
                                            <Infotip maxWidth="300px" pointer="left">
                                                With charm pricing, the left digit is reduced from a round number by one
                                                cent.
                                            </Infotip>
                                        </>
                                    }
                                    labelProps={{ labelPlacement: 'start' }}
                                    onChange={onCharmPricingChange}
                                />
                            </div>
                        </div>
                    )}

                    <TextInputFieldFormik
                        name="gatePrice"
                        type="number"
                        label={
                            <>
                                Gate price
                                <Infotip maxWidth="300px" pointer="left">
                                    Gate price is the ticket price guests pay at the entrance on the day of visit.
                                </Infotip>
                            </>
                        }
                        prefix={currencySymbol}
                        width="148px"
                    />
                </ExpandablePanel>
            </div>
            <div className="product-pricing-detail-form__right">
                {!showCalendar && loading && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <PlainLoader
                            style={{
                                margin: '7em 2em',
                                position: 'relative',
                                width: '8em',
                                height: '8em',
                            }}
                        />
                    </div>
                )}
                {showCalendar && (
                    <>
                        <TextWithHorizontalLine
                            text="CALENDAR PREVIEW"
                            alignment="left"
                            style={{ marginTop: '0', marginBottom: '1.4em' }}
                        />
                        {!!categoryIds && categoryIds.length > 0 && (
                            <SingleSelect
                                id="cat-select"
                                options={categoryOptions}
                                selected={currentCat}
                                height="2.5rem"
                                maxHeight="11em"
                                noSelectOption="Select product category"
                                onSelect={setCurrentCat}
                                style={{ marginBottom: '0.625em', zIndex: 11 }}
                            />
                        )}
                        <CalendarBlock
                            calendarData={calendarData}
                            loading={loading}
                            now={now}
                            onMonthChange={(month: Date) => {
                                setDateFrom(getStartDate(month))
                                setDateTo(getEndDate(month))
                            }}
                        />
                        {isEqualPriceInfoMessageShown && (
                            <Alert severity="info" className="equal-price-alert">
                                To enable static pricing in combination with Gate price on the same day, please add a
                                1ct difference between min and max price. Use Optional settings and Price rounding of
                                1.00
                            </Alert>
                        )}
                    </>
                )}
            </div>
            <ButtonWrapper>
                <ActionButton
                    size="large"
                    secondary
                    onClick={() => {
                        history.push(`/account/${accountSlug}/products/home/${viewMode}`)
                    }}
                >
                    {hasPermission('edit_pricing_settings', accountSlug) ? 'Cancel' : 'Back'}
                </ActionButton>
                {hasPermission('edit_pricing_settings', accountSlug) && (
                    <LoadingButton
                        id="saveSettings"
                        type="submit"
                        size="large"
                        variant="contained"
                        style={{ marginLeft: '1.5em' }}
                        loading={isSubmitting}
                    >
                        Save
                    </LoadingButton>
                )}
            </ButtonWrapper>
        </form>
    )
}

const formikCfg = {
    validationSchema,
    mapPropsToValues: ({ pricingData, productName = '' }: PricingFormProps) => {
        const { avgTargetPrice, priceVariation, minAcceptedPrice, originalPrice, stepSize, charmPricing, gatePrice } =
            pricingData || {}

        const isNew = !pricingData
        const arePricesDefined = !!avgTargetPrice && !!minAcceptedPrice && !!originalPrice
        const arePricesEqual = avgTargetPrice === minAcceptedPrice && minAcceptedPrice === originalPrice

        const initialValues: PricingFormState = {
            productName: productName,
            dynamicPricing: (priceVariation && priceVariation !== 'custom') || (arePricesDefined && !arePricesEqual),
            regularPrice: (avgTargetPrice && parseFloat(avgTargetPrice)) || 0,
            minPrice: (minAcceptedPrice && parseFloat(minAcceptedPrice)) || 0,
            maxPrice: (originalPrice && parseFloat(originalPrice)) || 0,
            rounding: (stepSize || DEFAULT_STEP_SIZE) as Rounding,
            gatePrice: (gatePrice && parseFloat(gatePrice)) || undefined,
            priceVariation: !!priceVariation ? priceVariation : isNew ? 'medium' : 'custom',
            charmPricing: charmPricing || false,
        }

        return initialValues
    },
    handleSubmit: async (
        values: PricingFormState,
        { props, setSubmitting, validateForm }: { props: EnhancedPricingFormProps } & FormikHelpers<PricingFormState>,
    ) => {
        const { match, pricingService, pricingData, replaceMessages, updateAfterSave } = props
        const { regularPrice, minPrice, maxPrice, gatePrice, rounding, charmPricing, priceVariation, dynamicPricing } =
            values
        const { accountSlug } = match.params
        const errors = await validateForm()
        if (Object.keys(errors).length > 0) {
            replaceMessages('validation_error', 'error', 'Please check the validation errors below.')
            setSubmitting(false)
            return
        }
        setSubmitting(true)

        const responseData = await pricingService.sendPricingSettings({
            pricingType: 'rtp',
            id: match.params.id,
            account: accountSlug,
            avgTargetPrice: regularPrice,
            originalPrice: dynamicPricing ? maxPrice : regularPrice,
            minAcceptedPrice: dynamicPricing ? minPrice : regularPrice,
            gatePrice: gatePrice,
            stepSize: dynamicPricing ? rounding : DEFAULT_STEP_SIZE,
            charmPricing: dynamicPricing ? charmPricing : false,
            priceVariation: dynamicPricing ? priceVariation : 'custom',
            version: pricingData?.version,
        })

        responseData
            .ifFailure((err: CreatePricingSettingsError) => {
                handleFailure(err, replaceMessages)
                setSubmitting(false)
            })
            .ifSuccess(async () => {
                replaceMessages('pricing_settings_save_success', 'success', 'Pricing settings were successfully saved.')
                updateAfterSave()
                await delay(500)
                setSubmitting(false)
            })
    },
}

export default compose<FC<PricingFormProps>>(
    withFeatures,
    withCurrency,
    withNavigation,
    withFormik(formikCfg),
)(PricingForm)
