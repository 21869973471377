import ConviousMetricsPage from 'admin/conviousMetrics'
import FundTransfer from 'admin/fundTransfer'
import { OnboardingPage } from 'admin/onboardingPage'
import ImpersonationBanner from 'auth/impersonationBanner'
import { Account } from 'auth/state'
import * as config from 'config'
import CopyEditorPage from 'copyEditor'
import CRMHome from 'crm'
import DashboardHomePage from 'dashboard/index'
import EmailTemplatesHome from 'emailTemplates'
import { MenuStepType, TemplateType } from 'emailTemplates/schema'
import EmailTemplatesEditStudio from 'emailTemplates/studio'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import { AppServices } from 'middleware'
import { NotificationCampaignsOverview, NotificationCampaignsStudio } from 'notificationCampaigns'
import OrdersPage from 'orders'
import ProductsPage from 'products'
import DiscountRulesContainer from 'products/discountRules'
import React from 'react'
import { connect } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'
import ReportsHome from 'reports'
import ResellersHomePage from 'resellers'
import { redirectUser } from 'routesUtils'
import MollieAuthCallback from 'settings/accountSettings/integrations/mollie/callback'
import NotificationsPage from 'settings/notifications'
import PersonalSettingsPage from 'settings/personalSettings'
import RequiresCompleteSignup from 'signUp/requiresCompleteSignup'
import RequiresWelcomeSeen from 'signUp/requiresWelcomeSeen'
import WelcomePopup from 'signUp/welcomePopup'
import AddSnippetPage from 'snippet'
import { State } from 'store'
import APFStudio from 'studioAPF'
import TapPlacementEditStudio from 'tapPlacements/studio'
import TicketTemplatesOverviewPage from 'ticketTemplates'
import GiftCardTemplatesStudioPage from 'ticketTemplates/studio/giftCards/giftCardStudio'
import TicketTemplatesStudioPage from 'ticketTemplates/studio/regularTickets/ticketsStudio'
import SidebarMenuAndHeader from 'uiComponents/appLayouts/headerSidebar'
import HeaderOnly from 'uiComponents/appLayouts/withHeaderOnly'
import { ConfigReader } from 'utils/confiReader'
import WithRoutePermission from '../guards/withRoutePermission'
import EngageToolsRoutes from './engageToolsRoutes'
import PushNotificationsRoutes from './pushNotificationsRoutes'
import SettingsRoutes from './settings'
import VenueRoutes from './venueRoutes'
import BackgroundFetcher from '../../utils/backgroundFetcher'
import GPSStudio from '../../globalPricingSettings/studio'
import ReleaseNotes from 'releaseNotes'
import { RELEASED_WIDGET } from 'releaseNotes/htmlComponents'
import { Feature } from 'features'

const backofficeEndpoint = config.getRequired('backoffice-endpoint')
const crmApiEndpoint = config.getRequired('customers-endpoint')

interface AppRoutesProps extends WithNavigationProps<EmailTemplateRouteParams> {
    accounts: Account[]
    activeAccount: string
    requiresPasswordChange: boolean
    isReseller: boolean
    selfSignUp: string | null
}

export interface EmailTemplateRouteParams extends BaseRouteParams {
    templateType: TemplateType
    step: MenuStepType
}

const AppRoutes = (pageProps: AppRoutesProps) => {
    const { selfSignUp, activeAccount } = pageProps
    const history = useHistory()

    return (
        <>
            <BackgroundFetcher />
            <Route
                path="/account/:accountSlug/welcome_popup"
                render={() => (
                    <HeaderOnly logoutOnly>
                        <WelcomePopup
                            history={history}
                            accountSettingsService={AppServices.accountSettingsService}
                        />
                    </HeaderOnly>
                )}
            />
            <RequiresCompleteSignup>
                {pageProps.requiresPasswordChange && (
                    <Route
                        path="/new_user/welcome"
                        render={() => (
                            <HeaderOnly logoutOnly>
                                <PersonalSettingsPage
                                    accountSlug={activeAccount}
                                    history={history}
                                    accountSettingsService={AppServices.accountSettingsService}
                                    userService={AppServices.userService}
                                    loginService={AppServices.loginService}
                                    passwordResetService={AppServices.passwordResetService}
                                    location={history.location}
                                    welcomePage={true}
                                />
                            </HeaderOnly>
                        )}
                    />
                )}
                <RequiresWelcomeSeen>
                    <ImpersonationBanner>
                        <Route
                            path="/account/:accountSlug/settings/add_snippet"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <AddSnippetPage
                                        history={history}
                                        snippetService={AppServices.snippetService}
                                    />
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/new_account/add_snippet"
                            render={() => (
                                <HeaderOnly logoutOnly>
                                    <AddSnippetPage
                                        history={history}
                                        snippetService={AppServices.snippetService}
                                    />
                                </HeaderOnly>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/tools/add_snippet"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission requiredPermissions={['view_pricing_settings']}>
                                        <AddSnippetPage
                                            history={history}
                                            snippetService={AppServices.snippetService}
                                        />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />
                        {!pageProps.isReseller && (
                            <Route
                                path="/account/:accountSlug/dashboard/overview"
                                render={() => (
                                    <SidebarMenuAndHeader>
                                        <WithRoutePermission requiredPermissions={['access_account']}>
                                            <DashboardHomePage
                                                statsService={AppServices.statsService}
                                                loggingService={AppServices.loggingService}
                                                history={history}
                                            />
                                        </WithRoutePermission>
                                    </SidebarMenuAndHeader>
                                )}
                            />
                        )}
                        <Route
                            path="/account/:accountSlug/engage/tools"
                            render={() => (
                                <WithRoutePermission requiredPermissions={['view_wonderbar']}>
                                    <EngageToolsRoutes />
                                </WithRoutePermission>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/email_templates/home"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission requiredPermissions={['partner_admin']}>
                                        <EmailTemplatesHome
                                            emailTemplatesService={AppServices.emailTemplatesService}
                                            history={history}
                                        />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/email_templates/edit/:templateType/:step?"
                            render={() => (
                                <WithRoutePermission requiredPermissions={['partner_admin']}>
                                    <EmailTemplatesEditStudio
                                        history={history}
                                        articleService={AppServices.adminArticleService}
                                        emailTemplatesService={AppServices.emailTemplatesService}
                                        bookingCodesService={AppServices.bookingCodesService}
                                    />
                                </WithRoutePermission>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/copy_editor"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission requiredPermissions={['partner_admin']}>
                                        <CopyEditorPage
                                            history={history}
                                            location={history.location}
                                        />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/push_notifications"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <PushNotificationsRoutes />
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/crm"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission requiredPermissions={['partner_admin']}>
                                        <CRMHome
                                            history={history}
                                            location={history.location}
                                            crmService={AppServices.crmService}
                                            loginService={AppServices.loginService}
                                            loggingService={AppServices.loggingService}
                                            crmEndpoint={crmApiEndpoint}
                                        />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/tap/placements/studio/:step/:id?"
                            render={() => (
                                <WithRoutePermission requiredPermissions={['partner_admin']}>
                                    <TapPlacementEditStudio
                                        tapPlacementService={AppServices.tapPLacementService}
                                        history={history}
                                        componentsService={AppServices.componentsService}
                                    />
                                </WithRoutePermission>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/notification-campaigns/overview"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission requiredPermissions={['partner_admin']}>
                                        <NotificationCampaignsOverview
                                            notificationCampaignsService={AppServices.notificationCampaignsService}
                                            history={history}
                                            location={history.location}
                                        />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/notification-campaigns/studio/:step/:id?"
                            render={() => (
                                <WithRoutePermission requiredPermissions={['partner_admin']}>
                                    <NotificationCampaignsStudio
                                        history={history}
                                        notificationCampaignsService={AppServices.notificationCampaignsService}
                                    />
                                </WithRoutePermission>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/ticket_templates/:id"
                            render={() => (
                                <WithRoutePermission requiredPermissions={['partner_admin']}>
                                    <TicketTemplatesStudioPage
                                        history={history}
                                        ticketTemplatesService={AppServices.ticketTemplatesService}
                                        backofficeEndpoint={backofficeEndpoint}
                                        loginService={AppServices.loginService}
                                    />
                                </WithRoutePermission>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/ticket_templates"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission requiredPermissions={['partner_admin']}>
                                        <TicketTemplatesOverviewPage
                                            history={history}
                                            ticketTemplatesService={AppServices.ticketTemplatesService}
                                        />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/gift_card_templates/:id"
                            render={() => (
                                <WithRoutePermission requiredPermissions={['partner_admin']}>
                                    <GiftCardTemplatesStudioPage
                                        history={history}
                                        ticketTemplatesService={AppServices.ticketTemplatesService}
                                        backofficeEndpoint={backofficeEndpoint}
                                        loginService={AppServices.loginService}
                                    />
                                </WithRoutePermission>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/engage/gift_card_templates"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission requiredPermissions={['partner_admin']}>
                                        <TicketTemplatesOverviewPage
                                            history={history}
                                            ticketTemplatesService={AppServices.ticketTemplatesService}
                                        />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/reports"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission requiredPermissions={['access_analytics']}>
                                        <ReportsHome
                                            statsService={AppServices.statsService}
                                            engageToolsService={AppServices.engageToolsService}
                                            requestService={AppServices.requestService}
                                            backofficeEndpoint={backofficeEndpoint}
                                            loggingService={AppServices.loggingService}
                                            loginService={AppServices.loginService}
                                            history={history}
                                            location={history.location}
                                        />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/orders"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission requiredPermissions={['access_orders']}>
                                        <OrdersPage
                                            {...pageProps}
                                            location={history.location}
                                            history={history}
                                            ordersService={AppServices.ordersService}
                                            loginService={AppServices.loginService}
                                            loggingService={AppServices.loggingService}
                                            paypalService={AppServices.paypalService}
                                            backofficeEndpoint={backofficeEndpoint}
                                            bookingCodesService={AppServices.bookingCodesService}
                                            articleService={AppServices.adminArticleService}
                                        />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />

                        {pageProps.isReseller && (
                            <Route
                                path="/resellers"
                                render={() => (
                                    <HeaderOnly>
                                        <ResellersHomePage
                                            {...pageProps}
                                            location={history.location}
                                            accountSlug={activeAccount}
                                            history={history}
                                            ordersService={AppServices.ordersService}
                                            loginService={AppServices.loginService}
                                            loggingService={AppServices.loggingService}
                                            paypalService={AppServices.paypalService}
                                            accountSettingsService={AppServices.accountSettingsService}
                                            userService={AppServices.userService}
                                            passwordResetService={AppServices.passwordResetService}
                                            backofficeEndpoint={backofficeEndpoint}
                                        />
                                    </HeaderOnly>
                                )}
                            />
                        )}
                        <Route
                            path="/account/:accountSlug/products/apf_studio/:step?"
                            render={() => (
                                <WithRoutePermission requiredPermissions={['partner_admin']}>
                                    <APFStudio
                                        articleService={AppServices.adminArticleService}
                                        history={history}
                                    />
                                </WithRoutePermission>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/products/discount_rules"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission requiredPermissions={['partner_admin']}>
                                        <DiscountRulesContainer />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/products"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission
                                        requiredPermissions={['view_pricing_settings', 'view_product_validity']}
                                    >
                                        <ProductsPage
                                            history={history}
                                            location={history.location}
                                            articleConfigurationService={AppServices.articleConfigurationService}
                                            articleService={AppServices.adminArticleService}
                                            pricingService={AppServices.pricingService}
                                            channelsService={AppServices.channelsService}
                                            managementService={AppServices.managementService}
                                            statsService={AppServices.statsService}
                                            optionsService={AppServices.optionsService}
                                            ticketTemplatesService={AppServices.ticketTemplatesService}
                                            {...pageProps}
                                        />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route path="/account/:accountSlug/settings" component={SettingsRoutes} />
                        <Route
                            path="/personal_settings"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <PersonalSettingsPage
                                        accountSlug={activeAccount}
                                        history={history}
                                        accountSettingsService={AppServices.accountSettingsService}
                                        userService={AppServices.userService}
                                        loginService={AppServices.loginService}
                                        passwordResetService={AppServices.passwordResetService}
                                        location={history.location}
                                        welcomePage={false}
                                    />
                                </SidebarMenuAndHeader>
                            )}
                        />
                        {!pageProps.isReseller && (
                            <Route
                                path="/account/:accountSlug/notifications"
                                render={() => (
                                    <SidebarMenuAndHeader>
                                        <WithRoutePermission requiredPermissions={['access_account']}>
                                            <NotificationsPage
                                                accountSettingsService={AppServices.accountSettingsService}
                                                articleService={AppServices.adminArticleService}
                                                history={history}
                                                location={history.location}
                                            />
                                        </WithRoutePermission>
                                    </SidebarMenuAndHeader>
                                )}
                            />
                        )}
                        {!pageProps.isReseller && (
                            <Route
                                path="/account/:accountSlug/venue"
                                render={() => (
                                    <SidebarMenuAndHeader>
                                        <VenueRoutes />
                                    </SidebarMenuAndHeader>
                                )}
                            />
                        )}
                        <Route
                            path="/account/:accountSlug/release_notes"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <ReleaseNotes />
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/global_pricing_settings"
                            render={() => (
                                <WithRoutePermission requiredPermissions={['partner_admin']}>
                                    <GPSStudio />
                                </WithRoutePermission>
                            )}
                        />
                        <Route
                            path="/admin/onboarding"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission requiredPermissions={['convious_admin']}>
                                        <OnboardingPage checkoutDemoEndpoint={ConfigReader.getCheckoutDemoEndpoint()} />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/admin/fund_transfer"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <WithRoutePermission requiredPermissions={['convious_admin', 'fund_transfers']}>
                                        <FundTransfer />
                                    </WithRoutePermission>
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/mollie/callback"
                            render={() => (
                                <SidebarMenuAndHeader>
                                    <MollieAuthCallback />
                                </SidebarMenuAndHeader>
                            )}
                        />
                        <Route
                            path="/kpis"
                            render={() => (
                                <WithRoutePermission requiredPermissions={['convious_admin']}>
                                    <ConviousMetricsPage statsService={AppServices.statsService} history={history} />
                                </WithRoutePermission>
                            )}
                        />
                        <Route
                            render={() =>
                                redirectUser(!!selfSignUp, activeAccount, pageProps.isReseller, pageProps.accounts)
                            }
                        />
                    </ImpersonationBanner>
                </RequiresWelcomeSeen>
            </RequiresCompleteSignup>
            {!pageProps.isReseller && (
                <Feature name="release_notes_widget" accountSlug={activeAccount}>
                    <div dangerouslySetInnerHTML={{ __html: RELEASED_WIDGET }} />
                </Feature>
            )}
        </>
    )
}

const mapStateToProps = (state: State) => {
    return {
        selfSignUp: state.profile?.profile?.signUp ?? null,
        accounts: state.auth?.user?.accounts ?? [],
        isReseller: !!state.auth.user?.resellerId,
        requiresPasswordChange: !!state.profile?.profile?.requiresPasswordChange || false,
        activeAccount: state.preferences.activeAccount,
    }
}

export default connect(mapStateToProps)(withNavigation(AppRoutes))
