import * as React from 'react'
import { Col } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { WonderbarConfiguration, TargetType, OnChangeFunc, GetConfigValueFunc } from 'engageTools/studio/schema'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { FormFieldComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { FieldType, SelectField } from 'uiComponents/studio/form/fieldComponentSchema'
import { NumberFieldElement } from 'engageTools/studio/form/numberFieldElement'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'

interface GeneralSectionProps {
    getConfigValue: GetConfigValueFunc
    section: ConfigurationUnit
    configuration: WonderbarConfiguration
    handleInputChange: OnChangeFunc
    deviceType: TargetType
}

export function GeneralSection(props: GeneralSectionProps) {
    const { section, configuration, handleInputChange, getConfigValue } = props
    const bottom = configuration.placement === 'bottom'
    const renderField = (field: FieldType) => {
        if (field.name === 'display') {
            const targetDevice = !props.deviceType ? 'all' : props.deviceType === 'any' ? 'all' : props.deviceType
            const displayOn = (field as SelectField).availableOptions.find((ao) => ao.value === targetDevice).name
            return <div id="display-device">{displayOn}</div>
        }
        return (
            <FormFieldComponent
                field={field}
                handleChange={props.handleInputChange}
                value={props.getConfigValue(field, props.configuration)}
            />
        )
    }

    if (section.fields) {
        return (
            <SectionWrapper key={section.name} title={section.name}>
                {section.fields.map((field, j) => {
                    let style = field.span === 6 ? { display: 'flex', alignItems: 'center' } : {}
                    const labelName = field.label === '' || field.type === 'checkbox' ? null : field.label

                    const showDisablePushFixedElements = field.name === 'disable-fixed-elements-push-down' && !props.configuration.pushDown
                    if ((bottom && (field.name === 'visible-on-scroll' || field.name === 'push-down')) || showDisablePushFixedElements) {
                        return null
                    } else {
                        return (
                            <FormItem key={j}>
                                {labelName && (
                                    <Col span={field.span} style={style}>
                                        <label htmlFor={field.name}>
                                            {labelName}
                                            {field.infotip && (
                                                <Infotip pointer="left" maxWidth="16em" smallBoxText>
                                                    {field.infotip}
                                                </Infotip>
                                            )}
                                        </label>
                                    </Col>
                                )}
                                {field.type === 'number' ? (
                                    <NumberFieldElement
                                        field={field}
                                        style={style}
                                        handleChange={handleInputChange}
                                        value={getConfigValue(field, props.configuration)}
                                    />
                                ) : (
                                    <Col
                                        span={field.span}
                                        style={{ ...style }}
                                        className={field.span === 6 ? 'justify-right' : ''}
                                    >
                                        {renderField(field)}
                                    </Col>
                                )}
                            </FormItem>
                        )
                    }
                })}
            </SectionWrapper>
        )
    } else {
        return null
    }
}
