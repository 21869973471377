import React from 'react'
import { NavigationTabs } from 'uiComponents/navigation/tabs'
import { History } from 'history'
import { usePermission } from 'admin/hocs'

interface ExternalConfigurationSectionProps {
    accountSlug: string
    history: History
    id: string
    viewMode: 'flat' | 'nested' | ''
}

function ExternalConfigurationSection(props: ExternalConfigurationSectionProps) {
    const { hasPermission } = usePermission()

    const tabs = [
        {
            name: 'Edit Details',
            onClick: () =>
                props.history.push(`/account/${props.accountSlug}/products/crud/${props.id}?from=${props.viewMode}`),
            current: false,
            disabled: !hasPermission('partner_admin', props.accountSlug),
        },
        {
            name: 'Edit Validity',
            onClick: () => {},
            current: true,
            disabled: !hasPermission('view_product_validity', props.accountSlug),
        },
        {
            name: 'Edit Pricing',
            onClick: () =>
                props.history.push(
                    `/account/${props.accountSlug}/products/pricing/${props.id}/detail?from=${props.viewMode}`,
                ),
            current: false,
            disabled: !hasPermission('view_pricing_settings', props.accountSlug),
        },
    ]

    return (
        <div style={{ marginBottom: '2em' }}>
            <NavigationTabs tabs={tabs} />
        </div>
    )
}

export default ExternalConfigurationSection
