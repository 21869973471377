import React, { useState, useEffect, useContext } from 'react'
import { useAccountChange } from 'reactUtils'
import styled from 'styled-typed'
import { connect } from 'react-redux'
import { State } from 'store'
import { Account } from 'auth/state'
import { History } from 'history'
import { MessageKind } from 'uiComponents/messages'
import { OptionsService, OptionItemDetails, getCopyKey } from 'products/options/optionsService'
import { ArticleService, LocalesInfo, Locale, InventoryPool } from 'admin/articleService'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { delay } from 'utils'
import cloneDeep from 'lodash/cloneDeep'
import { ContainerBody } from 'uiComponents/settingsContainer'
import { Row, Col } from 'uiComponents/flex'
import { FormItem, FormItemName, ValidationMessage, WrapWithSymbol } from 'uiComponents/form/formElements'
import Infotip from 'uiComponents/infotip'
import { TextInput, NumberInput } from 'uiComponents/input'
import { ButtonWrapper } from 'uiComponents/pageElements'
import { ActionButton, ActionButtonA } from 'uiComponents/buttons'
import { Tabs } from 'uiComponents/navigation/tabs'
import { PageLoader } from 'uiComponents/loaders'
import { TextWithHorizontalLine } from 'uiComponents/pageElements'
import { InventorySection } from 'products/inventorySection'
import { InventoryServiceContext } from 'inventory/context'
import { InventoryItem } from 'inventory/inventoryService'
import { PricingServiceContext } from 'products/pricing/context'
import { ProductData } from 'products/pricing/pricingService'
import { mapArticleData } from 'products/crud/utils'
import { FormWrapper } from 'uiComponents/form/form'
import { TaxSelect } from 'settings/accountSettings/taxConfigurations/taxSelect'
import { useGetTaxConfigurationsQuery } from 'settings/accountSettings/taxConfigurations/rtkQuery'
import { FeatureProps, withFeatures } from 'features'

const SectionTitle = styled.div`
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 1em;
`

const dummyLocales: LocalesInfo = {
    locales: [{ code: 'EN', name: 'English' }],
    defaultLocale: 'EN',
}

const dummyOptionItemDetails: OptionItemDetails = {
    id: '',
    name: '',
    pricingId: '',
    price: 0,
    priority: 1,
    taxConfigurationUuid: null,
    depositFee: null,
    usedResources: [],
    nameTranslations: { key: 'option_item.name', text: { en: '' } },
}

interface OptionItemDetailsProps extends FeatureProps {
    navigation: Navigation
    match: RouteMatch<{ itemId: string; groupUuid: string }>
    history: History
    accountSlug: string
    accounts: Account[]
    optionsService: OptionsService
    articleService: ArticleService
    setActiveSection: (section: string, header: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

function OptionItemDetailsForm(props: OptionItemDetailsProps) {
    const inventoryService = useContext(InventoryServiceContext)
    const pricingService = React.useContext(PricingServiceContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingMetadata, setLoadingMetadata] = useState<boolean>(false)
    const [itemData, setItemData] = useState<OptionItemDetails>(dummyOptionItemDetails)
    const [itemPricingData, setItemPricingData] = useState<ProductData | null>(null)
    const [locales, setLocales] = useState<LocalesInfo>(dummyLocales)
    const [inventoryPools, setInventoryPools] = useState<InventoryItem[]>([])
    const [activeLanguageIndex, setActiveLanguageIndex] = useState<number>(0)
    const [langWithMissingName, setLangWithMissingName] = useState<string>('')
    const [markErrors, setMarkErrors] = useState<boolean>(false)
    const getTaxConfigurationsQuery = useGetTaxConfigurationsQuery(props.accountSlug)
    const loadingTaxConfigurations = getTaxConfigurationsQuery.isLoading
    const taxConfigurations = getTaxConfigurationsQuery.data?.taxConfigurations || null
    const defaultTaxConfigurationUuid = getTaxConfigurationsQuery.data?.defaultTaxConfigurationUuid || null
    const updatedData = cloneDeep(itemData)

    async function getItemDetails(id: string) {
        try {
            setLoading(true)
            const data = await props.optionsService.getOptionItemDetails(
                props.accountSlug,
                id,
                props.match.params.groupUuid,
            )
            const pricing = await pricingService.getProductPricing([data.pricingId])
            const pricingData = pricing.find((p) => p.productId === data.pricingId)
            data.price = pricingData?.originalPrice || 0
            setItemPricingData(pricingData || null)
            setItemData(mapArticleData(data) as OptionItemDetails)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Could not get the options item with this ID, please try again later.',
            )
        } finally {
            setLoading(false)
        }
    }

    async function getMetadata() {
        try {
            setLoadingMetadata(true)
            const accountLocales = await props.articleService.getAccountLocales(props.accountSlug)
            setLocales(accountLocales)
            const inventory = await inventoryService.getInventoryList(props.accountSlug)
            setInventoryPools(inventory)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! Could not get the options item details, please try again later.',
            )
        } finally {
            setLoadingMetadata(false)
        }
    }

    useEffect(() => {
        props.setActiveSection('optionGroups', 'Option Groups')
        getMetadata()
        const productId = props.match.params.itemId
        if (!!productId && productId !== 'new') {
            getItemDetails(productId)
        }
    }, [])

    useAccountChange(props.accountSlug, () =>
        props.history.push(`/account/${props.accountSlug}/products/option_groups/home`),
    )

    const setNameCopyKey = () => {
        const enName = updatedData.nameTranslations.text.en
        updatedData.nameTranslations.key = getCopyKey(props.accountSlug, enName)
        setItemData(updatedData)
    }

    const isDataValid = () => {
        let missingLang = ''
        locales.locales.every((l, i) => {
            if (!itemData.nameTranslations.text[l.code]) {
                missingLang = l.code
                setActiveLanguageIndex(i)
                return false
            } else {
                return true
            }
        })
        setLangWithMissingName(missingLang)

        let missingInventoryId = false
        itemData.usedResources.every((r) => {
            if (!r.poolId) {
                missingInventoryId = true
                return false
            } else {
                return true
            }
        })
        if (missingInventoryId || itemData.priority === null) {
            setMarkErrors(true)
        }

        return !missingLang && !missingInventoryId && itemData.priority !== null
    }

    const savePrice = async (pricingId: string) => {
        const price = Number(itemData.price)
        const response = await pricingService.sendPricingSettings({
            pricingType: 'static',
            id: pricingId,
            account: props.accountSlug,
            originalPrice: price,
            minAcceptedPrice: null,
            avgTargetPrice: null,
            gatePrice: null,
            stepSize: itemPricingData?.stepSize || '0.10',
            charmPricing: itemPricingData?.charmPricing || false,
            version: itemPricingData?.version || null,
        })
        return response
    }

    const setProductTax = (formValues: OptionItemDetails) => {
        updatedData.taxConfigurationUuid = formValues.taxConfigurationUuid
        setItemData(updatedData)
    }

    const onSaveOptionGroup = async (values: OptionItemDetails) => {
        if (isDataValid()) {
            setLoading(true)
            try {
                setProductTax(values)
                const groupUuid = props.match.params.groupUuid
                if (props.match.params.itemId === 'new') {
                    setNameCopyKey()
                    const created = await props.optionsService.createOptionItem(
                        props.accountSlug,
                        updatedData,
                        groupUuid,
                    )
                    const responseData = await savePrice(created.pricingId)
                    responseData
                        .ifFailure(() => {
                            throw new Error('pricing-error')
                        })
                        .ifSuccess(() => {
                            props.history.push(`/account/${props.accountSlug}/products/option_groups/home`)
                        })
                } else {
                    const savedItem = await props.optionsService.updateOptionItem(
                        props.accountSlug,
                        updatedData,
                        props.match.params.itemId,
                        groupUuid,
                    )
                    const responseData = await savePrice(itemData.pricingId)
                    responseData
                        .ifFailure(() => {
                            throw new Error('pricing-error')
                        })
                        .ifSuccess((result) => {
                            savedItem.price = result.savePriceSettings.priceSettings.originalPrice
                        })
                    getItemDetails(savedItem.id)
                }
                props.replaceMessages('success', 'success', 'Option item has been saved successfully.')
                await delay(3000)
                props.removeAllMessages()
            } catch (e) {
                const messagePart = e.message === 'pricing-error' ? 'price of the' : ''
                props.replaceMessages(
                    'server_error',
                    'error',
                    `Oops! Could not save the ${messagePart} option item, please try again later.`,
                )
                setLoading(false)
            }
        }
    }

    function onNameChange(e: React.ChangeEvent<HTMLInputElement>, language: Locale) {
        updatedData.nameTranslations.text[language.code] = e.target.value
        setItemData(updatedData)
        setLangWithMissingName('')
    }

    function onPriceChange(e: React.ChangeEvent<HTMLInputElement>) {
        updatedData.price = e.target.value
        setItemData(updatedData)
    }

    function onTaxConfigurationChange(value: string | null) {
        updatedData.taxConfigurationUuid = value
        setItemData(updatedData)
    }

    function onDepositFeeChange(e: React.ChangeEvent<HTMLInputElement>) {
        updatedData.depositFee = e.target.value ? Number(e.target.value) : null
        setItemData(updatedData)
    }

    function onPriorityChange(e: React.ChangeEvent<HTMLInputElement>) {
        updatedData.priority = e.target.value === '' ? null : +e.target.value
        setItemData(updatedData)
    }

    function onInventoryDataChange(inventoryList: InventoryPool[]) {
        updatedData.usedResources = inventoryList
        setItemData(updatedData)
    }

    const activeAccount = props.accounts.find((x) => x.slug === props.accountSlug) || props.accounts[0]
    const missingPriority = markErrors && itemData.priority === null

    return (
        <FormWrapper
            enableReinitialize
            initialValues={itemData}
            onSubmit={onSaveOptionGroup}
            formId="options-group-page"
            formName="options-group-page"
        >
            {({ submitForm }) => (
                <ContainerBody style={{ flexDirection: 'column' }}>
                    {(loading || loadingMetadata) && <PageLoader style={{ paddingBottom: '4em' }} />}
                    {!loading && !loadingMetadata && (
                        <>
                            <Row>
                                <Col span={6}>
                                    <SectionTitle>Translations</SectionTitle>
                                    <Tabs
                                        titles={locales.locales.map((l) => l.name)}
                                        setActive={setActiveLanguageIndex}
                                        activeTabIndex={activeLanguageIndex}
                                    >
                                        {locales.locales.map((l, i) => {
                                            return (
                                                <div key={l.code}>
                                                    <FormItem htmlFor="option-item-name">
                                                        <FormItemName>
                                                            Options item name
                                                            <Infotip pointer="left">
                                                                This is the name of the options item that will appear in
                                                                the checkout.
                                                            </Infotip>
                                                        </FormItemName>
                                                        <TextInput
                                                            id={`option-item-name-${[l.code]}`}
                                                            name="option-item-name"
                                                            type="text"
                                                            maxLength={60}
                                                            value={
                                                                itemData.nameTranslations.text[
                                                                    locales.locales[activeLanguageIndex].code
                                                                ] || ''
                                                            }
                                                            status={
                                                                langWithMissingName && langWithMissingName === l.code
                                                                    ? 'error'
                                                                    : 'normal'
                                                            }
                                                            placeholder="Add options item name..."
                                                            onChange={(e) => onNameChange(e, l)}
                                                            locale={l.code}
                                                            block
                                                        />
                                                    </FormItem>
                                                    <ValidationMessage
                                                        className={
                                                            langWithMissingName === l.code
                                                                ? 'validation-message-visible'
                                                                : ''
                                                        }
                                                    >
                                                        This field is required
                                                    </ValidationMessage>
                                                </div>
                                            )
                                        })}
                                    </Tabs>
                                </Col>
                                <Col span={6}>
                                    <FormItem htmlFor="priority">
                                        <FormItemName>
                                            Priority
                                            <Infotip pointer="left" maxWidth="20em">
                                                This number determines the order of options in the checkout. The option
                                                with the lowest number will be displayed on top.
                                            </Infotip>
                                        </FormItemName>
                                        <NumberInput
                                            align="left"
                                            id="priority"
                                            placeholder="1"
                                            max={100}
                                            min={1}
                                            value={itemData.priority === null ? '' : itemData.priority}
                                            onChange={onPriorityChange}
                                            status={missingPriority ? 'error' : 'normal'}
                                            block
                                        />
                                    </FormItem>
                                    <ValidationMessage className={missingPriority ? 'validation-message-visible' : ''}>
                                        This field is required
                                    </ValidationMessage>
                                    <FormItem htmlFor="deposit-fee" style={{ marginTop: '0.6rem' }}>
                                        <FormItemName>Deposit fee (optional)</FormItemName>
                                        <WrapWithSymbol symbol={activeAccount.currencySymbol} position="left">
                                            <NumberInput
                                                align="left"
                                                id="deposit-fee"
                                                placeholder="-"
                                                max={100}
                                                value={itemData.depositFee !== null ? itemData.depositFee : ''}
                                                onChange={onDepositFeeChange}
                                                block
                                            />
                                        </WrapWithSymbol>
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <FormItem htmlFor="price">
                                        <FormItemName>Price</FormItemName>
                                        <WrapWithSymbol symbol={activeAccount.currencySymbol} position="left">
                                            <NumberInput
                                                align="left"
                                                id="price"
                                                max={9999}
                                                value={itemData.price !== null ? itemData.price : ''}
                                                onChange={onPriceChange}
                                                block
                                            />
                                        </WrapWithSymbol>
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <TaxSelect
                                        title="Product tax"
                                        taxConfigurationUuid={itemData.taxConfigurationUuid || null}
                                        onTaxConfigurationChange={onTaxConfigurationChange}
                                        accountSlug={props.accountSlug}
                                        loadingTaxConfigurations={loadingTaxConfigurations}
                                        taxConfigurations={taxConfigurations}
                                        defaultTaxConfigurationUuid={defaultTaxConfigurationUuid}
                                    />
                                </Col>
                            </Row>
                            <Col span={12} style={{ position: 'relative', zIndex: 1 }}>
                                <TextWithHorizontalLine
                                    style={{ margin: '2.5em 0 1em 0' }}
                                    text="SALES LIMITS"
                                    alignment="left"
                                />
                                <InventorySection
                                    inventoryPools={inventoryPools}
                                    usedInventory={itemData.usedResources}
                                    updateInventoryData={onInventoryDataChange}
                                    missingId={markErrors}
                                />
                            </Col>

                            <ButtonWrapper style={{ marginTop: '2em' }}>
                                <ActionButtonA
                                    size="large"
                                    secondary
                                    href={`/account/${props.accountSlug}/products/option_groups/home`}
                                >
                                    Cancel
                                </ActionButtonA>
                                <ActionButton
                                    id="save-option-group"
                                    size="large"
                                    onClick={submitForm}
                                    style={{ marginLeft: '1.5em' }}
                                >
                                    Save
                                </ActionButton>
                            </ButtonWrapper>
                        </>
                    )}
                </ContainerBody>
            )}
        </FormWrapper>
    )
}

const mapStateToProps = (state: State) => {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}

export default withFeatures(withNavigation(connect(mapStateToProps)(OptionItemDetailsForm)))
