import React, { useMemo } from 'react'
import { MetaData } from 'uiComponents/filter/schema'
import Filter from 'uiComponents/filter'
import { useAppSelector } from 'store/hooks'
import { getActiveAccount } from 'preferences/selectors'
import { applicableFilters, staticMetadaCategories } from './config'
import { useHasFeature } from 'features'

const CartTableFilters: React.FC = () => {
    const accountSlug = useAppSelector(getActiveAccount)
    const shouldShowOpenStatus = useHasFeature('should_show_open_status')

    if (!accountSlug) throw new Error('No account selected')

    const filteredReservationStatuses = useMemo(() => {
        return staticMetadaCategories.map((category) => {
            if (category.category === 'reservation_status') {
                return {
                    ...category,
                    options: category.options.filter((option) =>
                        shouldShowOpenStatus ? true : option.slug !== 'open',
                    ),
                }
            }
            return category
        })
    }, [shouldShowOpenStatus])

    const data = useMemo((): MetaData[] => {
        return [...filteredReservationStatuses]
    }, [])

    return (
        <Filter
            accountSlug={accountSlug}
            applicableFilters={applicableFilters}
            metaData={data}
            standaloneFilters={[]}
            singleSelectCategories={[]}
            compact
        />
    )
}

export default CartTableFilters
