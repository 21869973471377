import React, { useEffect, useState } from 'react'
import { getDay } from 'date-fns'
import isEqual from 'lodash/isEqual'
import { useAccountChange } from 'reactUtils'
import { usePermission } from 'admin/hocs'
import { AccountSettingsService } from 'settings/settingsService'
import { ArticleService } from 'admin/articleService'
import { MessageKind } from 'uiComponents/messages'
import { ContentContainer } from 'uiComponents/pageElements'
import { TableLoader } from 'uiComponents/loaders'
import { TransactionNotifications, OrderAlertStatus } from './transactionNotifications'
import { PerformanceReports, ReportFrequencyType } from './performanceReports'
import { BarcodeAlerts } from './barcodeAlerts'
import { Body } from 'uiComponents/typography'
import { PageActionsSection } from 'uiComponents/pageElements'
import { ActionButton } from 'uiComponents/buttons'
import { FilterOption } from 'uiComponents/filter/schema'
import { getProductTreeOptions } from 'uiComponents/filter/filterHelpers'
import { ScheduledEmail } from 'settings/schema'
import { parseISODate } from 'utils/dates'

interface PageProps {
    accountSlug: string
    settingsService: AccountSettingsService
    articleService: ArticleService
    onSectionChange: (section: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

function EmailNotificationsPage(props: PageProps) {
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingMetadata, setLoadingMetadata] = useState<boolean>(false)
    const [productOptions, setProductOptions] = useState<FilterOption[]>([])
    const [initialOrderAlerts, setInitialOrderAlertsOn] = useState<OrderAlertStatus>(null)
    const [initialProducts, setInitialProducts] = useState<string[]>([])
    const [initialReportFrequency, setInitialReportFrequency] = useState<ReportFrequencyType>('never')
    const [initialBarcodeAlertsOn, setInitialBarcodeAlertsOn] = useState<boolean>(false)
    const [orderAlerts, setOrderAlerts] = useState<OrderAlertStatus>(null)
    const [products, setProducts] = useState<string[]>([])
    const [reportFrequency, setReportFrequency] = useState<ReportFrequencyType>('never')
    const [weekday, setWeekday] = useState<string>('1')
    const [barcodeAlertsOn, setBarcodeAlertsOn] = useState<boolean>(false)
    const [currentScheduledEmails, setCurrentScheduledEmails] = useState<ScheduledEmail[]>([])
    const [showErrors, setShowErrors] = useState<boolean>(false)
    const { hasPermission } = usePermission()
    
    async function getScheduledEmails() {
        const scheduledEmails = await props.settingsService.getScheduledEmails()
        const accountScheduledEmails = scheduledEmails.filter((e) => e.checkout === props.accountSlug)
        const performanceReportEmail = accountScheduledEmails.find((e) => e.type === 'performance_report')
        const barcodeAlerts = !!accountScheduledEmails.find((e) => e.type === 'barcodes_alert')
        const frequency = performanceReportEmail?.frequency || 'never'
        const date = performanceReportEmail?.date
        setInitialReportFrequency(frequency as ReportFrequencyType)
        setReportFrequency(frequency as ReportFrequencyType)
        setWeekday(frequency === 'weekly' && date ? getDay(parseISODate(date)).toString() : '1')
        setInitialBarcodeAlertsOn(barcodeAlerts)
        setBarcodeAlertsOn(barcodeAlerts)
        setCurrentScheduledEmails(accountScheduledEmails)
    }

    async function getOrderAlertsInfo() {
        const data = await props.settingsService.getTransactionsSubscriptionInfo(props.accountSlug)
        const alertsStatus = data.subscribed ? (!!data.articles.length ? 'selectedProducts' : 'allProducts') : null
        setInitialOrderAlertsOn(alertsStatus)
        setOrderAlerts(alertsStatus)
        setInitialProducts(data.articles)
        setProducts(data.articles)
    }

    async function getProductTree() {
        try {
            setLoadingMetadata(true)
            const categories = await props.articleService.getProductLists(props.accountSlug)
            const articlesList = await props.articleService.getMinimalSortedArticlesList(props.accountSlug)
            const multiselectOptions = getProductTreeOptions(articlesList, categories)
            setProductOptions(multiselectOptions)
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoadingMetadata(false)
        }
    }

    async function getData() {
        setLoading(true)
        try {
            await getOrderAlertsInfo()
            await getScheduledEmails()
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        props.onSectionChange('settings')
        getData()
        getProductTree()
    }, [])

    useAccountChange(props.accountSlug, () => {
        getData()
        getProductTree()
    })

    const onSave = async () => {
        props.removeAllMessages()
        if (orderAlerts === 'selectedProducts' && !products.length) {
            setShowErrors(true)
            return
        }
        try {
            setLoading(true)
            await saveOrderAlertsChange()
            await saveBarcodesAlertChange()
            await savePerformanceReportChange()
            await getData()
        } catch {
            props.replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again later.')
            setLoading(false)
        }
    }

    const saveOrderAlertsChange = async () => {
        const productsListDidNotChange = isEqual(initialProducts.sort(), products.sort())
        if (initialOrderAlerts === orderAlerts && productsListDidNotChange) {
            return
        }
        const articles = orderAlerts ? products : []
        await props.settingsService.updateTransactionsSubscription(props.accountSlug, !!orderAlerts, articles)
    }

    const saveBarcodesAlertChange = async () => {
        const alreadyScheduled = !!currentScheduledEmails.find((e) => e.type === 'barcodes_alert')
        if (alreadyScheduled === barcodeAlertsOn) {
            return
        }
        const frequency = barcodeAlertsOn ? 'custom' : 'never'
        await props.settingsService.scheduleEmail('barcodes_alert', props.accountSlug, frequency, null)
    }

    const savePerformanceReportChange = async () => {
        const alreadyScheduled = currentScheduledEmails.find((e) => e.type === 'performance_report')
        const currentWeekday =
            alreadyScheduled && alreadyScheduled.frequency === 'weekly'
                ? getDay(parseISODate(alreadyScheduled.date)).toString()
                : '1'
        if (alreadyScheduled && alreadyScheduled.frequency === reportFrequency && currentWeekday === weekday) {
            return
        }
        const apiWeekday = Number(weekday) - 1 > -1 ? Number(weekday) - 1 : 6
        await props.settingsService.scheduleEmail('performance_report', props.accountSlug, reportFrequency, apiWeekday)
    }

    const onOrderAlertStatusChange = (status: OrderAlertStatus) => {
        setOrderAlerts(status)
        if (status !== 'selectedProducts') {
            setProducts([])
        }
    }

    const showOrdersSection = hasPermission('access_orders', props.accountSlug) || !!initialOrderAlerts
    const showReportSection =
        hasPermission('access_analytics', props.accountSlug) || initialReportFrequency !== 'never'
    const showBarcodesSection = hasPermission('access_codes', props.accountSlug) || initialBarcodeAlertsOn

    return (
        <ContentContainer>
            {(loading || loadingMetadata) && <TableLoader />}
            {!loading && !loadingMetadata && (
                <>
                    {showOrdersSection && (
                        <TransactionNotifications
                            orderAlerts={orderAlerts}
                            onStatusChange={onOrderAlertStatusChange}
                            products={products}
                            productOptions={productOptions}
                            showErrors={showErrors}
                            onProductsChange={(v: string[]) => setProducts(v)}
                        />
                    )}
                    {showReportSection && (
                        <PerformanceReports
                            reportFrequency={reportFrequency}
                            weekday={weekday}
                            setReportFrequency={(v) => setReportFrequency(v as ReportFrequencyType)}
                            setWeekday={(v) => setWeekday(v)}
                        />
                    )}
                    {showBarcodesSection && (
                        <BarcodeAlerts
                            barcodeAlertsOn={barcodeAlertsOn}
                            toggleBarcodeAlerts={() => setBarcodeAlertsOn(!barcodeAlertsOn)}
                        />
                    )}
                    {(showOrdersSection || showReportSection || showBarcodesSection) && (
                        <PageActionsSection style={{ marginTop: '5em' }}>
                            <ActionButton size="large" onClick={onSave}>
                                Save
                            </ActionButton>
                        </PageActionsSection>
                    )}
                    {!showOrdersSection && !showReportSection && !showBarcodesSection && (
                        <Body size={1}>
                            You do not have any permissions that would allow you to subscribe to any of the email
                            notifications.
                        </Body>
                    )}
                </>
            )}
        </ContentContainer>
    )
}

export default EmailNotificationsPage
