import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

interface CheckAccountCreatedProps {
    children: React.ReactNode
}

export const RequiresCreatedAccount = ({ children }: CheckAccountCreatedProps) => {
    const user = useAppSelector((state) => state.auth.user)
    
    if (user && !user.isAdmin && user.accounts.length === 0) {
        return <Redirect to="/sign_up/account_details" />
    }
    return <>{children}</>
}
