import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-typed'
import { State } from 'store'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { format, startOfToday } from 'date-fns'
import { FormItem as Item, FormItemName, WrapWithSymbol } from 'uiComponents/form/formElements'
import { match as RouteMatch } from 'react-router-dom'
import { Account } from 'auth/state'
import { MessageKind } from 'uiComponents/messages'
import { ActionButton } from 'uiComponents/buttons/'
import Infotip from 'uiComponents/infotip'
import { TextInput, NumberInput, SingleSelect, SingleSelectOption } from 'uiComponents/input'
import { TextWithHorizontalLine, Column, ColumnGap, ButtonWrapper } from 'uiComponents/pageElements'
import { Row } from 'uiComponents/flex'
import { History } from 'history'
import { withFeatures } from 'features'
import { AvailabilityDates, PricingType } from 'products/articleConfigurationService'
import { Validities } from '../productPricingDetailRouter'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { ArticleConfigurationServiceContext } from 'products/context'
import { ValidationNotice } from 'products/components/validationNotice'
import { ValidationMessage } from 'products/pricing/articlePricingService'
import { delay } from 'utils'
import CalendarBlock from 'products/components/calendarBlock'
import { Availability, Price, getStartDate, getEndDate, generateUnavailableDates, getTimeslots } from 'products/utils'
import { ProductListWithPath } from 'products/crud/common'
import {
    validateGatePrice,
    getValidity,
    isAnyDateAvailable,
    priceStepSizeOptions,
    priceStepSizeInfotipText,
    charmPricingInfotipText,
} from 'products/pricing/common'
import { FormTogglerCol, FormToggler } from 'uiComponents/input/toggle'
import { AppServices } from 'middleware'
import { useGetAllCategoriesForSelect } from 'products/redux'
import LoadingButton from '@mui/lab/LoadingButton'

interface ProductPricingPageParams {
    accountSlug: string
    id: string
}

const FormItem = styled(Item)`
    margin-bottom: 2em;
`

interface PricingDetailsFormProps {
    history: History
    match: RouteMatch<ProductPricingPageParams>
    hasFeature: (feature: string, slug: string) => boolean
    accountSlug: string
    accounts: Account[]
    existingPricingSettings: boolean
    originalPrice: number | null
    gatePrice: number | null
    stepSize: string
    charmPricing: boolean
    onSettingsSave: (e: React.FormEvent<HTMLElement>) => void
    onMaxAcceptedPriceChange: (e: React.ChangeEvent<any>) => void
    onGatePriceChange: (e: React.ChangeEvent<any>) => void
    onStepSizeChange: (v: string) => void
    onCharmPricingChange: (v: boolean) => void
    getCurrency: (accountSlug: string) => Currency
    hasPermission: (permission: string, accountSlug: string) => boolean
    articleName: string
    pricingType: PricingType
    categoryIds: string[] | null
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    hideTopMessage: (id: string) => void
    viewMode: 'flat' | 'nested' | ''
    allCategories: ProductListWithPath[]
    isSubmitting: boolean
}

const initialValidities = {
    BOX: {
        valid: true,
        message: '',
    },
    GATE: {
        valid: true,
        message: '',
    },
}

function PricingDetailsForm(props: PricingDetailsFormProps) {
    const {
        accountSlug,
        originalPrice,
        gatePrice,
        articleName,
        pricingType,
        stepSize,
        charmPricing,
        isSubmitting,
    } = props
    const maxPricingSettingValue: number = 9999
    const articleConfigurationService = useContext(ArticleConfigurationServiceContext)
    const now = startOfToday()
    const currency = props.getCurrency(accountSlug)
    const [calendarData, setCalendarData] = useState<Price[] | null>(null)
    const [dateFrom, setDateFrom] = useState<Date>(now)
    const [dateTo, setDateTo] = useState<Date>(getEndDate(now))
    const [loading, setLoading] = useState<boolean>(false)
    const [validationMessages, setValidationMessages] = useState<ValidationMessage[]>([])
    const [validities, setValidities] = useState<Validities>(initialValidities)
    const [showCalendar, setShowCalendar] = useState<boolean>(false)
    const [typingFinished, setTypingFinished] = useState<boolean>(false)
    const [originalSentToPricer, setOriginalSentToPricer] = useState<number | null>(null)
    const [datesSentToPricer, setDatesSentToPricer] = useState<AvailabilityDates | null>(null)
    const [categorySentToPricer, setCategorySentToPricer] = useState<string>('')
    const [currentCat, setCurrentCat] = useState<string>(props.categoryIds ? props.categoryIds[0] : '')

    const fetchData = async () => {
        setLoading(true)
        const dates = await fetchAvailabilities()

        if (
            !dates ||
            !props.existingPricingSettings ||
            originalPrice == null ||
            (originalPrice === originalSentToPricer &&
                isEqual(dates, datesSentToPricer) &&
                categorySentToPricer === currentCat &&
                !!currentCat)
        ) {
            setLoading(false)
            return
        }
        const productId = props.match.params.id

        setOriginalSentToPricer(originalPrice)
        setDatesSentToPricer(dates)
        setCategorySentToPricer(currentCat)

        const productData = {
            id: productId,
            priceSettings: {
                productId: productId,
                account: accountSlug,
                originalPrice: String(originalPrice),
                stepSize: stepSize,
                charmPricing: charmPricing,
            },
            override: {},
        }
        try {
            let calendarPrices: Price[] = []
            if (!isAnyDateAvailable(dates)) {
                calendarPrices = generateUnavailableDates(dateFrom, dateTo)
            } else {
                const data = await AppServices.articlePricingService.getPricesForCalendar(
                    accountSlug,
                    currentCat,
                    productData,
                    format(dateFrom, 'yyyy-MM-dd'),
                    format(dateTo, 'yyyy-MM-dd'),
                )
                setValidationMessages(data.validationMessages)
                calendarPrices = data.prices.map((pr) => {
                    return {
                        date: pr.dt,
                        pricing: {
                            availability: dates[pr.dt]
                                ? dates[pr.dt].available
                                    ? ('AVAILABLE' as Availability)
                                    : ('NOT_AVAILABLE' as Availability)
                                : ('AVAILABLE' as Availability),
                            price: props.formatCurrencyString(pr.products[0].price, props.accountSlug),
                            weight: pr.color,
                        },
                        timeslots: getTimeslots(
                            data.prices.filter((price) => price.dt === pr.dt),
                            accountSlug,
                            props.formatCurrencyString,
                        ),
                    }
                })
            }
            setCalendarData(calendarPrices)
            setLoading(false)
        } catch {
            props.replaceTopMessages('server_error', 'error', 'There was a problem fetching the calendar prices.')
            await delay(3000)
            props.hideTopMessage('server_error')
            setLoading(false)
        }
    }

    const areSettingsValid = (): boolean => {
        const maxValidity = getValidity(originalPrice)
        const gateValidity = validateGatePrice(gatePrice, originalPrice)
        setValidities({
            BOX: maxValidity,
            GATE: gateValidity,
        })
        return maxValidity.valid && gateValidity.valid
    }

    useEffect(() => {
        if (!!typingFinished) {
            const settingsValid = areSettingsValid()
            if (settingsValid) {
                fetchData()
            }
            setTypingFinished(false)
        }
    }, [typingFinished])

    useEffect(() => {
        if (
            (!!gatePrice && gatePrice <= maxPricingSettingValue) ||
            (!!originalPrice && originalPrice <= maxPricingSettingValue)
        ) {
            const settingsValid = areSettingsValid()
            if (settingsValid && originalPrice === maxPricingSettingValue) {
                fetchData()
            }
        }
    }, [originalPrice, gatePrice])

    useEffect(() => {
        const settingsValid = areSettingsValid()
        if (settingsValid) {
            fetchData()
        }
    }, [currentCat, stepSize, charmPricing])

    const onFinishTyping = () => {
        setTypingFinished(true)
        props.hideTopMessage('validation_error')
    }

    const fetchAvailabilities = async (): Promise<AvailabilityDates | null> => {
        const productId = props.match.params.id
        try {
            const availabilityData = await articleConfigurationService.getAvailability(
                accountSlug,
                productId,
                currentCat || null,
                dateFrom,
                dateTo,
            )
            setShowCalendar(
                !!availabilityData && !!props.existingPricingSettings && (!!originalPrice || originalPrice === 0),
            )
            if (!availabilityData) {
                return null
            }
            return availabilityData
        } catch {
            props.replaceTopMessages('server_error', 'error', 'There was a problem fetching the availabilities.')
            return null
        }
    }

    useEffect(() => {
        const displayInfoMessage = async () => {
            props.replaceTopMessages(
                'calendar_info',
                'success',
                'By changing the price settings you will be able to see the pregenerated prices in the calendar.',
            )
            await delay(9000)
            props.hideTopMessage('calendar_info')
        }

        if (pricingType === 'static') {
            if (
                !window.localStorage.getItem('CalendarExplanationShown') ||
                window.localStorage.getItem('CalendarExplanationShown') === 'false'
            ) {
                displayInfoMessage()
                window.localStorage.setItem('CalendarExplanationShown', 'true')
            }
            fetchData()
        }
    }, [])

    useEffect(() => {
        if (pricingType !== 'static') {
            return
        }
        fetchData()
    }, [dateFrom, dateTo])

    useEffect(() => {
        const displayValidationMessages = () => {
            let messagesText = ''
            validationMessages.forEach((msg) => {
                messagesText = messagesText ? messagesText + '; ' + msg.message : msg.message
            })
            props.replaceTopMessages('calendar_warning', 'warn', messagesText)
        }
        if (validationMessages.length > 0) {
            displayValidationMessages()
        } else {
            props.hideTopMessage('calendar_warning')
        }
    }, [validationMessages])

    const categories = useGetAllCategoriesForSelect()
    const getCategoryOptions = (): SingleSelectOption[] => {
        return categories?.filter((cat) => props.categoryIds?.includes(cat.value)) || []
    }

    return (
        <form noValidate id="product-pricing-detail-form">
            <Row>
                <Column style={{ zIndex: 11 }}>
                    <FormItem htmlFor="productName">
                        <FormItemName style={{ display: 'flex' }}>Product name</FormItemName>
                        <TextInput value={articleName} block disabled />
                    </FormItem>
                    <FormItem htmlFor="productType">
                        <FormItemName style={{ display: 'flex' }}>Pricing type</FormItemName>
                        <TextInput value={pricingType} block disabled />
                    </FormItem>
                    <FormItem htmlFor="maxAccepted">
                        <FormItemName style={{ display: 'flex' }}>
                            Price
                            <Infotip pointer="left" maxWidth="18em">
                                The maximum price your visitors might pay. Usually your box office price.
                            </Infotip>
                        </FormItemName>
                        <WrapWithSymbol symbol={currency.symbol} position="left">
                            <NumberInput
                                align="left"
                                id="maxAccepted"
                                name="maxAccepted"
                                maxLength={7}
                                max={maxPricingSettingValue}
                                value={originalPrice !== null ? originalPrice : ''}
                                onChange={props.onMaxAcceptedPriceChange}
                                onFinishTyping={onFinishTyping}
                                status={validities['BOX'].valid ? 'normal' : 'error'}
                                block={true}
                            />
                        </WrapWithSymbol>
                        <ValidationNotice className={!validities['BOX'].valid ? 'validation-message-visible' : ''}>
                            {validities['BOX'].message}
                        </ValidationNotice>
                    </FormItem>
                    <TextWithHorizontalLine
                        text="OPTIONAL SETTINGS"
                        alignment="left"
                        style={{ marginTop: '1em', marginBottom: '1.7em' }}
                    />
                    <Row>
                        <Column>
                            <FormItem htmlFor="gatePrice">
                                <FormItemName style={{ display: 'flex' }}>
                                    Gate price
                                    <Infotip pointer="left" maxWidth="18em">
                                        The box office price. This is the price your users will see stroked out to
                                        emphasise the discounted price.
                                    </Infotip>
                                </FormItemName>
                                <WrapWithSymbol symbol={currency.symbol} position="left">
                                    <NumberInput
                                        align="left"
                                        id="gatePrice"
                                        name="gatePrice"
                                        maxLength={7}
                                        max={maxPricingSettingValue}
                                        value={gatePrice !== null ? gatePrice : ''}
                                        onChange={props.onGatePriceChange}
                                        onFinishTyping={onFinishTyping}
                                        status={validities['GATE'].valid ? 'normal' : 'error'}
                                        block={true}
                                    />
                                </WrapWithSymbol>
                                <ValidationNotice
                                    className={!validities['GATE'].valid ? 'validation-message-visible' : ''}
                                >
                                    {validities['GATE'].message}
                                </ValidationNotice>
                            </FormItem>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <FormItem htmlFor="stepSize">
                                <FormItemName style={{ display: 'flex' }}>
                                    Price step size
                                    <Infotip pointer="left" maxWidth="26em">
                                        {priceStepSizeInfotipText}
                                    </Infotip>
                                </FormItemName>
                                <SingleSelect
                                    id="stepSize"
                                    options={priceStepSizeOptions}
                                    selected={stepSize}
                                    height="2.5rem"
                                    maxHeight="11em"
                                    noSelectOption="Select step size"
                                    onSelect={props.onStepSizeChange}
                                    style={{ marginBottom: '0.625em', zIndex: 11 }}
                                />
                            </FormItem>
                        </Column>
                        <FormTogglerCol span={6}>
                            <FormItemName style={{ display: 'flex' }}>
                                Charm pricing
                                <Infotip pointer="left" maxWidth="26em">
                                    {charmPricingInfotipText}
                                </Infotip>
                            </FormItemName>
                            <FormToggler
                                id="card-open-by-default"
                                isOn={charmPricing}
                                onClick={props.onCharmPricingChange}
                            />
                        </FormTogglerCol>
                    </Row>
                </Column>
                <ColumnGap />
                <Column>
                    {pricingType === 'static' && showCalendar && (
                        <>
                            <TextWithHorizontalLine
                                text="CALENDAR PREVIEW"
                                alignment="left"
                                style={{ marginTop: '1em', marginBottom: '1.7em' }}
                            />
                            {!!props.categoryIds && props.categoryIds.length > 0 && (
                                <SingleSelect
                                    id="cat-select"
                                    options={getCategoryOptions()}
                                    selected={currentCat}
                                    height="2.5rem"
                                    maxHeight="11em"
                                    noSelectOption="Select product category"
                                    onSelect={setCurrentCat}
                                    style={{ marginBottom: '0.625em', zIndex: 11 }}
                                />
                            )}
                            <CalendarBlock
                                calendarData={calendarData}
                                loading={loading}
                                now={now}
                                onMonthChange={(month: Date) => {
                                    setDateFrom(getStartDate(month))
                                    setDateTo(getEndDate(month))
                                }}
                            />
                        </>
                    )}
                </Column>
            </Row>

            <ButtonWrapper>
                <ActionButton
                    size="large"
                    secondary
                    onClick={() => {
                        props.history.push(`/account/${accountSlug}/products/home/${props.viewMode}`)
                    }}
                >
                    {props.hasPermission('edit_pricing_settings', accountSlug) ? 'Cancel' : 'Back'}
                </ActionButton>
                {props.hasPermission('edit_pricing_settings', accountSlug) && (
                    <LoadingButton
                        id="saveSettings"
                        type="button"
                        size="large"
                        variant="contained"
                        disabled={!(validities['BOX'].valid && validities['GATE'].valid)}
                        onClick={props.onSettingsSave}
                        style={{ marginLeft: '1.5em' }}
                        loading={isSubmitting}
                    >
                        Save
                    </LoadingButton>
                )}
            </ButtonWrapper>
        </form>
    )
}

const mapStateToProps = (state: State) => {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
    }
}

export default connect(mapStateToProps)(withFeatures(withCurrency(PricingDetailsForm)))
