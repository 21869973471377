import React, { useState, useEffect, useContext } from 'react'
import { ChannelsServiceContext } from 'channels/context'
import { ResellerResponseItem } from 'settings/settingsService'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import Filter from 'uiComponents/filter'
import { OrdersService, Location } from 'orders/ordersService'
import { MessageKind } from 'uiComponents/messages'
import { staticMetadaCategories, applicableFilters } from 'orders/ordersFilterConfig'
import { MetaData, FilterCategory, ProductsMetaData } from 'uiComponents/filter/schema'
import { Locale } from 'admin/articleService'
import { getProductOptions, formatFlatOptions } from 'uiComponents/filter/filterHelpers'
import { withFeatures } from 'features'
import { usePermission } from 'admin/hocs'

interface OrdersFilterProps {
    page: 'orders' | 'tickets' | 'ordersBeta'
    navigation: Navigation
    match: RouteMatch<any>
    accountSlug: string
    ordersService: OrdersService
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function OrdersFilter(props: OrdersFilterProps) {
    const { hasPermission } = usePermission()
    const channelsService = useContext(ChannelsServiceContext)
    const [metaData, setMetaData] = useState<MetaData[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        getData()
    }, [props.accountSlug])

    const getData = async () => {
        try {
            setLoading(true)
            let channelsData: ResellerResponseItem[] = []
            if (hasPermission('partner_admin', props.accountSlug)) {
                const query = '?include_archived=true'
                channelsData = await channelsService.getResellersList(props.accountSlug, query)
            }
            const accountData = await props.ordersService.getProductListsWithArticles(props.accountSlug)
            let articles: ProductsMetaData[] = []
            if (accountData.productsLists.length === 0) {
                const articlesList = await props.ordersService.getMinimalArticlesList(props.accountSlug)
                articles = articlesList as any as ProductsMetaData[]
            }
            const productsData = accountData.productsLists.length > 0 ? accountData.productsLists : articles
            const categories: MetaData[] = [
                ...staticMetadaCategories,
                getProductCategory(productsData),
                getChannelsCategory(channelsData),
                getLocationCategory(accountData.locations),
                getLanguageCategory(accountData.locales),
            ]
            const categoriesInOrder: MetaData[] = []
            applicableFilters[props.page].forEach((f) => {
                const category = categories.find((c) => c.category === f)
                if (!!category) {
                    categoriesInOrder.push(category)
                }
            })
            setMetaData(categoriesInOrder)
        } catch {
            props.replaceMessages(
                'server_error',
                'error',
                'Oops! We could not get order filter details. Please try again later.',
            )
        } finally {
            setLoading(false)
        }
    }

    const getProductCategory = (productsData: ProductsMetaData[]) => {
        return {
            category: 'products' as FilterCategory,
            options: getProductOptions(productsData),
        }
    }

    const getLocationCategory = (locations: Location[]) => {
        const optionsConfig = locations.map((l) => ({
            name: l.name,
            slug: l.uuid,
        }))
        return {
            category: 'location_id' as FilterCategory,
            options: formatFlatOptions('location_id', optionsConfig),
        }
    }

    const getLanguageCategory = (locales: Locale[]) => {
        const optionsConfig = locales.map((l) => ({ name: l.name, slug: l.code }))
        return {
            category: 'language' as FilterCategory,
            options: formatFlatOptions('language', optionsConfig),
        }
    }

    const getChannelsCategory = (resellers: ResellerResponseItem[]) => {
        const options =
            resellers.length > 0
                ? [
                      {
                          name: 'Direct Sales',
                          slug: 'direct_sales',
                          category: 'channels' as FilterCategory,
                          parents: [],
                          children: [],
                          leafNode: true,
                      },
                      {
                          name: 'All resellers',
                          slug: 'all_resellers',
                          category: 'channels' as FilterCategory,
                          parents: [],
                          children: mapResellerOptions(resellers),
                          leafNode: false,
                      },
                  ]
                : []
        return {
            category: 'channels' as FilterCategory,
            options,
        }
    }

    const mapResellerOptions = (resellers: ResellerResponseItem[]) => {
        return resellers.map((r) => ({
            name: r.name,
            slug: r.resellerId,
            category: 'channels' as FilterCategory,
            parents: ['all_resellers'],
            children: [],
            leafNode: true,
        }))
    }

    const getApplicableFilters = () => {
        const filtersToHide: FilterCategory[] = []
        if (
            !hasPermission('partner_admin', props.accountSlug) &&
            !props.hasFeature('ResellersFeature', props.accountSlug)
        ) {
            filtersToHide.push('channels')
        }
        return applicableFilters[props.page].filter((f) => filtersToHide.indexOf(f) < 0)
    }

    return (
        <Filter
            accountSlug={props.accountSlug}
            compact
            metaData={metaData}
            applicableFilters={getApplicableFilters()}
            standaloneFilters={[]}
            singleSelectCategories={[]}
            loading={loading}
        />
    )
}

export default withFeatures(withNavigation(OrdersFilter))
