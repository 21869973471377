import { IconProp } from '@fortawesome/fontawesome'
import {
    faAnalytics,
    faBullhorn,
    faCog,
    faHome,
    faMapMarkerAlt,
    faShoppingBasket,
    faClipboard,
    faTag,
    faTicketAlt,
    faUserCog,
    faUserFriends,
} from '@fortawesome/pro-regular-svg-icons'
import AdminRequired from 'admin/adminRequired'
import Permission from 'admin/permissionRequired'
import { User } from 'auth/state'
import classNames from 'classnames'
import Feature from 'features/feature'
import { LoggingService } from 'http/loggingService'
import { ORDER_PATHS } from 'orders/paths'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useListSupersetReports } from 'reports/superset/hooks'
import MenuItem from 'sidebar/sidebarMenuItem'
import { pathUtils } from 'utils/pathnameFormatter'
import { useLazyListOrdersQuery } from '../orders/reduxQueries'
import { isSidebarOpen } from './selectors'
import { AccountSelector } from './sidebarAccountSelector'
import { RELEASED_BADGE } from 'releaseNotes/htmlComponents'
import './styles.scss'

interface SidebarProps {
    accountSlug: string
    selfSignUp: string | null
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, accountSlug?: string) => boolean
    loggingService: LoggingService
    user: User | null
}

export function SidebarMenu(props: Readonly<SidebarProps>) {
    const { accountSlug, selfSignUp, hasPermission, hasFeature, loggingService, user } = props
    const showProducts =
        (hasPermission('view_pricing_settings', accountSlug) || hasPermission('view_product_validity', accountSlug)) &&
        !props.user?.resellerId
    const isNotReseller = !props.user?.resellerId
    const showChannelsManagerPage =
        hasFeature('ResellersFeature', accountSlug) ||
        hasFeature('RetailersReservationCodes', accountSlug) ||
        hasFeature('GoogleThingsToDo', accountSlug)
    const [openItem, setOpenItem] = React.useState<string>('')
    const open = useSelector(isSidebarOpen)
    const supersetReports = useListSupersetReports()
    const [_, status] = useLazyListOrdersQuery()
    const isNewCartPageEnabled = hasFeature('show_new_cart_page', accountSlug)

    function logClick(path: string) {
        if (path === 'bug_report') {
            loggingService.logAction('bug_report')
        } else {
            loggingService.logAction('navigation', {
                click_to: path,
                source: 'sidebar',
            })
        }
    }

    function clickChildless(path: string) {
        logClick(path)
        setOpenItem(path)
    }

    return (
        <div
            className={classNames('sidebar-wrapper', {
                'with-impersonation-banner': !!user?.impersonatedBy,
                open: open,
            })}
        >
            <div className="account-selector">
                <AccountSelector selfSignUp={selfSignUp} />
            </div>
            <nav>
                <ul className="menu">
                    {isNotReseller && (
                        <MenuItem
                            path={`/account/${accountSlug}/dashboard/overview/`}
                            text="Dashboard"
                            onClick={clickChildless}
                            icon={faHome as IconProp}
                            bordered
                        />
                    )}
                    <Permission name="access_orders" accountSlug={accountSlug}>
                        <MenuItem
                            path={`/account/${accountSlug}/orders/`}
                            text="Orders"
                            icon={faShoppingBasket as IconProp}
                            onClick={setOpenItem}
                            close={openItem !== `/account/${accountSlug}/orders/`}
                        >
                            <Feature name="oldOrdersPages" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/orders/transactions/`}
                                    text="Orders"
                                    onClick={logClick}
                                    withOld
                                />
                                <MenuItem
                                    path={`/account/${accountSlug}/orders/details/`}
                                    text="Orders Details"
                                    onClick={logClick}
                                    withOld
                                />
                            </Feature>
                            <MenuItem
                                path={`/account/${accountSlug}/orders/orders/`}
                                text="Order List"
                                onClick={logClick}
                            />
                            <Feature name="PaypalResolutionCenter" accountSlug={accountSlug}>
                                <Permission name="partner_admin" accountSlug={accountSlug}>
                                    <MenuItem
                                        count={status.currentData?.openDisputeCount ?? 0}
                                        path={`/account/${accountSlug}/orders/paypal/disputes/active`}
                                        text="Order Disputes"
                                        onClick={logClick}
                                    />
                                </Permission>
                            </Feature>
                            <Feature name="openCartView" accountSlug={accountSlug}>
                                <MenuItem
                                    path={pathUtils.populateParams(ORDER_PATHS.fullPaths.cartPage, { accountSlug })}
                                    text={isNewCartPageEnabled ? 'Pending Reservations' : 'Carts'}
                                    onClick={logClick}
                                />
                            </Feature>
                            {showChannelsManagerPage && (
                                <Permission name="partner_admin" accountSlug={accountSlug}>
                                    <MenuItem
                                        path={`/account/${accountSlug}/orders/channels/`}
                                        text="Channels"
                                        onClick={logClick}
                                    />
                                </Permission>
                            )}
                        </MenuItem>
                    </Permission>
                    {showProducts && (
                        <MenuItem
                            path={`/account/${accountSlug}/products`}
                            text="Products"
                            icon={faTicketAlt as IconProp}
                            onClick={setOpenItem}
                            close={openItem !== `/account/${accountSlug}/products`}
                        >
                            <MenuItem
                                path={`/account/${accountSlug}/products/home/nested`}
                                text="Products"
                                onClick={logClick}
                            />
                            <Feature name="productsBeta" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/products/productTable`}
                                    text="Products"
                                    withBeta
                                    onClick={logClick}
                                />
                            </Feature>
                            <Feature name="Pricing" accountSlug={accountSlug}>
                                <Permission name="view_pricing_settings" accountSlug={accountSlug}>
                                    <MenuItem
                                        path={`/account/${accountSlug}/products/pricing/exceptions/list`}
                                        text="Pricing Exceptions"
                                        onClick={logClick}
                                    />
                                </Permission>
                            </Feature>
                            <Permission name="view_product_validity" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/products/validity/exceptions/list`}
                                    text="Validity Exceptions"
                                    onClick={logClick}
                                />
                            </Permission>
                            <Permission name="partner_admin" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/products/inventory/home/`}
                                    text="Crowd Control"
                                    onClick={logClick}
                                />
                                <MenuItem
                                    path={`/account/${accountSlug}/products/time_slots/home/`}
                                    text="Time Slots"
                                    onClick={logClick}
                                />
                                <MenuItem
                                    path={`/account/${accountSlug}/products/apf_studio/`}
                                    text="APF Studio"
                                    onClick={logClick}
                                />
                                <Feature name="DiscountRulesPage" accountSlug={accountSlug}>
                                    <MenuItem
                                        path={`/account/${accountSlug}/products/discount_rules/home/`}
                                        text="Discount Rules"
                                        onClick={logClick}
                                    />
                                </Feature>
                            </Permission>
                        </MenuItem>
                    )}
                    {isNotReseller && (
                        <MenuItem
                            path={`/account/${accountSlug}/venue/`}
                            text="Venue"
                            icon={faMapMarkerAlt as IconProp}
                            bordered
                            onClick={setOpenItem}
                            close={openItem !== `/account/${accountSlug}/venue/`}
                        >
                            <Permission name="partner_admin" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/venue/opening_times/`}
                                    text="Opening Times"
                                    onClick={logClick}
                                />
                            </Permission>
                            <Permission name="access_codes" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/venue/codes`}
                                    text="Codes"
                                    onClick={logClick}
                                />
                            </Permission>
                            <Feature name="parkMap" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/venue/park_map`}
                                    text="Park Map"
                                    onClick={logClick}
                                />
                            </Feature>
                            <Feature name="is_capacity_report_enabled" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/venue/capacity_report`}
                                    text="Capacity"
                                    onClick={logClick}
                                    withBeta
                                />
                            </Feature>
                        </MenuItem>
                    )}
                    <Feature name="pricingMenu" accountSlug={accountSlug}>
                        <Permission name="partner_admin" accountSlug={accountSlug}>
                            <MenuItem
                                path={`/account/${accountSlug}/global_pricing_settings`}
                                text="Pricing"
                                icon={faTag as IconProp}
                                onClick={setOpenItem}
                                close={openItem !== `/account/${accountSlug}/global_pricing_settings`}
                                bordered
                            >
                                <MenuItem
                                    path={`/account/${accountSlug}/global_pricing_settings`}
                                    text="Global Pricing"
                                    onClick={logClick}
                                />
                            </MenuItem>
                        </Permission>
                    </Feature>
                    <Permission name="partner_admin" accountSlug={accountSlug}>
                        <MenuItem
                            path={`/account/${accountSlug}/crm/`}
                            text="CRM"
                            icon={faUserFriends as IconProp}
                            bordered
                            onClick={setOpenItem}
                            close={openItem !== `/account/${accountSlug}/crm/`}
                        >
                            <Feature name="CRMProfilesPage" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/crm/profiles/list`}
                                    text="Profiles"
                                    onClick={logClick}
                                />
                            </Feature>
                            <MenuItem
                                path={`/account/${accountSlug}/crm/audiences/list`}
                                text="Audiences"
                                onClick={logClick}
                            />
                        </MenuItem>
                    </Permission>
                    <Permission name="view_wonderbar" accountSlug={accountSlug}>
                        <MenuItem
                            path={`/account/${accountSlug}/engage/`}
                            text="Engage"
                            icon={faBullhorn as IconProp}
                            bordered
                            onClick={setOpenItem}
                            close={openItem !== `/account/${accountSlug}/engage/`}
                        >
                            <MenuItem
                                path={`/account/${accountSlug}/engage/tools/home/checkout`}
                                text="Engage Tools"
                                onClick={logClick}
                            />
                            <Permission name="partner_admin" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/engage/copy_editor/home/`}
                                    text="Copy Editor"
                                    onClick={logClick}
                                />
                                <MenuItem
                                    path={`/account/${accountSlug}/engage/email_templates/home/`}
                                    text="Email Templates"
                                    onClick={logClick}
                                />
                                <MenuItem
                                    path={`/account/${accountSlug}/engage/ticket_templates/`}
                                    text="Ticket Templates"
                                    onClick={logClick}
                                />
                                <MenuItem
                                    path={`/account/${accountSlug}/engage/gift_card_templates/`}
                                    text="Gift Card Templates"
                                    onClick={logClick}
                                />
                                <MenuItem
                                    path={`/account/${accountSlug}/engage/notification-campaigns/overview/`}
                                    text="App Push Notifications"
                                    onClick={logClick}
                                />
                            </Permission>
                        </MenuItem>
                    </Permission>
                    <Permission name="access_analytics" accountSlug={accountSlug}>
                        <MenuItem
                            path={`/account/${accountSlug}/reports/`}
                            text="Reports"
                            icon={faAnalytics as IconProp}
                            bordered
                            onClick={setOpenItem}
                            close={openItem !== `/account/${accountSlug}/reports/`}
                        >
                            <MenuItem
                                path={`/account/${accountSlug}/reports/overview/`}
                                text="Overview"
                                onClick={logClick}
                            />
                            <Feature name="sale_reports" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/reports/sales/`}
                                    text="Sales Overview"
                                    userpilot="menu-sales"
                                    onClick={logClick}
                                />
                            </Feature>
                            {supersetReports.map((report) => (
                                <MenuItem
                                    key={report.dashboardId}
                                    path={`/account/${accountSlug}/reports/superset/${report.dashboardId}/`}
                                    text={report.menuTitle}
                                    onClick={logClick}
                                />
                            ))}
                            <Feature name="ForecastChartInOverview" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/reports/forecast/`}
                                    text="Forecast"
                                    onClick={logClick}
                                />
                            </Feature>
                            <MenuItem
                                path={`/account/${accountSlug}/reports/pricing/`}
                                text="Pricing"
                                userpilot="menu-pricing"
                                onClick={logClick}
                            />
                            <MenuItem
                                path={`/account/${accountSlug}/reports/traffic/`}
                                text="Traffic"
                                userpilot="menu-traffic"
                                onClick={logClick}
                            />
                            <Feature name="CheckoutFunnelReport" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/reports/checkout_funnel/`}
                                    text="Checkout Funnel"
                                    onClick={logClick}
                                />
                            </Feature>
                            <MenuItem
                                path={`/account/${accountSlug}/reports/customers/`}
                                text="Customers"
                                userpilot="menu-customers"
                                onClick={logClick}
                            />
                            <MenuItem
                                path={`/account/${accountSlug}/reports/emails/`}
                                text="Emails"
                                onClick={logClick}
                            />
                            <MenuItem
                                path={`/account/${accountSlug}/reports/barcodes/`}
                                text="Barcodes"
                                onClick={logClick}
                            />
                            <MenuItem
                                path={`/account/${accountSlug}/reports/discount_codes/`}
                                text="Discount Codes"
                                onClick={logClick}
                            />
                            <Feature name="CRMReportPage" accountSlug={accountSlug}>
                                <MenuItem path={`/account/${accountSlug}/reports/crm/`} text="CRM" onClick={logClick} />
                            </Feature>
                            <Permission name="view_wonderbar" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/reports/engage_tools/`}
                                    text="Engage Tools"
                                    onClick={logClick}
                                />
                            </Permission>
                            <Feature name="NotificationCampaignReport" accountSlug={accountSlug}>
                                <MenuItem
                                    path={`/account/${accountSlug}/reports/notification_campaigns/`}
                                    text="App Push Notifications"
                                    onClick={logClick}
                                />
                            </Feature>
                        </MenuItem>
                    </Permission>
                    {isNotReseller && (
                        <Feature name="release_notes_page" accountSlug={accountSlug}>
                            <MenuItem
                                path={`/account/${accountSlug}/release_notes/`}
                                text="What's New"
                                onClick={logClick}
                                icon={faClipboard as IconProp}
                                bordered
                                rightComponent={<div dangerouslySetInnerHTML={{ __html: RELEASED_BADGE }} />}
                            />
                        </Feature>
                    )}
                    <Permission name="partner_admin" accountSlug={accountSlug}>
                        <MenuItem
                            path={`/account/${accountSlug}/settings`}
                            text="Settings"
                            icon={faCog as IconProp}
                            onClick={setOpenItem}
                            close={openItem !== `/account/${accountSlug}/settings`}
                        >
                            <MenuItem
                                path={`/account/${accountSlug}/settings/account_settings/`}
                                text="Account"
                                onClick={logClick}
                            />
                            <MenuItem
                                path={`/account/${accountSlug}/settings/add_snippet/`}
                                text="Script"
                                onClick={logClick}
                            />
                            <MenuItem
                                path={`/account/${accountSlug}/settings/users/`}
                                text="Users"
                                onClick={logClick}
                            />
                        </MenuItem>
                    </Permission>
                    <AdminRequired>
                        <MenuItem
                            path="/admin/"
                            text="Admin"
                            icon={faUserCog as IconProp}
                            onClick={setOpenItem}
                            close={openItem !== '/admin/'}
                        >
                            <MenuItem path="/kpis/" text="Metrics" onClick={logClick} />
                            <MenuItem path="/admin/onboarding/" text="Onboarding" onClick={logClick} />
                            <Permission name="fund_transfers" accountSlug={accountSlug}>
                                <MenuItem path="/admin/fund_transfer/" text="Fund transfer" onClick={logClick} />
                            </Permission>
                        </MenuItem>
                    </AdminRequired>
                </ul>
            </nav>
            <div className="bottom-section">
                <div className="button-wrapper">
                    <div id="google_translate_element" />
                </div>
                <div className="app-version" data-version={process.env.VERSION} title={process.env.VERSION}>
                    {process.env.VERSION}
                </div>
            </div>
        </div>
    )
}
