import React from 'react'
import { useHistory, useParams } from 'react-router'

import { AccessDeniedPage } from 'admin/accessDeniedPage'
import { BaseRouteParams } from 'hocs'
import { usePermission } from 'admin/hocs'
import { Account } from 'auth/state'
import { connect } from 'react-redux'
import { State } from 'store'

interface WithRoutePermissionProps {
    requiredPermissions: string[]
    accounts: Account[]
}

const WithRoutePermission: React.FC<WithRoutePermissionProps> = ({
    requiredPermissions,
    accounts,
    children,
}) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const history = useHistory()
    const { hasPermission } = usePermission()
    
    if (accounts.length === 0) {
        history.push('/login/?next=' + encodeURIComponent(location.pathname + location.search))
    }

    const hasRequiredPermission = !!requiredPermissions.find((p) => hasPermission(p, accountSlug))

    return hasRequiredPermission ? <>{children}</> : <AccessDeniedPage />
}

const mapStateToProps = (state: State) => {
    return {
        accounts: state.auth?.user?.accounts || [],
    }
}

export default connect(mapStateToProps)(WithRoutePermission)
