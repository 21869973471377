import React from 'react'
import { usePermission } from 'admin/hocs'
import { InlineEdit } from 'uiComponents/input'
import { useUpdateArticlePriorityMutation, useUpdateProductListStatusAndPriorityMutation } from 'products/reduxApi'

interface Props {
    priority: number
    id: string
    enabled: boolean
    isArticle: boolean
}

const Priority: React.FC<Props> = ({ priority, id, enabled, isArticle }) => {
    const [updatePLStatusAndPriority] = useUpdateProductListStatusAndPriorityMutation()
    const [updateArticlePriority] = useUpdateArticlePriorityMutation()
    const { hasPermission } = usePermission()

    function onEditAccept(prio: string) {
        if (isArticle) {
            updateArticlePriority({ id, priority: +prio })
        } else {
            updatePLStatusAndPriority({ id, enabled, priority: +prio })
        }
    }

    return (
        <div>
            <InlineEdit
                isNumber
                integerOnly
                min={1}
                max={1000}
                limitedTextWidth="5em"
                placeholder="Enter"
                disabled={!hasPermission('partner_admin')}
                id="product_priority_inline_edit"
                value={priority}
                onEditAccept={onEditAccept}
            />
        </div>
    )
}

export default Priority
