import { Locale } from 'admin/articleService'
import { ArticleServiceContext } from 'admin/context'
import { useFetchAudiences } from 'crm/audiences/hooks/useFetchAudiences'
import { crmSelectors } from 'crm/selectors'
import { compareDesc, format } from 'date-fns'
import { ComponentsService } from 'engageTools/studio/componentsService'
import { withFeatures } from 'features'
import { History } from 'history'
import { withNavigation } from 'hocs'
import { cloneDeep } from 'lodash'
import { Navigation } from 'navigation'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { match as RouteMatch } from 'react-router-dom'
import NavigationPrompt from 'react-router-navigation-prompt'
import { Message } from 'uiComponents/messages'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { AudienceModal } from 'uiComponents/studio/audienceModal'
import Header from 'uiComponents/studio/header'
import { MobileDeviceWarning } from 'uiComponents/studio/mobileDeviceWarning'
import { onNavigateAway } from 'uiComponents/studio/onNavigateAway'
import { Body, Page, Side } from 'uiComponents/studio/pageComponents'
import { PreviewContainer, PreviewSize } from 'uiComponents/studio/preview/components'
import {
    MenuStepType,
    PlacementAudiencePayload,
    PlacementContent,
    PlacementContentPayload,
    PlacementPayload,
    PlacementResponse,
} from '../schema'
import { TapPlacementService } from '../service'
import Form from './form'
import { useDateInput, useFormInput, usePrimaryColor, useVenueLocations } from './helpers'
import { TemplatePreview } from './preview'
import { useMessages } from 'messagesContext'

interface StudioProps {
    tapPlacementService: TapPlacementService
    history: History
    navigation: Navigation
    match: RouteMatch<any>
    componentsService: ComponentsService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

const Studio = (props: StudioProps) => {
    const { accountSlug } = props.match.params
    const NAVIGATION_PATH = `/account/${accountSlug}/engage/tools`
    const DEFAULT_LOCALE = 'en'

    const [previewSize, setPreviewSize] = React.useState<PreviewSize>('mobile')
    const [currentStep, setCurrentStep] = React.useState<MenuStepType>('type')
    const defaultLanguage = useFormInput(DEFAULT_LOCALE)
    const name = useFormInput('')
    const placementSize = useFormInput('WIDE')
    const backgroundColor = useFormInput('DARK')
    const customBackgroundColor = useFormInput('')
    const image = useFormInput('')
    const buttonAction = useFormInput('')
    const actionLink = useFormInput('')
    const startDate = useDateInput()
    const endDate = useDateInput()
    const [accountLocales, setAccountLocales] = React.useState<Locale[]>([])
    const [translations, setTranslations] = React.useState<PlacementContent[]>([
        { locale: DEFAULT_LOCALE, default: true },
    ])
    const [activeTranslation, setActiveTranslation] = React.useState<PlacementContent>(translations[0])
    const activeLanguage = useFormInput(DEFAULT_LOCALE)
    const [editingPlacementId, setEditingPlacementId] = React.useState<string>()
    const [loading, setLoading] = React.useState<boolean>(false)
    const brandColor = usePrimaryColor(props.componentsService, accountSlug)
    const active = useFormInput('ACTIVE')
    const articleService = React.useContext(ArticleServiceContext)
    const audience = useFormInput('')
    const hasPlacementAudienceFeature = React.useMemo(
        () => props.hasFeature('CRMAudiencesPage', accountSlug),
        [accountSlug],
    )
    const hasPlacementLocationFeature = React.useMemo(
        () => props.hasFeature('PlacementLocation', accountSlug),
        [accountSlug],
    )
    const audiences = useFetchAudiences()

    const venueLocations = useVenueLocations(accountSlug)
    const selectedVenueLocation = useFormInput('')
    const marketingConsent = useFormInput()
    const shouldShowAudienceModal = () => {
        return audience.value.length > 0 && !marketingConsent.value
    }
    const [showAudienceModal, setShowAudienceModal] = React.useState(false)
    const { replaceMessages, removeAllMessages } = useMessages()

    React.useEffect(() => {
        if (!translationExists(activeLanguage.value, translations)) {
            const copy = cloneDeep(translations)
            copy.push({
                locale: activeLanguage.value,
                default: defaultLanguage.value === activeLanguage.value,
            })

            setTranslations(copy)
        }

        translations.forEach((translation) => {
            if (translation.locale === activeLanguage.value) {
                setActiveTranslation(translation)
            }
        })
    }, [activeLanguage])

    React.useEffect(() => {
        translations.forEach((translation) => {
            translation.default = translation.locale === defaultLanguage.value
        })
    }, [defaultLanguage])

    React.useEffect(() => {
        const step = props.match.params.step || 'type'
        setCurrentStep(step)
    }, [props.match.params.step])

    React.useEffect(() => {
        audiences.fetch()
        const getData = async () => {
            try {
                const localesDetails = await articleService.getAccountLocales(accountSlug)
                setAccountLocales(localesDetails.locales)
            } catch {
                replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again later.')
            }
        }

        getData()
    }, [])

    React.useEffect(() => {
        const placementId = props.match.params.id
        setEditingPlacementId(placementId)
        fetchPlacement(placementId)
    }, [])

    const fetchPlacement = async (placementId?: string) => {
        if (placementId) {
            setLoading(true)

            try {
                const placement = await props.tapPlacementService.fetch(placementId, accountSlug)
                mapPlacementToState(placement)
                setLoading(false)
            } catch {
                replaceMessages(
                    'server_error',
                    'error',
                    'Oops! We were unable to retrieve the placement. Please try again later.',
                )
                setLoading(false)
            }
        }
    }

    const mapPlacementToState = (placement: PlacementResponse) => {
        name.onChange(placement.name)
        placementSize.onChange(placement.style.size)
        backgroundColor.onChange(placement.style.theme)
        image.onChange(placement.style.image)
        active.onChange(placement.active ? 'ACTIVE' : 'INACTIVE')
        customBackgroundColor.onChange(placement.style.background)
        marketingConsent.onChange(!!placement.consentRequired)

        if (placement.locationId) {
            selectedVenueLocation.onChange(placement.locationId)
        }

        if (placement.startDate) {
            startDate.onChange('date')
            startDate.updateDate(new Date(placement.startDate))
            startDate.updateHours(format(new Date(placement.startDate), 'HH:mm'))
        }

        if (placement.endDate) {
            endDate.onChange('date')
            endDate.updateDate(new Date(placement.endDate))
            endDate.updateHours(format(new Date(placement.endDate), 'HH:mm'))
        } else {
            endDate.onChange('never')
        }

        if (placement.action) {
            actionLink.onChange(placement.action.value)
        }

        const allTranslations: PlacementContent[] = []
        placement.content.forEach((content: any) => {
            const translation: PlacementContent = {
                locale: content.locale,
                default: content.default,
                title: content.title,
                subtitle: content.subtitle,
            }

            if (content.default) {
                defaultLanguage.onChange(content.locale)
            }

            if (content.buttons && content.buttons.length > 0) {
                translation.buttonCaption = content.buttons[0].caption || ''
            }

            allTranslations.push(translation)
        })
        setTranslations(allTranslations)
        setActiveTranslation(allTranslations[0])

        if (!!placement?.audiences?.length) {
            audience.onChange(placement.audiences.map((_audience) => _audience.audience))
        }
    }

    const validatePlacement = (placementInput: PlacementPayload): string | undefined => {
        if (placementInput.name === '') {
            return 'Please enter a name for the placement'
        }

        if (translations.length) {
            if (translations[0].title === '' && translations[0].subtitle === '') {
                return 'Please enter some content for the placement'
            }
        }

        if (placementInput.endDate) {
            const placementEndDate = placementInput.endDate
            const now = new Date()

            if (compareDesc(new Date(placementEndDate), now) >= 0) {
                return 'End date is earlier than today'
            }
        }

        return undefined
    }

    const mapStateToPlacementPayload = (): PlacementPayload => {
        const content: PlacementContentPayload[] = []

        translations.forEach((translation) => {
            const translationItem: PlacementContentPayload = {
                title: translation.title || '',
                subtitle: translation.subtitle || '',
                locale: translation.locale,
                default: defaultLanguage.value === translation.locale,
                buttons: [],
            }

            if (translation.buttonCaption) {
                translationItem.buttons = [
                    {
                        caption: translation.buttonCaption,
                        action: {
                            value: actionLink.value,
                        },
                    },
                ]
            }

            content.push(translationItem)
        })

        const formatAudience = (): PlacementAudiencePayload[] | undefined => {
            if (!hasPlacementAudienceFeature || !Array.isArray(audience.value)) {
                return undefined
            }

            return audience?.value.map((_audience) => ({ audience: _audience }))
        }

        return {
            active: active.value === 'ACTIVE',
            slug: accountSlug,
            name: name.value,
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
            style: {
                theme: backgroundColor.value.toUpperCase(),
                image: image.value,
                size: placementSize.value.toUpperCase(),
                background: customBackgroundColor.value,
            },
            action: {
                value: actionLink.value,
            },
            content: content,
            audiences: formatAudience(),
            consentRequired: !!marketingConsent.value,
            locationId: selectedVenueLocation.value.length ? selectedVenueLocation.value : undefined,
        }
    }

    const onPublishConfirm = async () => {
        setLoading(true)
        const content: PlacementContentPayload[] = []

        translations.forEach((translation) => {
            const translationItem: PlacementContentPayload = {
                title: translation.title,
                subtitle: translation.subtitle,
                locale: translation.locale,
                default: defaultLanguage.value === translation.locale,
                buttons: [
                    {
                        caption: translation.buttonCaption,
                        action: {
                            value: actionLink.value,
                        },
                    },
                ],
            }

            content.push(translationItem)
        })

        const placementInput = mapStateToPlacementPayload()
        const error = validatePlacement(placementInput)

        if (error) {
            setLoading(false)
            replaceMessages('server_error', 'error', `Oops! We were unable to save the placement. ${error}`)
            return
        }

        if (editingPlacementId) {
            placementInput['id'] = editingPlacementId
            props.tapPlacementService
                .update(placementInput)
                .then(() => {
                    onPublishSucces(`${name.value} placement has been updated successfully`)
                })
                .catch(() => {
                    setLoading(false)
                    replaceMessages(
                        'server_error',
                        'error',
                        'Oops! We were unable to update the placement. Please try again later.',
                    )
                })
        } else {
            props.tapPlacementService
                .add(placementInput)
                .then(() => {
                    onPublishSucces(`${name.value} placement has been added successfully`)
                })
                .catch(() => {
                    setLoading(false)
                    replaceMessages(
                        'server_error',
                        'error',
                        'Oops! We were unable to add the placement. Please try again later.',
                    )
                })
        }
    }

    const onPublish = () => {
        if (shouldShowAudienceModal()) {
            setShowAudienceModal(true)
        } else {
            onPublishConfirm()
        }
    }

    const onPublishSucces = (text: string) => {
        setLoading(false)
        const message: Message = {
            id: 'publish_sucess',
            status: 'success',
            text,
            visible: true,
        }

        props.navigation.replaceWithMessages([message], `${NAVIGATION_PATH}/home/placements`)
    }

    const onStepChange = (step: MenuStepType) => {
        setCurrentStep(step)
        props.history.push(`${NAVIGATION_PATH}/placements/${step}/${editingPlacementId}`)
    }

    const onConfirmNavigation = () => {}

    const translationExists = (key: string, allTranslations: PlacementContent[]) =>
        allTranslations.some((translation) => translation.locale === key)

    const onTranslationUpdate = (key: string, value: string) => {
        const activeLocale = activeLanguage.value
        const copy = cloneDeep(translations)

        if (!translationExists(activeLocale, copy)) {
            copy.push({
                locale: activeLocale,
                default: defaultLanguage.value === activeLocale,
            })
        }

        copy.forEach((translation) => {
            if (translation.locale === activeLocale) {
                translation[key] = value
            }
        })

        setTranslations(copy)
    }

    const hasUnsavedChanges = false // to do
    const selectedAudiences = useSelector(crmSelectors.findAudienceBySlugArray(audience.value as unknown as string[]))
        .map((_audience) => _audience?.name)
        .join(', ')
    return (
        <>
            <Page id="wizard-page" style={{ position: 'relative', justifyContent: 'center' }}>
                <NavigationPrompt
                    when={(crntLocation, nextLocation) => onNavigateAway(nextLocation, hasUnsavedChanges)}
                    afterConfirm={onConfirmNavigation}
                >
                    {({ onConfirm, onCancel }) => (
                        <ConfirmationDialog
                            title="Do you want to exit without publishing?"
                            text="Watch out! By exiting the Convious Studio, all your changes will be lost."
                            buttonText="Exit"
                            destructive
                            onCancel={onCancel}
                            onConfirm={onConfirm}
                        />
                    )}
                </NavigationPrompt>
                <MobileDeviceWarning
                    history={props.history}
                    returnPath={`${NAVIGATION_PATH}/home/placements/overview`}
                />
                {showAudienceModal && (
                    <AudienceModal
                        title="Are you sure you want to publish?"
                        audienceName={selectedAudiences}
                        buttonText="Publish"
                        onCancel={() => setShowAudienceModal(false)}
                        onConfirm={() => onPublishConfirm()}
                    />
                )}
                <Side>
                    <Form
                        history={props.history}
                        accountSlug={accountSlug}
                        currentStep={currentStep}
                        setCurrentStep={(step) => onStepChange(step)}
                        onPublish={onPublish}
                        replaceMessages={replaceMessages}
                        removeAllMessages={removeAllMessages}
                        defaultLanguage={defaultLanguage}
                        name={name}
                        placementSize={placementSize}
                        backgroundColor={backgroundColor}
                        customBackgroundColor={customBackgroundColor}
                        actionLink={actionLink}
                        image={image}
                        buttonAction={buttonAction}
                        accountLocales={accountLocales}
                        startDate={startDate}
                        endDate={endDate}
                        activeLanguage={activeLanguage}
                        activeTranslation={activeTranslation}
                        onTranslationUpdate={onTranslationUpdate}
                        loading={loading}
                        active={active}
                        audience={audience}
                        hasPlacementAudienceFeature={hasPlacementAudienceFeature}
                        hasPlacementLocationFeature={hasPlacementLocationFeature}
                        venueLocations={venueLocations}
                        selectedVenueLocation={selectedVenueLocation}
                        marketingConsent={marketingConsent}
                        audiences={audiences.data}
                    />
                </Side>
                <Body>
                    <Header
                        accountSlug={accountSlug}
                        previewSize="mobile"
                        onPreviewSizeChange={(size: PreviewSize) => setPreviewSize(size)}
                        targetDevice="mobile"
                        replaceMessages={replaceMessages}
                        removeAllMessages={removeAllMessages}
                        studioType="tapPlacements"
                        onPublish={onPublish}
                        forceClosePath={`${NAVIGATION_PATH}/home/placements/overview`}
                    />
                    <PreviewContainer className={previewSize}>
                        <TemplatePreview
                            title={activeTranslation.title}
                            subtitle={activeTranslation.subtitle}
                            loading={loading}
                            buttonCaption={activeTranslation.buttonCaption}
                            size={placementSize.value}
                            image={image.value}
                            appearance={backgroundColor.value}
                            brandColor={brandColor}
                            customColor={customBackgroundColor.value}
                        />
                    </PreviewContainer>
                </Body>
            </Page>
        </>
    )
}

export default withFeatures(withNavigation(Studio))
