import * as React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { format } from 'date-fns'
import { Navigation } from 'navigation'
import { withNavigation } from 'hocs'
import { StatsService } from 'http/statsService'
import { PageTitle } from 'uiComponents/typography'
import {
    areDateRangeDatesEqual,
    chartRowsGap,
    getDataFromQuery,
    ReportsToolsWrapper,
    ReportUpdateInfo,
} from 'reports/helpers'
import SingleDataItems from './singleDataItems'
import { Row, Col } from 'uiComponents/flex'
import CheckoutDropChart from './checkoutDropChart'
import CheckoutFunnelChart from './checkoutFunnelChart'
import ConversionTrend from './conversionTrendChart'
import { dateRangeToQuery, DateRange } from 'dateRanges'
import SankeyChart from './sankeyChart'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import ReportsFilter from 'reports/reportsFilter'
import { StatsServiceContext } from 'http/context'
import { InventoryServiceContext } from 'inventory/context'
import { AVAILABLE_FILTERS, STANDALONE_FILTERS, METADATA_CATEGORIES } from 'reports/constants'
import { FilterCategory, Filters, FilterQueryItems } from 'uiComponents/filter/schema'
import { createTimeseriesBarChart } from 'reports/checkoutFunnel/conversionTrendChartSource'
import { createMiniTimeseriesBarChart } from 'reports/checkoutFunnel/miniChartSource'
import { ChartContainer } from 'uiComponents/charts/styleComponents'
import { QueryConfig } from 'reports/queryGenerator'
import MiniCharts from './miniCharts'
import { renderFilterQuery } from 'uiComponents/filter'
import { CharonIncidentWarning } from '../utils/charonIncidentWarning'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import { useMessages } from 'messagesContext'

const queryConfig: QueryConfig = {
    querySetName: 'ScreenOverviewDefaultScreen',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'checkoutFunnelDefaultScreen',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'checkout_funnel_default_screen' }],
            presetResult: 'dataSeries',
        },
    ],
}

interface ReportsPageProps {
    navigation: Navigation
    match: RouteMatch<any>
    statsService: StatsService
    accountSlug: string
}

function CheckoutFunnelReports(props: ReportsPageProps) {
    const { dateRange, filters, sankeyFilter } = getDataFromQuery(props.navigation.query(), ['sankeyFilter'])
    const _lastRequest = React.useRef<number>()
    const statsService = React.useContext(StatsServiceContext)
    const inventoryService = React.useContext(InventoryServiceContext)
    const { replaceMessages, hideMessage } = useMessages()
    
    const allFilters = [...filters, ...((sankeyFilter as Filters[]) || [])]
    const appliedFilters = allFilters.filter(
        (f) =>
            AVAILABLE_FILTERS['checkout_funnel']
                .concat(AVAILABLE_FILTERS['checkout_funnel_screen_overview'])
                .indexOf(f.attribute as FilterCategory) > -1,
    )
    const [conversionTrendChart, setConversionTrendChart] = React.useState(createTimeseriesBarChart(dateRange))
    const [miniChartSourceAvgTimeSpent, setMiniChartSourceAvgTimeSpent] = React.useState(
        createMiniTimeseriesBarChart(dateRange),
    )
    const [miniChartSourceVisitorReach, setMiniChartSourceVisitorReach] = React.useState(
        createMiniTimeseriesBarChart(dateRange),
    )
    const [miniChartSourceDropOffRate, setMiniChartSourceDropOffRate] = React.useState(
        createMiniTimeseriesBarChart(dateRange),
    )
    const [miniChartSourceConversionRate, setMiniChartSourceConversionRate] = React.useState(
        createMiniTimeseriesBarChart(dateRange),
    )

    React.useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    React.useEffect(() => {
        if (!filters.length || !sankeyFilter) {
            getDefaultFilters()
        }
    }, [props.accountSlug, props.navigation.query().filter, props.navigation.query().sankeyFilter])

    const onDateRangeChanged = (range: DateRange) => {
        if (!areDateRangeDatesEqual(dateRange, range)) {
            props.navigation.addQueryWithReplace(dateRangeToQuery(range))
            setConversionTrendChart(createTimeseriesBarChart(range))
            setMiniChartSourceAvgTimeSpent(createMiniTimeseriesBarChart(range))
            setMiniChartSourceVisitorReach(createMiniTimeseriesBarChart(range))
            setMiniChartSourceDropOffRate(createMiniTimeseriesBarChart(range))
            setMiniChartSourceConversionRate(createMiniTimeseriesBarChart(range))
        }
    }

    const getDefaultScreen = async () => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        const dateFrom = format(dateRange.from, 'yyyy-MM-dd')
        const dateTo = format(dateRange.to, 'yyyy-MM-dd')
        const variables = {
            widget: props.accountSlug,
            dateFrom,
            dateTo,
        }

        const data = await statsService.getStats(queryConfig, variables)

        if (_lastRequest.current !== requestTime) {
            return
        }

        const defaultScreenStats = data.checkoutFunnelDefaultScreen.dataSeries
        return defaultScreenStats.data[0] as string
    }

    const getDefaultFilters = async () => {
        try {
            const filtersValue = !!filters.length ? filters[0].values[0] : 'first_screen'
            const sankeyFilterValue = !!sankeyFilter ? sankeyFilter[0].values[0] : await getDefaultScreen()

            const filterQueryItem: FilterQueryItems[] = [
                {
                    category: 'product_lists',
                    slug: filtersValue,
                },
            ]
            const sankeyFilterQueryItem: FilterQueryItems[] = [
                {
                    category: 'product_lists_with_screens',
                    slug: sankeyFilterValue,
                },
            ]

            props.navigation.addQueryWithReplace({
                filter: renderFilterQuery(filterQueryItem),
                sankeyFilter: renderFilterQuery(sankeyFilterQueryItem),
            })
        } catch {
            replaceMessages('server_error', 'error', 'Oops! Unable to get the default screen filter!')
        }
    }

    return (
        <div style={{ marginBottom: '7em' }}>
            <PageTitle data-userpilot="sales-header-tooltip">Checkout funnel</PageTitle>
            <CharonIncidentWarning dateRange={dateRange}>
                Due to a technical issue, the traffic metrics from 20th to 23rd of February, 2024 will be excluded from
                our reports. We apologize for any inconvenience.
            </CharonIncidentWarning>
            <ReportsToolsWrapper>
                <div />
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    firstAvailableDay={new Date(2019, 0, 1)}
                />
            </ReportsToolsWrapper>
            <ReportUpdateInfo metric="Data" text="updated every night." style={{ marginTop: '-1.5em' }} />
            <SingleDataItems
                accountSlug={props.accountSlug}
                dateRange={dateRange}
                replaceMessages={replaceMessages}
            />
            <Row style={{ marginTop: chartRowsGap, marginBottom: '2.5em' }}>
                <Col span={12}>
                    <ReportsToolsWrapper>
                        <ReportsFilter
                            accountSlug={props.accountSlug}
                            statsService={statsService}
                            inventoryService={inventoryService}
                            dateRange={dateRange}
                            applicableFilters={AVAILABLE_FILTERS['checkout_funnel']}
                            standaloneFilters={STANDALONE_FILTERS['checkout_funnel']}
                            metadataCategories={METADATA_CATEGORIES['checkout_funnel']}
                            appliedFilters={appliedFilters}
                            customBaseElementText="Filter checkout flow"
                            replaceMessages={replaceMessages}
                            hideMessage={hideMessage}
                            userpilot="checkout_funnel-filter"
                        />
                    </ReportsToolsWrapper>
                    <ChartContainer>
                        <CheckoutFunnelChart
                            accountSlug={props.accountSlug}
                            dateRange={dateRange}
                            replaceMessages={replaceMessages}
                            filters={filters}
                        />
                        <ConversionTrend
                            accountSlug={props.accountSlug}
                            replaceTopMessages={replaceMessages}
                            dateRange={dateRange}
                            chart={conversionTrendChart}
                            filters={filters}
                        />
                    </ChartContainer>
                </Col>
            </Row>
            <Row style={{ marginTop: chartRowsGap, marginBottom: '2.5em' }}>
                <Col span={12}>
                    <CheckoutDropChart
                        accountSlug={props.accountSlug}
                        dateRange={dateRange}
                        replaceMessages={replaceMessages}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: chartRowsGap, marginBottom: '2.5em' }}>
                <Col span={12}>
                    <ReportsToolsWrapper>
                        <ReportsFilter
                            accountSlug={props.accountSlug}
                            statsService={statsService}
                            inventoryService={inventoryService}
                            dateRange={dateRange}
                            applicableFilters={AVAILABLE_FILTERS['checkout_funnel_screen_overview']}
                            standaloneFilters={STANDALONE_FILTERS['checkout_funnel_screen_overview']}
                            metadataCategories={METADATA_CATEGORIES['checkout_funnel_screen_overview']}
                            appliedFilters={appliedFilters}
                            customBaseElementText="Screen selection"
                            replaceMessages={replaceMessages}
                            hideMessage={hideMessage}
                            userpilot="checkout_funnel_snakey-filter"
                            filterKey="sankeyFilter"
                        />
                    </ReportsToolsWrapper>
                    <ChartContainer>
                        <SankeyChart
                            accountSlug={props.accountSlug}
                            dateRange={dateRange}
                            replaceMessages={replaceMessages}
                            filters={(sankeyFilter as Filters[]) || []}
                        />
                        <Row style={{ marginTop: chartRowsGap }}>
                            <Col span={12}>
                                <MiniCharts
                                    accountSlug={props.accountSlug}
                                    dateRange={dateRange}
                                    replaceMessages={replaceMessages}
                                    filters={(sankeyFilter as Filters[]) || []}
                                    checkoutScreenTimeSpentChart={miniChartSourceAvgTimeSpent}
                                    checkoutScreenReachRatioChart={miniChartSourceVisitorReach}
                                    checkoutScreenDropRatioChart={miniChartSourceDropOffRate}
                                    checkoutScreenConversionRateChart={miniChartSourceConversionRate}
                                />
                            </Col>
                        </Row>
                    </ChartContainer>
                </Col>
            </Row>
            <BaseKnowledgeLink link="https://support.convious.com/help/how-to-use-your-checkout-funnel-report" />
        </div>
    )
}

export default withNavigation(CheckoutFunnelReports)
