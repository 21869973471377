import styleIcon from 'uiComponents/studio/icons/style.svg'
import copyIcon from 'uiComponents/studio/icons/copy.svg'
import restrictionsIcon from 'uiComponents/studio/icons/target.svg'
import settingsIcon from 'uiComponents/studio/icons/settings.svg'
import { SectionObject } from 'uiComponents/studio/menuItem'
import { parsePixels } from 'utils/css'
import { fontOptions } from '../shared'

export interface FormDefinitionSchema {
    text: SectionObject
    style: SectionObject
    targeting: SectionObject
    settings: SectionObject
}

const px = {
    render: (val: number) => (val === 0 ? '0' : val + 'px'),
    parse: parsePixels,
}

export const WonderBarFormDefinition: FormDefinitionSchema = {
    text: {
        name: 'text',
        label: '1. Text',
        header: 'Create your Message',
        icon: copyIcon,
        description: 'Create and edit your WonderBar text and all corresponding translations.',
        sections: [
            {
                name: 'Languages',
                fields: [],
            },
        ],
    },
    style: {
        name: 'style',
        label: '2. Style',
        header: 'Design your Look & Feel',
        icon: styleIcon,
        description:
            "To start, we use a default style and default colors for your WonderBar, but you can customize them below to match your website's look and feel.",
        sections: [
            {
                name: 'General',
                fields: [
                    {
                        name: 'size-template',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Small',
                                value: 'small',
                            },
                            {
                                name: 'Normal',
                                value: 'normal',
                            },
                            {
                                name: 'Large',
                                value: 'large',
                            },
                        ],
                        label: 'WonderBar size',
                        span: 6,
                        path: 'styling.sizeTemplate',
                        defaultValue: 'normal',
                    },
                    {
                        name: 'text-font',
                        type: 'select',
                        availableOptions: [...fontOptions],
                        label: 'Font-family',
                        span: 6,
                        path: 'styling.fontFamily',
                        defaultValue: '',
                    },
                    {
                        name: 'text-font-size',
                        type: 'number',
                        min: 9,
                        max: 24,
                        unit: 'px',
                        label: 'Text size',
                        span: 6,
                        path: 'styling.fontSize',
                        defaultValue: 15,
                        converter: px,
                    },
                ],
            },
            {
                name: 'Bar styling',
                fields: [
                    {
                        name: 'bar-color',
                        type: 'picker',
                        label: 'Bar color',
                        span: 6,
                        path: 'styling.bar.background',
                        defaultValue: '#FFFFFF',
                    },
                    {
                        name: 'bar-text-color',
                        type: 'picker',
                        label: 'Text color',
                        span: 6,
                        path: 'styling.bar.textColor',
                        defaultValue: '#999999',
                    },
                ],
            },
            {
                name: 'Button styling',
                fields: [
                    {
                        name: 'button-color',
                        type: 'picker',
                        label: 'Button color',
                        span: 6,
                        path: 'styling.cta.background',
                        defaultValue: '#CCCCCC',
                    },
                    {
                        name: 'border-color',
                        type: 'picker',
                        label: 'Border color',
                        span: 6,
                        path: 'styling.cta.borderColor',
                        defaultValue: '#FFFFFF',
                    },
                    {
                        name: 'button-text-color',
                        type: 'picker',
                        label: 'Text color',
                        span: 6,
                        path: 'styling.cta.textColor',
                        defaultValue: '#999999',
                    },
                    {
                        name: 'button-corner',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Square',
                                value: '0px',
                            },
                            {
                                name: 'Slightly rounded',
                                value: '6px',
                            },
                            {
                                name: 'Rounded',
                                value: '10px',
                            },
                        ],
                        label: 'Button corners',
                        span: 6,
                        path: 'styling.cta.borderRadius',
                        defaultValue: '6x',
                    },
                    {
                        name: 'border-width',
                        type: 'number',
                        min: 0,
                        max: 5,
                        unit: 'px',
                        label: 'Border width',
                        span: 6,
                        path: 'styling.cta.borderWidth',
                        defaultValue: 1,
                        converter: px,
                    },
                ],
            },
        ],
    },
    targeting: {
        name: 'targeting',
        label: '3. Targeting',
        header: 'Set your Restrictions',
        icon: restrictionsIcon,
        description: 'Choose the pages you want your WonderBar to be shown and/or excluded.',
        sections: [
            {
                name: 'Audience',
                fields: [],
            },
            {
                name: 'Display on',
                infotip: 'Decide on which devices you want to show your WonderBar.',
                fields: [
                    {
                        name: 'display',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'all devices',
                                value: 'all',
                            },
                            {
                                name: 'only mobile/tablet',
                                value: 'mobile',
                            },
                            {
                                name: 'only desktop',
                                value: 'desktop',
                            },
                        ],
                        label: '',
                        span: 12,
                        path: '',
                        defaultValue: 'all',
                    },
                ],
            },
            {
                name: 'Blacklist',
                label: 'Hide your WonderBar on (optional)',
                infotip: 'Define which pages of your website will not show the WonderBar.',
                fields: [],
            },
        ],
    },
    settings: {
        name: 'settings',
        label: '4. Settings',
        header: 'Set your Visibility Rules',
        icon: settingsIcon,
        description: 'Define and apply simple rules on how your WonderBar displays to your audience.',
        sections: [
            {
                name: 'Placement',
                fields: [
                    {
                        name: 'placement',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Top',
                                value: 'top',
                            },
                            {
                                name: 'Bottom',
                                value: 'bottom',
                            },
                        ],
                        label: 'Display on',
                        span: 6,
                        premium: true,
                        path: 'placement',
                        defaultValue: 'top',
                    },
                ],
            },
            {
                name: 'Appearance',
                fields: [
                    {
                        name: 'display-after',
                        type: 'number',
                        min: 0,
                        max: 60,
                        unit: 'sec',
                        label: 'Display after',
                        span: 6,
                        path: 'showDelay',
                        defaultValue: 5,
                        infotip: "The delay between your website's loading time and showing the WonderBar.",
                    },
                    {
                        name: 'visible-on-scroll',
                        type: 'toggle',
                        label: 'Visible on scroll',
                        span: 6,
                        path: 'visibleOnScroll',
                        defaultValue: false,
                        infotip:
                            'When enabled, your WonderBar will be fixed on top of your page unless the user clicks ‘x’ to close and dismiss the WonderBar.', // tslint:disable-line
                    },
                    {
                        name: 'push-down',
                        type: 'toggle',
                        label: 'Push the page down',
                        span: 6,
                        path: 'pushDown',
                        defaultValue: true,
                        infotip:
                            "This setting will prevent the WonderBar from covering any of your website's content upon scrolling.",
                    },
                    {
                        name: 'disable-fixed-elements-push-down',
                        type: 'toggle',
                        label: 'Disable pushing down fixed elements',
                        span: 6,
                        path: 'disablePushFixedElements',
                        defaultValue: false,
                        infotip:
                            'This setting will prevent the WonderBar push down functionality from having effect on fixed elements. This is useful when you have a cookie bot fixed element on your page.',
                    },
                    {
                        name: 'allow-user-close',
                        type: 'toggle',
                        label: 'Allow users to close',
                        span: 6,
                        path: 'showCloseBtn',
                        defaultValue: true,
                        infotip:
                            'When enabled, it will display an ‘x’, which allows your website visitor to close and dismiss your WonderBar.',
                    },
                    // {
                    //   name: 'convious-logo',
                    //   type: 'toggle',
                    //   label: 'Convious Logo',
                    //   span: 6,
                    //   premium: true,
                    //   path: 'enableConviousLogo',
                    //   defaultValue: true,
                    //   infotip: 'When enabled, Convious logo will be displayed in the top left corner of your WonderBar.'
                    // }
                ],
            },
            {
                name: 'Animation',
                fields: [
                    {
                        name: 'animate-on-entry-exit',
                        type: 'toggle',
                        label: 'Animate on entry/exit',
                        span: 6,
                        path: 'animation.enableOnEntryExit',
                        defaultValue: true,
                        infotip: 'When enabled, your WonderBar will slide into your page.',
                    },
                    {
                        name: 'pulse-button',
                        type: 'toggle',
                        label: 'Pulse button',
                        span: 6,
                        path: 'animation.pulseButton',
                        defaultValue: false,
                        infotip:
                            'When enabled, the button on your WonderBar will animate pulsating to catch the viewer’s attention.',
                    },
                ],
            },
            {
                name: 'Display delay after click',
                fields: [
                    {
                        name: 'display-delay',
                        type: 'select',
                        availableOptions: [
                            {
                                name: 'Always display',
                                value: '0',
                            },
                            {
                                name: '1 day',
                                value: '86400',
                            },
                            {
                                name: '1 week',
                                value: '604800',
                            },
                            {
                                name: '1 month',
                                value: '18144000',
                            },
                        ],
                        label: 'After a viewer chooses to dismiss the WonderBar via the ‘x’, it won’t show again for:',
                        span: 12,
                        path: 'throttling.cooldown',
                        defaultValue: '1 day',
                    },
                ],
            },
        ],
    },
}
