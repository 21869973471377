import uuid from 'uuid'
import { InventoryItem } from 'inventory/inventoryService'
import { Availability } from 'reservations/types'
import { TimelineItem } from './types'
import { format, formatInTimeZone } from 'date-fns-tz'

export const getCssVariables = (capacity: number | null, available: number | null, isInstant: boolean): string => {
    if (capacity === 0) {
        return `
            --item-background: var(--aluminium-20);
            --item-border: 1px solid transparent;
            --item-text-color: var(--text-dark);
        `
    }

    if (capacity === null || available === null || capacity - available === 0) {
        return `
            --item-background: var(--white);
            --item-border: 1px solid var(--boy-blue-50);
            --item-text-color: var(${isInstant ? '--text-dark' : '--boy-blue-50'});
            --flag-color: var(--boy-blue-50);
        `
    }

    const capacityPercentage = Math.min(Math.floor(((capacity - available) / capacity) * 100), 100)

    // Map 1-100% to 10-90 range
    const colorStep = Math.max(Math.min(Math.ceil(capacityPercentage / 10) * 10, 90), 10)
    const boyBlueVar = `var(--boy-blue-${colorStep})`

    const textColor = colorStep >= 40 ? 'white' : 'black'

    return `
        --item-background: ${boyBlueVar};
        --item-border: 1px solid ${isInstant ? boyBlueVar : 'transparent'};
        --item-text-color: ${textColor};
        --flag-color: ${boyBlueVar};
    `
}

export const getGroups = (inventoryList: InventoryItem[]) =>
    inventoryList.map((inventory) => ({
        id: inventory.id,
        content: inventory.name,
        reservationPeriodType: inventory.reservationPeriodType,
    }))

export const getItems = (availabilityList: Availability[], timeZone: string, selectedDate: string): TimelineItem[] =>
    availabilityList.reduce((items, { resourceId, availability, reservationPeriodType }) => {
        const convertedItems = availability
            // NOTE: For reservationPeriodType === 'instant' we only show Gate Capacity, which in the DB is always 1 second long.
            .filter(({ dateTo }) => reservationPeriodType === 'period' || dateTo.endsWith(':01Z'))
            .map((item) => {
                const available = item.available ?? null
                const capacity = item.capacity ?? null

                const isInstant = reservationPeriodType === 'instant' && item.dateTo.endsWith(':01Z')

                const localStart = formatInTimeZone(new Date(item.dateFrom), timeZone, 'HH:mm:ss')
                const localEnd = formatInTimeZone(new Date(item.dateTo), timeZone, 'HH:mm:ss')

                const start = `${selectedDate}T${localStart}`
                const end = `${selectedDate}T${localEnd}`

                return {
                    id: uuid.v4(),
                    available: available,
                    capacity: capacity,
                    reserved: capacity !== null && available !== null ? capacity - available : null,
                    start,
                    end,
                    group: resourceId,
                    reservationType: isInstant ? ('instant' as const) : ('period' as const),
                    className: isInstant ? 'vis-item-instant' : 'vis-item-period',
                    style: getCssVariables(capacity, available, isInstant),
                }
            })

        return [...items, ...convertedItems]
    }, [])

export const getVenueOperatingHours = (selectedDate: Date | string, openingTime: string, closingTime: string) => {
    const dateStr = selectedDate instanceof Date ? format(selectedDate, 'yyyy-MM-dd') : selectedDate

    return {
        start: `${dateStr}T${openingTime}`,
        end: `${dateStr}T${closingTime}`,
    }
}
