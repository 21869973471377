import { Locale } from 'admin/articleService'
import { ArticleServiceContext } from 'admin/context'
import { crmSelectors } from 'crm/selectors'
import { withFeatures } from 'features'
import { History } from 'history'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { match as RouteMatch } from 'react-router-dom'
import NavigationPrompt from 'react-router-navigation-prompt'
import { PromotionService } from 'tapPromotions/types'
import { Message } from 'uiComponents/messages'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { AudienceModal } from 'uiComponents/studio/audienceModal'
import Header from 'uiComponents/studio/header'
import { MobileDeviceWarning } from 'uiComponents/studio/mobileDeviceWarning'
import { onNavigateAway } from 'uiComponents/studio/onNavigateAway'
import { Body, Page, Side } from 'uiComponents/studio/pageComponents'
import { PreviewContainer, PreviewSize } from 'uiComponents/studio/preview/components'
import { useFetchAudiences } from '../../../crm/audiences/hooks/useFetchAudiences'
import { formDefinition } from './configurationOptions'
import { usePromotion } from './context'
import Form from './form'
import TemplatePreview from './preview'
import { Appearances, MenuStepType } from './types'
import { useMessages } from 'messagesContext'

interface StudioProps {
    accountSlug: string
    history: History
    navigation: Navigation
    match: RouteMatch<any>
    promotionService: PromotionService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

const Studio = (props: StudioProps) => {
    const { hasChanges, error, getValueForPath, setLocales, loadConfig, toPayload, setHasChanges } = usePromotion()
    const promotionId = React.useMemo(() => props.match.params.id || 'new', [])
    const MODULE = 'promotions'
    const NAVIGATION_PATH = `/account/${props.accountSlug}/engage/tools`

    const [loading, setLoading] = React.useState(true)
    const [previewSize, setPreviewSize] = React.useState<PreviewSize>('mobile')
    const [currentStep, setCurrentStep] = React.useState<MenuStepType>('type')
    const [accountLocales, setAccountLocales] = React.useState<Locale[]>([])
    const articleService = React.useContext(ArticleServiceContext)
    const shouldSowAudienceModal = React.useMemo(() => {
        const selectedAudience = getValueForPath('audience')
        const marketingConsent = getValueForPath('marketingConsent')
        return !!selectedAudience && !!!marketingConsent
    }, [getValueForPath])

    const [showAudienceModal, setShowAudienceModal] = React.useState(false)
    const audience = useFetchAudiences()
    const { removeAllMessages, replaceMessages } = useMessages()

    React.useEffect(() => {
        audience.fetch()
    }, [])

    React.useEffect(() => {
        const step = props.match.params.step || 'type'
        setCurrentStep(step)
    }, [props.match.params.step])

    React.useEffect(() => {
        if (error.length) {
            replaceMessages(
                'server_error',
                'error',
                `Oops! Something went wrong. Please try again later. ${error}`,
            )
        }
    }, [error])

    React.useEffect(() => {
        const getData = async () => {
            try {
                const localesDetails = await articleService.getAccountLocales(props.accountSlug)
                setAccountLocales(localesDetails.locales)

                setLocales(localesDetails.locales)
                if (promotionId && promotionId !== 'new') {
                    const promo = await props.promotionService.get(props.accountSlug, promotionId)
                    loadConfig(promo)
                }
            } catch {
                replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again later.')
            } finally {
                setLoading(false)
            }
        }

        getData()
    }, [])

    const onConfirmNavigation = () => {}

    const onPublish = () => {
        if (shouldSowAudienceModal) {
            setShowAudienceModal(true)
        } else {
            onPublishConfirm()
        }
    }

    const onPublishConfirm = async () => {
        setLoading(true)

        let text = `Your promotion ${getValueForPath('promotionName')} has been added successfully!`
        try {
            if (promotionId && promotionId !== 'new') {
                await props.promotionService.update(promotionId, toPayload(props.accountSlug))
                text = `Your promotion ${getValueForPath('promotionName')} has been updated successfully!`
            } else {
                await props.promotionService.add(toPayload(props.accountSlug))
            }
            setLoading(false)
            setHasChanges(false)
            const message: Message = {
                id: 'publish_sucess',
                status: 'success',
                text,
                visible: true,
            }

            props.navigation.replaceWithMessages([message], `${NAVIGATION_PATH}/home/${MODULE}`)
        } catch (error) {
            setLoading(false)
            replaceMessages(
                'server_error',
                'error',
                `Oops! Something went wrong. Please try again later. ${error.message}`,
            )
        }
    }

    const onStepChange = (step: MenuStepType) => {
        setCurrentStep(step)
        props.history.push(`${NAVIGATION_PATH}/${MODULE}/${step}/${promotionId}`)
    }

    const selectedAudiences = useSelector(crmSelectors.findAudienceBySlugArray(getValueForPath('audience') as string[]))
        .map((_audience) => _audience?.name)
        .join(', ')

    return (
        <>
            <Page id="wizard-page" style={{ position: 'relative', justifyContent: 'center' }}>
                <NavigationPrompt
                    when={(crntLocation, nextLocation) => onNavigateAway(nextLocation, hasChanges)}
                    afterConfirm={onConfirmNavigation}
                >
                    {({ onConfirm, onCancel }) => (
                        <ConfirmationDialog
                            title="Do you want to exit without publishing?"
                            text="Watch out! By exiting the Convious Studio, all your changes will be lost."
                            buttonText="Exit"
                            destructive
                            onCancel={onCancel}
                            onConfirm={onConfirm}
                        />
                    )}
                </NavigationPrompt>
                {showAudienceModal && (
                    <AudienceModal
                        title="Are you sure you want to publish?"
                        audienceName={selectedAudiences}
                        buttonText="Publish"
                        onCancel={() => setShowAudienceModal(false)}
                        onConfirm={() => onPublishConfirm()}
                    />
                )}
                <MobileDeviceWarning
                    history={props.history}
                    returnPath={`${NAVIGATION_PATH}/home/${MODULE}/overview`}
                />
                <Side>
                    <Form
                        history={props.history}
                        accountSlug={props.accountSlug}
                        currentStep={currentStep}
                        setCurrentStep={(step) => onStepChange(step)}
                        onPublish={onPublish}
                        replaceMessages={replaceMessages}
                        removeAllMessages={removeAllMessages}
                        configuration={formDefinition(accountLocales, audience.data)}
                        loading={loading}
                    />
                </Side>
                <Body>
                    <Header
                        accountSlug={props.accountSlug}
                        previewSize="mobile"
                        onPreviewSizeChange={(size: PreviewSize) => setPreviewSize(size)}
                        targetDevice="mobile"
                        replaceMessages={replaceMessages}
                        removeAllMessages={removeAllMessages}
                        studioType="tapPromotions"
                        onPublish={onPublish}
                        forceClosePath={`${NAVIGATION_PATH}/home/${MODULE}/overview`}
                    />
                    <PreviewContainer className={previewSize}>
                        <TemplatePreview
                            title={getValueForPath('content-title') as string}
                            partnerName={getValueForPath('partnerName') as string}
                            loading={loading}
                            partnerImage={getValueForPath('logo') as string}
                            appearance={getValueForPath('theme') as Appearances}
                            brandColor={getValueForPath('brandColor') as Appearances}
                        />
                    </PreviewContainer>
                </Body>
            </Page>
        </>
    )
}

export default withFeatures(withNavigation(Studio))
