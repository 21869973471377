import React, { useEffect } from 'react'
import { History } from 'history'
import { withFeatures } from 'features'
import { match as RouteMatch } from 'react-router-dom'
import { usePermission } from 'admin/hocs'
import { ActionButton } from 'uiComponents/buttons/'
import { MessageKind } from 'uiComponents/messages'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { SingleSelect, SingleSelectOption } from 'uiComponents/input'
import { delay } from 'utils'
import { Column, ColumnGap, ButtonWrapper, TextWithHorizontalLine } from 'uiComponents/pageElements'
import { Row, Col } from 'uiComponents/flex'
import ExternalConfigurationSection from './externalConfigurations'
import { Price } from 'products/utils'
import CalendarBlock from 'products/components/calendarBlock'
import Infotip from 'uiComponents/infotip'
import { ProductListWithPath } from 'products/crud/common'
import TextInputFieldFormik from 'uiComponents/input/textInput/textInputFieldFormik'
import DatePickerFieldFormik from 'uiComponents/input/date/datePickerFieldFormik'
import { useField } from 'formik'

interface DetailsFormParams {
    accountSlug: string
    id: string
}

interface DetailsFormProps {
    history: History
    match: RouteMatch<DetailsFormParams>
    hasFeature: (feature: string, slug: string) => boolean
    accountSlug: string
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hideTopMessage: (id: string) => void
    onMonthChange: (month: Date) => void
    calendarData: Price[] | null
    showCalendar: boolean
    categoryIds: string[] | null
    now: Date
    calendarLoading: boolean
    viewMode: 'flat' | 'nested' | ''
    allCategories: ProductListWithPath[]
    selectedCat: string
    onCategoryChange: (cat: string) => void
}

function DetailsForm({
    accountSlug,
    history,
    replaceTopMessages,
    hideTopMessage,
    onMonthChange,
    calendarData,
    categoryIds,
    match,
    calendarLoading,
    showCalendar,
    allCategories,
    selectedCat,
    onCategoryChange,
    viewMode,
    now,
}: DetailsFormProps) {
    const [validFromField] = useField('validFrom')
    const [validToField] = useField('validTo')
    const [showFromField] = useField('showFrom')
    const [showToField] = useField('showTo')
    const { hasPermission } = usePermission()
    
    useEffect(() => {
        const displayInfoMessage = async () => {
            replaceTopMessages(
                'calendar_info',
                'success',
                'By changing the validity settings you will be able to see the pregenerated availabilities and prices of your products in the calendar.',
            )
            await delay(9000)
            hideTopMessage('calendar_info')
        }
        if (
            !window.localStorage.getItem('CalendarExplanationShown') ||
            window.localStorage.getItem('CalendarExplanationShown') === 'false'
        ) {
            displayInfoMessage()
            window.localStorage.setItem('CalendarExplanationShown', 'true')
        }
    }, [])

    const getCategoryOptions = (): SingleSelectOption[] => {
        const options = categoryIds
            ? categoryIds.map((id) => {
                  return {
                      value: id,
                      name: allCategories.find((cat) => cat.uuid === id)?.name || '',
                  }
              })
            : []
        return options
    }

    return (
        <>
            <ExternalConfigurationSection
                history={history}
                accountSlug={accountSlug}
                id={match.params.id}
                viewMode={viewMode}
            />
            <Row>
                <Column style={{ zIndex: 11 }}>
                    <Row>
                        <Col span={12}>
                            <FormItem htmlFor="productName">
                                <FormItemName>Product name</FormItemName>
                                <TextInputFieldFormik name="name" block={true} disabled />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <FormItem htmlFor="validityStartDate">
                                <FormItemName>Validity start date</FormItemName>
                                <DatePickerFieldFormik
                                    id="validityStartDate"
                                    name="validFrom"
                                    upperBoundary={validToField.value ? new Date(validToField.value) : null}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem htmlFor="validityEndDate">
                                <FormItemName>
                                    Validity end date
                                    <Infotip pointer="left">Date inclusive</Infotip>
                                </FormItemName>
                                <DatePickerFieldFormik
                                    id="validityEndDate"
                                    name="validTo"
                                    lowerBoundary={validFromField.value ? new Date(validFromField.value) : null}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <FormItem htmlFor="visibilityStartDate">
                                <FormItemName>Visibility start date</FormItemName>
                                <DatePickerFieldFormik
                                    id="visibilityStartDate"
                                    name="showFrom"
                                    upperBoundary={showToField.value ? new Date(showToField.value) : null}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem htmlFor="visibilityEndDate">
                                <FormItemName>
                                    Visibility end date
                                    <Infotip>Date inclusive</Infotip>
                                </FormItemName>
                                <DatePickerFieldFormik
                                    id="visibilityEndDate"
                                    name="showTo"
                                    lowerBoundary={showFromField.value ? new Date(showFromField.value) : null}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                </Column>
                <ColumnGap />
                <Column>
                    {showCalendar && (
                        <>
                            <TextWithHorizontalLine
                                text="CALENDAR PREVIEW"
                                alignment="left"
                                style={{ marginTop: '1em', marginBottom: '1.7em' }}
                            />
                            {!!categoryIds && categoryIds.length > 0 && (
                                <SingleSelect
                                    id="cat-select"
                                    options={getCategoryOptions()}
                                    selected={selectedCat}
                                    height="2.5rem"
                                    maxHeight="11em"
                                    noSelectOption="Select product category"
                                    onSelect={onCategoryChange}
                                    style={{ marginBottom: '0.625em', zIndex: 11 }}
                                />
                            )}
                            <CalendarBlock
                                calendarData={calendarData}
                                loading={calendarLoading}
                                now={now}
                                onMonthChange={onMonthChange}
                            />
                        </>
                    )}
                </Column>
            </Row>
            <ButtonWrapper>
                <ActionButton
                    size="large"
                    secondary
                    onClick={() => {
                        history.push(`/account/${accountSlug}/products/home/${viewMode}`)
                    }}
                >
                    {hasPermission('edit_product_validity', accountSlug) ? 'Cancel' : 'Back'}
                </ActionButton>
                {hasPermission('edit_product_validity', accountSlug) && (
                    <ActionButton id="saveSettings" type="submit" size="large" style={{ marginLeft: '1.5em' }}>
                        Save
                    </ActionButton>
                )}
            </ButtonWrapper>
        </>
    )
}

export default withFeatures(DetailsForm)
