import * as React from 'react'
import styled from 'styled-typed'
import { State } from 'store'
import { useSelector } from 'react-redux'
import { Account } from 'auth/state'
import { Sorting, Pagination } from 'uiComponents/table'
import { PageTitle } from 'uiComponents/typography'
import OrderSearchField from 'uiComponents/search/searchWithButton'
import ExportMenu from 'orders/export'
import { DateRange } from 'dateRanges'
import TransactionsTable from 'orders/transactions/transactionsTable'
import { OrdersService } from 'orders/ordersService'
import { cancelOutOffsetBeforeConvertionToUTC } from 'utils'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import { OrderPage, defaultPageData, DateAndTime } from 'orders/schema'
import { DateRangeType } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { History } from 'history'
import Permission from 'admin/permissionRequired'
import { format } from 'date-fns'
import { UpdateDialog } from 'orders/transactions/updateDialogs'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { CompareRangeQuery } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { PaginationSection } from 'uiComponents/table/pagination'
import Feature from 'features/feature'
import { ActionButtonExternalLink, ActionButtonA, ActionButton } from 'uiComponents/buttons'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { withFeatures } from 'features'
import { usePermission } from 'admin/hocs'
import OrdersFilter from '../ordersFilter'
import cloneDeep from 'lodash/cloneDeep'
import { useMessages } from 'messagesContext'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'

const PageFeatures = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 1em;
`
const RightSideFeatures = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
`
const LeftSideFeatures = styled.div`
    display: flex;
    align-items: baseline;
`
const DatePickerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 1rem 0 2rem 0;
`
const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const SearchResults = styled.div`
    font-style: italic;
    font-size: 0.875em;
    color: ${(props) => props.theme.colors.textLight};
    margin-left: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.3em;
    font-weight: lighter;
    height: 1.35em;
`
const DisputesCenterLink = styled(ActionButtonA)`
    display: flex;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.border};
    position: relative;
    padding-right: 2.5em;
    color: ${(props) => props.theme.colors.textDark};
    background: ${(props) => props.theme.colors.white};

    &:hover:not(:disabled) {
        background: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.border};
    }

    &.no-disputes {
        padding-right: 1rem;
        background: ${(props) => props.theme.colors.background};
    }
`
const DisputesCount = styled.div`
    position: absolute;
    top: 0.2rem;
    right: 0.5rem;
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.convious};
    border-radius: 50%;
    height: 24px;
    width: 24px;
    font-size: 0.875em;
    display: flex;
    align-items: center;
    justify-content: center;
`

interface TransactionsPageProps {
    history: History
    search?: string
    dateRange: DateRange
    onDateRangeChanged: (dr: DateRange, compareRange: CompareRangeQuery) => void
    dateRangeType: DateRangeType
    onDateRangeTypeChange: (type: DateRangeType) => void
    sort: Sorting
    onSortChanged: (s: Sorting) => void
    pagination: Pagination
    onPaginationChanged: (p: Pagination) => void
    accountSlug: string
    ordersService: OrdersService
    loginService: LoginService
    loggingService: LoggingService
    navigation: Navigation
    match: RouteMatch<{}>
    backofficeEndpoint: string
    hasFeature: (feature: string, accountSlug: string) => boolean
}

const TransactionsPage: React.FC<TransactionsPageProps> = (props) => {
    const [ordersPageData, setOrdersPageData] = React.useState<OrderPage>(defaultPageData)
    const [updateDialog, setUpdateDialog] = React.useState<'visitDate' | 'visitTime' | null>(null)
    const [updatedDateTime, setUpdatedDateTime] = React.useState<DateAndTime>({ date: null, time: null })
    const [updatedOrderId, setUpdatedOrderId] = React.useState<string>('')
    const [disputesCount, setDisputesCount] = React.useState<number>(0)
    const [loading, setLoading] = React.useState<boolean>(false)
    const lastCall = React.useRef<number>(0)
    const accounts = useSelector((state: State) => (state.auth.user ? state.auth.user.accounts : []))
    const user = useSelector((state: State) => state.auth.user)
    const { replaceMessages, hideMessage } = useMessages()
    const { hasPermission } = usePermission()
    
    const {
        sort,
        onSortChanged,
        dateRange,
        search,
        accountSlug,
        ordersService,
        loginService,
        loggingService,
        history,
        onPaginationChanged,
        pagination,
        dateRangeType,
        onDateRangeChanged,
        onDateRangeTypeChange,
        backofficeEndpoint,
        hasFeature,
    } = props

    const query = props.navigation.query()
    const {
        searchType,
        filter,
    } = query

    const loadOrdersList = React.useCallback(async () => {
        setLoading(true)
        await getOrdersList()
    }, [
        accountSlug,
        dateRange.from,
        dateRange.to,
        dateRangeType,
        search,
        sort.prop,
        sort.direction,
        pagination.page,
        pagination.pageSize,
        searchType,
        filter,
    ])

    const getOrdersList = React.useCallback(async () => {
        const innerDate = new Date().valueOf()
        lastCall.current = innerDate

        try {
            const data = await ordersService.getTransactionsList(
                accountSlug,
                dateRange.from,
                dateRange.to,
                dateRangeType,
                search,
                sort.prop,
                sort.direction,
                pagination.page,
                pagination.pageSize,
                searchType === 'extended' ? 'extended' : 'simple',
                filter,
            )

            if (lastCall.current === innerDate) {
                setOrdersPageData(data.orders)
                setDisputesCount(data.openDisputeCount)
                setLoading(false)
            }
        } catch {
            replaceMessages(
                'server_error',
                'error',
                'Oops! Transactions table could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }, [
        accountSlug,
        dateRange.from,
        dateRange.to,
        dateRangeType,
        search,
        sort.prop,
        sort.direction,
        pagination.page,
        pagination.pageSize,
        searchType,
        filter,
        ordersService,
        replaceMessages,
    ])

    React.useEffect(() => {
        loadOrdersList()
    }, [
        loadOrdersList,
    ])

    const saveNewEmail = async (orderUuid: string, email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!re.test(email)) {
            replaceMessages('email_error', 'error', 'Invalid email. Please try again.')
        } else {
            setLoading(true)
            try {
                await ordersService.updateEmail(orderUuid, email)
                await getOrdersList()
            } catch {
                replaceMessages(
                    'email_error',
                    'error',
                    `Oops! There was a problem with saving email ${email}. Please try again.`,
                )
            }
            setLoading(false)
        }
    }

    const saveNewVisitDate = (orderId: string, date: Date) => {
        setUpdateDialog('visitDate')
        setUpdatedOrderId(orderId)
        setUpdatedDateTime({
            date: format(date, 'yyyy-MM-dd'),
            time: null,
        })
    }

    const saveNewVisitTime = async (orderId: string, time: string) => {
        setUpdatedOrderId(orderId)
        setUpdatedDateTime({
            date: null,
            time,
        })
        await saveDateAndTime(orderId, { date: null, time })
    }

    const cancelSaveNewDateOrTime = () => {
        const clonedOrdersPageData = cloneDeep(ordersPageData)
        setOrdersPageData(clonedOrdersPageData)
        setUpdateDialog(null)
    }

    const saveDateAndTime = async (orderId: string, dateTime: DateAndTime) => {
        try {
            setUpdateDialog(null)
            setLoading(true)
            await ordersService.updateVisitDateAndTime(orderId, dateTime.date, dateTime.time)
            await getOrdersList()
        } catch (e) {
            if (e.message === 'no_availability') {
                replaceMessages(
                    'no_availability_error',
                    'error',
                    'No availability left for selected date or time.',
                )
            } else if (e.message === 'order_already_redeemed') {
                replaceMessages(
                    'already_redeemed_error',
                    'error',
                    'Cannot change date because order is already redeemed.',
                )
            } else if (e.message === 'date_change_not_possible') {
                replaceMessages('date_change_not_possible', 'error', 'Cannot change date for this order.')
            } else {
                replaceMessages(
                    'visit_date_error',
                    'error',
                    'Oops! There was a problem with saving new visit details. Please try again.',
                )
            }
        } finally {
            setLoading(false)
            setUpdatedOrderId('')
            setUpdatedDateTime({ date: null, time: null })
        }
    }

    const getLinkToCheckout = (account: Account) => {
        if (!!account.url) {
            const checkoutUrlParts = account.url.split('/')
            if (checkoutUrlParts[0].indexOf('http') < 0) {
                return `http://${account.url}#convious-control-panel`
            }
        }
        return `${account.url}#convious-control-panel`
    }

    const checkResellerHasRequiredPermissions = () => {
        const accountPermissions = user?.permissions.filter((a) => a.account === accountSlug)
        const permissionsList =
            accountPermissions && accountPermissions.length > 0 ? accountPermissions[0].permissions : []
        return permissionsList.includes('edit_orders') && permissionsList.includes('view_pricing_settings')
    }

    const dateFrom = cancelOutOffsetBeforeConvertionToUTC(dateRange.from).toISOString()
    const dateTo = cancelOutOffsetBeforeConvertionToUTC(dateRange.to).toISOString()

    const exportQuery = {
        search,
        widget_slug: accountSlug,
        date_from: dateFrom,
        date_to: dateTo,
        date_range_type: dateRangeType,
        sort_by: sort.prop,
        sort_direction: sort.direction,
        filters: query.filter,
    }

    const orderEntries = ordersPageData.entries
    const activeAccount = accounts.find((x) => x.slug === accountSlug) || accounts[0]
    const withTimeSlots = activeAccount ? activeAccount.timeSlotsEnabled : false
    const checkoutLink = getLinkToCheckout(activeAccount)
    const searchPlaceholder =
        query.searchType === 'extended'
            ? 'Search by ID, email, location or discount code'
            : 'Search by order ID or email'

    const userIsReseller = !!user?.resellerId
    const showInlineCOToResellers =
        userIsReseller &&
        hasFeature('ResellersFeature', accountSlug) &&
        activeAccount.activeResellerContract &&
        checkResellerHasRequiredPermissions()
    const showInlineCOToRegularUsers =
        hasFeature('CPInlineCheckout', accountSlug) &&
        hasPermission('edit_orders', accountSlug) &&
        hasPermission('view_pricing_settings', accountSlug)
    const inlineCheckout = showInlineCOToResellers || showInlineCOToRegularUsers
    const hideNewOrderButton = userIsReseller && !showInlineCOToResellers
    const resellerWithMultipleAccounts = !!user && user.accounts.length > 1
    const inliceCheckoutLink =
        userIsReseller && resellerWithMultipleAccounts
            ? `/resellers/account/${accountSlug}/new_order/`
            : `/account/${accountSlug}/orders/reseller/new_order`

    return (
        <>
            {updateDialog && (
                <UpdateDialog
                    dialogType={updateDialog}
                    order={orderEntries.find((o) => o.id === updatedOrderId)}
                    updatedDateTime={updatedDateTime}
                    onCancel={cancelSaveNewDateOrTime}
                    onConfirm={() => saveDateAndTime(updatedOrderId, updatedDateTime)}
                />
            )}
            <PageTitle data-userpilot="orders-page-header">Orders</PageTitle>
            <DatePickerWrapper>
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    dateRangeTypeDisabled={loading}
                    dateRangeType={dateRangeType}
                    onDateRangeTypeChange={onDateRangeTypeChange}
                    allowFutureDateSelection
                />
            </DatePickerWrapper>
            <PageFeatures>
                <LeftSideFeatures>
                    <SearchContainer>
                        <OrderSearchField
                            placeholder={searchPlaceholder}
                            style={{ width: '22em' }}
                            disabled={loading}
                        />
                        <SearchResults>{!loading ? `${ordersPageData.totalCount} results` : ''}</SearchResults>
                    </SearchContainer>
                </LeftSideFeatures>
                <RightSideFeatures>
                    <OrdersFilter
                        page="orders"
                        accountSlug={accountSlug}
                        ordersService={ordersService}
                        replaceMessages={replaceMessages}
                        hideMessage={hideMessage}
                    />
                    <Feature name="PaypalResolutionCenter" accountSlug={accountSlug}>
                        <Permission name="partner_admin" accountSlug={accountSlug}>
                            <DisputesCenterLink
                                kind="action"
                                secondary
                                href={`/account/${accountSlug}/orders/paypal/disputes/active/${location.search}&activeDisputesDateRange=last30days`}
                                style={{ marginLeft: '1em' }}
                                className={disputesCount === 0 ? 'no-disputes' : ''}
                            >
                                Order disputes
                                {!!disputesCount && (
                                    <DisputesCount>
                                        <span>{disputesCount}</span>
                                    </DisputesCount>
                                )}
                            </DisputesCenterLink>
                        </Permission>
                    </Feature>
                    {inlineCheckout && !hideNewOrderButton && (
                        <ActionButton kind="action" secondary style={{ marginLeft: '1em', zIndex: 1 }}>
                            <UnstyledLink to={inliceCheckoutLink}>New order</UnstyledLink>
                        </ActionButton>
                    )}
                    {!inlineCheckout && !hideNewOrderButton && (
                        <ActionButtonExternalLink
                            href={checkoutLink}
                            target="_blank"
                            kind="action"
                            secondary
                            style={{ marginLeft: '1em', zIndex: 1 }}
                        >
                            New order
                        </ActionButtonExternalLink>
                    )}
                    <Permission name="export_orders" accountSlug={accountSlug}>
                        <ExportMenu
                            ordersService={ordersService}
                            ordersNumber={ordersPageData.totalCount}
                            ordersQuery={exportQuery}
                            loginService={loginService}
                            loggingService={loggingService}
                            backofficeEndpoint={backofficeEndpoint}
                            activeSlug={accountSlug}
                            hideMessage={hideMessage}
                            replaceMessages={replaceMessages}
                            dateRange={dateRange}
                            page="transactions"
                        />
                    </Permission>
                </RightSideFeatures>
            </PageFeatures>
            <TransactionsTable
                history={history}
                orders={orderEntries}
                loading={loading}
                sort={sort}
                onSortChanged={onSortChanged}
                ordersService={ordersService}
                loggingService={loggingService}
                accountSlug={accountSlug}
                saveNewEmail={saveNewEmail}
                saveNewVisitDate={saveNewVisitDate}
                saveNewVisitTime={saveNewVisitTime}
                reloadOrderList={getOrdersList}
                replaceMessages={replaceMessages}
                hideMessage={hideMessage}
                withTimeSlots={withTimeSlots}
            />
            <PaginationSection
                pagination={pagination}
                onPaginationChanged={onPaginationChanged}
                totalItemsCount={ordersPageData.totalCount}
                style={{ marginBottom: 0 }}
            />
            <BaseKnowledgeLink link="https://support.convious.com/help/how-to-manage-orders" />
        </>
    )
}

export default withFeatures(withNavigation(TransactionsPage))
