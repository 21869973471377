import * as React from 'react'
import styled from 'styled-typed'
import { Switch, Route, Redirect, useParams } from 'react-router-dom'
import { History, Location } from 'history'
import { connect } from 'react-redux'
import { State } from 'store'
import { Account } from 'auth/state'
import { BaseRouteParams, withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { AccountSettingsService } from 'settings/settingsService'
import { ArticleService } from 'admin/articleService'
import EmailNotificationsPage from 'settings/notifications/emailNotifications'
import NotificationsFeedSection from './notificationsFeed'
import { Container, ContainerHeading, ContainerBody, HeadingSectionName, Section } from 'uiComponents/settingsContainer'
import { Headline } from 'uiComponents/typography'
import { Checkbox } from 'uiComponents/input'
import { withFeatures } from 'features'
import { useMessages } from 'messagesContext'

const AllPartnersToggle = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 1.5em;
    top: -2em;
    span {
        margin-left: 0.2em;
        font-size: 0.75em;
    }
`
const ToggleWrapper = styled.div`
    position: relative;
    background: red;
`

interface NotificationsPageProps {
    history: History
    location: Location
    accountSettingsService: AccountSettingsService
    articleService: ArticleService
    accounts: Account[]
    navigation: Navigation
    match: RouteMatch<{}>
    isAdmin: boolean
    hasFeature: (feature: string, accountSlug?: string) => boolean
}

function NotificationsPage(props: NotificationsPageProps) {
    const [activeSection, setActiveSection] = React.useState<string>('feed')
    const [allPartners, setAllPartners] = React.useState<boolean>(false)
    const { replaceMessages, removeAllMessages } = useMessages()
    const { accountSlug } = useParams<BaseRouteParams>()

    React.useEffect(() => {
        if (!allPartners && !!props.navigation.query().showAllPartners) {
            setAllPartners(true)
        }
    }, [props.navigation.query().showAllPartners])

    const onSectionChange = (section: string) => {
        setActiveSection(section)
    }

    const onAllPartnersChange = () => {
        props.navigation.addQueryWithReplace({
            showAllPartners: allPartners ? null : 'true',
        })
        setAllPartners(!allPartners)
    }

    const activeAccount = props.accounts.find((x) => x.slug === accountSlug) || props.accounts[0]
    const accountName = props.isAdmin && allPartners && activeSection === 'feed' ? '' : ` - ${activeAccount.name}`

    return (
        <div style={{ position: 'relative' }}>
            <Headline size={1} style={{ marginBottom: '2em' }}>
                Notifications{accountName}
            </Headline>
            {props.isAdmin && activeSection === 'feed' && (
                <ToggleWrapper>
                    <AllPartnersToggle>
                        <Checkbox checked={allPartners} onChange={onAllPartnersChange} />
                        <span>Show all partners&apos; notifications</span>
                    </AllPartnersToggle>
                </ToggleWrapper>
            )}
            <Container>
                <ContainerHeading>
                    <HeadingSectionName
                        className={activeSection === 'feed' ? 'active' : ''}
                        to={`/account/${accountSlug}/notifications/feed`}
                    >
                        Notifications
                    </HeadingSectionName>
                    <HeadingSectionName
                        className={activeSection === 'settings' ? 'active' : ''}
                        to={`/account/${accountSlug}/notifications/settings/`}
                    >
                        Email settings
                    </HeadingSectionName>
                </ContainerHeading>
                <ContainerBody>
                    <Switch location={props.location}>
                        <Route
                            path="/account/:accountSlug/notifications/settings"
                            render={() => (
                                <Section>
                                    <EmailNotificationsPage
                                        settingsService={props.accountSettingsService}
                                        articleService={props.articleService}
                                        accountSlug={accountSlug}
                                        onSectionChange={onSectionChange}
                                        replaceMessages={replaceMessages}
                                        removeAllMessages={removeAllMessages}
                                    />
                                </Section>
                            )}
                        />
                        <Route
                            path="/account/:accountSlug/notifications/feed"
                            render={() => (
                                <Section>
                                    <NotificationsFeedSection
                                        accountSlug={accountSlug}
                                        allPartners={allPartners}
                                        isAdmin={props.isAdmin}
                                        accounts={props.accounts}
                                        onSectionChange={onSectionChange}
                                        replaceMessages={replaceMessages}
                                        removeAllMessages={removeAllMessages}
                                    />
                                </Section>
                            )}
                        />
                        <Route render={() => <Redirect to={`/account/${accountSlug}/notifications/feed`} />} />
                    </Switch>
                </ContainerBody>
            </Container>
        </div>
    )
}

const mapStateToProps = (state: State) => {
    return {
        accounts: state.auth.user ? state.auth.user.accounts : [],
        isAdmin: !!state.auth.user?.isAdmin,
    }
}

export default withFeatures(withNavigation(connect(mapStateToProps)(NotificationsPage)))
