import * as React from 'react'
import { match as RouteMatch } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { StatsServiceContext } from 'http/context'
import { InventoryServiceContext } from 'inventory/context'
import { PageTitle } from 'uiComponents/typography'
import { StatsService } from 'http/statsService'
import { dateRangeToQuery, DateRange } from 'dateRanges'
import RevenueChart from 'reports/sales/timeseriesChart'
import RevenuePieCharts from 'reports/sales/pieCharts'
import SalesAndRevenueTable from 'reports/sales/table'
import ResellersRevenueTable from 'reports/sales/resellersRevenueTable'
import SingleDataItems from 'reports/sales/singleDataItems'
import { createTimeseriesBarChart, TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { getDataFromQuery, areDateRangeDatesEqual, ReportsToolsWrapper, ReportUpdateInfo } from 'reports/helpers'
import ReportsFilter from 'reports/reportsFilter'
import { FilterCategory, MetaData } from 'uiComponents/filter/schema'
import { AVAILABLE_FILTERS, STANDALONE_FILTERS, METADATA_CATEGORIES } from 'reports/constants'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { RevenuePageMetricType } from 'reports/schema'
import { ChartContainer } from 'uiComponents/charts/styleComponents'
import PresetDateRangeElement, { PresetType } from 'uiComponents/popups/presetsElement'
import { withFeatures } from 'features'
import { DateRangeType } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { LoginService } from 'http/loginService'
import { LoggingService } from 'http/loggingService'
import Disclaimer from 'reports/disclaimer'
import { CharonIncidentWarning } from '../utils/charonIncidentWarning'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import { useMessages } from 'messagesContext'

interface RevenueReportsPageProps {
    navigation: Navigation
    match: RouteMatch<any>
    statsService: StatsService
    accountSlug: string
    loginService: LoginService
    loggingService: LoggingService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function RevenueReportsPage(props: RevenueReportsPageProps) {
    const statsService = React.useContext(StatsServiceContext)
    const inventoryService = React.useContext(InventoryServiceContext)
    const { replaceMessages, hideMessage } = useMessages()
    const { dateRange, filters } = getDataFromQuery(props.navigation.query())
    const [chart, setChart] = React.useState<TimeseriesBarChartSource>(createTimeseriesBarChart(dateRange))
    const [metadata, setMetadata] = React.useState<MetaData[]>([])

    const presetName = 'metric'
    const defaultPreset: PresetType = 'revenue'
    const presetOptions: PresetType[] = ['revenue', 'transactions', 'products_sold']

    React.useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const onDateRangeChanged = (range: DateRange) => {
        if (!areDateRangeDatesEqual(dateRange, range)) {
            props.navigation.addQueryWithReplace(dateRangeToQuery(range))
            setChart(createTimeseriesBarChart(range))
        }
    }

    const onDateRangeTypeChange = (rangeType: DateRangeType) => {
        props.navigation.addQueryWithReplace({ dateRangeType: rangeType })
    }

    const resellersFeatureOn = props.hasFeature('ResellersFeature', props.accountSlug)
    const availableFilters = resellersFeatureOn
        ? AVAILABLE_FILTERS['sales']
        : AVAILABLE_FILTERS['sales'].filter((f) => f !== 'channels')

    const appliedFilters = filters.filter((f) => availableFilters.indexOf(f.attribute as FilterCategory) > -1)
    const query = props.navigation.query()
    const dateRangeType = query.dateRangeType === 'visit' ? 'visit' : 'sale'
    const reportTimeIdentifierType = query.dateRangeType === 'visit' ? 'visit_date' : 'purchase_date'
    const metric =
        presetOptions.indexOf(query.metric as PresetType) > -1 ? (query.metric as RevenuePageMetricType) : 'revenue'

    return (
        <div id="reports-sales-page">
            <PageTitle data-userpilot="sales-header-tooltip">Sales Overview</PageTitle>
            <CharonIncidentWarning dateRange={dateRange}>
                Due to a technical issue, the conversion rate metrics from 20th to 23rd of February, 2024 will be
                excluded from our reports. We apologize for any inconvenience.
            </CharonIncidentWarning>
            <ReportUpdateInfo metric="Data" text="updated every 20 minutes." />
            <ReportsToolsWrapper>
                <ReportsFilter
                    accountSlug={props.accountSlug}
                    statsService={statsService}
                    inventoryService={inventoryService}
                    dateRange={dateRange}
                    applicableFilters={availableFilters}
                    standaloneFilters={STANDALONE_FILTERS['sales']}
                    metadataCategories={METADATA_CATEGORIES['sales']}
                    appliedFilters={appliedFilters}
                    replaceMessages={replaceMessages}
                    hideMessage={hideMessage}
                    userpilot="sales-filter"
                    setMetadata={setMetadata}
                />
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    userpilot="sales-date-range"
                    firstAvailableDay={new Date(2019, 0, 1)}
                    dateRangeType={dateRangeType}
                    onDateRangeTypeChange={onDateRangeTypeChange}
                    allowFutureDateSelection
                />
            </ReportsToolsWrapper>
            <SingleDataItems
                filters={appliedFilters}
                accountSlug={props.accountSlug}
                dateRange={dateRange}
                timeIdentifierType={reportTimeIdentifierType}
                replaceMessages={replaceMessages}
            />
            <PresetDateRangeElement
                presetName={presetName}
                default={defaultPreset}
                options={presetOptions}
                style={{ marginRight: '1em' }}
            />
            <ChartContainer style={{ marginTop: '1em' }}>
                <RevenueChart
                    filters={appliedFilters}
                    accountSlug={props.accountSlug}
                    dateRange={dateRange}
                    timeIdentifierType={reportTimeIdentifierType}
                    chart={chart}
                    metric={metric}
                    loginService={props.loginService}
                    loggingService={props.loggingService}
                    replaceTopMessages={replaceMessages}
                    hideMessage={hideMessage}
                    groupByLocation={props.hasFeature('ReportGroupByLocation', props.accountSlug)}
                    metadata={metadata}
                />
            </ChartContainer>
            <RevenuePieCharts
                filters={appliedFilters}
                accountSlug={props.accountSlug}
                dateRange={dateRange}
                timeIdentifierType={reportTimeIdentifierType}
                metric={metric}
                loginService={props.loginService}
                loggingService={props.loggingService}
                replaceTopMessages={replaceMessages}
                hideMessage={hideMessage}
            />
            <SalesAndRevenueTable
                accountSlug={props.accountSlug}
                dateRange={dateRange}
                filters={appliedFilters}
                timeIdentifierType={reportTimeIdentifierType}
                statsService={statsService}
                loginService={props.loginService}
                loggingService={props.loggingService}
                replaceTopMessages={replaceMessages}
                hideMessage={hideMessage}
            />
            {resellersFeatureOn && (
                <ResellersRevenueTable
                    accountSlug={props.accountSlug}
                    dateRange={dateRange}
                    timeIdentifierType={reportTimeIdentifierType}
                    filters={appliedFilters}
                    loginService={props.loginService}
                    loggingService={props.loggingService}
                    replaceTopMessages={replaceMessages}
                    hideMessage={hideMessage}
                />
            )}
            <BaseKnowledgeLink link="https://support.convious.com/help/sales-report" />
            <Disclaimer />
        </div>
    )
}

export default withFeatures(withNavigation(RevenueReportsPage))
