import { Route } from 'react-router-dom'
import {
    createReactRouterV5Options,
    getWebInstrumentations,
    initializeFaro,
    LogLevel,
    ReactIntegration,
} from '@grafana/faro-react'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'

// bot filtering
// taken from: https://grafana.com/docs/grafana-cloud/monitor-applications/frontend-observability/instrument/filter-bots/
let bots =
    '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)'
let botsRegex = new RegExp(bots, 'i')
let samplingFactor = botsRegex.test(navigator.userAgent) ? 0 : 1

export function faroInstrumentation(faroEndpoint: string) {
    initializeFaro({
        url: faroEndpoint,
        app: {
            name: 'control-panel',
            version: process.env.VERSION,
            environment: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
        },

        ignoreUrls: [/sentry.io/, /hubspot.com/, /forms.hscollectedforms.net/, /released.so/, /maps.googleapis/, /api.openreplay/],

        instrumentations: [
            ...getWebInstrumentations({
                captureConsole: true,
                captureConsoleDisabledLevels: [LogLevel.DEBUG, LogLevel.TRACE, LogLevel.INFO, LogLevel.LOG],
            }),

            new TracingInstrumentation({
                instrumentationOptions: {
                    fetchInstrumentationOptions: {
                        applyCustomAttributesOnSpan: (span, request, result) => {
                            // @ts-ignore
                            span.updateName(`${request.method || 'GET'} ${result.url.replace(/https?:\/\//, '')}`)
                        },
                    },
                    xhrInstrumentationOptions: {
                        applyCustomAttributesOnSpan: (span, xhr) => {
                            span.updateName(`${xhr.responseURL.replace(/https?:\/\//, '')}`)
                        },
                    },
                },
            }),

            new ReactIntegration({
                router: createReactRouterV5Options({
                    history,
                    Route,
                }),
            }),
        ],

        sessionTracking: {
            enabled: true,
            persistent: true,
            maxSessionPersistenceTime: 2 * 60 * 60 * 1000, // 2 hour
            samplingRate: samplingFactor,
        },
    })
}
