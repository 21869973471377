import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AppServices } from 'middleware'
import { BookingFee, BookingFeesResponse, NewBookingFee } from './bookingFeesService'

export const bookingFeesApi = createApi({
    reducerPath: 'bookingFeesApi',
    tagTypes: ['bookingFees'],
    baseQuery: fetchBaseQuery({}),
    endpoints: (builder) => ({}),
})

export const extendedBookingFeesApi = bookingFeesApi.injectEndpoints({
    endpoints: (builder) => ({
        getBookingFees: builder.query<BookingFeesResponse | null, string>({
            queryFn: async (accountSlug) => {
                try {
                    const data = await AppServices.bookingFeesService.getBookingFees(accountSlug)
                    return { data }
                } catch (error) {
                    return { data: null, error }
                }
            },
            providesTags: ['bookingFees'],
        }),
        createBookingFee: builder.mutation<BookingFee, { accountSlug: string; bookingFee: NewBookingFee }>({
            invalidatesTags: ['bookingFees'],
            queryFn: async ({ accountSlug, bookingFee }) => {
                try {
                    const data = await AppServices.bookingFeesService.createBookingFee(accountSlug, bookingFee)
                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        updateBookingFee: builder.mutation<BookingFee, { accountSlug: string; bookingFee: BookingFee }>({
            invalidatesTags: ['bookingFees'],
            queryFn: async ({ accountSlug, bookingFee }) => {
                try {
                    const data = await AppServices.bookingFeesService.updateBookingFee(accountSlug, bookingFee)
                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
        deleteBookingFee: builder.mutation<void, { accountSlug: string; bookingFeeUuid: string }>({
            invalidatesTags: ['bookingFees'],
            queryFn: async ({ accountSlug, bookingFeeUuid }) => {
                try {
                    const data = await AppServices.bookingFeesService.deleteBookingFee(accountSlug, bookingFeeUuid)
                    return { data }
                } catch (error) {
                    return { error }
                }
            },
        }),
    }),
})

export const { reducer, reducerPath } = bookingFeesApi
export const {
    useGetBookingFeesQuery,
    useCreateBookingFeeMutation,
    useUpdateBookingFeeMutation,
    useDeleteBookingFeeMutation,
} = extendedBookingFeesApi
