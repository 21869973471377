import { History, Location } from 'history'
import { LoggingService } from 'http/loggingService'
import { LoginService } from 'http/loginService'
import * as React from 'react'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import AudiencesRoutes from './audiences/audiencesRoutes'
import { CRMService } from './crmService'
import ProfileDetailsHome from './profiles/profileDetails'
import ProfileList from './profiles/profileList'
import { BaseRouteParams } from 'hocs'

interface CRMHomeProps {
    history: History
    location: Location
    crmService: CRMService
    loginService: LoginService
    loggingService: LoggingService
    crmEndpoint: string
}

function CRMHome(props: CRMHomeProps) {
    const { accountSlug } = useParams<BaseRouteParams>()
    return (
        <Switch location={props.location}>
            <Route
                path="/account/:accountSlug/crm/profiles/list"
                render={() => (
                    <ProfileList
                        accountSlug={accountSlug}
                        history={props.history}
                        crmService={props.crmService}
                    />
                )}
            />
            <Route
                path="/account/:accountSlug/crm/profiles/details/:cookieId"
                render={() => (
                    <ProfileDetailsHome
                        accountSlug={accountSlug}
                        history={props.history}
                        location={props.location}
                        crmService={props.crmService}
                    />
                )}
            />
            <Route path="/account/:accountSlug/crm/audiences" render={AudiencesRoutes} />
            <Route render={() => <Redirect to={`/account/${accountSlug}/crm/profiles/list`} />} />
        </Switch>
    )
}

export default CRMHome
