import React from 'react'
import './tabContent.scss'

interface TabContentProps {
    children?: React.ReactNode
    className?: string
}

export const TabContent = ({ children, className }: TabContentProps) => (
    <div className={`tab-content ${className}`.trim()}>{children}</div>
)

export default TabContent
