/* eslint-disable id-denylist */
import * as React from 'react'
import { Order, SelectedForRefund, TicketForRefund, Ticket } from 'orders/schema'
import isEqual from 'lodash/isEqual'
import { ChartDataLoader } from 'uiComponents/loaders'
import { flatMap } from 'utils'
import { User } from 'auth/state'
import { OrdersService } from 'orders/ordersService'
import { MessageKind } from 'uiComponents/messages'
import RefundDialogTable from './refundDialogTable'
import { withFeatures } from 'features'
import { RefundPaypalDetails } from 'orders/paypalDisputes/schema'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { RefundFeeType } from 'settings/accountSettings/contract/managementService'
import { State } from 'store'
import { connect } from 'react-redux'

const emptyOrder = {
    id: '',
    number: '',
    uuid: '',
    location: '',
    status: '',
    orderStatus: '',
    paymentStatus: '',
    customer: { email: '', language: '' },
    orderDate: '',
    paymentDate: '',
    visitDate: null,
    visitTime: null,
    tickets: [],
    total: 0,
    totalAfterDiscount: 0,
    discountAmount: 0,
    discountCode: '',
    quantity: 0,
    canRefund: true,
    canPartialRefund: true,
    partnerRefundFee: null,
    partnerRefundFeeType: null,
    ticketDownloadUrl: '',
    emailStatus: '',
    resellerName: null,
    paymentProvider: 'Adyen',
    unfilledApf: false,
    downloaded: null,
    paymentMethod: null,
    payments: [],
}

interface RefundDialogProps {
    user: User
    accountSlug: string
    orderNumber: string
    refunding: boolean
    cancellation: boolean
    paypalRefund?: RefundPaypalDetails
    onRefund?: (cancellation: boolean, e?: Error) => void
    onRefundPaypalDispute?: (e?: Error) => void
    cancelRefund: () => void
    onHeightChange: (height: number) => void
    ordersService: OrdersService
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    hasFeature: (feature: string, slug: string) => boolean
    disallowPartialRefunds?: boolean
    selectedItems?: Record<string, Record<string, boolean>>
}

interface RefundDialogState {
    order: Order
    ticketsForRefund: TicketForRefund[]
    fullRefund: boolean
    selectedTickets: SelectedForRefund[]
    refundReason: string
    confirmRefundView: boolean
    partnerRefundFeeOff: boolean
    noneSelectedMsg: string
    showCancellationConfirmation: boolean
    loading: boolean
}

class RefundDialog extends React.Component<RefundDialogProps, RefundDialogState> {
    constructor(props: RefundDialogProps) {
        super(props)
        this.state = {
            order: emptyOrder,
            ticketsForRefund: [],
            fullRefund: true,
            selectedTickets: [],
            refundReason: '',
            confirmRefundView: false,
            partnerRefundFeeOff: true,
            noneSelectedMsg: '',
            showCancellationConfirmation: false,
            loading: true,
        }
    }

    container: HTMLDivElement | null = null

    async componentDidMount() {
        await this.getOrderDetails()
    }

    getOrderDetails = async () => {
        try {
            const { orderNumber, accountSlug } = this.props
            const order = await this.props.ordersService.getOrderDetailsForRefundOld(accountSlug, orderNumber)
            this.setState(
                {
                    order,
                    partnerRefundFeeOff: !order.partnerRefundFee,
                },
                () => {
                    const orderUuid = order.uuid
                    const ticketsForRefund = this.getTicketsForRefund()
                    const preselectedItems =
                        this.props.selectedItems &&
                        orderUuid &&
                        this.props.selectedItems[orderUuid] &&
                        Object.entries(this.props.selectedItems[orderUuid])
                            .filter(([, value]) => value)
                            .map(([uuid]) => uuid)

                    const allTickets = order.tickets
                        .filter((ticket) => ticket.canRefund)
                        .reduce((acc: string[], current) => {
                            current.groupedTicketEntities.forEach(([ticket]) => {
                                if (ticket) {
                                    acc.push(ticket.uuid)
                                }
                            })
                            return acc
                        }, [] as string[])

                    const areAllTicketsPreselected = allTickets.every((ticketUuid) =>
                        preselectedItems?.includes(ticketUuid),
                    )

                    this.setState({
                        ticketsForRefund,
                        selectedTickets: this.getAllSelectedList(ticketsForRefund, orderUuid),
                        loading: false,
                        fullRefund:
                            !preselectedItems || preselectedItems.length === 0 ? true : areAllTicketsPreselected,
                    })
                },
            )
        } catch {
            this.setState({ loading: false })
            this.props.cancelRefund()
            this.props.replaceMessages(
                'unknown_error',
                'error',
                `Oops! There was a problem with getting order ${this.props.orderNumber} details for refund`,
            )
        }
    }

    setRef = (node: HTMLDivElement) => {
        this.container = node
    }

    updateHeight = () => {
        if (this.container) {
            this.props.onHeightChange(this.container.scrollHeight)
        }
    }

    handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ refundReason: event.target.value })
    }

    getTicketsForRefund = () => {
        const mappedTicketItemsData = this.state.order.tickets.map((t) => ({
            ...t,
            items: this.getTicketItemsForRefund(t),
        }))
        const mappedTicketsData = mappedTicketItemsData.map((t) => ({
            ...t,
            status: this.checkStatusesEqual(t.items.map((b) => b.status)) ? t.items[0].status : 'partial',
        }))
        return mappedTicketsData
    }

    getTicketItemsForRefund = (t: Ticket) => {
        const items = t.groupedTicketEntities.map((gte, j) => {
            const itemStatus = this.checkStatusesEqual(gte.map((te) => te.status))
                ? gte[0].status
                : 'partially redeemed'
            return {
                ticketEntities: gte,
                ticketUuids: gte.map((i) => i.uuid),
                barcodeList: gte.map((b) => b.barcode?.barcode || ''),
                status: itemStatus,
                itemRefundable: gte.filter((i) => !i.canRefund).length === 0,
                price: t.isBundle
                    ? t.bundleItemPrices
                        ? t.bundleItemPrices[j]
                        : t.pricePerItemAfterDiscount
                    : gte[0]?.price || 0,
            }
        })
        return items
    }

    checkStatusesEqual = (statuses: string[]) => {
        return statuses.every((s, i, arr) => s === arr[0])
    }

    handleOrderItemSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ noneSelectedMsg: '' })
        // order item checkbox is an order item's uuid
        const orderItemUuid = event.target.name
        let updatedSelection = this.state.selectedTickets.map((t) => ({
            uuid: t.uuid,
            ticketUuids: [...t.ticketUuids],
        }))
        const ticketOnCurrentList = updatedSelection.filter((t) => t.uuid === orderItemUuid).length > 0
        if (ticketOnCurrentList) {
            updatedSelection = updatedSelection.filter((t) => t.uuid !== orderItemUuid)
        } else {
            const selectedTicket = this.state.ticketsForRefund.filter((t) => t.orderItemUuid === orderItemUuid)[0]
            updatedSelection.push({
                uuid: orderItemUuid,
                ticketUuids: selectedTicket.items.filter((i) => i.itemRefundable).flatMap((i) => i.ticketUuids),
            })
        }
        const fullRefund = this.checkIfFullRefund(updatedSelection)
        this.setState({ selectedTickets: updatedSelection, fullRefund })
    }

    handleTicketSelect = (orderItemUuid: string, ticketUuidList: string[]) => {
        this.setState({ noneSelectedMsg: '' })
        const updatedSelection = this.state.selectedTickets.map((i) => ({
            uuid: i.uuid,
            ticketUuids: [...i.ticketUuids],
        }))
        const updatedTicket = updatedSelection.filter((st) => st.uuid === orderItemUuid)[0]
        if (!updatedTicket) {
            updatedSelection.push({
                uuid: orderItemUuid,
                ticketUuids: [],
            })
        }
        this.addRemoveItems(updatedSelection, orderItemUuid, ticketUuidList)
    }

    addRemoveItems = (updatedSelection: SelectedForRefund[], orderItemUuid: string, ticketUuidList: string[]) => {
        const updatedTicket = updatedSelection.filter((st) => st.uuid === orderItemUuid)[0]
        ticketUuidList.forEach((ticket) => {
            const barcodeOnCurrentList = updatedTicket.ticketUuids.indexOf(ticket) > -1
            if (barcodeOnCurrentList) {
                updatedTicket.ticketUuids.splice(updatedTicket.ticketUuids.indexOf(ticket), 1)
            } else {
                updatedTicket.ticketUuids.push(ticket)
            }
        })

        if (updatedTicket.ticketUuids.length === 0) {
            updatedSelection = updatedSelection.filter((st) => st.uuid !== orderItemUuid)
        }
        const fullRefund = this.checkIfFullRefund(updatedSelection)
        this.setState({ selectedTickets: updatedSelection, fullRefund })
    }

    getAllSelectedList = (ticketsForRefund: TicketForRefund[] = [], orderUuid?: string) => {
        const preselectedItems =
            this.props.selectedItems &&
            orderUuid &&
            this.props.selectedItems[orderUuid] &&
            Object.entries(this.props.selectedItems[orderUuid])
                .filter(([, v]) => v)
                .map(([k]) => k)
        const tickets =
            this.state && this.state.ticketsForRefund.length > 0 ? this.state.ticketsForRefund : ticketsForRefund
        const allItems = tickets
            .map((t) => {
                return {
                    uuid: t.orderItemUuid,
                    ticketUuids: t.items
                        .filter((i) => i.itemRefundable)
                        .flatMap((i) => i.ticketUuids)
                        .filter((t) =>
                            preselectedItems && preselectedItems.length > 0 ? preselectedItems.includes(t) : true,
                        ),
                }
            })
            .filter((t) => t.ticketUuids.length > 0)

        return [...allItems]
    }

    checkIfFullRefund = (updatedSelection: SelectedForRefund[]) => {
        const updatedItems = updatedSelection.reduce((a, s) => ({ ...a, [s.uuid]: s.ticketUuids.sort() }), {})
        const oldItems = this.getAllSelectedList().reduce((a, s) => ({ ...a, [s.uuid]: s.ticketUuids.sort() }), {})
        return isEqual(updatedItems, oldItems)
    }

    toggleFullRefund = () => {
        this.setState({ noneSelectedMsg: '' })
        const updatedList = this.state.fullRefund ? [] : this.getAllSelectedList()
        this.setState({
            fullRefund: !this.state.fullRefund,
            selectedTickets: updatedList,
        })
    }

    togglePartnerRefundFee = () => {
        this.setState({ partnerRefundFeeOff: !this.state.partnerRefundFeeOff })
    }

    onCancel = () => {
        if (this.state.confirmRefundView) {
            this.setState({ confirmRefundView: false })
        } else {
            this.props.cancelRefund()
        }
    }

    onNext = () => {
        if (this.state.confirmRefundView) {
            if (this.props.cancellation) {
                this.setState({ showCancellationConfirmation: true })
                return
            }
            this.onRefund(this.props.cancellation)
        } else {
            if (this.state.selectedTickets.length === 0) {
                this.setState({
                    noneSelectedMsg: 'Please select at least one item to refund',
                })
                return
            }
            this.setState({ confirmRefundView: true }, () => this.updateHeight())
        }
    }

    onConfirmCancellation = () => {
        this.setState({ showCancellationConfirmation: false })
        this.onRefund(true)
    }

    onRefund = async (useCancellationCopy: boolean) => {
        const { order, selectedTickets, refundReason, partnerRefundFeeOff } = this.state
        try {
            this.setState({ loading: true })
            await this.props.ordersService.refundOrder(
                this.props.accountSlug,
                order.uuid,
                selectedTickets,
                refundReason,
                partnerRefundFeeOff,
                this.props.cancellation,
            )
            if (this.props.onRefund) {
                this.props.onRefund(useCancellationCopy)
            } else if (this.props.onRefundPaypalDispute) {
                this.props.onRefundPaypalDispute()
            }
        } catch (e) {
            if (this.props.onRefund) {
                this.props.onRefund(useCancellationCopy, e)
            } else if (this.props.onRefundPaypalDispute) {
                this.props.onRefundPaypalDispute(e)
            }
        }
    }

    filterTicketsForConfirmation = (tickets: TicketForRefund[]) => {
        const selectedOrderItemIds = this.state.selectedTickets.map((t) => t.uuid)
        const selectedOrderItems = tickets.filter(
            (t) => selectedOrderItemIds.indexOf(t.orderItemUuid) > -1 && t.canRefund,
        )
        return selectedOrderItems.map((t) => {
            const selectedTickets = this.state.selectedTickets.filter((st) => st.uuid === t.orderItemUuid)[0]
                .ticketUuids
            return {
                ...t,
                items: t.items.filter((i) => selectedTickets.indexOf(i.ticketUuids[0]) > -1),
            }
        })
    }

    checkThereAreRedeemedBarcodes = () => {
        const allTicketItems = flatMap(
            this.state.ticketsForRefund.map((t) => t.items),
            (x) => x,
        )
        const redeemedItems = allTicketItems.filter((i) => i.status === 'redeemed' || i.status === 'partially redeemed')
        return redeemedItems.length > 0
    }

    calculateRefundFee = (total: number, fee: number | null, feeType: RefundFeeType | null) => {
        if (!!total && !!fee && !!feeType) {
            return feeType === 'absolute' ? fee : (total * fee) / 100
        }
        return 0
    }

    getTicketTotalAmount = (ticket: TicketForRefund) => {
        return ticket.items.reduce((s, item) => s + item.price, 0)
    }

    getTotalAmount = (tickets: TicketForRefund[]) => {
        return tickets.reduce((s, t) => s + this.getTicketTotalAmount(t), 0).toFixed(2)
    }

    render() {
        const {
            order,
            fullRefund,
            refundReason,
            selectedTickets,
            confirmRefundView,
            noneSelectedMsg,
            loading,
            partnerRefundFeeOff,
            showCancellationConfirmation,
        } = this.state
        const { accountSlug, refunding, user, paypalRefund, hasFeature, disallowPartialRefunds } = this.props

        const partialRefundsDisallowed = !hasFeature('OrderPartialRefunds', accountSlug) || !!disallowPartialRefunds
        const fullRefundOnly = !this.props.cancellation && (partialRefundsDisallowed || !order.canPartialRefund)
        const ticketList = confirmRefundView
            ? this.filterTicketsForConfirmation(this.state.ticketsForRefund)
            : this.state.ticketsForRefund
        const refundableTicketsList = ticketList
            .filter((t) => t.canRefund)
            .map((t) => ({ ...t, items: t.items.filter((i) => i.itemRefundable) }))
        const totalAmount = fullRefundOnly
            ? order.totalAfterDiscount.toFixed(2)
            : this.getTotalAmount(refundableTicketsList)
        const partnerRefundFeeAmount = partnerRefundFeeOff
            ? 0
            : this.calculateRefundFee(Number(totalAmount), order.partnerRefundFee, order.partnerRefundFeeType) || 0
        const showPartialRedeemWarning = this.checkThereAreRedeemedBarcodes()

        return (
            <div style={{ width: '32em' }} ref={this.setRef} id="refund-container">
                {(refunding || loading) && <ChartDataLoader />}
                {showCancellationConfirmation && (
                    <ConfirmationDialog
                        title="Are you sure?"
                        text="There will be no refund issued and the selected order items will be cancelled."
                        buttonText="Cancel items"
                        destructive
                        onCancel={() => this.setState({ showCancellationConfirmation: false })}
                        onConfirm={this.onConfirmCancellation}
                    />
                )}
                <RefundDialogTable
                    user={user}
                    accountSlug={accountSlug}
                    order={order}
                    ticketList={ticketList}
                    selectedTickets={selectedTickets}
                    totalAmount={totalAmount}
                    partnerRefundFeeAmount={partnerRefundFeeAmount}
                    refundReason={refundReason}
                    noneSelectedMsg={noneSelectedMsg}
                    fullRefund={fullRefund}
                    partnerRefundFeeOff={partnerRefundFeeOff}
                    confirmRefundView={confirmRefundView}
                    refunding={refunding}
                    showPartialRedeemWarning={showPartialRedeemWarning}
                    loading={loading}
                    fullRefundOnly={fullRefundOnly}
                    toggleFullRefund={this.toggleFullRefund}
                    updateHeight={this.updateHeight}
                    handleOrderItemSelect={this.handleOrderItemSelect}
                    handleTicketSelect={this.handleTicketSelect}
                    togglePartnerRefundFee={this.togglePartnerRefundFee}
                    handleTextAreaChange={this.handleTextAreaChange}
                    onNext={this.onNext}
                    onCancel={this.onCancel}
                    cancellation={this.props.cancellation}
                    paypalRefund={paypalRefund}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: State) => ({
    selectedItems: state.orderTable.selectedItems,
    user: state.auth.user,
})

export default connect(mapStateToProps)(withFeatures(RefundDialog))
