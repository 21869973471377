import { Locale } from 'admin/articleService'
import { ArticleServiceContext } from 'admin/context'
import { useFetchAudiences } from 'crm/audiences/hooks/useFetchAudiences'
import { History } from 'history'
import { BaseRouteParams, withNavigation } from 'hocs'
import { isEmpty } from 'lodash'
import { Navigation } from 'navigation'
import InboxMessage from 'notificationCampaigns/components/inboxMessage'
import PushNotification from 'notificationCampaigns/components/pushNotification'
import { Container } from 'notificationCampaigns/components/ui'
import {
    payloadToDefaultLanguage,
    payloadToState,
    payloadToTranslations,
    stateToPayload,
} from 'notificationCampaigns/helpers'
import NotificationCampaignsService from 'notificationCampaigns/service'
import Form, { StudioStep } from 'notificationCampaigns/studio/form'
import { LanguageValue, NotificationCampaignConfiguration, Translations } from 'notificationCampaigns/types'
import * as React from 'react'
import { match as RouteMatch, useParams } from 'react-router-dom'
import { Message } from 'uiComponents/messages'
import { AudienceModal } from 'uiComponents/studio/audienceModal'
import Header from 'uiComponents/studio/header'
import { Body, Page, Side } from 'uiComponents/studio/pageComponents'
import { FrameWrapper, PreviewContainer, SafariMobilePreviewSrollWrapper } from 'uiComponents/studio/preview/components'
import { setPropertyPath } from 'utils'
import { formDefinition } from './config'
import { useMessages } from 'messagesContext'

export interface StudioParams {
    step: StudioStep
    id: string
}

interface StudioProps {
    match: RouteMatch<StudioParams>
    history: History
    navigation: Navigation
    notificationCampaignsService: NotificationCampaignsService
}

const Studio = (props: StudioProps) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const NAVIGATION_PATH = `/account/${accountSlug}/engage/notification-campaigns`
    const articleService = React.useContext(ArticleServiceContext)
    const notificationCampaignId = React.useMemo(() => {
        return props.match.params.id === 'undefined' ? undefined : props.match.params.id
    }, [])
    const audiences = useFetchAudiences()

    const [currentStep, setCurrentStep] = React.useState<StudioStep>('name')
    const [componentValues, setComponentValues] = React.useState<NotificationCampaignConfiguration>({})
    const [accountLocales, setAccountLocales] = React.useState<Locale[]>([])
    const [translations, setTranslations] = React.useState<Translations>({})
    const [activeLanguage, setActiveLanguage] = React.useState<string>('')
    const [defaultLanguage, setDefaultLanguage] = React.useState<string>('')
    const [loadingCampaign, setLoadingCampaign] = React.useState<boolean>(false)
    const [loadingLocales, setLoadingLocales] = React.useState<boolean>(false)
    const [showAudienceModal, setShowAudienceModal] = React.useState<boolean>(false)
    const { removeAllMessages, replaceMessages } = useMessages()
    
    const languages: LanguageValue[] = React.useMemo(() => {
        if (!!accountLocales.length) {
            setActiveLanguage(accountLocales[0].code)
        }

        return accountLocales.map((locale) => {
            return {
                name: locale.name,
                value: locale.code,
            }
        })
    }, [accountLocales])

    React.useEffect(() => {
        const step = props.match.params.step || 'name'
        setCurrentStep(step)
    }, [props.match.params.step])

    React.useEffect(() => {
        audiences.fetch()

        const getData = async () => {
            setLoadingLocales(true)
            try {
                const localesDetails = await articleService.getAccountLocales(accountSlug)
                setAccountLocales(localesDetails.locales)
            } catch {
            replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again later.')
            } finally {
                setLoadingLocales(true)
            }
        }

        getData()
    }, [])

    React.useEffect(() => {
        updateComponent('defaultLanguage', defaultLanguage)
    }, [defaultLanguage])

    React.useEffect(() => {
        if (notificationCampaignId) {
            setLoadingCampaign(true)
            props.notificationCampaignsService.fetchCampaign(accountSlug, notificationCampaignId).then((item) => {
                setComponentValues(payloadToState(item))
                setTranslations(payloadToTranslations(item.content))
                setDefaultLanguage(payloadToDefaultLanguage(item.content))
                setLoadingCampaign(false)
            })
        }
    }, [notificationCampaignId])

    const section = () => {
        switch (currentStep) {
            case 'name':
                return formDefinition(audiences.data).name
            case 'notification':
                return formDefinition(audiences.data).notification
            case 'target':
                return formDefinition(audiences.data).target
            case 'scheduling':
                return formDefinition(audiences.data).scheduling
            default:
                return formDefinition(audiences.data).name
        }
    }

    const updateComponent = (path: string, value: string | number | boolean | string[]) => {
        const updatedComponentValues = setPropertyPath(componentValues, path, value)
        if (updatedComponentValues) {
            setComponentValues(updatedComponentValues)
        }
    }

    const updateLangaugeComponent = (path: string, value: string | number | boolean | string[]) => {
        let copy = { ...translations }
        if (isEmpty(copy[activeLanguage])) {
            copy[activeLanguage] = { [path]: value }
        } else {
            copy[activeLanguage][path] = value
        }

        setTranslations(copy)
    }

    const updateActiveLanguage = (language: string) => {
        setActiveLanguage(language)
    }

    const updateDefaultLanguage = (language: string) => {
        setDefaultLanguage(language)
    }

    const saveCampaignConfirm = async (status: string) => {
        try {
            let result = false
            if (notificationCampaignId) {
                result = await props.notificationCampaignsService.updateCampaign(
                    stateToPayload({ ...componentValues, status }, translations),
                    accountSlug,
                    notificationCampaignId,
                )
            } else {
                result = await props.notificationCampaignsService.addCampaign(
                    stateToPayload({ ...componentValues, status }, translations),
                    accountSlug,
                )
            }

            if (result) {
                const message: Message = {
                    id: 'publish_sucess',
                    status: 'success',
                    text: 'Campaign was saved',
                    visible: true,
                }

                props.navigation.replaceWithMessages([message], `${NAVIGATION_PATH}/overview`)
            } else {
                replaceMessages('server_error', 'error', 'Oops! We were unable to save the campaign.')
            }
        } catch (error) {
            replaceMessages('server_error', 'error', `Oops! We were unable to save the campaign. ${error}.`)
        }
    }

    const saveCampaign = (status: string) => {
        const selectedAudience = componentValues.target?.audience
        if (selectedAudience && !!!componentValues.target?.marketingConsent) {
            setShowAudienceModal(true)
        } else {
            saveCampaignConfirm(status)
        }
    }

    const shouldRenderPreview = () => {
        const content = translations[activeLanguage]

        if (content) {
            return !!content.title || !!content.body || !!content.buttonLabel
        }

        return false
    }

    return (
        <>
            <Page id="wizard-page" style={{ position: 'relative', justifyContent: 'center' }}>
                {showAudienceModal && (
                    <AudienceModal
                        title="Are you sure you want to publish?"
                        audienceName=""
                        buttonText="Publish"
                        onCancel={() => setShowAudienceModal(false)}
                        onConfirm={() => saveCampaignConfirm('Active')}
                        onDraft={() => saveCampaignConfirm('Draft')}
                    />
                )}
                <Side>
                    <Form
                        accountSlug={accountSlug}
                        replaceTopMessages={replaceMessages}
                        removeAllMessages={removeAllMessages}
                        currentStep={currentStep}
                        loading={loadingCampaign && loadingLocales && audiences.loading}
                        section={section()}
                        setCurrentStep={(newStep) => {
                            props.history.push(`${NAVIGATION_PATH}/studio/${newStep}/${notificationCampaignId}`)
                        }}
                        updateConfiguration={updateComponent}
                        updateLangaugeComponent={updateLangaugeComponent}
                        updateActiveLanguage={updateActiveLanguage}
                        updateDefaultLanguage={updateDefaultLanguage}
                        activeLanguage={activeLanguage}
                        defaultLanguage={defaultLanguage}
                        componentValues={componentValues}
                        languages={languages}
                        translations={translations}
                        history={props.history}
                        onFormSubmit={() => {
                            saveCampaign('Active')
                        }}
                    />
                </Side>
                <Body>
                    <Header
                        accountSlug={accountSlug}
                        previewSize="mobile"
                        targetDevice="mobile"
                        replaceMessages={replaceMessages}
                        removeAllMessages={removeAllMessages}
                        studioType="notificationCampaigns"
                        onPublish={() => {
                            saveCampaign('Active')
                        }}
                        onSaveDraft={() => {
                            saveCampaign('Draft')
                        }}
                        configIsPublished={false}
                        forceClosePath={`${NAVIGATION_PATH}/overview`}
                    />
                    <PreviewContainer className="mobile">
                        <FrameWrapper>
                            <SafariMobilePreviewSrollWrapper>
                                <Container>
                                    {(componentValues.target?.channel === 'Push_and_Chat' ||
                                        componentValues.target?.channel === 'Push') &&
                                        shouldRenderPreview() && (
                                            <PushNotification
                                                title={translations[activeLanguage]?.title}
                                                message={translations[activeLanguage]?.body}
                                            />
                                        )}
                                    {(componentValues.target?.channel === 'Push_and_Chat' ||
                                        componentValues.target?.channel === 'Chat') &&
                                        shouldRenderPreview() && (
                                            <InboxMessage
                                                title={translations[activeLanguage]?.title}
                                                message={translations[activeLanguage]?.body}
                                                buttonLabel={translations[activeLanguage]?.buttonLabel}
                                            />
                                        )}
                                </Container>
                            </SafariMobilePreviewSrollWrapper>
                        </FrameWrapper>
                    </PreviewContainer>
                </Body>
            </Page>
        </>
    )
}

export default withNavigation(Studio)
