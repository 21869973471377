import * as React from 'react'
import { Navigation } from 'navigation'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { StatsService } from 'http/statsService'
import DatedChart from './datedChart'
import UndatedChart from './undatedChart'
import { createTimeseriesBarChart, TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { PageTitle } from 'uiComponents/typography'
import { dateRangeToQuery, DateRange } from 'dateRanges'
import {
    ChartWrapperWithDateRange as WithDateRangeNotice,
    getDataFromQuery,
    chartRowsGap,
    areDateRangeDatesEqual,
    ReportsToolsWrapper,
    ReportUpdateInfo,
} from '../helpers'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import ReportsFilter from 'reports/reportsFilter'
import { AVAILABLE_FILTERS, STANDALONE_FILTERS, METADATA_CATEGORIES } from 'reports/constants'
import { FilterCategory } from 'uiComponents/filter/schema'
import { ChartContainer } from 'uiComponents/charts/styleComponents'
import { InventoryService } from 'inventory/inventoryService'
import Disclaimer from 'reports/disclaimer'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import { useMessages } from 'messagesContext'

interface BarcodesReportProps {
    accountSlug: string
    statsService: StatsService
    inventoryService: InventoryService
    navigation: Navigation
    match: RouteMatch<any>
}

const BarcodesReport: React.FC<BarcodesReportProps> = (props) => {
    const { accountSlug, statsService, inventoryService, navigation } = props
    const { dateRange, filters } = getDataFromQuery(navigation.query())
    const [datedChart, setDatedChart] = React.useState<TimeseriesBarChartSource>(createTimeseriesBarChart(dateRange))
    const [undatedChart, setUndatedChart] = React.useState<TimeseriesBarChartSource>(createTimeseriesBarChart(dateRange))
    const appliedFilters = filters.filter(
        (f) => AVAILABLE_FILTERS['barcodes'].indexOf(f.attribute as FilterCategory) > -1,
    )
    const { replaceMessages, hideMessage } = useMessages()

    React.useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    const onDateRangeChanged = (range: DateRange) => {
        const { dateRange: currentDateRange } = getDataFromQuery(navigation.query())
        if (!areDateRangeDatesEqual(currentDateRange, range)) {
            navigation.addQueryWithReplace(dateRangeToQuery(range))
            setDatedChart(createTimeseriesBarChart(range))
            setUndatedChart(createTimeseriesBarChart(range))
        }
    }

    return (
        <div id="reports-barcodes-page">
            <PageTitle>Barcodes Report</PageTitle>
            <ReportUpdateInfo
                metric="Barcodes that are redeemed directly via Convious"
                text="updated every 15 minutes."
            />
            <ReportsToolsWrapper>
                <ReportsFilter
                    accountSlug={accountSlug}
                    statsService={statsService}
                    inventoryService={inventoryService}
                    applicableFilters={AVAILABLE_FILTERS['barcodes']}
                    standaloneFilters={STANDALONE_FILTERS['barcodes']}
                    metadataCategories={METADATA_CATEGORIES['barcodes']}
                    appliedFilters={appliedFilters}
                    replaceMessages={replaceMessages}
                    hideMessage={hideMessage}
                    dateRange={dateRange}
                />
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    firstAvailableDay={new Date(2019, 0, 1)}
                    allowFutureDateSelection
                />
            </ReportsToolsWrapper>
            <ChartContainer>
                <WithDateRangeNotice dateRange={dateRange}>
                    <DatedChart
                        statsService={statsService}
                        filters={appliedFilters}
                        accountSlug={accountSlug}
                        chart={datedChart}
                        dateRange={dateRange}
                        replaceTopMessages={replaceMessages}
                    />
                </WithDateRangeNotice>
            </ChartContainer>
            <ChartContainer style={{ marginTop: chartRowsGap, marginBottom: '2em' }}>
                <WithDateRangeNotice dateRange={dateRange}>
                    <UndatedChart
                        statsService={statsService}
                        filters={appliedFilters}
                        accountSlug={accountSlug}
                        chart={undatedChart}
                        dateRange={dateRange}
                        replaceTopMessages={replaceMessages}
                    />
                </WithDateRangeNotice>
            </ChartContainer>
            <BaseKnowledgeLink link="https://support.convious.com/help/barcode-report-faq" />
            <Disclaimer />
        </div>
    )
}

export default withNavigation(BarcodesReport)
