import React, { useState } from 'react'
import { Button } from '@mui/material'
import { ReplySolid, TimesSolid } from '@convious/icons'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import { ModalDialog } from 'uiComponents/popups/modal'
import { IOrder } from 'orders/schema'
import { getLastPayment } from 'orders/orders/utils'
import SingleCancelRefund from '.'
import { ReplaceMessagesFunc } from 'messagesContext'
import { useHasFeature } from 'utils/useHasFeature'

interface RefundActionProps extends WithNavigationProps<BaseRouteParams> {
    order: IOrder
    isCancellation?: boolean
    replaceMessages: ReplaceMessagesFunc
}

const SingleCancelRefundButton: React.FC<RefundActionProps> = ({ order, match, isCancellation = false, replaceMessages }) => {
    const [open, setOpen] = useState(false)
    const { accountSlug } = match.params
    const nonRefundableStatuses = ['refunded', 'refunding', 'pending']
    const lastPayment = getLastPayment(order.payments)
    const showRefundOption =
        lastPayment &&
        !nonRefundableStatuses.includes(lastPayment.paymentStatus) &&
        Number(order.totalPriceInclTax) > 0 &&
        lastPayment.paymentProvider !== 'Settled externally'
    const showCancelOption =
        order.status !== 'cancelled' && !['refunded', 'refunding'].includes(lastPayment?.paymentStatus ?? '')
    const hasSingleRefundFeature = useHasFeature({ featureName: 'OrderRefunds' })

    if ((!isCancellation && !showRefundOption) || (isCancellation && !showCancelOption) || !hasSingleRefundFeature) {
        return null
    }

    return (
        <>
            {open && (
                <ModalDialog interactive fromTop="6%" className="single-cancel-refund-modal">
                    <SingleCancelRefund
                        accountSlug={accountSlug}
                        orderId={order.id}
                        isCancellation={isCancellation}
                        onClose={() => setOpen(false)}
                        replaceMessages={replaceMessages}
                    />
                </ModalDialog>
            )}
            <Button
                onClick={() => setOpen(true)}
                size="medium"
                color="error"
                variant="outlined"
                startIcon={isCancellation ? <TimesSolid /> : <ReplySolid />}
                data-testid={isCancellation ? 'single-cancel-button' : 'single-refund-button'}
                className="action-button"
            >
                {isCancellation ? 'Cancel' : 'Refund'}
            </Button>
        </>
    )
}

export default withNavigation(SingleCancelRefundButton)
