import * as React from 'react'
import styled from 'styled-typed'
import { UploadInput } from 'uiComponents/input/upload'
import { EditBox, InlineEditActionSection, InputBox, ItemValue, ItemValueText } from 'uiComponents/input/inlineEdit'
import { faCamera, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { UploadedFile } from 'orders/ordersService'
import { delay } from 'utils'
import { ActionIcon } from 'uiComponents/buttons/actionIcon'
import { ModalDialog } from 'uiComponents/popups/modal'
import { CameraInput } from 'uiComponents/input/cameraInput/cameraInput'
import { Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMessages } from 'messagesContext'

const ImgLink = styled.a`
    color: ${(props) => props.theme.colors.textDark};
`
const NewItemValue = styled(ItemValue)`
    display: flex;
    align-items: center;
    height: 1.9em;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s;

    &.visible {
        opacity: 1;
        visibility: visible;
    }
`

interface InlineUploadProps {
    id: string
    label?: string
    value: string
    disabled?: boolean
    fileSizeLimit: number
    limitedTextWidth?: string
    onEditAccept: (value: string) => void
    maxLength?: number
    placeholder?: string | React.ReactElement
    onUpload: (file: File) => Promise<UploadedFile>
    allowCameraCapture?: boolean
}

const InlineUploadComponent: React.FC<InlineUploadProps> = (props) => {
    const {
        id,
        value,
        disabled,
        limitedTextWidth,
        placeholder,
        onUpload,
        fileSizeLimit,
        allowCameraCapture,
        onEditAccept,
    } = props
    const { replaceMessages, removeAllMessages } = useMessages()
    const [editMode, setEditMode] = React.useState<boolean>(false)
    const [newValue, setNewValue] = React.useState<string>('')
    const [photoModalOpen, setPhotoModalOpen] = React.useState<boolean>(false)

    const enableEdit = () => {
        setEditMode(true)
    }

    const handleUploadSuccess = (response: UploadedFile) => {
        delay(1000)
        setNewValue(response.file_id)
    }

    const onCancel = () => {
        setEditMode(false)
        setNewValue('')
    }

    const onAccept = () => {
        onEditAccept(newValue)
        setEditMode(false)
    }

    const useWebcamPhoto = async (file: File) => {
        const uploadedImage = await onUpload(file)
        handleUploadSuccess(uploadedImage)
        setPhotoModalOpen(false)
    }

    return (
        <>
            {!editMode && (
                <ItemValue id={id} limitedTextWidth={limitedTextWidth}>
                    <ItemValueText>
                        <ImgLink href={value} target="_blank">
                            {value}
                        </ImgLink>
                    </ItemValueText>
                    {!value && placeholder && <ItemValueText className="placeholder">{placeholder}</ItemValueText>}
                    {!disabled && (
                        <ActionIcon
                            onClick={enableEdit}
                            icon={faPencilAlt}
                            title="Edit"
                            className="open-edit"
                            style={{ marginLeft: '0.5em' }}
                        />
                    )}
                </ItemValue>
            )}
            {editMode && (
                <EditBox limitedTextWidth={limitedTextWidth} className="stacked">
                    <InputBox
                        limitedTextWidth={limitedTextWidth}
                        className={newValue ? 'hide-underline hide-box' : 'hide-underline'}
                        style={{ fontSize: '.85em' }}
                    >
                        <UploadInput
                            type="img"
                            fileSizeLimit={fileSizeLimit}
                            onUpload={onUpload}
                            handleUploadSuccessResponse={handleUploadSuccess}
                            style={{ height: '2.2em' }}
                            inputId={id}
                            replaceTopMessages={replaceMessages}
                            removeAllMessages={removeAllMessages}
                        />
                        {allowCameraCapture && (
                            <Button
                                variant="outlined"
                                size="small"
                                startIcon={<FontAwesomeIcon icon={faCamera} width={24} height={24} />}
                                onClick={() => setPhotoModalOpen(true)}
                            >
                                Take a photo
                            </Button>
                        )}
                    </InputBox>

                    {photoModalOpen && (
                        <ModalDialog onDismiss={() => setPhotoModalOpen(false)}>
                            <CameraInput onChange={useWebcamPhoto} />
                        </ModalDialog>
                    )}
                    <NewItemValue limitedTextWidth={limitedTextWidth} className={newValue ? 'visible' : ''}>
                        <ItemValueText>
                            <ImgLink href={newValue} target="_blank">
                                {newValue}
                            </ImgLink>
                        </ItemValueText>
                    </NewItemValue>
                    <InlineEditActionSection stacked={true} onAccept={onAccept} onCancel={onCancel} />
                </EditBox>
            )}
        </>
    )
}

export const InlineUpload = InlineUploadComponent
