import React, { useState, useEffect, useContext, useRef } from 'react'
import { usePrevious } from 'reactUtils'
import Feature from 'features/feature'
import { LoggingServiceContext, LoginServiceContext, StatsServiceContext } from 'http/context'
import { match as RouteMatch } from 'react-router-dom'
import { format } from 'date-fns'
import isEqual from 'lodash/isEqual'
import { withNavigation } from 'hocs'
import { Navigation, QueryData } from 'navigation'
import { PageTitle, Headline } from 'uiComponents/typography'
import { InventoryServiceContext } from 'inventory/context'
import { dateRangeToQuery, DateRange } from 'dateRanges'
import CustomersChart from 'reports/customers/timeseriesChart'
import SingleDataItems from 'reports/customers/singleDataItems'
import { DataSeries, dummyDownloadData, DownloadData } from 'reports/schema'
import { createTimeseriesBarChart, TimeseriesBarChartSource } from 'uiComponents/charts/timeseriesBarChartSource'
import { getDataFromQuery, areDateRangeDatesEqual, ReportsToolsWrapper, ReportUpdateInfo } from 'reports/helpers'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import ReportsFilter from 'reports/reportsFilter'
import { AVAILABLE_FILTERS, STANDALONE_FILTERS, METADATA_CATEGORIES } from 'reports/constants'
import { renderFilterQuery } from 'uiComponents/filter'
import { FilterCategory, Filters, FilterQueryItems } from 'uiComponents/filter/schema'
import { ChartContainer } from 'uiComponents/charts/styleComponents'
import { QueryConfig } from 'reports/queryGenerator'
import ExportMenu from 'reports/exportMenu'
import Disclaimer from 'reports/disclaimer'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import { useMessages } from 'messagesContext'

const queryConfig: QueryConfig = {
    querySetName: 'CapacityFilters',
    variablesConfig: [
        { name: 'widget', type: 'String' },
        { name: 'dateFrom', type: 'Date' },
        { name: 'dateTo', type: 'Date' },
        { name: 'filters', type: '[FilterDictionary]' },
    ],
    queries: [
        {
            name: 'inventoryFilters',
            type: 'stats',
            configVariables: ['widget', 'dateFrom', 'dateTo', 'filters'],
            customVariables: [{ name: 'metric', customValue: 'capacity_filters' }],
            presetResult: 'dataSeries',
        },
    ],
}

const defaultFilters: FilterQueryItems[] = [
    {
        category: 'product_types' as FilterCategory,
        slug: 'single_visit_ticket',
    },
    {
        category: 'product_types' as FilterCategory,
        slug: 'visit_reservation',
    },
]

interface VisitorsReportsPageProps {
    navigation: Navigation
    match: RouteMatch<any>
    accountSlug: string
}

function VisitorsReportsPage(props: VisitorsReportsPageProps) {
    const _defaultFiltersApplied = useRef(false)
    const _lastRequest = useRef<number>()
    const loggingService = useContext(LoggingServiceContext)
    const loginService = useContext(LoginServiceContext)
    const statsService = useContext(StatsServiceContext)
    const inventoryService = useContext(InventoryServiceContext)
    const { replaceMessages, hideMessage } = useMessages()
    const innitialQuery = props.navigation.query()
    const initialData = getDataFromQuery(innitialQuery)
    const [loading, setLoading] = useState<boolean>(false)
    const [dateRange, setDateRange] = useState<DateRange>(initialData.dateRange)
    const [filters, setFilters] = useState<Filters[]>([])
    const [downloadData, setDownloadData] = React.useState<DownloadData>(dummyDownloadData)
    const [chart, setChart] = useState<TimeseriesBarChartSource>(createTimeseriesBarChart(initialData.dateRange))

    const defaultFiltersMessage = 'The default single ticket and reservations filters are applied.'

    useEffect(() => {
        const data = getDataFromQuery(props.navigation.query())
        if (data.filters.length === 0) {
            setDefaultFilters()
        } else if (checkOnlyDefaultFiltersApplied(data.filters)) {
            replaceMessages('default-filter-warning', 'warn', defaultFiltersMessage)
        }
    }, [])

    const prevAccountSlug = usePrevious(props.accountSlug)
    const prevQuery: QueryData = usePrevious(props.navigation.query()) || {}
    useEffect(() => {
        const data = getDataFromQuery(props.navigation.query())
        const prevData = getDataFromQuery(prevQuery)
        if (
            !!prevAccountSlug &&
            prevAccountSlug !== props.accountSlug &&
            !checkOnlyDefaultFiltersApplied(data.filters)
        ) {
            setDefaultFilters()
            return
        }
        if (!areDateRangeDatesEqual(prevData.dateRange, data.dateRange)) {
            setChart(createTimeseriesBarChart(data.dateRange))
        }

        if (
            prevAccountSlug !== props.accountSlug ||
            !areDateRangeDatesEqual(prevData.dateRange, data.dateRange) ||
            !isEqual(prevData.filters, data.filters)
        ) {
            setDateRange(data.dateRange)
            setFilters(data.filters)
            updateWarningMessage()
            getData(data.dateRange, data.filters)
        }
    }, [props.navigation.query(), props.accountSlug])

    const updateWarningMessage = () => {
        if (checkOnlyDefaultFiltersApplied(initialData.filters)) {
            replaceMessages('default-filter-warning', 'warn', defaultFiltersMessage)
        } else {
            hideMessage('default-filter-warning')
        }
    }

    const getData = async (range: DateRange, newFilters: Filters[] | null = null) => {
        const requestTime = new Date().valueOf()
        _lastRequest.current = requestTime

        if (
            !!newFilters &&
            _defaultFiltersApplied.current &&
            !newFilters.find((f) => f.attribute === 'product_types')
        ) {
            return
        }
        hideMessage('server_error')
        setLoading(true)
        const dateFrom = range.from
        const dateTo = range.to
        try {
            const variables = {
                widget: props.accountSlug,
                dateFrom: format(dateFrom, 'yyyy-MM-dd'),
                dateTo: format(dateTo, 'yyyy-MM-dd'),
                filters: newFilters || filters,
            }
            const data = await statsService.getStats(queryConfig, variables)

            if (_lastRequest.current !== requestTime) {
                return
            }

            setFiltersData(data.inventoryFilters.dataSeries)
            setLoading(false)
        } catch {
            replaceMessages(
                'server_error',
                'error',
                'Oops! Customers chart could not be loaded, please try again later.',
            )
            setLoading(false)
        }
    }

    const checkOnlyDefaultFiltersApplied = (newFilters: Filters[] = initialData.filters) => {
        return (
            !!newFilters &&
            newFilters.length === 1 &&
            newFilters[0].attribute === 'product_types' &&
            isEqual(newFilters[0].values.sort(), ['single_visit_ticket', 'visit_reservation'])
        )
    }

    const setDefaultFilters = async () => {
        _defaultFiltersApplied.current = true
        props.navigation.addQueryWithReplace({
            filter: renderFilterQuery(defaultFilters),
        })
        replaceMessages('default-filter-warning', 'warn', defaultFiltersMessage)
    }

    const setFiltersData = (data: DataSeries) => {
        const filtersStringArray = (data?.data[0] as string[]) || []
        const filtersData = filtersStringArray.map((f) => JSON.parse(f.split("'").join('"')))
        setFilters(filtersData)
    }

    const onDateRangeChanged = async (range: DateRange) => {
        if (!areDateRangeDatesEqual(dateRange, range)) {
            props.navigation.addQueryWithReplace(dateRangeToQuery(range))
            setChart(createTimeseriesBarChart(range))
            await getData(range)
        }
    }

    const appliedFilters = filters.filter(
        (f) => AVAILABLE_FILTERS['customers'].indexOf(f.attribute as FilterCategory) > -1,
    )

    return (
        <div id="reports-customers-page" style={{ marginBottom: '7em' }}>
            <PageTitle data-userpilot="customers-header-tooltip">
                Customers Report
            </PageTitle>
            <ReportUpdateInfo metric="Data" text="updated every 20 minutes." />
            <ReportsToolsWrapper>
                <ReportsFilter
                    accountSlug={props.accountSlug}
                    statsService={statsService}
                    inventoryService={inventoryService}
                    applicableFilters={AVAILABLE_FILTERS['customers']}
                    standaloneFilters={STANDALONE_FILTERS['customers']}
                    metadataCategories={METADATA_CATEGORIES['customers']}
                    appliedFilters={appliedFilters}
                    replaceMessages={replaceMessages}
                    hideMessage={hideMessage}
                    dateRange={dateRange}
                    maintainFilters={checkOnlyDefaultFiltersApplied()}
                    userpilot="customers-filter"
                />
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    firstAvailableDay={new Date(2019, 0, 1)}
                    futureDateRange
                />
            </ReportsToolsWrapper>
            <ChartContainer style={{ marginBottom: '2em' }}>
                <Headline size={4}>Booked visits</Headline>
                <Feature name="CustomersReportExport" accountSlug={props.accountSlug}>
                    <ExportMenu
                        accountSlug={props.accountSlug}
                        downloadData={downloadData}
                        loginService={loginService}
                        loggingService={loggingService}
                        replaceMessages={replaceMessages}
                        hideMessage={hideMessage}
                        withinChartWrapper
                    />
                </Feature>
                <SingleDataItems
                    accountSlug={props.accountSlug}
                    dateRange={dateRange}
                    filters={appliedFilters}
                    replaceMessages={replaceMessages}
                    loading={loading}
                />
                <CustomersChart
                    accountSlug={props.accountSlug}
                    dateRange={dateRange}
                    filters={appliedFilters}
                    chart={chart}
                    replaceTopMessages={replaceMessages}
                    hideMessage={hideMessage}
                    loading={loading}
                    setDownloadData={setDownloadData}
                />
            </ChartContainer>
            <BaseKnowledgeLink link="https://support.convious.com/help/custom-report" />
            <Disclaimer />
        </div>
    )
}

export default withNavigation(VisitorsReportsPage)
