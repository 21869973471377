import { useRedeemBarcodesMutation } from 'orders/reduxQueries'
import { delay } from 'utils'
import { CreateExceptionBarcodeError } from 'orders/ordersService'
import { ReplaceMessagesFunc } from 'messagesContext'

interface useRedeemBarcodesProps {
    accountSlug: string
    hideMessage: (id: string) => void
    replaceMessages: ReplaceMessagesFunc
}

const useRedeemBarcodes = ({ accountSlug, replaceMessages, hideMessage }: useRedeemBarcodesProps) => {
    const [redeemBarcodes] = useRedeemBarcodesMutation()

    const redeem = async (barcodes: string[]) => {
        const s = barcodes.length === 1 ? '' : 's'
        const responseData = await redeemBarcodes({ accountSlug, barcodes: barcodes }).unwrap()

        if (responseData) {
            responseData.ifFailure((error: CreateExceptionBarcodeError) => handleBarcodeRedeemFailure(error, s))
            responseData.ifSuccess(async () => await handleBarcodeRedeemSuccess(barcodes, s))
        }
    }

    const handleBarcodeRedeemFailure = (error: CreateExceptionBarcodeError, s: string) => {
        const getErrorKeys = () => Object.keys(error).filter((key) => !['type', 'barcodes'].includes(key))
        const hasErrors = () => getErrorKeys().filter((key) => !!error[key as keyof CreateExceptionBarcodeError])
        const getErrorBarcodes = () =>
            getErrorKeys()
                .map((key) => error[key as keyof CreateExceptionBarcodeError])
                .filter((value) => !!value)
                .join(', ')

        if (hasErrors()) {
            replaceMessages(
                error.type,
                'error',
                `Oops! Barcode${s} were not redeemed. There was an issue with the following item${s}:
        ${getErrorBarcodes()}`,
            )
        } else {
            replaceMessages(
                error.type,
                'error',
                `Oops! There was an issue with redeeming barcode${s}. Please try again later.`,
            )
        }
    }

    const handleBarcodeRedeemSuccess = async (barcodes: string[], s: string) => {
        const barcodesString = barcodes.join(', ')
        replaceMessages(
            'redeemed_successfully',
            'success',
            `The barcode${s} ${barcodesString} ${s ? 'were' : 'was'} redeemed successfully`,
        )
        await delay(3000)
        hideMessage('redeemed_successfully')
    }

    return {
        redeem,
    }
}

export default useRedeemBarcodes
