import React, { MouseEvent, useCallback, useEffect } from 'react'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'
import { SingleSelect } from 'uiComponents/input'
import { Link } from '../../../products/crud/utils'
import { TaxConfiguration } from 'settings/accountSettings/taxConfigurations/types'
import ReadOnlyTaxConfigurationElement from 'settings/accountSettings/taxConfigurations/readOnlyTaxConfigurationElement'
import { LoaderWrapper } from 'uiComponents/loaders'
import './taxSelect.scss'
import { delay } from 'lodash'

interface TaxSelectProps {
    taxConfigurationUuid: string | null
    onTaxConfigurationChange: (value: string | null) => void
    accountSlug: string
    loadingTaxConfigurations: boolean
    taxConfigurations: TaxConfiguration[] | null
    defaultTaxConfigurationUuid: string | null
    title: string
    style?: React.CSSProperties
    disabled?: boolean
    onExternalNavigate?: (event: MouseEvent, destination: string) => void
}

function getTaxConfigurationByUuid(taxConfigurations: TaxConfiguration[], uuid: string): TaxConfiguration {
    return taxConfigurations?.find((taxConfiguration) => taxConfiguration.uuid === uuid)!
}

export const TaxSelect = ({
    taxConfigurationUuid,
    onTaxConfigurationChange,
    accountSlug,
    loadingTaxConfigurations,
    taxConfigurations,
    defaultTaxConfigurationUuid,
    title,
    style,
    disabled,
    onExternalNavigate,
}: TaxSelectProps) => {
    const [fakeLoading, setFakeLoading] = React.useState(false)
    const taxConfigurationsLink = `/account/${accountSlug}/settings/account_settings/tax_configurations`

    useEffect(() => {
        setFakeLoading(true)
        delay(() => setFakeLoading(false), 1000)
    }, [taxConfigurationUuid])

    const selectedTaxConfiguration = getTaxConfigurationByUuid(
        taxConfigurations || [],
        taxConfigurationUuid || defaultTaxConfigurationUuid!,
    )

    const onEdit = useCallback(
        (event: MouseEvent) => {
            if (onExternalNavigate) {
                onExternalNavigate(event, taxConfigurationsLink)
            }
        },
        [taxConfigurationsLink, onExternalNavigate],
    )

    return (
        <div className="tax-select-container" style={style}>
            <FormItem htmlFor="taxConfigurationUuid">
                <FormItemName className="tax-select-title">{title}</FormItemName>
                <LoaderWrapper loading={loadingTaxConfigurations}>
                    {taxConfigurations && defaultTaxConfigurationUuid && (
                        <>
                            <SingleSelect
                                id="tax-configurations"
                                name="taxConfigurationUuid"
                                selected={taxConfigurationUuid || null}
                                onSelect={onTaxConfigurationChange}
                                options={[
                                    { name: 'Use default tax', value: null },
                                    ...taxConfigurations.map((taxConfiguration) => ({
                                        name: taxConfiguration.name,
                                        value: taxConfiguration.uuid,
                                    })),
                                ]}
                                height="2.5rem"
                                noSelectOption="Use default tax"
                                disabled={disabled}
                            />
                            <div id="tax-configuration-preview" className={fakeLoading ? 'loading' : ''}>
                                <ReadOnlyTaxConfigurationElement
                                    taxConfiguration={selectedTaxConfiguration}
                                    isDefault={!taxConfigurationUuid}
                                />
                            </div>
                            <Link>
                                Taxes can be edited in your&nbsp;
                                <a href={taxConfigurationsLink} onClick={onEdit}>
                                    Account settings
                                </a>
                                .
                            </Link>
                        </>
                    )}
                </LoaderWrapper>
            </FormItem>
        </div>
    )
}
