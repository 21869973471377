import React, { useState } from 'react'
import styled from 'styled-typed'
import { FormItem, FormItemName, ValidationMessage } from 'uiComponents/form/formElements'
import { ModalDialogTitle } from 'uiComponents/popups/modal'
import { ActionButton } from 'uiComponents/buttons'
import { SingleSelect, SingleSelectOption } from 'uiComponents/input/singleSelect'
import { Card } from 'uiComponents/cards'
import { TextArea, TextInputProps, TextInput } from 'uiComponents/input'
import Svg from 'svg'
import { ChartDataLoader } from 'uiComponents/loaders'
import { MessageKind } from 'uiComponents/messages'
import { useAppSelector } from 'store/hooks'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`
const ContainerCard = styled(Card)`
    flex: 1;
    max-width: 45em;
    padding: 2em 3em 2.8em 3em;
`
const Form = styled.form`
    width: 35em;
    padding: 0 5em;
`
const FormTextArea = styled(TextArea)<TextInputProps>`
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-weight: 400;
`
export const Icon = styled(Svg)`
    width: 3.5em;
    margin-bottom: 1.5em;
`
const SuccessMessage = styled.div`
    text-align: center;
    font-size: 0.875em;
    max-width: 28.6em;
    min-width: 35em;
`

interface RequestFormProps {
    accountSlug: string
    iconSrc: SVGAElement
    title: string
    categories?: SingleSelectOption[]
    successMessage: string
    requirementsDescription: string
    returnButtonText: string
    onDismiss?: () => void
    onRequestSubmit: (request: string, account: string, category?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

export const RequestForm: React.FC<RequestFormProps> = (props) => {
    const user = useAppSelector((state) => state.auth.user)
    const profile = useAppSelector((state) => state.profile.profile)
    const [request, setRequest] = useState('')
    const [category, setCategory] = useState('')
    const [categoryMsg, setCategoryMsg] = useState('')
    const [requestMsg, setRequestMsg] = useState('')
    const [sending, setSending] = useState(false)
    const [sent, setSent] = useState(false)

    const {
        iconSrc,
        title,
        categories,
        successMessage,
        returnButtonText,
        requirementsDescription,
        accountSlug,
        onRequestSubmit,
        replaceTopMessages,
        removeAllMessages,
        onDismiss,
    } = props

    const name = profile && profile.firstName ? `${profile.firstName} ${profile.lastName}` : user?.username

    const checkValidForm = () => {
        let valid = true
        if (categories && !category) {
            setCategoryMsg('Please select a category')
            valid = false
        }
        if (!request) {
            setRequestMsg('Please provide request requirements')
            valid = false
        }
        return valid
    }

    const handleSelectChange = (value: string) => {
        setCategory(value)
        setCategoryMsg('')
    }

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRequest(event.target.value)
        setRequestMsg('')
    }

    const onSubmit = async (evt: React.FormEvent<HTMLElement>) => {
        evt.preventDefault()
        removeAllMessages()
        if (!checkValidForm()) {
            return
        }
        setSending(true)
        try {
            const account = user?.accounts.find((x) => x.slug === accountSlug) || user?.accounts[0]
            if (!account) {
                throw new Error('Account not found')
            }

            await onRequestSubmit(request, account.name, category)
            setSending(false)
            setSent(true)
        } catch {
            setSending(false)
            replaceTopMessages('server_error', 'error', 'Oops! We could not send your request. Please try again.')
        }
    }

    return (
        <Container>
            {sending && <ChartDataLoader />}
            <Icon src={iconSrc} />
            <ModalDialogTitle>{sent ? 'Success' : title}</ModalDialogTitle>
            {!sent && (
                <Form noValidate onSubmit={onSubmit}>
                    <FormItem htmlFor="name" style={{ marginBottom: '2em' }}>
                        <FormItemName>Name</FormItemName>
                        <TextInput id="name" name="name" type="text" value={name} block disabled />
                    </FormItem>
                    {categories && (
                        <>
                            <FormItem htmlFor="category">
                                <FormItemName>Category</FormItemName>
                                <SingleSelect
                                    id="category"
                                    options={categories}
                                    selected={category}
                                    noSelectOption="Select a category"
                                    height="2.8em"
                                    onSelect={handleSelectChange}
                                    style={{ marginBottom: '.2em' }}
                                    status={categoryMsg ? 'error' : 'normal'}
                                />
                            </FormItem>
                            <ValidationMessage className={categoryMsg ? 'validation-message-visible' : ''}>
                                {categoryMsg || ' '}
                            </ValidationMessage>
                        </>
                    )}
                    <FormItem htmlFor="request">
                        <FormItemName>{requirementsDescription}</FormItemName>
                        <FormTextArea
                            id="request"
                            name="request"
                            value={request}
                            onChange={handleTextAreaChange}
                            status={requestMsg ? 'error' : 'normal'}
                        />
                    </FormItem>
                    <ValidationMessage className={requestMsg ? 'validation-message-visible' : ''}>
                        {requestMsg || ' '}
                    </ValidationMessage>
                    <div style={{ textAlign: 'center' }}>
                        <ActionButton size="large" onClick={onSubmit} style={{ marginTop: '1.5em' }}>
                            Send request
                        </ActionButton>
                    </div>
                </Form>
            )}
            {sent && (
                <SuccessMessage>
                    <div>{successMessage}</div>
                    {onDismiss && (
                        <ActionButton onClick={onDismiss} style={{ marginTop: '3em' }}>
                            {returnButtonText}
                        </ActionButton>
                    )}
                </SuccessMessage>
            )}
        </Container>
    )
}

interface RequestCardProps {
    children: React.ReactNode
    userpilot?: string
    style?: React.CSSProperties
}

export function RequestCard(props: RequestCardProps) {
    return (
        <Container style={props.style}>
            <ContainerCard data-userpilot={props.userpilot}>{props.children}</ContainerCard>
        </Container>
    )
}
