import * as React from 'react'
import styled from 'styled-typed'
import { match as RouteMatch } from 'react-router-dom'
import { History } from 'history'
import { Navigation } from 'navigation'
import { PageActions } from 'uiComponents/settingsContainer'
import { ActionButton } from 'uiComponents/buttons'
import { Row, ButtonWrapper } from 'uiComponents/pageElements'
import { MessageKind } from 'uiComponents/messages'
import { withNavigation } from 'hocs'
import { ExceptionInfoFormSection } from 'products/pricing/exceptions/exceptionInfoFormSection'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import {
    PricingService,
    Exception,
    AdjustmentType,
    PriceSetting,
    CreateExceptionError,
    DynamicInterval,
    OverviewData,
    OverrideType,
    OverviewAdjustmentData,
    OverviewIntervalData,
    PricingSettingToEnforce,
} from '../pricingService'
import { ArticleService as AdminArticleService, ArticleListItem } from 'admin/articleService'
import { PricingType } from 'products/articleConfigurationService'
import { StatsService } from 'http/statsService'
import { ExceptionDayTimeFormItem, Recurrence } from './exceptionDayTimeFormItem'
import { RulesSection } from './rules/exceptionRulesFormSection'
import { delay } from 'utils'
import { Adjustment } from './rules/shared'
import ExceptionOverviewTable, { fetchOkStatus } from './regularExceptionOverviewTable'
import DynamicExceptionOverviewTable, { fetchOkStatus as fetchDynamicOkStatus } from './dynamicExceptionOverviewTable'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { withFeatures } from 'features'
import { ChannelsService, ResellerResponseItem } from 'channels/channelsService'
import { SingleSelectOption } from 'uiComponents/input/singleSelect'
import LoadingButton from '@mui/lab/LoadingButton'
export interface PriceTypeOption {
    value: PriceSetting
    name: string
}

interface ExceptionFormProps {
    navigation: Navigation
    history: History
    match: RouteMatch<any>
    pricingService: PricingService
    articleService: AdminArticleService
    channelsService: ChannelsService
    statsService: StatsService
    accountSlug: string
    exception?: Exception | null
    exceptionProducts?: ArticleListItem[]
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    getCurrency: (accountSlug: string) => Currency
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, slug: string) => boolean
    className?: string
    prototype?: boolean
}

interface ExceptionFormState {
    exceptionName: string
    exceptionActive: boolean
    exceptionProducts: ArticleListItem[]
    exceptionResellers: string[]
    weekdays: string | null
    adjustments: Adjustment[]
    recurrence: Recurrence | null
    startDate: string | null
    startTime: string | null
    endDate: string | null
    endTime: string | null
    currency: Currency
    validate: boolean
    confirmDeletion: boolean
    pricingType: PricingType | null
    priceSettingToEnforce: PricingSettingToEnforce | null
    intervalsForStatic: DynamicInterval[] | null
    overviewData: OverviewData[] | null
    overrideType: OverrideType | null
    isFormValid: boolean
    overviewError: boolean
    showDuplicateWarning: boolean
    allResellers: ResellerResponseItem[]
    resellersOptions: SingleSelectOption[]
    isSubmitting: boolean
}

export type SetAdjustmentFunc = (priceType: PriceSetting, value: number | null, type: AdjustmentType) => void

const ExceptionContainerBody = styled.div`
    flex-direction: column;
    flex: 1;
`

export const ConfirmationNotice = styled.div`
    font-size: 0.9em;
`

class ExceptionForm extends React.Component<ExceptionFormProps, ExceptionFormState> {
    constructor(props: ExceptionFormProps) {
        super(props)
        const { exception, exceptionProducts } = this.props
        const adjustments = [
            {
                name: 'BOX' as PriceSetting,
                type: exception ? exception.originalPriceAdjustmentType : null,
                value: exception ? exception.originalPriceAdjustment : null,
                on: exception ? !!exception.originalPriceAdjustment || exception.originalPriceAdjustment === 0 : false,
            },
            {
                name: 'MIN' as PriceSetting,
                type: exception ? exception.minAcceptedPriceAdjustmentType : null,
                value: exception ? exception.minAcceptedPriceAdjustment : null,
                on: exception
                    ? !!exception.minAcceptedPriceAdjustment || exception.minAcceptedPriceAdjustment === 0
                    : false,
            },
            {
                name: 'AVG' as PriceSetting,
                type: exception ? exception.targetAvgPriceAdjustmentType : null,
                value: exception ? exception.targetAvgPriceAdjustment : null,
                on: exception
                    ? !!exception.targetAvgPriceAdjustment || exception.targetAvgPriceAdjustment === 0
                    : false,
            },
        ]
        this.state = {
            exceptionName: exception ? (this.props.prototype ? `Copy of ${exception.name}` : exception.name) : '',
            exceptionActive: exception ? exception.active : true,
            weekdays: exception ? exception.weekdays : null,
            recurrence: exception ? exception.recurrence : null,
            startDate: exception ? exception.startDate : null,
            startTime: exception ? exception.startTime : null,
            endDate: exception ? exception.endDate : null,
            endTime: exception ? exception.endTime : null,
            adjustments:
                exception && exception.overrideType === 'dynamic' ? this.resetAdjustments(adjustments) : adjustments,
            exceptionProducts: exceptionProducts ? exceptionProducts : [],
            exceptionResellers: exception?.forResellers ? exception.forResellers : [],
            currency: this.props.getCurrency(this.props.accountSlug),
            validate: false,
            confirmDeletion: false,
            pricingType: exception ? exception.pricingType : null,
            priceSettingToEnforce: exception ? exception.enforceFrom : null,
            intervalsForStatic: exception ? exception.intervals : null,
            overviewData: null,
            overrideType: exception ? exception.overrideType : 'adjust',
            isFormValid: true,
            overviewError: false,
            showDuplicateWarning: false,
            allResellers: [],
            resellersOptions: [],
            isSubmitting: false,
        }
    }

    setPageHeader = () => {
        this.props.setActiveSection('pricingExceptions', 'Pricing Exceptions')
    }

    componentDidMount() {
        this.setPageHeader()
        if (!this.state.exceptionActive) {
            this.props.replaceTopMessages(
                'inactive_warning',
                'warn',
                'This pricing exception is expired. Update the date range to activate.',
            )
        }
        this.getSecondaryInformation()
    }

    async componentDidUpdate(prevProps: ExceptionFormProps) {
        if (prevProps.accountSlug !== this.props.accountSlug) {
            this.props.history.push(`/account/${this.props.accountSlug}/products/pricing/exceptions/list`)
        }
    }

    checkFormValidity = () => {
        let isValid = true
        if (
            !this.state.exceptionName ||
            this.state.exceptionName.length < 1 ||
            this.state.exceptionProducts.length === 0 ||
            !this.areAdjustmentsValid() ||
            !this.areDateAndTimeSettingsValid() ||
            !this.areIntervalsValid(this.state.intervalsForStatic)
        ) {
            isValid = false
        }
        this.setState({ isFormValid: isValid })
        return isValid
    }

    enoughDataForEstimate = () => {
        const { adjustments, intervalsForStatic, priceSettingToEnforce } = this.state
        if (
            this.state.exceptionProducts.length > 0 &&
            (!!adjustments.find((a) => a.on && !!a.value) ||
                !!priceSettingToEnforce ||
                (intervalsForStatic &&
                    intervalsForStatic.find(
                        (int) => !!int.adjustment && !!int.startDay && !!int.endDay && !!int.adjustmentType,
                    )))
        ) {
            return true
        }
        return false
    }

    areAdjustmentsValid = (): boolean => {
        const { adjustments, priceSettingToEnforce, intervalsForStatic } = this.state
        if (
            (!adjustments.find((a) => a.on) || !!adjustments.find((a) => a.on && !a.value && a.value !== 0)) &&
            !priceSettingToEnforce &&
            (!intervalsForStatic || intervalsForStatic.length === 0)
        ) {
            return false
        }
        return true
    }

    areDateAndTimeSettingsValid = (): boolean => {
        if (
            !(this.state.startDate && this.state.endDate) &&
            !(this.state.startTime && this.state.endTime) &&
            !this.state.weekdays
        ) {
            return false
        }
        if (this.state.startTime && this.state.endTime && this.state.endTime < this.state.startTime) {
            return false
        }
        if (
            this.state.startDate &&
            this.state.endDate &&
            new Date(this.state.endDate) < new Date(this.state.startDate)
        ) {
            return false
        }
        return true
    }

    doOverlap = (interval1: DynamicInterval, interval2: DynamicInterval): boolean => {
        if (
            interval1.endDay !== null &&
            interval2.endDay !== null &&
            interval1.startDay !== null &&
            interval2.startDay !== null
        ) {
            const interval1FirstDay = Math.min(interval1.startDay, interval1.endDay)
            const interval1SecondDay = Math.max(interval1.startDay, interval1.endDay)
            const interval2FirstDay = Math.min(interval2.startDay, interval2.endDay)
            const interval2SecondDay = Math.max(interval2.startDay, interval2.endDay)
            return Math.max(interval1FirstDay, interval2FirstDay) <= Math.min(interval1SecondDay, interval2SecondDay)
        } else {
            return false
        }
    }

    areIntervalsValid = (intervals: DynamicInterval[] | null): boolean => {
        if (!intervals || intervals.length === 0) {
            return true
        }
        if (intervals.find((int) => !int.adjustment)) {
            return false
        }

        return !this.doIntervalsOverlap(intervals)
    }

    doIntervalsOverlap = (intervals: DynamicInterval[] | null): boolean => {
        if (!intervals || intervals.length === 0) {
            return false
        }
        for (let i = 0; i < intervals.length; i++) {
            if (intervals.find((int) => int !== intervals[i] && this.doOverlap(int, intervals[i]))) {
                return true
            }
        }
        return false
    }

    getExceptionId = (): string | null => {
        const { exception, prototype } = this.props
        return prototype ? null : exception ? exception.id : null
    }

    getExceptionVersion = (): string | null => {
        const { exception, prototype } = this.props
        return prototype ? null : exception ? exception.version : null
    }

    getAdjustment = (adjustment?: Adjustment): string | null => {
        if (!adjustment) {
            return null
        }
        if (adjustment.value === null) {
            return null
        }
        return String(adjustment.value)
    }

    onSave = async (evt: React.FormEvent<HTMLElement>) => {
        evt.preventDefault()
        const {
            exceptionProducts,
            adjustments,
            exceptionName,
            weekdays,
            recurrence,
            startDate,
            endDate,
            startTime,
            endTime,
            pricingType,
            priceSettingToEnforce,
            intervalsForStatic,
            overrideType,
            exceptionResellers,
        } = this.state
        const { accountSlug } = this.props
        const id = this.getExceptionId()
        const version = this.getExceptionVersion()
        const productIds = exceptionProducts.map((ep) => ep.id)
        const minAdjustment = adjustments.find((a) => a.name === 'MIN')
        const maxAdjustment = adjustments.find((a) => a.name === 'BOX')
        const avgAdjustment = adjustments.find((a) => a.name === 'AVG')
        const exceptionData = {
            id: id,
            name: exceptionName,
            version: version,
            account: accountSlug,
            forResellers: exceptionResellers,
            priceSettings: productIds,
            minAcceptedPriceAdjustment: this.getAdjustment(minAdjustment),
            minAcceptedPriceAdjustmentType: minAdjustment ? minAdjustment.type : null,
            originalPriceAdjustment: this.getAdjustment(maxAdjustment),
            originalPriceAdjustmentType: maxAdjustment ? maxAdjustment.type : null,
            targetAvgPriceAdjustment: this.getAdjustment(avgAdjustment),
            targetAvgPriceAdjustmentType: avgAdjustment ? avgAdjustment.type : null,
            weekdays: overrideType === 'dynamic' ? null : weekdays,
            recurrence: overrideType === 'dynamic' ? null : recurrence,
            startDate: startDate,
            startTime: overrideType === 'dynamic' ? null : startTime,
            endDate: endDate,
            endTime: overrideType === 'dynamic' ? null : endTime,
            pricingType: pricingType,
            enforceFrom: priceSettingToEnforce,
            intervals: intervalsForStatic,
            overrideType: overrideType,
        }
        this.setState({ validate: true })
        if (!this.checkFormValidity()) {
            this.props.replaceTopMessages('validation_error', 'error', 'Please check the validation errors below.')
            await delay(3000)
            this.props.removeAllMessages()
            return
        }

        this.setState({ isSubmitting: true })
        const responseData = await this.props.pricingService.createException(exceptionData)

        responseData.ifFailure(this.handleFailure).ifSuccess(() => {
            this.props.removeAllMessages()
            this.props.replaceTopMessages('exception_save_sucess', 'success', 'The exception was successfully saved.')
            this.setState({ isSubmitting: false })
        })
    }

    handleFailure = (err: CreateExceptionError) => {
        this.setState({ isSubmitting: false })

        switch (err.type) {
            case 'server_error':
                this.props.replaceTopMessages(
                    'unknown_error',
                    'error',
                    'Oops! Something went wrong. Please try again later.',
                )
                break
            case 'conflict':
                this.props.replaceTopMessages(
                    'conflict',
                    'error',
                    `Current settings of this exception conflict with the '${err.overrideName}' exception`,
                )
                break
            case 'validation':
                this.props.replaceTopMessages('validation', 'error', `Error: ${err.message}.`)
                break
            case 'unknown':
                this.props.replaceTopMessages('unknown', 'error', `Error: ${err.message}.`)
                break
            default:
                throw new Error(`Unknown error type ${(err as any).type}`)
        }
        throw new Error('Failed to save exception')
    }

    onDuplicateException = () => {
        this.setState({ showDuplicateWarning: true })
    }

    onConfirmDuplication = () => {
        if (this.props.exception) {
            this.props.history.push(
                `/account/${this.props.accountSlug}/products/pricing/exceptions/duplicate?copy_from=${this.props.exception.id}`,
            ) // tslint:disable-line
        }
    }

    updateExceptionName = (n: string) => {
        this.setState({ exceptionName: n, validate: false })
    }

    updateExceptionProducts = (products: ArticleListItem[]) => {
        this.setState({ exceptionProducts: products, validate: false }, () => {
            this.getSecondaryInformation()
        })
    }

    updateExceptionResellers = (resellers: string[]) => {
        this.setState({ exceptionResellers: resellers, validate: false })
    }

    updateExceptionPricingType = (type: PricingType) => {
        this.setState({ pricingType: type, validate: false })
    }

    getResellersList = async () => {
        if (this.props.hasFeature('ResellersFeature', this.props.accountSlug)) {
            try {
                const query = '?include_archived=true'
                const data = await this.props.channelsService.getResellersList(this.props.accountSlug, query)
                const resellersOptions = data
                    .filter((i) => !i.archived)
                    .map((i) => ({
                        name: i.name,
                        value: i.resellerId,
                    }))
                this.setState({ resellersOptions, allResellers: data })
            } catch {
                this.props.replaceTopMessages('reseller_error', 'error', 'Oops! Unable to get resellers list.')
            }
        }
    }

    getOverviewData = async () => {
        const {
            exceptionProducts,
            adjustments,
            exceptionName,
            pricingType,
            intervalsForStatic,
            overrideType,
            priceSettingToEnforce,
        } = this.state
        const intervalsToSend = intervalsForStatic
            ? intervalsForStatic.filter((i) => {
                  return i.startDay !== null && i.endDay !== null && i.adjustment !== null
              })
            : []
        const productIds = exceptionProducts.map((ep) => ep.id)
        const minAdjustment = adjustments.find((a) => a.name === 'MIN')
        const maxAdjustment = adjustments.find((a) => a.name === 'BOX')
        const avgAdjustment = adjustments.find((a) => a.name === 'AVG')

        const exceptionData = {
            name: exceptionName,
            id: this.getExceptionId(),
            overrideType: overrideType,
            account: this.props.accountSlug,
            priceSettings: productIds,
            pricingType: pricingType,
            minAcceptedPriceAdjustment: this.getAdjustment(minAdjustment),
            minAcceptedPriceAdjustmentType: minAdjustment ? minAdjustment.type : null,
            originalPriceAdjustment: this.getAdjustment(maxAdjustment),
            originalPriceAdjustmentType: maxAdjustment ? maxAdjustment.type : null,
            targetAvgPriceAdjustment: this.getAdjustment(avgAdjustment),
            targetAvgPriceAdjustmentType: avgAdjustment ? avgAdjustment.type : null,
            enforceFrom: priceSettingToEnforce,
            intervals: intervalsToSend,
        }
        const data = await this.props.pricingService.getExceptionOverview(exceptionData)
        this.setState({ overviewData: data, overviewError: this.isOverviewError(data) })
    }

    isOverviewError = (data: OverviewData[]): boolean => {
        if (!data || data.length === 0) {
            return false
        }
        if (data[0].__typename === 'RegularPriceChange') {
            if (data.find((od) => !fetchOkStatus(od as OverviewAdjustmentData))) {
                return true
            } else {
                return false
            }
        }
        if (data[0].__typename === 'DynamicPriceChange') {
            if (data.find((od) => !fetchDynamicOkStatus(od as OverviewIntervalData))) {
                return true
            } else {
                return false
            }
        }
        return false
    }

    getSecondaryInformation = () => {
        this.getOverviewData()
        this.getResellersList()
    }

    setAdjustment = (priceSetting: PriceSetting, value: number | null, type: AdjustmentType) => {
        let newAdjustments: Adjustment[] = []
        if (this.state.pricingType === 'static') {
            newAdjustments = this.resetAdjustments(this.state.adjustments).map((adjustment) => {
                if (adjustment.name === 'BOX') {
                    adjustment.value = value
                    adjustment.type = type
                    adjustment.on = true
                }
                return adjustment
            })
        } else {
            newAdjustments = this.state.adjustments.map((adjustment) => {
                if (adjustment.name === priceSetting) {
                    adjustment.value = value
                    adjustment.type = type
                }
                return adjustment
            })
        }
        this.setState(
            {
                adjustments: newAdjustments,
                overrideType: 'adjust',
                validate: false,
            },
            () => {
                this.getSecondaryInformation()
            },
        )
    }

    setDateRange = (fromDate: string | null, toDate: string | null) => {
        this.setState({
            startDate: fromDate,
            endDate: toDate,
        })
    }

    handleRecurrenceSelectChange = (recurrence: Recurrence) => {
        this.setState({ recurrence: recurrence })
    }

    handleWeekdayToggleChange = (weekdays: string[]) => {
        if (weekdays.length === 7) {
            this.setState({ weekdays: null })
        } else {
            this.setState({ weekdays: weekdays.toString() })
        }
    }

    handleDayTimeInputChange = (startTime: string | null, endTime: string | null) => {
        this.setState({
            startTime: startTime,
            endTime: endTime,
        })
    }

    onDeleteException = async () => {
        if (this.state.confirmDeletion) {
            if (this.props.exception) {
                try {
                    await this.props.pricingService.deleteException(this.props.exception.id)
                    this.props.history.replace(`/account/${this.props.accountSlug}/products/pricing/exceptions/list`)
                } catch {
                    this.props.replaceTopMessages(
                        'server_error',
                        'error',
                        'There was a problem deleting the exception.',
                    )
                }
            }
        } else {
            this.setState({ confirmDeletion: true })
        }
    }

    resetAdjustments = (adjustments: Adjustment[]): Adjustment[] => {
        const newAdjustments = adjustments.map((a) => {
            a.value = null
            a.type = null
            a.on = false
            return a
        })
        return newAdjustments
    }

    handlePriceSettingRuleActivation = (priceSetting: PriceSetting, activate: boolean) => {
        let newAdjustments
        if (activate) {
            newAdjustments = this.state.adjustments.map((a) => {
                if (a.name === priceSetting) {
                    a.on = true
                }
                return a
            })
        } else {
            newAdjustments = this.state.adjustments.map((a) => {
                if (a.name === priceSetting) {
                    a.value = null
                    a.type = null
                    a.on = false
                }
                return a
            })
        }
        this.setState(
            {
                adjustments: newAdjustments,
                validate: false,
            },
            () => {
                this.getSecondaryInformation()
            },
        )
    }

    setEnforceRule = (priceSetting: PricingSettingToEnforce | null) => {
        this.setState(
            {
                priceSettingToEnforce: priceSetting,
                overrideType: priceSetting ? 'enforce' : 'adjust',
                validate: false,
            },
            () => {
                this.getSecondaryInformation()
            },
        )
    }

    onOverrideTypeChange = (type: OverrideType) => {
        const newAdjustments =
            type !== 'adjust' ? this.resetAdjustments(this.state.adjustments) : this.state.adjustments
        const newIntervals = type !== 'dynamic' ? [] : this.state.intervalsForStatic
        const toEnforce = type !== 'enforce' ? null : this.state.priceSettingToEnforce
        this.setState(
            {
                overrideType: type,
                adjustments: newAdjustments,
                intervalsForStatic: newIntervals,
                priceSettingToEnforce: toEnforce,
                validate: false,
            },
            () => {
                this.getSecondaryInformation()
            },
        )
    }

    setIntervals = (intervals: DynamicInterval[] | null) => {
        const newAdjustments = intervals ? this.resetAdjustments(this.state.adjustments) : this.state.adjustments
        this.setState(
            {
                intervalsForStatic: intervals,
                overrideType: intervals ? 'dynamic' : 'adjust',
                adjustments: newAdjustments,
                validate: false,
            },
            () => {
                this.getSecondaryInformation()
            },
        )
    }

    render() {
        const { accountSlug, exception, className, prototype } = this.props
        const {
            exceptionName,
            pricingType,
            currency,
            weekdays,
            recurrence,
            startDate,
            endDate,
            startTime,
            endTime,
            validate,
            exceptionProducts,
            adjustments,
            priceSettingToEnforce,
            intervalsForStatic,
            overviewData,
            overrideType,
            showDuplicateWarning,
            resellersOptions,
            allResellers,
            exceptionResellers,
        } = this.state

        return (
            <>
                <ExceptionContainerBody className={className} id="pricing-settings-exception-detail-form">
                    {showDuplicateWarning && (
                        <ConfirmationDialog
                            title="Duplicate pricing exception?"
                            text="Pricing exception will be duplicated with all its settings."
                            buttonText="Duplicate"
                            onCancel={() => this.setState({ showDuplicateWarning: false })}
                            onConfirm={this.onConfirmDuplication}
                        />
                    )}
                    <form noValidate id="exception-detail-form" style={{ width: '100%' }}>
                        <ExceptionInfoFormSection
                            exceptionProducts={exceptionProducts}
                            exception={!!exception}
                            prototype={prototype}
                            exceptionName={exceptionName}
                            pricingType={pricingType}
                            resellersOptions={resellersOptions}
                            allResellers={allResellers}
                            resellers={exceptionResellers}
                            updateExceptionResellers={this.updateExceptionResellers}
                            onDuplicateException={this.onDuplicateException}
                            updateExceptionName={this.updateExceptionName}
                            updateProducts={this.updateExceptionProducts}
                            updatePricingType={this.updateExceptionPricingType}
                            validate={validate}
                            articleService={this.props.articleService}
                            accountSlug={accountSlug}
                            replaceTopMessages={this.props.replaceTopMessages}
                            hasPermission={this.props.hasPermission}
                        />
                        {exceptionProducts.length > 0 && (
                            <>
                                <RulesSection
                                    exception={!!exception}
                                    pricingType={pricingType}
                                    activatePriceSettingRule={this.handlePriceSettingRuleActivation}
                                    currency={currency}
                                    adjustments={adjustments}
                                    intervals={intervalsForStatic}
                                    setAdjustment={this.setAdjustment}
                                    setEnforceRule={this.setEnforceRule}
                                    setIntervals={this.setIntervals}
                                    priceSettingToEnforce={priceSettingToEnforce}
                                    validate={validate}
                                    areIntervalsValid={this.areIntervalsValid(intervalsForStatic)}
                                    doIntervalsOverlap={this.doIntervalsOverlap(intervalsForStatic)}
                                    overrideType={overrideType}
                                    onOverrideTypeChange={this.onOverrideTypeChange}
                                />
                                {(!!pricingType || (exception && !pricingType)) && (
                                    <ExceptionDayTimeFormItem
                                        showPatterns={overrideType !== 'dynamic'}
                                        handleCalendarInputChange={this.setDateRange}
                                        handleWeekdayToggleChange={this.handleWeekdayToggleChange}
                                        handleRecurrenceSelectChange={this.handleRecurrenceSelectChange}
                                        handleDayTimeInputChange={this.handleDayTimeInputChange}
                                        weekdays={weekdays}
                                        recurrence={recurrence ? recurrence : 'WEEKLY'}
                                        startDate={startDate}
                                        endDate={endDate}
                                        startTime={startTime}
                                        endTime={endTime}
                                        validate={validate}
                                    />
                                )}
                            </>
                        )}
                        {overviewData &&
                            overviewData.length > 0 &&
                            overviewData[0].__typename === 'RegularPriceChange' &&
                            exceptionProducts && (
                                <ExceptionOverviewTable
                                    overviewData={overviewData as OverviewAdjustmentData[]}
                                    pricingType={pricingType}
                                    exceptionProducts={exceptionProducts}
                                    accountSlug={accountSlug}
                                />
                            )}
                        {overviewData &&
                            overviewData.length > 0 &&
                            overviewData[0].__typename === 'DynamicPriceChange' &&
                            exceptionProducts && (
                                <DynamicExceptionOverviewTable
                                    overviewData={overviewData as OverviewIntervalData[]}
                                    pricingType={pricingType}
                                    exceptionProducts={exceptionProducts}
                                    accountSlug={accountSlug}
                                />
                            )}
                        {this.state.confirmDeletion && (
                            <Row>
                                <ConfirmationNotice>Are you sure you want to delete this exception?</ConfirmationNotice>
                            </Row>
                        )}
                        <Row>
                            {this.props.exception &&
                                !this.props.prototype &&
                                this.props.hasPermission('edit_pricing_settings', accountSlug) && (
                                    <PageActions align="left">
                                        <ActionButton
                                            type="button"
                                            kind="destructive"
                                            size="large"
                                            onClick={this.onDeleteException}
                                        >
                                            Delete
                                        </ActionButton>
                                        {this.state.confirmDeletion && (
                                            <ActionButton
                                                size="large"
                                                secondary
                                                onClick={() => {
                                                    this.setState({ confirmDeletion: false })
                                                }}
                                            >
                                                Cancel
                                            </ActionButton>
                                        )}
                                    </PageActions>
                                )}
                            <ButtonWrapper>
                                <ActionButton
                                    size="large"
                                    secondary
                                    onClick={() => {
                                        this.props.history.push(
                                            `/account/${accountSlug}/products/pricing/exceptions/list`,
                                        )
                                    }}
                                >
                                    {this.props.hasPermission('edit_pricing_settings', accountSlug) ? 'Cancel' : 'Back'}
                                </ActionButton>
                                {this.props.hasPermission('edit_pricing_settings', accountSlug) && (
                                    <LoadingButton
                                        id="saveOverride"
                                        type="button"
                                        size="large"
                                        variant="contained"
                                        onClick={this.onSave}
                                        style={{ marginLeft: '1.5em' }}
                                        loading={this.state.isSubmitting}
                                    >
                                        Save
                                    </LoadingButton>
                                )}
                            </ButtonWrapper>
                        </Row>
                    </form>
                </ExceptionContainerBody>
            </>
        )
    }
}

export default withFeatures(withNavigation(withCurrency(ExceptionForm)))
