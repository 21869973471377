import {
    OrderRefundDetails,
    RefundOrderItems,
    TicketItemsForRefund,
    TicketsForRefund,
} from 'orders/orders/commons/orderTicketsActions/cancelAndRefund/types'
import { RefundFeeType } from 'settings/accountSettings/contract/managementService'

export const calculateRefundFee = (total: number, fee: number | null, feeType: RefundFeeType | null) => {
    if (!!total && !!fee && !!feeType) {
        return feeType === 'absolute' ? fee : (total * fee) / 100
    }

    return 0
}

const getTicketTotalAmount = (ticket: TicketsForRefund) => {
    return ticket.items.reduce((s, item) => s + item.price, 0)
}

export const getTotalAmount = (tickets: TicketsForRefund[]) => {
    return tickets.reduce((s, t) => s + getTicketTotalAmount(t), 0).toFixed(2)
}

export const getTicketItemsForRefund = (t: RefundOrderItems): TicketItemsForRefund[] => {
    return t.refundTickets.map((gte) => {
        return {
            ticketEntity: gte,
            ticketUuids: gte.ids,
            barcodeList: gte.barcodes,
            itemRefundable: gte.canRefund,
            price: Number(gte.priceInclTax),
        }
    })
}

export const getTicketsForRefund = (order: OrderRefundDetails): TicketsForRefund[] => {
    return order.refundOrderItems.map((t) => ({
        ...t,
        items: getTicketItemsForRefund(t),
    }))
}

interface CalculateOrderRefund {
    order: OrderRefundDetails
    fullRefundOnly?: boolean
}

export const calculateOrderTotalAmount = ({ order, fullRefundOnly }: CalculateOrderRefund = {} as any) => {
    const ticketsForRefund = getTicketsForRefund(order)
    const refundableTicketsList = ticketsForRefund
        .filter((t) => t.canRefund)
        .map((t) => ({ ...t, items: t.items.filter((i) => i.itemRefundable) }))

    const totalAmount = fullRefundOnly
        ? Number(order.totalDiscountedPriceInclTax)
        : Number(getTotalAmount(refundableTicketsList))

    return totalAmount
}

export const calculateOrderPartnerRefundFee = ({ order, fullRefundOnly }: CalculateOrderRefund) => {
    const totalAmount = calculateOrderTotalAmount({ order, fullRefundOnly })

    return calculateRefundFee(totalAmount, Number(order.partnerRefundFee), order.partnerRefundFeeType) || 0
}
