import React, { FC, PropsWithChildren } from 'react'  
import { useAdminPermission } from './hocs'

const AdminRequired: FC<PropsWithChildren<{}>>  = ({ children }) => {
    const { isAdmin } = useAdminPermission()
    if (!isAdmin || !children) {
        return null
    }

    return <>{children}</>
}

export default AdminRequired
