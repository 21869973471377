import * as React from 'react'
import { dateRangeFromQuery, DateRange, dateRangeToQuery } from 'dateRanges'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import { ConfigurationVersions, ComponentsService } from 'engageTools/studio/componentsService'
import { ProductName } from 'engageTools/studio/schema'
import { Container } from 'uiComponents/pageElements'
import { PageTitle } from 'uiComponents/typography'
import { EngageToolsService } from 'engageTools/engageToolsService'
import { withNavigation } from 'hocs'
import ProductContainer from './productContainer'
import TAPAppContainer from './tapAppContainer'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { PageLoader } from 'uiComponents/loaders'
import Filters, { FILTERS } from 'engageTools/filters'
import { withFeatures } from 'features'
import { format } from 'date-fns'
import { ReportsToolsWrapper, ReportUpdateInfo } from 'reports/helpers'
import { parseFilterQuery } from 'uiComponents/filter'
import { Filters as FiltersType } from 'uiComponents/filter/schema'
import Disclaimer from 'reports/disclaimer'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import { useMessages } from 'messagesContext'

interface CampaignsPageProps {
    accountSlug: string
    engageToolsService: EngageToolsService
    navigation: Navigation
    match: RouteMatch<{}>
    componentsService: ComponentsService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

const CampaignsPage: React.FC<CampaignsPageProps> = (props) => {
    const { accountSlug, engageToolsService, hasFeature } = props
    const [loading, setLoading] = React.useState(true)
    const [configurationVersions, setConfigurationVersions] = React.useState<ConfigurationVersions[]>([])
    const query = props.navigation.query()
    const dateRange = dateRangeFromQuery(query)
    const { hideMessage, replaceMessages } = useMessages()

    const handleErrorMessage = () => {
        replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again.')
    }

    const onDateRangeChanged = (range: DateRange) => {
        props.navigation.addQueryWithReplace(dateRangeToQuery(range))
    }

    const checkShouldShowTap = (appliedFilters: FiltersType[]) => {
        const deviceType = appliedFilters.find((f) => f.attribute === 'device_type')
        const toolType = appliedFilters.find((f) => f.attribute === 'tool_type')
        return (
            (!deviceType || deviceType.values.includes('mobile')) && (!toolType || toolType.values.includes('tap_app'))
        )
    }

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const navQuery = props.navigation.query()
                const range = dateRangeFromQuery(navQuery)
                const dateFrom = format(range.from, 'yyyy-MM-dd')
                const dateTo = format(range.to, 'yyyy-MM-dd')
                const dateRangeString = `?date_from=${dateFrom}&date_to=${dateTo}`
                const filtersString = query.filter ? `&filters=${query.filter}` : ''
                const defaultQueryString = '?configuration_type=default' + filtersString
                const campaignsQueryString = dateRangeString + filtersString
                const defaultVersions = await props.componentsService.getConfigurationsVersions(
                    props.accountSlug,
                    'all',
                    defaultQueryString,
                )
                const campaignVersions = await props.componentsService.getConfigurationsVersions(
                    props.accountSlug,
                    'all',
                    campaignsQueryString,
                )
                setConfigurationVersions([...defaultVersions, ...campaignVersions])
            } catch {
                handleErrorMessage()
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [props.accountSlug, props.navigation])

    const currentOrNextVersionWonderbar = configurationVersions.find(
        (v) => v.product === 'wonderbar' && (v.current || v.next),
    )
    const currentOrNextVersionModalWindow = configurationVersions.find(
        (v) => v.product === 'modal_window' && (v.current || v.next),
    )
    const currentOrNextVersionTriggerButton = configurationVersions.find(
        (v) => v.product === 'trigger_button' && (v.current || v.next),
    )
    const currentOrNextVersionCheckout = configurationVersions.find(
        (v) => v.product === 'checkout' && (v.current || v.next),
    )

    const campaignProducts = [
        {
            name: 'checkout' as ProductName,
            versions: configurationVersions.filter((v) => v.product === 'checkout'),
            currentOrNextVersion: !!currentOrNextVersionCheckout,
        },
        {
            name: 'trigger_button' as ProductName,
            versions: configurationVersions.filter((v) => v.product === 'trigger_button'),
            currentOrNextVersion: !!currentOrNextVersionTriggerButton,
        },
        {
            name: 'wonderbar' as ProductName,
            versions: configurationVersions.filter((v) => v.product === 'wonderbar'),
            currentOrNextVersion: !!currentOrNextVersionWonderbar,
        },
        {
            name: 'modal_window' as ProductName,
            versions: configurationVersions.filter((v) => v.product === 'modal_window'),
            currentOrNextVersion: !!currentOrNextVersionModalWindow,
        },
    ]

    const filtersToApply = hasFeature('CRMAudiencesPage', accountSlug)
        ? FILTERS
        : FILTERS.filter((f) => f !== 'audience')
    const appliedFilters = parseFilterQuery(query.filter)
    const showTapStats = checkShouldShowTap(appliedFilters)

    const sortByPublish = (a: any, b: any) => b.published - a.published
    const sortByCreated = (a: any, b: any) => b.showStats - a.showStats

    return (
        <Container id="engage-tools-page" style={{ marginBottom: '10em' }}>
            <PageTitle>Engage Tools</PageTitle>
            <div style={{ display: 'flex' }}>
                <ReportUpdateInfo metric="Ticket sales" text="updated every 30 min." />
                <ReportUpdateInfo metric="Capacity" text="updated every 1 hour." />
            </div>
            <ReportsToolsWrapper>
                <Filters
                    accountSlug={accountSlug}
                    dateRange={dateRange}
                    applicableFilters={filtersToApply}
                    standaloneFilters={filtersToApply}
                    includeTap
                    replaceMessages={replaceMessages}
                    hideMessage={hideMessage}
                />
                <DateRangePicker
                    range={dateRange}
                    onChange={onDateRangeChanged}
                    firstAvailableDay={new Date(2019, 0, 1)}
                />
            </ReportsToolsWrapper>
            {loading && <PageLoader />}
            {!loading &&
                campaignProducts
                    .sort(sortByPublish)
                    .sort(sortByCreated)
                    .map((p) => (
                        <ProductContainer
                            key={p.name}
                            product={p.name}
                            dateRange={dateRange}
                            versions={p.versions}
                            activeAccount={accountSlug}
                            engageToolsService={engageToolsService}
                            currentOrNextVersion={p.currentOrNextVersion}
                            replaceTopMessages={replaceMessages}
                        />
                    ))}
            {!loading && showTapStats && (
                <TAPAppContainer
                    dateRange={dateRange}
                    accountSlug={accountSlug}
                    engageToolsService={engageToolsService}
                    replaceTopMessages={replaceMessages}
                />
            )}
            <div style={{ marginTop: '2em' }}>
                <BaseKnowledgeLink link="https://support.convious.com/help/how-to-use-your-engage-tools-report" />
                <Disclaimer />
            </div>
        </Container>
    )
}

export default withFeatures(withNavigation(CampaignsPage))
