import { IconProp } from '@fortawesome/fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BaseRouteParams } from 'hocs'
import { history } from 'middleware'
import React from 'react'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import ActionButton from 'uiComponents/buttons'

import { Col, Row } from 'uiComponents/flex'
import { pathUtils } from 'utils/pathnameFormatter'
import { CODES_PATHS } from '../paths'

const DiscountHeader = () => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const { search, pathname } = useLocation()
    const shouldShowAdd =
        !matchPath(location.pathname, CODES_PATHS.fullPaths.editCode)?.isExact &&
        pathname.includes(CODES_PATHS.internalPaths.discountPath.pools)

    return (
        <Row style={{ justifyContent: 'space-between', marginBottom: '1rem' }}>
            <Col span="auto" />
            {shouldShowAdd && (
                <Col span="auto">
                    <ActionButton
                        kind="action"
                        onClick={() =>
                            history.push(
                                pathUtils.populateParams(
                                    pathUtils.addQueryString(CODES_PATHS.fullPaths.poolsPageAddPool, search),
                                    {
                                        accountSlug,
                                    },
                                ),
                            )
                        }
                    >
                        <FontAwesomeIcon icon={faPlus as IconProp} />
                        &nbsp; Add new
                    </ActionButton>
                </Col>
            )}
        </Row>
    )
}

export default DiscountHeader
