import * as React from 'react'
import styled from 'styled-typed'
import { Order, OrderDetails, statusDescriptors } from '../schema'
import { OrdersService } from 'orders/ordersService'
import { LoggingService } from 'http/loggingService'
import { format } from 'date-fns'
import { Cell, DataRow } from 'uiComponents/table'
import { Text, StatusType } from 'uiComponents/typography'
import { utcValue } from 'utils'
import Money from 'uiComponents/money'
import { InlineEdit, InlineTimePicker } from 'uiComponents/input'
import ActionsMenu from './transactionActions'
import { MessageKind } from 'uiComponents/messages'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { History } from 'history'
import { match as RouteMatch } from 'react-router-dom'
import { withNavigation } from 'hocs'
import { Navigation, renderSearch } from 'navigation'
import { usePermission } from 'admin/hocs'
import { withFeatures } from 'features'
import { UnstyledLink } from 'uiComponents/navigation/unstyledLink'
import { getDiscountCodeString, DiscountCodesElement } from 'orders/helpers'
import { DateFormats, parseISODate, formatISOString, convertTimeFormat, parseDate } from 'utils/dates'

export const Link = styled.div`
    color: ${(props) => props.theme.colors.boyBlue};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

interface OrderSummaryProps {
    history: History
    navigation: Navigation
    match: RouteMatch<any>
    order: Order
    accountSlug: string
    withTimeSlots: boolean
    showResellerColumn: boolean
    loggingService: LoggingService
    ordersService: OrdersService
    rowIndex: number
    rowCount: number
    loading: boolean
    saveNewEmail: (orderUuid: string, email: string) => void
    saveNewVisitDate: (orderId: string, date: Date) => void
    saveNewVisitTime: (orderId: string, time: string) => void
    reloadOrderList: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    hideMessage: (id: string) => void
    hasFeature: (feature: string, accountSlug: string) => boolean
}

export const getOrderDate = (order: Order) => {
    if (!order.orderDate) {
        return '-'
    }

    const utcOrderDate = utcValue(parseISODate(order.orderDate))
    return format(utcOrderDate, DateFormats.LONG_DATE)
}

export const getPaymentDate = (order: Order | OrderDetails) => {
    if (!order.paymentDate) {
        return '-'
    }

    const utcPaymentDate = utcValue(parseISODate(order.paymentDate))
    return format(utcPaymentDate, DateFormats.LONG_DATE)
}

function TransactionRow(props: OrderSummaryProps) {
    const getLinkToOrderDetails = () => {
        const query = props.navigation.query()
        query.prevSortBy = query.sortBy
        query.prevSortDirection = query.sortDirection
        delete query.sortBy
        delete query.sortDirection
        query.prevPage = query.page
        query.page = '1'
        query.prevQ = query.q
        query.backTo = 'transactions_page'
        const search = renderSearch({ ...query, q: props.order.id })
        return `/account/${props.accountSlug}/orders/details${search}`
    }

    const {
        order,
        accountSlug,
        saveNewEmail,
        ordersService,
        reloadOrderList,
        replaceMessages,
        hideMessage,
        loggingService,
        withTimeSlots,
        showResellerColumn,
        rowIndex,
        rowCount,
        loading,
    } = props
    const { hasPermission } = usePermission()
    const canEditOrders =
        hasPermission('edit_orders', props.accountSlug) &&
        order.status !== 'refunded' &&
        order.status !== 'refunding'
    const searchResult = !!props.navigation.query().q

    return (
        <DataRow className="transactions-row" style={{ minHeight: rowCount === 1 ? '6.8em' : '' }} narrow>
            <Cell />
            <Cell className="order-location productName" title={order.location}>
                <span>{order.location}</span>
            </Cell>
            <Cell className="order-id">
                <Link>
                    <UnstyledLink to={getLinkToOrderDetails()}>{order.id}</UnstyledLink>
                </Link>
            </Cell>
            <Cell className="order-email productName" title={order.customer.email || 'No email'}>
                <InlineEdit
                    id="email"
                    value={order.customer.email}
                    onEditAccept={(newEmail) => saveNewEmail(order.uuid, newEmail)}
                    limitedTextWidth="15em"
                    responsiveWidth
                    maskIcon={!!order.customer.email}
                    profileIcon={props.hasFeature('CRMProfilesPage', props.accountSlug) && !!order.customer.email}
                    disabled={order.status === 'refunded' || order.status === 'refunding'}
                    maskData={(!searchResult || loading) && !!order.customer.email}
                    accountSlug={accountSlug}
                />
            </Cell>
            <Cell align="center" className="order-quantity">
                {order.quantity}
            </Cell>
            <Cell className="order-subtotal">
                <Money amount={order.total?.toFixed(2)} accountSlug={accountSlug} />
            </Cell>
            <Cell className="order-discount">
                <Money
                    amount={
                        order.discountAmount > 0
                            ? `-${order.discountAmount.toFixed(2)}`
                            : order.discountAmount.toFixed(2)
                    }
                    accountSlug={accountSlug}
                />
            </Cell>
            <Cell className="order-discount-code productName" title={getDiscountCodeString(order.discountCode)}>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <DiscountCodesElement discountsString={order.discountCode} />
                </span>
            </Cell>
            <Cell className="order-total-after-discount">
                <Money amount={order.totalAfterDiscount.toFixed(2)} accountSlug={accountSlug} />
            </Cell>
            <Cell className="order-date">{getOrderDate(order)}</Cell>
            <Cell className="payment-date">{getPaymentDate(order)}</Cell>
            <Cell className="order-visit-date overflow-allowed">
                {order.visitDate && (
                    <DatePicker
                        date={order.visitDate ? parseDate(order.visitDate) : null}
                        inlineEdit
                        modalDatePicker
                        onInlineEditAccept={(newDate) => props.saveNewVisitDate(order.uuid, newDate)}
                        disabled={!canEditOrders}
                        inlineEditDateFormat={DateFormats.LONG_DATE}
                    />
                )}
                {!order.visitDate && <>Undated</>}
            </Cell>
            {withTimeSlots && (
                <Cell className="visit-time">
                    {order.visitTime && (
                        <InlineTimePicker
                            id="time"
                            value={order.visitTime}
                            onSave={(newTime) => props.saveNewVisitTime(order.uuid, newTime!)}
                            disabled={!canEditOrders}
                            required
                            requireConfirmation
                            confirmationMessage={(time) =>
                                `Have you checked the availability at ${convertTimeFormat(time!)}?`
                            }
                        />
                    )}
                    {!order.visitTime && 'Any time'}
                </Cell>
            )}
            <Cell className="order-status" title={statusDescriptors[order.orderStatus]?.text}>
                <Text status={statusDescriptors[order.orderStatus]?.style as StatusType}>
                    {statusDescriptors[order.orderStatus]?.text}
                </Text>
            </Cell>
            <Cell className="payment-status" title={statusDescriptors[order.paymentStatus]?.text}>
                <Text status={statusDescriptors[order.paymentStatus]?.style as StatusType}>
                    {statusDescriptors[order.paymentStatus]?.text}
                </Text>
            </Cell>
            {showResellerColumn && (
                <Cell title={order.resellerName || ''} className="order-reseller productName">
                    <span>{order.resellerName || '-'}</span>
                </Cell>
            )}
            <Cell style={{ textTransform: 'capitalize' }} className="order-email-status" title={order.emailStatus}>
                <Text>
                    {order.emailStatus === 'failed' || order.emailStatus === 'disabled' ? (
                        <span style={{ color: 'red' }}>{order.emailStatus}</span>
                    ) : (
                        <>{order.emailStatus}</>
                    )}
                </Text>
            </Cell>
            <Cell>{order.downloaded ? formatISOString(order.downloaded, DateFormats.LONG_DATE) : '-'}</Cell>
            <Cell className="overflow-allowed">
                {order.paymentStatus !== 'refunded' &&
                    order.paymentStatus !== 'refunding' &&
                    order.orderStatus !== 'cancelled' && (
                        <ActionsMenu
                            accountSlug={accountSlug}
                            order={order}
                            ordersService={ordersService}
                            reloadOrderList={reloadOrderList}
                            replaceMessages={replaceMessages}
                            hideMessage={hideMessage}
                            loggingService={loggingService}
                            rowCount={rowCount}
                            rowIndex={rowIndex}
                        />
                    )}
            </Cell>
        </DataRow>
    )
}

export default withFeatures(withNavigation(TransactionRow))
