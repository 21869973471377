import React from 'react'

import { PageTitle } from 'uiComponents/typography'
import SearchField from 'uiComponents/search/searchField'
import CartTable from './cartTable'
import ErrorBoundary from 'uiComponents/errorBoundary'
import CartFilters from './filters/searchAndDate'
import { Alert } from '@mui/material'
import { useHasFeature } from 'features'
import './cartPage.scss'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'

const Cart: React.FC = ({ children }) => {
    const isOpenReservationWarningShown = useHasFeature('show_open_reservation_hidden_warning')

    return (
        <div id="cart__elem">
            <PageTitle>Pending Reservations</PageTitle>
            {isOpenReservationWarningShown && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                    We&apos;re improving Pending Reservations. Unfinished &apos;Open&apos; reservations will no longer
                    appear from 2025-01-15. If this negatively impacts you, please contact&nbsp;
                    <a href="mailto:support@convious.com">support@convious.com</a>
                </Alert>
            )}
            <div className="cart-filters-container">
                <SearchField placeholder="Search by Email, Reservation ID, Notes" className="cart-search" />
                <CartFilters />
            </div>
            <div>
                <CartTable />
            </div>
            <ErrorBoundary>{children}</ErrorBoundary>
            <BaseKnowledgeLink link="https://support.convious.com/help/pending-reservations" />
        </div>
    )
}

export default Cart
