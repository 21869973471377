import React from 'react'
import styled from 'styled-typed'
import GeneralNotifications from './notification'

const PageContentScroll = styled.div`
    min-height: 600px;

    @media (max-width: ${(props) => props.theme.breakPoints.md}) {
        overflow: auto;
        min-height: calc(100vh - 80px - 16px);
    }
`

const PageContentSizer = styled.div`
    width: 1200px;
    max-width: 1200px;
    margin: 0 24px;
    margin-bottom: 15em;
    &.no-sidebar {
        margin: 0 auto;
    }

    @media screen and (min-width: 1440px) {
        width: auto;
        margin: 0 auto;
    }

    @media screen and (min-width: 1560px) {
        width: fit-content;
        min-width: 1200px;
        max-width: 1350px;
    }

    @media screen and (min-width: 1680px) {
        max-width: 1450px;
    }

    @media screen and (min-width: 1800px) {
        max-width: 1600px;
    }

    @media screen and (min-width: 2000px) {
        max-width: 1800px;
    }
`

export const FilterSection = styled.div`
    margin-top: 1em;
    margin-bottom: 1.5em;

    &:after {
        content: '';
        display: table;
        clear: both;
    }
`

interface PageContentProps {
    children?: React.ReactNode | undefined
    className?: string
}

export function PageContent({ children, className, ...rest }: PageContentProps | React.HTMLAttributes<HTMLDivElement>) {

    return (
        <PageContentScroll {...rest}>
            <PageContentSizer className={`PageContentSizer ${className || ''}`}>
                <GeneralNotifications />
                {children}
            </PageContentSizer>
        </PageContentScroll>
    )
}
