import * as React from 'react'
import { useHasFeature } from '../../features'
import { Headline } from '../../uiComponents/typography'
import AudiencesTabsNavigation from './components/navigation'

const AudiencesContainer: React.FC = ({ children }) => {
    const hasAccessToPredefinedAudiences = useHasFeature('CRMAudiencesPage')
    const hasAccessToCustomAudiences = useHasFeature('CRMAudiencesCustom')

    return (
        <div style={{ position: 'relative' }} id="audiences">
            <Headline size={1} style={{ marginBottom: '0.5em' }}>
                Audiences
            </Headline>
            {hasAccessToPredefinedAudiences || hasAccessToCustomAudiences ? (
                <>
                    <AudiencesTabsNavigation />
                </>
            ) : null}
            {children}
        </div>
    )
}

export default AudiencesContainer
