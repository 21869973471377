import React, { useState, useEffect } from 'react'
import { History } from 'history'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { PaypalService } from 'orders/paypalService'
import { Sorting, Pagination } from 'uiComponents/table'
import { PaginationSection } from 'uiComponents/table/pagination'
import { DateRange } from 'dateRanges'
import { MessageKind } from 'uiComponents/messages'
import { ModalType, PaypalDisputesPage, Dispute, disputeReasonMap } from 'orders/paypalDisputes/schema'
import { defaultPageData } from 'orders/schema'
import DisputesTable from './disputesTable'
import { ModalDialog } from 'uiComponents/popups/modal'
import RefundDialog from 'orders/transactions/refunds/refundDialog'
import { OrdersService } from 'orders/ordersService'
import { DisputesDialog } from './disputesDialog'
import { cancelOutOffsetBeforeConvertionToUTC } from 'utils'
import { delay } from 'utils'
import { generateOnRefundErrorMessage } from 'orders/utils/orderRefundErrorMessage'
import { useAppSelector } from 'store/hooks'

interface DisputesPageProps {
    section: 'active' | 'resolved'
    history: History
    dateRange: DateRange
    sort: Sorting
    onSortChanged: (s: Sorting) => void
    pagination: Pagination
    onPaginationChanged: (p: Pagination) => void
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    paypalService: PaypalService
    ordersService: OrdersService
    showAllPartners: boolean
    onSectionChange: (section: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

function DisputesPage(props: DisputesPageProps) {
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingModal, setLoadingModal] = useState<boolean>(false)
    const [pageData, setPageData] = useState<PaypalDisputesPage>(defaultPageData)
    const [openDispute, setOpenDispute] = useState<Dispute | null>(null)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [openDialog, setOpenDialog] = useState<ModalType>('details')
    const [modalContentHeight, setModalContentHeight] = useState<number>(500)
    const user = useAppSelector((state) => state.auth.user)

    async function getDisputesList() {
        const dateFrom = cancelOutOffsetBeforeConvertionToUTC(props.dateRange.from).toISOString()
        const dateTo = cancelOutOffsetBeforeConvertionToUTC(props.dateRange.to).toISOString()
        const dateRange = `date_from=${dateFrom}&date_to=${dateTo}&`
        const pagination = `page=${props.pagination.page}&page_size=${props.pagination.pageSize}&`
        const sort = props.sort.prop ? `sort_by=${props.sort.prop}&sort_direction=${props.sort.direction}&` : ''
        const status = props.section === 'resolved' ? 'status=resolved&' : ''
        const queryString = '?' + status + pagination + sort + dateRange
        const account = props.showAllPartners ? null : props.accountSlug
        const data = await props.paypalService.getDisputesList(account, queryString)
        setPageData(data)
    }

    async function getData() {
        setLoading(true)
        try {
            await getDisputesList()
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        props.onSectionChange(props.section)
        getData()
    }, [props.accountSlug, props.section, props.showAllPartners, props.dateRange, props.pagination, props.sort])

    const onOpenModal = (id: string, type: ModalType) => {
        const dispute = pageData.entries.find((d) => d.id === id) || null
        setOpenDispute(dispute)
        setOpenDialog(type)
        setShowModal(true)
    }

    const onSendMessage = async (id: string, message: string, receiver: 'paypal' | 'buyer', file?: File) => {
        const dispute = pageData.entries.find((d) => d.id === id)
        if (!dispute) {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
            setShowModal(false)
            return
        }
        try {
            setLoadingModal(true)
            if (receiver === 'buyer') {
                await props.paypalService.sendMessageToBuyer(dispute.account.slug, dispute.id, message)
            } else if (receiver === 'paypal' && !!file) {
                await props.paypalService.rejectDispute(dispute.account.slug, dispute.id, message, file)
            }
            setLoadingModal(false)
            setShowModal(false)
            await getData()
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
            setLoadingModal(false)
        }
    }

    const onRefund = async (e: Error | undefined = undefined) => {
        setShowModal(false)
        if (e) {
            props.replaceMessages(
                'refund_error',
                'error',
                generateOnRefundErrorMessage(e, false, openDispute?.order.number ?? ''),
            )
        } else {
            await getData()
            const message = `Order ${openDispute?.order.number} refund request was successfuly placed and is being processed`
            props.replaceMessages('refunded_successfully', 'success', message)
            await delay(5000)
            props.removeAllMessages()
        }
    }

    return (
        <>
            {showModal && openDispute && (
                <ModalDialog
                    onDismiss={() => setShowModal(false)}
                    interactive
                    fromTop="10%"
                    dynamicHeight={openDialog === 'refund' ? modalContentHeight : undefined}
                >
                    {openDialog !== 'refund' && (
                        <DisputesDialog
                            loading={loadingModal}
                            paypalService={props.paypalService}
                            accountSlug={props.accountSlug}
                            user={user}
                            dispute={openDispute}
                            dialogType={openDialog}
                            onOpenModal={onOpenModal}
                            onSendMessage={onSendMessage}
                            replaceMessages={props.replaceMessages}
                            removeAllMessages={props.removeAllMessages}
                        />
                    )}
                    {openDialog === 'refund' && (
                        <RefundDialog
                            accountSlug={openDispute.account.slug}
                            orderNumber={openDispute.order.number}
                            cancellation={false}
                            onRefundPaypalDispute={onRefund}
                            cancelRefund={() => setShowModal(false)}
                            refunding={loadingModal}
                            onHeightChange={(h) => setModalContentHeight(h)}
                            ordersService={props.ordersService}
                            replaceMessages={props.replaceMessages}
                            paypalRefund={{
                                id: openDispute.disputeId,
                                accountName: openDispute.account.name,
                                location: openDispute.order.location,
                                reason: disputeReasonMap[openDispute.reason],
                                refundAmount: Number(openDispute.amount),
                            }}
                            disallowPartialRefunds={true}
                        />
                    )}
                </ModalDialog>
            )}
            <DisputesTable
                section={props.section}
                accountSlug={props.accountSlug}
                disputesList={pageData.entries}
                loading={loading}
                sort={props.sort}
                onSortChanged={props.onSortChanged}
                user={user}
                onOpenModal={onOpenModal}
            />
            <PaginationSection
                pagination={props.pagination}
                onPaginationChanged={props.onPaginationChanged}
                totalItemsCount={pageData.totalCount}
                pageSizeOptions={['10', '20', '50']}
                style={{ margin: '2em 0 2em 2em' }}
                onWhiteBackground
            />
        </>
    )
}

export default withNavigation(DisputesPage)
