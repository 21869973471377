import React, { useMemo, FunctionComponent, createElement } from 'react'
import { List } from 'react-virtualized'
import { createDataTree, FieldValue, TreeLeafComponentProps } from './utils'

interface TreeProps {
    data: any[]
    idField: FieldValue
    parentField: FieldValue
    component: FunctionComponent<TreeLeafComponentProps>
    height: number
    width: number
    rowHeight: number
}

const VirtualizedTree: React.FC<TreeProps> = ({ data, idField, parentField, component, height, rowHeight, width }) => {
    const nestedData = useMemo(() => createDataTree(data, idField, parentField), [data, idField, parentField])
    const flattenedListData = useMemo(() => {
        const flattened = [] as any[]

        function flatten(data: any, depth = 0) {
            for (const item of data) {
                flattened.push({ data: item, depth: depth })
                if (item.childNodes?.length) {
                    flatten(item.childNodes, depth + 1)
                }
            }
        }

        flatten(nestedData, 1)

        return flattened as any[]
    }, [nestedData])

    return (
        <List
            width={width}
            height={height}
            rowHeight={rowHeight}
            autoWidth
            rowRenderer={({ key, index, style }) => {
                const row = flattenedListData[index]

                return createElement(component, { key, data: row.data, depth: row.depth, style, idField })
            }}
            rowCount={flattenedListData.length}
        />
    )
}

export default VirtualizedTree
