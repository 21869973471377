import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { History, Location } from 'history'
import { PageTitle } from 'uiComponents/typography'
import CopySearchListPage from 'copyEditor/searchList'
import CopyEditPage from 'copyEditor/editForm'
import SearchBar from 'uiComponents/search/searchBar'
import { BaseRouteParams, withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { match as RouteMatch } from 'react-router-dom'
import './copyEditor.scss'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import { useAppSelector } from 'store/hooks'
import { useMessages } from 'messagesContext'

interface CopyEditorPageProps {
    history: History
    location: Location
    navigation: Navigation
    match: RouteMatch<BaseRouteParams>
}

function CopyEditorPage(props: CopyEditorPageProps) {
    const user = useAppSelector((state) => state.auth.user)
    const { hideMessage, removeAllMessages, replaceMessages } = useMessages()
    
    const searchByOpts = [
        { value: 'text', name: 'Copy text' },
        { value: 'key', name: 'Copy key' },
    ]

    const onSearch = () => {
        // navigate to list even if search params haven't changed
        if (location.pathname.indexOf('engage/copy_editor/edit/') > -1) {
            props.history.push(`/account/${props.match.params.accountSlug}/engage/copy_editor/home${location.search}`)
        }
    }

    return (
        <div style={{ position: 'relative', marginBottom: '7em' }} id="copy-editor-page">
            <PageTitle>Copy Editor</PageTitle>
            <div>
                <SearchBar
                    searchByOptions={user?.isAdmin ? searchByOpts : searchByOpts.filter((o) => o.value !== 'slug')}
                    autoSearchByPatterns={[
                        [/ /, 'text'],
                        [/_/, 'key'],
                    ]}
                    defaultSearchBy="text"
                    minSearchLength={3}
                    maxSearchLength={130}
                    onSearch={onSearch}
                    replaceMessages={replaceMessages}
                    removeAllMessages={removeAllMessages}
                />
                <Switch location={props.location}>
                    <Route
                        path="/account/:accountSlug/engage/copy_editor/home"
                        render={() => (
                            <CopySearchListPage
                                history={props.history}
                                accountSlug={props.match.params.accountSlug}
                                replaceMessages={replaceMessages}
                            />
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/engage/copy_editor/edit/:copySlug"
                        render={() => (
                            <CopyEditPage
                                history={props.history}
                                accountSlug={props.match.params.accountSlug}
                                replaceMessages={replaceMessages}
                                hideMessage={hideMessage}
                            />
                        )}
                    />
                    <Route render={() => <Redirect to={`/account/${props.match.params.accountSlug}/engage/copy_editor/home`} />} />
                </Switch>
            </div>
            <BaseKnowledgeLink link='https://support.convious.com/help/how-to-use-the-copy-editor' />
        </div>
    )
}

export default withNavigation(CopyEditorPage)
