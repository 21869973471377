import React, { useState } from 'react'
import { Button } from '@mui/material'
import { ReplySolid, TimesSolid } from '@convious/icons'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import { ModalDialog } from 'uiComponents/popups/modal'
import { IOrder } from 'orders/schema'
import BulkCancelRefund from '.'
import { useHasFeature } from 'utils/useHasFeature'
import { ReplaceMessagesFunc } from 'messagesContext'

interface BulkCancelRefundButtonProps extends WithNavigationProps<BaseRouteParams> {
    orders: IOrder[]
    isCancellation?: boolean
    replaceMessages: ReplaceMessagesFunc
}

const BulkCancelRefundButton = ({
    orders,
    match,
    isCancellation = false,
    replaceMessages,
}: BulkCancelRefundButtonProps) => {
    const [open, setOpen] = useState(false)
    const { accountSlug } = match.params
    const hasBulkRefundFeature = useHasFeature({ featureName: 'ordersBulkRefund' })

    if (!hasBulkRefundFeature) {
        return null
    }

    return (
        <>
            {open && (
                <ModalDialog interactive fromTop="10%" className="bulk-cancel-refund-modal">
                    <BulkCancelRefund
                        accountSlug={accountSlug}
                        orders={orders}
                        isCancellation={isCancellation}
                        onClose={() => setOpen(false)}
                        replaceMessages={replaceMessages}
                    />
                </ModalDialog>
            )}
            <Button
                onClick={() => setOpen(true)}
                size="medium"
                color="error"
                variant="outlined"
                startIcon={isCancellation ? <TimesSolid /> : <ReplySolid />}
                data-testid={isCancellation ? 'bulk-cancel-button' : 'bulk-refund-button'}
                className="action-button"
            >
                {isCancellation ? 'Bulk cancel' : 'Bulk refund'}
            </Button>
        </>
    )
}

export default withNavigation(BulkCancelRefundButton)
