import React from 'react'
import { BaseRouteParams, withNavigation, WithNavigationProps } from 'hocs'
import { usePermission } from 'admin/hocs'
import { InlineTimePicker } from 'uiComponents/input'
import { IOrder } from 'orders/schema'
import { useUpdateVisitDateAndTimeMutation } from 'orders/reduxQueries'
import { addMessage } from 'uiComponents/messages/actions'
import { useDispatch } from 'react-redux'
import { convertDateFormat, convertTimeFormat } from 'utils/dates'

interface InlineVisitTimeProps extends WithNavigationProps<BaseRouteParams> {
    order: IOrder
    onUpdated?: (order: IOrder) => void
}

function InlineVisitTime({ order, match, onUpdated }: InlineVisitTimeProps) {
    const { accountSlug } = match.params
    const [updateDateTime] = useUpdateVisitDateAndTimeMutation()
    const dispatch = useDispatch()
    const { hasPermission } = usePermission()
    
    const canEditOrders =
        hasPermission('edit_orders', accountSlug) && order.status !== 'refunded' && order.status !== 'refunding'

    const saveTime = async (time: string) => {
        try {
            await updateDateTime({
                orderUuid: order.id,
                dateTime: null,
                visitTime: time || null,
            })
            if (onUpdated && time) {
                onUpdated({ ...order, visitTime: time })
            }
        } catch (e) {
            if (e.message === 'no_availability') {
                dispatch(addMessage('no_availability_error', 'error', 'No availability left for selected time.'))
            } else if (e.message === 'order_already_redeemed') {
                dispatch(
                    addMessage(
                        'already_redeemed_error',
                        'error',
                        'Cannot change visit time because order is already redeemed.',
                    ),
                )
            } else if (e.message === 'date_change_not_possible') {
                dispatch(addMessage('date_change_not_possible', 'error', 'Cannot change visit time for this order.'))
            } else {
                dispatch(
                    addMessage(
                        'visit_date_error',
                        'error',
                        'Oops! There was a problem with saving new visit details. Please try again.',
                    ),
                )
            }
        }
    }

    if (!order.visitTime) {
        return <>Any time</>
    }

    return (
        <InlineTimePicker
            id="time"
            key={order.id}
            value={convertDateFormat(order.visitTime, 'HH:mm:ss', 'HH:mm')}
            onSave={saveTime}
            disabled={!canEditOrders}
            requireConfirmation={true}
            confirmationMessage={(time) => `Have you checked the availability at ${convertTimeFormat(time!)}?`}
            required
            style={{ width: '100px' }}
        />
    )
}

export default withNavigation(InlineVisitTime)
