import * as React from 'react'
import styled from 'styled-typed'
import { match as RouteMatch } from 'react-router-dom'
import { History } from 'history'
import { Navigation } from 'navigation'
import { PageActions } from 'uiComponents/settingsContainer'
import { ActionButton } from 'uiComponents/buttons'
import { MessageKind, Message } from 'uiComponents/messages'
import { withNavigation } from 'hocs'
import { Row, ButtonWrapper } from 'uiComponents/pageElements'
import { withCurrency, Currency } from 'uiComponents/money/moneyHoc'
import { ArticleConfigurationService, ValidityException, Recurrence } from 'products/articleConfigurationService'
import { ArticleService as AdminArticleService } from 'admin/articleService'
import { ExceptionInfoFormSection } from 'products/validity/exceptions/exceptionInfoFormSection'
import { ExceptionDayTimeFormItem } from './exceptionDayTimeFormItem'
import { RelativeMessage } from 'uiComponents/messages'
import { ExceptionSummary } from './exceptionSummary'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { withFeatures } from 'features'
import { ArticleFromApi } from '../../types'

interface ExceptionFormProps {
    navigation: Navigation
    history: History
    match: RouteMatch<any>
    articleConfigurationService: ArticleConfigurationService
    articleService: AdminArticleService
    accountSlug: string
    exception?: ValidityException | null
    exceptionProducts?: ArticleFromApi[]
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    getCurrency: (accountSlug: string) => Currency
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, slug: string) => boolean
    className?: string
    prototype?: boolean
}

interface ExceptionFormState {
    exceptionName: string
    exceptionProducts: ArticleFromApi[]
    weekdays: string | null
    recurrence: Recurrence | null
    startDate: string | null
    endDate: string | null
    validate: boolean
    confirmDeletion: boolean
    showWarningMessage: boolean
    showDuplicateWarning: boolean
}

const ExceptionContainerBody = styled.div`
    flex-direction: column;
    flex: 1;
`

export const ConfirmationNotice = styled.div`
    font-size: 0.9em;
`

const exceptionSaveSuccessMessage: Message = {
    id: 'exception_save_sucess',
    status: 'success',
    text: 'The exception was successfully saved.',
    visible: true,
}

class ExceptionForm extends React.Component<ExceptionFormProps, ExceptionFormState> {
    constructor(props: ExceptionFormProps) {
        super(props)
        const { exception, exceptionProducts } = this.props
        this.state = {
            exceptionName: exception ? (this.props.prototype ? `Copy of ${exception.name}` : exception.name) : '',
            weekdays: exception ? exception.weekday : null,
            recurrence: exception ? exception.recurrence : 'WEEKLY',
            startDate: exception ? exception.appliesFrom : null,
            endDate: exception ? exception.appliesTo : null,
            exceptionProducts: exceptionProducts ? exceptionProducts : [],
            validate: false,
            confirmDeletion: false,
            showWarningMessage: true,
            showDuplicateWarning: false,
        }
    }

    setPageHeader = () => {
        this.props.setActiveSection('validityExceptions', 'Validity Exceptions')
    }

    componentDidMount() {
        this.setPageHeader()
    }

    componentDidUpdate(prevProps: Readonly<ExceptionFormProps>, prevState: Readonly<ExceptionFormState>) {
        if (this.props.exceptionProducts !== prevProps.exceptionProducts) {
            this.setState({
                exceptionProducts: this.props.exceptionProducts ?? [],
            })
        }
        if (prevProps.match.params.accountSlug !== this.props.match.params.accountSlug) {
            this.props.history.push(`/account/${this.props.match.params.accountSlug}/products/validity/exceptions/list`)
        }
    }

    checkFormValidity = () => {
        if (
            !this.state.exceptionName ||
            this.state.exceptionName.length < 1 ||
            this.state.exceptionProducts.length === 0 ||
            !this.areDateSettingsValid()
        ) {
            return false
        }
        return true
    }

    areDateSettingsValid = (): boolean => {
        if (!this.state.startDate || !this.state.endDate) {
            return false
        }
        if (
            this.state.startDate &&
            this.state.endDate &&
            new Date(this.state.endDate) < new Date(this.state.startDate)
        ) {
            return false
        }
        return true
    }

    onSave = async (evt: React.FormEvent<HTMLElement>) => {
        evt.preventDefault()
        this.props.removeAllMessages()
        this.setState({ validate: true })
        if (!this.checkFormValidity()) {
            this.props.replaceTopMessages('validation_error', 'error', 'Please check the validation errors below.')
            return
        }
        const { exceptionProducts, exceptionName, weekdays, recurrence, startDate, endDate } = this.state
        const { exception, accountSlug, prototype } = this.props
        const id = prototype ? null : exception ? exception.id : null
        const productIds = exceptionProducts.map((ep) => ep.id)
        const exceptionData = {
            id: id,
            name: exceptionName,
            account: accountSlug,
            articles: productIds,
            weekday: weekdays,
            recurrence: recurrence ? recurrence : 'WEEKLY',
            appliesFrom: startDate,
            appliesTo: endDate,
        }
        const responseData = await this.props.articleConfigurationService.saveValidityExeption(exceptionData)
        responseData.ifFailure(this.handleFailure).ifSuccess(() => {
            this.props.navigation.replaceWithMessages(
                [exceptionSaveSuccessMessage],
                `/account/${this.props.accountSlug}/products/validity/exceptions/list`,
            )
        })
    }

    handleFailure = () => {
        this.props.replaceTopMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        throw new Error('Failed to save exception')
    }

    onDuplicateException = () => {
        this.setState({ showDuplicateWarning: true })
    }

    onConfirmDuplication = () => {
        if (this.props.exception) {
            this.props.history.push(
                `/account/${this.props.accountSlug}/products/validity/exceptions/duplicate?copy_from=${this.props.exception.id}`,
            ) // tslint:disable-line
        }
    }

    updateExceptionName = (n: string) => {
        this.setState({ exceptionName: n })
    }

    updateExceptionProducts = (products: ArticleFromApi[]) => {
        this.setState({ exceptionProducts: products })
    }

    setDateRange = (fromDate: string | null, toDate: string | null) => {
        this.setState({
            startDate: fromDate,
            endDate: toDate,
        })
    }

    handleRecurrenceSelectChange = (recurrence: Recurrence) => {
        this.setState({ recurrence: recurrence })
    }

    handleWeekdayToggleChange = (weekdays: string[]) => {
        if (weekdays.length === 7) {
            this.setState({ weekdays: null })
        } else {
            this.setState({ weekdays: weekdays.toString() })
        }
    }

    onDeleteException = async () => {
        if (this.state.confirmDeletion) {
            if (this.props.exception) {
                try {
                    await this.props.articleConfigurationService.deleteValidityException(this.props.exception.id)
                    this.props.history.replace(`/account/${this.props.accountSlug}/products/validity/exceptions/list`)
                } catch {
                    this.props.replaceTopMessages(
                        'server_error',
                        'error',
                        'There was a problem deleting the exception.',
                    )
                }
            }
        } else {
            this.setState({ confirmDeletion: true })
        }
    }

    render() {
        const { accountSlug, exception, className, prototype } = this.props
        const {
            exceptionName,
            weekdays,
            recurrence,
            startDate,
            endDate,
            validate,
            exceptionProducts,
            showWarningMessage,
            showDuplicateWarning,
        } = this.state
        const formValid = this.checkFormValidity()

        return (
            <ExceptionContainerBody className={className} id="validity-exception-form">
                {showDuplicateWarning && (
                    <ConfirmationDialog
                        title="Duplicate validity exception?"
                        text="Validity exception will be duplicated with all its settings."
                        buttonText="Duplicate"
                        onCancel={() => this.setState({ showDuplicateWarning: false })}
                        onConfirm={this.onConfirmDuplication}
                    />
                )}
                <form noValidate style={{ width: '100%' }}>
                    <ExceptionInfoFormSection
                        exceptionProducts={exceptionProducts}
                        exception={!!exception}
                        prototype={prototype}
                        exceptionName={exceptionName}
                        onDuplicateException={this.onDuplicateException}
                        updateExceptionName={this.updateExceptionName}
                        updateProducts={this.updateExceptionProducts}
                        validate={validate}
                        articleService={this.props.articleService}
                        accountSlug={accountSlug}
                        replaceTopMessages={this.props.replaceTopMessages}
                    />
                    <ExceptionDayTimeFormItem
                        handleCalendarInputChange={this.setDateRange}
                        handleWeekdayToggleChange={this.handleWeekdayToggleChange}
                        handleRecurrenceSelectChange={this.handleRecurrenceSelectChange}
                        weekdays={weekdays}
                        recurrence={recurrence}
                        startDate={startDate}
                        endDate={endDate}
                        validate={validate}
                        startTime={null}
                        endTime={null}
                        handleDayTimeInputChange={() => {}}
                        patternType="strict"
                    />
                    <ExceptionSummary
                        name={exceptionName}
                        products={exceptionProducts}
                        from={startDate}
                        to={endDate}
                        weekdays={weekdays}
                        recurrence={recurrence}
                    />
                    {showWarningMessage && (
                        <RelativeMessage
                            kind="warn"
                            showMessage
                            onDismiss={() => this.setState({ showWarningMessage: false })}
                            style={{
                                paddingTop: '.85em',
                                paddingBottom: '.85em',
                                fontSize: '0.875em',
                                marginBottom: '2em',
                            }}
                        >
                            Use exceptions to define when your products should not be available.
                        </RelativeMessage>
                    )}
                    {this.state.confirmDeletion && (
                        <Row>
                            <ConfirmationNotice>Are you sure you want to delete this exception?</ConfirmationNotice>
                        </Row>
                    )}
                    <Row>
                        {this.props.exception &&
                            !this.props.prototype &&
                            this.props.hasPermission('edit_product_validity', accountSlug) && (
                                <PageActions align="left">
                                    <ActionButton
                                        type="button"
                                        kind="destructive"
                                        size="large"
                                        onClick={this.onDeleteException}
                                    >
                                        Delete
                                    </ActionButton>
                                    {this.state.confirmDeletion && (
                                        <ActionButton
                                            size="large"
                                            secondary
                                            onClick={() => {
                                                this.setState({ confirmDeletion: false })
                                            }}
                                        >
                                            Cancel
                                        </ActionButton>
                                    )}
                                </PageActions>
                            )}
                        <ButtonWrapper>
                            <ActionButton
                                size="large"
                                secondary
                                onClick={() => {
                                    this.props.history.push(`/account/${accountSlug}/products/validity/exceptions/list`)
                                }}
                            >
                                {this.props.hasPermission('edit_product_validity', accountSlug) ? 'Cancel' : 'Back'}
                            </ActionButton>
                            {this.props.hasPermission('edit_product_validity', accountSlug) && (
                                <ActionButton
                                    id="saveOverride"
                                    type="button"
                                    size="large"
                                    doneText={formValid ? undefined : 'Not saved'}
                                    onClick={this.onSave}
                                    style={{ marginLeft: '1.5em' }}
                                >
                                    Save
                                </ActionButton>
                            )}
                        </ButtonWrapper>
                    </Row>
                </form>
            </ExceptionContainerBody>
        )
    }
}

export default withFeatures(withNavigation(withCurrency(ExceptionForm)))
