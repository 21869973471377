import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import { match as RouteMatch, withRouter, RouteComponentProps } from 'react-router-dom'
import { parseSearch } from 'navigation'
import { withFeatures } from 'features'
import { SnippetService } from 'snippet/snippetService'
import { LoggingService, EventType } from 'http/loggingService'
import { LocaleService } from 'engageTools/studio/localesService'
import { ImagesService } from 'http/imagesService'
import { productURLSlugs } from 'engageTools/studio/shared'
import { MobileDeviceWarning } from 'uiComponents/studio/mobileDeviceWarning'
import {
    WonderbarConfiguration,
    ModalWindowConfiguration,
    Languages,
    StudioStep,
    TriggerButtonConfiguration,
    CheckoutConfiguration,
    UrlRules,
    Translation,
    ProductName,
    TargetType,
    ProductConfig,
} from 'engageTools/studio/schema'
import { ComponentsService, ConfigurationVersions } from 'engageTools/studio/componentsService'
import Form from 'engageTools/studio/form'
import Preview from 'engageTools/studio/preview/preview'
import { PreviewSize } from 'uiComponents/studio/preview/components'
import Header from 'uiComponents/studio/header'
import { Page, Side, Body } from 'uiComponents/studio/pageComponents'
import './index.css'
import AudienceConfirmation from './audienceConfirmation'
import { useMessages } from 'messagesContext'

export type MenuItem = 'style' | 'text' | 'targeting' | 'settings'

const menuItemsOld: MenuItem[] = ['text', 'style', 'targeting', 'settings']

const menuItems: MenuItem[] = ['style', 'text', 'settings', 'targeting']

export interface StudioParams {
    step: StudioStep
    id: string
}

interface StudioProps {
    product: ProductName
    accountSlug: string
    previewEndpoint: string
    localeService: LocaleService
    snippetService: SnippetService
    match: RouteMatch<StudioParams>
    componentsService: ComponentsService
    loggingService: LoggingService
    imagesService: ImagesService
    hasFeature: (feature: string, accountSlug: string) => boolean
}

const Studio: React.FC<StudioProps & RouteComponentProps<{}>> = ({
    product,
    accountSlug,
    componentsService,
    imagesService,
    hasFeature,
    location,
    match,
    loggingService,
    history,
    previewEndpoint,
    localeService,
}) => {
    const [previewSize, setPreviewSize] = useState<PreviewSize>('desktop')
    const formLoading = false
    const [translationsLoading, setTranslationsLoading] = useState(false)
    const [componentValues, setComponentValues] = useState<
        WonderbarConfiguration | ModalWindowConfiguration | TriggerButtonConfiguration | CheckoutConfiguration | null
    >(null)
    const [, setProductConfig] = useState<ProductConfig | null>(null)
    const targetDevice = (parseSearch(location.search).device as TargetType) || 'any'
    const [translations, setTranslations] = useState<Translation[]>([])
    const [languages, setLanguages] = useState<Languages | null>(null)
    const [urlRules, setUrlRules] = useState<UrlRules | null>(null)
    const [draftId, setDraftId] = useState('')
    const [triggerPublish, setTriggerPublish] = useState(false)
    const [triggerSaveDraft, setTriggerSaveDraft] = useState(false)
    const [currentStep, setCurrentStep] = useState<StudioStep>(
        product === 'checkout' ? 'style' : match.params.step || 'style',
    )
    const [activeLanguage, setActiveLanguage] = useState('')
    const [currentConfig, setCurrentConfig] = useState<ConfigurationVersions | null>(null)
    const timeoutHandler = useRef<number | null>(null)
    const { removeAllMessages, replaceMessages } = useMessages()

    const logEvent = (eventType: EventType, additionalData?: { [key: string]: string }) => {
        const eventData = {
            category: `engage_tools_${product}`,
            product: product,
        }
        if (additionalData) {
            loggingService.logAction(eventType, { ...eventData, ...additionalData })
        } else {
            loggingService.logAction(eventType, eventData)
        }
    }

    useEffect(() => {
        logEvent('studio_opened')
        const currentStepParam = match.params.step
        const step = product === 'checkout' ? 'style' : currentStepParam || 'style'
        if (step !== 'success') {
            setCurrentStep(currentStepParam)
        }
        setPreviewSize(targetDevice === 'mobile' ? 'mobile' : 'desktop')
        setCurrentStep(step)
        return () => {
            if (timeoutHandler.current) {
                clearTimeout(timeoutHandler.current)
            }
        }
    }, [])

    useEffect(() => {
        const currentStepParam = match.params.step
        if (currentStepParam !== 'success') {
            setCurrentStep(currentStepParam)
            logEvent('studio_step_accessed', { step_name: currentStepParam })
        }
    }, [location])

    const onPreviewTranslationsLoadToggle = (on: boolean) => {
        setTranslationsLoading(on)
    }

    return (
        <>
            <Page
                className="studio"
                id={`${product}-wizard-page`}
                style={{ position: 'relative', justifyContent: 'center' }}
            >
                <MobileDeviceWarning
                    history={history}
                    returnPath={`/account/${accountSlug}/${productURLSlugs[product]}/summary`}
                />
                <Side>
                    <AudienceConfirmation>
                        {({ handleSubmit }) => (
                            <Form
                                accountSlug={accountSlug}
                                hasFeature={hasFeature}
                                menuItems={product === 'modal_window' ? menuItems : menuItemsOld}
                                componentsService={componentsService}
                                imagesService={imagesService}
                                replaceTopMessages={replaceMessages}
                                removeAllMessages={removeAllMessages}
                                loading={formLoading}
                                translationsLoading={translationsLoading}
                                translations={translations}
                                targetDevice={targetDevice}
                                configurationId={match.params.id}
                                product={product}
                                onProductConfigUpdate={setProductConfig}
                                onComponentValuesUpdate={setComponentValues}
                                onLanguagesUpdate={setLanguages}
                                onTranslationsUpdate={setTranslations}
                                onUrlRulesUpdate={setUrlRules}
                                setDraftId={setDraftId}
                                publish={triggerPublish}
                                handleSubmit={handleSubmit}
                                saveDraft={triggerSaveDraft}
                                logEvent={logEvent}
                                currentStep={currentStep}
                                activeLanguage={activeLanguage}
                                setActiveLanguage={setActiveLanguage}
                                setCurrentProductConfiguration={setCurrentConfig}
                            />
                        )}
                    </AudienceConfirmation>
                </Side>
                <Body>
                    <Header
                        accountSlug={accountSlug}
                        previewSize={previewSize}
                        onPreviewSizeChange={setPreviewSize}
                        targetDevice={targetDevice}
                        replaceMessages={replaceMessages}
                        removeAllMessages={removeAllMessages}
                        studioType="engageTools"
                        onPublish={() => {
                            setTriggerPublish(true)
                            timeoutHandler.current = window.setTimeout(() => {
                                setTriggerPublish(false)
                            }, 1000)
                        }}
                        onSaveDraft={() => {
                            setTriggerSaveDraft(true)
                            timeoutHandler.current = window.setTimeout(() => {
                                setTriggerSaveDraft(false)
                            }, 1000)
                        }}
                        configIsPublished={
                            (currentConfig?.current?.publishedAt && !currentConfig?.current?.unpublishedAt) || false
                        }
                    />
                    <Preview
                        product={product}
                        accountSlug={accountSlug}
                        previewSize={previewSize}
                        previewEndpoint={previewEndpoint}
                        localeService={localeService}
                        toggleLoad={onPreviewTranslationsLoadToggle}
                        componentValues={componentValues}
                        languages={languages}
                        translations={translations}
                        urlRules={urlRules}
                        draftId={draftId}
                        handleInitialTranslations={setTranslations}
                        activeLanguage={activeLanguage}
                    />
                </Body>
            </Page>
        </>
    )
}

export default withFeatures(withRouter(Studio))
