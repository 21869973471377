import * as React from 'react'
import { usePermission } from './hocs'

interface PermissionProps {
    name: string
    invert?: boolean
    children: React.ReactNode
    accountSlug: string
}

function PermissionRequired({ name, invert, children, accountSlug }: PermissionProps) {
    const { hasPermission } = usePermission()
    let permitted = hasPermission(name, accountSlug)
    if (invert) {
        permitted = !permitted
    }

    if (!permitted || !children) {
        return null
    }

    return <>{children}</>
}

export default PermissionRequired
