import { History } from 'history'
import { BaseRouteParams, withNavigation } from 'hocs'
import { filter } from 'lodash'
import { useMessages } from 'messagesContext'
import { Navigation } from 'navigation'
import ListItem from 'notificationCampaigns/components/listItem'
import { NotificationCampaignListItem, NotificationCampaignsService } from 'notificationCampaigns/types'
import * as React from 'react'
import { RouteComponentProps, useParams } from 'react-router-dom'
import { ActionButton } from 'uiComponents/buttons'
import { TableLoader } from 'uiComponents/loaders'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import SearchField, { SearchContainer } from 'uiComponents/search/searchField'
import { Container, ContainerHeading, HeadingSectionName } from 'uiComponents/settingsContainer'
import { HeaderRow, InlineDataTable, TableHeader } from 'uiComponents/table'
import { NoResultsRow } from 'uiComponents/table/noResultsRow'
import { PageTitle } from 'uiComponents/typography'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'

interface CampaignsOverviewPageProps {
    notificationCampaignsService: NotificationCampaignsService
    history: History
    navigation: Navigation
}

const CampaignOverviewPage = ({
    notificationCampaignsService,
    history,
    navigation,
}: CampaignsOverviewPageProps & RouteComponentProps<{}>) => {
    const { accountSlug } = useParams<BaseRouteParams>()
    const [items, setItems] = React.useState<NotificationCampaignListItem[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = React.useState<boolean>(false)
    const [itemToDelete, setItemToDelete] = React.useState<string>('')
    const { replaceMessages } = useMessages()
    const searchQuery = React.useMemo(() => {
        if (navigation.query() && navigation.query().q) {
            return navigation.query().q.toLowerCase()
        }

        return ''
    }, [navigation.query()])

    React.useEffect(() => {
        fetch()
    }, [accountSlug])

    const fetch = async () => {
        setLoading(true)

        try {
            const result = await notificationCampaignsService.fetchAll(accountSlug)
            setItems(result)

            setLoading(false)
        } catch {
            replaceMessages('server_error', 'error', 'Oops! We could not retreive the campaigns. Please try again.')
            setLoading(false)
        }
    }

    const onAddNew = () => {
        history.push(`/account/${accountSlug}/engage/notification-campaigns/studio/name`)
    }

    const filteredItems = (): NotificationCampaignListItem[] => {
        return filter(items, (item) => {
            return (
                item.name.toLowerCase().includes(searchQuery) ||
                item.title.toLowerCase().includes(searchQuery) ||
                item.message.toLowerCase().includes(searchQuery)
            )
        })
    }

    const onStatusUpdateResult = (result: boolean, message?: string) => {
        if (result) {
            replaceMessages('success', 'success', 'Campaign status updated.')
            fetch()
        } else {
            replaceMessages(
                'server_error',
                'error',
                `Oops! We could not update the campaign status. Please try again. ${message}`,
            )
        }
        setLoading(false)
    }

    const setCampaignState = async (campaignId: string, state: 'Active' | 'Draft') => {
        setLoading(true)
        try {
            const result = await notificationCampaignsService.setCampaignState(
                { caid: campaignId, status: state },
                accountSlug,
            )

            onStatusUpdateResult(result)
        } catch (error) {
            onStatusUpdateResult(false, error.message)
        }
    }

    const renderItems = () => {
        return filteredItems().map((item, index) => (
            <ListItem
                key={`${index}`}
                item={item}
                onDelete={async (itemId: string) => {
                    setItemToDelete(itemId)
                    setShowDeleteConfirmationDialog(true)
                }}
                onEdit={() => {
                    history.push(`/account/${accountSlug}/engage/notification-campaigns/studio/name/${item.id}`)
                }}
                onSetActive={async () => {
                    setCampaignState(item.id, 'Active')
                }}
                onSetInactive={async () => {
                    setCampaignState(item.id, 'Draft')
                }}
            />
        ))
    }

    const onConfirmDelete = () => {
        if (itemToDelete && itemToDelete.length) {
            notificationCampaignsService.deleteCampaign(accountSlug, itemToDelete).then(() => {
                fetch()
                setItemToDelete('')
                setShowDeleteConfirmationDialog(false)
            })
        } else {
            replaceMessages('server_error', 'error', 'Oops! We could not delete the app campaign. Please try again.')

            setItemToDelete('')
            setShowDeleteConfirmationDialog(false)
        }
    }

    const columnWidths = ['8em', '15em', null, '15em', '11em']
    return (
        <div style={{ position: 'relative' }} id="tap-messaging-page">
            {showDeleteConfirmationDialog && (
                <ConfirmationDialog
                    title="Are you sure you want to delete this campaign?"
                    buttonText="Delete"
                    destructive
                    onCancel={() => setShowDeleteConfirmationDialog(false)}
                    onConfirm={onConfirmDelete}
                />
            )}
            <PageTitle>App Campaigns</PageTitle>
            <Container style={{ marginBottom: 0 }}>
                <ContainerHeading style={{ paddingLeft: '1.5em' }}>
                    <HeadingSectionName className="active" to="">
                        App Campaigns
                    </HeadingSectionName>
                </ContainerHeading>
                <SearchContainer style={{ margin: '1.5em' }}>
                    <SearchField placeholder="Search by name" />
                    <ActionButton kind="action" size="medium" onClick={onAddNew} id="add-new-campaign">
                        + Add new
                    </ActionButton>
                </SearchContainer>
                <InlineDataTable columnWidths={columnWidths} id="list">
                    <HeaderRow>
                        <TableHeader>Schedule type</TableHeader>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Message</TableHeader>
                        <TableHeader>State</TableHeader>
                        <TableHeader nonInteractive />
                    </HeaderRow>
                    <>
                        {loading && <TableLoader />}
                        {!loading && renderItems()}
                        {!loading && filteredItems().length === 0 && (
                            <NoResultsRow text="There are no notification campaigns yet." />
                        )}
                    </>
                </InlineDataTable>
            </Container>
            <BaseKnowledgeLink link="https://support.convious.com/help/how-to-create-push-notification-campaigns-in-your-control-panel" />
        </div>
    )
}

export default withNavigation(CampaignOverviewPage)
