import React from 'react'
import styled, { useTheme } from 'styled-typed'
import { Headline, Subtitle } from 'uiComponents/typography'

interface PageTitleProps {
    children: React.ReactNode
}

export function PageTitle(props: PageTitleProps & React.HTMLAttributes<HTMLDivElement>) {
    const { children, ...rest } = props
    const theme = useTheme()

    return (
        <Headline
            size={1}
            style={{ margin: '0', color: theme.colors.richBlack, marginBottom: '2rem' }}
            {...(rest as any)}
        >
            {children}
        </Headline>
    )
}

interface PageHeadingProps {
    noPadding?: boolean
    fullWidth?: boolean
    children: React.ReactNode
}

const SubtitleWithStyles = styled(Subtitle)<PageHeadingProps>`
    max-width: ${(p) => (p.fullWidth ? '1200px' : '35em')};
    margin: ${(p) => (p.noPadding ? '0' : '1em 0 2.5em 0')};
`

export function PageHeading(props: PageHeadingProps & React.HTMLAttributes<HTMLDivElement>) {
    const { children, noPadding, fullWidth, ...rest } = props
    return (
        <SubtitleWithStyles noPadding={noPadding} fullWidth={fullWidth} {...rest}>
            {children}
        </SubtitleWithStyles>
    )
}

export const SecondaryText = styled.span`
    color: ${(props) => props.theme.colors.textLight};
    font-size: 0.875rem;
    font-weight: lighter;
`

export const LightText = styled.span`
    font-weight: lighter;
    font-size: 1rem;
    line-height: 1.3rem;
    a,
    a:hover {
        color: ${(props) => props.theme.colors.textDark};
    }
`

export const Bold = styled.span`
    font-weight: bold;
`

export const Italic = styled.span`
    font-style: italic;
`

export const RegularText = styled.span`
    font-size: 0.875rem;
`

export const SmallText = styled.span`
    font-size: 0.775rem;
`

export const LinkText = styled.div`
    font-size: 0.8em;
    font-weight: lighter;
    color: ${(props) => props.theme.colors.textLight};
    white-space: nowrap;
    margin-top: 1em;
    span {
        color: ${(props) => props.theme.colors.boyBlue};
        cursor: pointer;
        text-decoration: underline;
    }
`

export const StyledATag = styled.a`
    color: ${(props) => props.theme.colors.boyBlue};
`
