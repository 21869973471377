import * as React from 'react'
import { Suspense } from 'react'
import { Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { RequireAuth } from 'routes/guards/requireAuth'
import UserPilotScript from 'userPilot'
import GoogleTranslateScript from 'googleTranslate'
import BreakPoint from '../utils/mediaQuery'
import HubSpot from 'utils/hubspot'
import DeveloperRoutes, { ALL_DEVELOPER_ROUTES } from './developerTools'
import AuthRoutes, { ALL_AUTH_ROUTES } from './auth'
import IntegrationRoutes, { ALL_INTEGRATION_ROUTES } from './integration'
import AppRoutes from './appRoutes'
import { history, navigation } from 'middleware'
import { RequiresCreatedAccount } from 'signUp/requiresCreatedAccount'
import UnauthorizedUserRoutes, { ALL_UNAUTHORIZED_USER_ROUTES } from './unauthorizedUser'
import UnlessWidget from 'unlessWidget'
import { PageLoader } from 'uiComponents/loaders'
import { FaroRoute } from '@grafana/faro-react'
import { MessagesProvider } from 'messagesContext'

const Routes = () => {
    return (
        <Suspense fallback={<PageLoader />}>
            <ConnectedRouter history={history}>
                <MessagesProvider>
                    <BreakPoint>
                        <Switch>
                            <FaroRoute path={ALL_DEVELOPER_ROUTES}>
                                <DeveloperRoutes />
                            </FaroRoute>
                            <FaroRoute path={ALL_INTEGRATION_ROUTES}>
                                <IntegrationRoutes />
                            </FaroRoute>

                            <FaroRoute path={ALL_UNAUTHORIZED_USER_ROUTES}>
                                <UnauthorizedUserRoutes />
                            </FaroRoute>

                            <FaroRoute path={ALL_AUTH_ROUTES}>
                                <AuthRoutes />
                            </FaroRoute>

                            <FaroRoute>
                                <RequireAuth>
                                    <RequiresCreatedAccount>
                                        <Switch>
                                            <AppRoutes />
                                        </Switch>
                                    </RequiresCreatedAccount>
                                </RequireAuth>
                            </FaroRoute>
                        </Switch>
                        <HubSpot />
                        <UserPilotScript history={history} navigation={navigation} />
                        <GoogleTranslateScript />
                        <UnlessWidget />
                    </BreakPoint>
                </MessagesProvider>
            </ConnectedRouter>
        </Suspense>
    )
}

export default Routes
