import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Location, History } from 'history'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { PageTitle } from 'uiComponents/typography'
import { Sorting, Pagination } from 'uiComponents/table'
import { CompareRangeQuery } from 'uiComponents/popups/comparisonDateRangePicker/schema'
import { PaypalService } from 'orders/paypalService'
import DisputesPage from './disputesPage'
import { Container, ContainerHeading, FullWidthContainerBody, HeadingSectionName } from 'uiComponents/settingsContainer'
import { NavigationBack } from 'uiComponents/navigation/navigationBack'
import DateRangePicker from 'uiComponents/popups/comparisonDateRangePicker'
import { Checkbox } from 'uiComponents/input'
import { OrdersService } from 'orders/ordersService'
import PresetDateRangeElement, { PresetType } from 'uiComponents/popups/presetsElement'
import { dateRangeWithName, DateRange, DateRangeName, last30days } from 'dateRanges'
import { useAppSelector } from 'store/hooks'
import { useMessages } from 'messagesContext'
import './styles.scss'

interface PaypalDisputesPageProps {
    history: History
    location: Location
    dateRange: DateRange
    onDateRangeChanged: (dr: DateRange, compareRange: CompareRangeQuery) => void
    sort: Sorting
    onSortChanged: (s: Sorting) => void
    pagination: Pagination
    onPaginationChanged: (p: Pagination) => void
    accountSlug: string
    navigation: Navigation
    match: RouteMatch<{}>
    paypalService: PaypalService
    ordersService: OrdersService
}

function PaypalDisputesPage(props: PaypalDisputesPageProps) {
    const query = props.navigation.query()
    const [activeSection, setActiveSection] = useState<string>('')
    const [allPartners, setAllPartners] = useState<boolean>(!!query.showAllPartners)
    const user = useAppSelector((state) => state.auth.user)
    const { removeAllMessages, replaceMessages } = useMessages()

    const onAllPartnersChange = () => {
        props.navigation.addQueryWithReplace({
            showAllPartners: allPartners ? null : 'true',
        })
        setAllPartners(!allPartners)
    }

    const navigateToOrders = () => {
        props.history.push(`/account/${props.accountSlug}/orders/transactions/${location.search}`)
    }

    const presetName = 'activeDisputesDateRange'
    const defaultPreset: PresetType = 'last30days'
    const presetOptions: PresetType[] = ['last30days', 'last90days', 'lifetime']

    const range = query.activeDisputesDateRange || 'last30days'
    const activeDateRange = dateRangeWithName(range as DateRangeName) || last30days()

    return (
        <div style={{ position: 'relative' }}>
            <PageTitle>PayPal Disputes</PageTitle>
            {activeSection === 'resolved' && (
                <DateRangePicker
                    range={props.dateRange}
                    onChange={props.onDateRangeChanged}
                    style={{ padding: '0.1rem 0' }}
                />
            )}
            {activeSection === 'active' && (
                <div className='preset-wrapper'>
                    <PresetDateRangeElement presetName={presetName} default={defaultPreset} options={presetOptions} />
                </div>
            )}
            <div className='navigation-wrapper'>
                <NavigationBack onClick={navigateToOrders} text="Back to orders" />
                {user?.isAdmin && (
                    <div className='all-partners-toggle'>
                        <Checkbox checked={!!query.showAllPartners} onChange={onAllPartnersChange} />
                        <span>Show all partners&quot; disputes</span>
                    </div>
                )}
            </div>
            <Container>
                <ContainerHeading style={{ paddingLeft: '1.5em' }}>
                    <HeadingSectionName
                        className={activeSection === 'active' ? 'active' : ''}
                        to={`/account/${props.accountSlug}/orders/paypal/disputes/active/${location.search}`}
                    >
                        Active disputes
                    </HeadingSectionName>
                    <HeadingSectionName
                        className={activeSection === 'resolved' ? 'active' : ''}
                        to={`/account/${props.accountSlug}/orders/paypal/disputes/resolved/${location.search}`}
                    >
                        Resolved disputes
                    </HeadingSectionName>
                </ContainerHeading>
                <Switch location={props.location}>
                    <Route
                        path="/account/:accountSlug/orders/paypal/disputes/active"
                        render={() => (
                            <FullWidthContainerBody>
                                <DisputesPage
                                    section="active"
                                    onSectionChange={(s) => setActiveSection(s)}
                                    history={props.history}
                                    accountSlug={props.accountSlug}
                                    sort={props.sort}
                                    onSortChanged={props.onSortChanged}
                                    pagination={props.pagination}
                                    onPaginationChanged={props.onPaginationChanged}
                                    dateRange={activeDateRange}
                                    paypalService={props.paypalService}
                                    ordersService={props.ordersService}
                                    showAllPartners={!!query.showAllPartners && (user?.isAdmin ?? false)}
                                    replaceMessages={replaceMessages}
                                    removeAllMessages={removeAllMessages}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/orders/paypal/disputes/resolved"
                        render={() => (
                            <FullWidthContainerBody>
                                <DisputesPage
                                    section="resolved"
                                    onSectionChange={(s) => setActiveSection(s)}
                                    history={props.history}
                                    accountSlug={props.accountSlug}
                                    sort={props.sort}
                                    onSortChanged={props.onSortChanged}
                                    pagination={props.pagination}
                                    onPaginationChanged={props.onPaginationChanged}
                                    dateRange={props.dateRange}
                                    paypalService={props.paypalService}
                                    ordersService={props.ordersService}
                                    showAllPartners={!!query.showAllPartners && (user?.isAdmin ?? false)}
                                    replaceMessages={replaceMessages}
                                    removeAllMessages={removeAllMessages}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        render={() => <Redirect to={`/account/${props.accountSlug}/orders/paypal/disputes/active`} />}
                    />
                </Switch>
            </Container>
        </div>
    )
}

export default withNavigation(PaypalDisputesPage)
