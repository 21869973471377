import React, { FC, useCallback, useState } from 'react'
import { ChevronLeftSolid, ChevronRightSolid } from '@convious/icons'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { PageTitle } from 'uiComponents/typography'
import { FormItem } from 'uiComponents/form/formElements'
import { Button } from '@mui/material'
import CapacityReportTimeline from './components/CapacityReportTimeline'
import CapacityReportFilters from './components/CapacityReportFilters'
import { useGetAccountLocationsQuery } from 'orders/reduxQueries'
import { useAppSelector } from 'store/hooks'
import { getActiveAccount } from 'preferences/selectors'
import BaseKnowledgeLink from 'uiComponents/typography/BaseKnowledgeLink'
import './capacityReportPage.scss'

const CapacityReport: FC = () => {
    const accountSlug = useAppSelector(getActiveAccount)
    if (!accountSlug) throw new Error('No account selected')
    const { data: locationData } = useGetAccountLocationsQuery({ accountSlug })
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const handleDateChange = useCallback((date: Date) => {
        setSelectedDate(date)
    }, [])

    const stepDate = useCallback(
        (step: 'increment' | 'decrement') => () => {
            const date = new Date(selectedDate)
            const newDate = new Date(date)

            if (step === 'increment') newDate.setDate(date.getDate() + 1)
            else newDate.setDate(date.getDate() - 1)

            handleDateChange(newDate)
        },
        [selectedDate, handleDateChange],
    )

    const hasMoreThanOneLocation = locationData?.length && locationData?.length > 1
    const shouldShowFilters = hasMoreThanOneLocation

    return (
        <>
            <PageTitle>Capacity</PageTitle>
            <div className="capacity-controls">
                <div className="capacity-controls__left">{shouldShowFilters && <CapacityReportFilters />}</div>
                <div className="capacity-controls__right">
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="medium"
                        onClick={() => handleDateChange(new Date())}
                    >
                        Today
                    </Button>
                    <FormItem className="date-picker" htmlFor="validFrom">
                        <DatePicker
                            id="selectedDate"
                            date={selectedDate ? new Date(selectedDate) : null}
                            onChange={handleDateChange}
                        />
                    </FormItem>
                    <Button
                        className="day-step-button"
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={stepDate('decrement')}
                    >
                        <ChevronLeftSolid />
                    </Button>
                    <Button
                        className="day-step-button"
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={stepDate('increment')}
                    >
                        <ChevronRightSolid />
                    </Button>
                </div>
            </div>
            <CapacityReportTimeline selectedDate={selectedDate} />
            <BaseKnowledgeLink link="https://support.convious.com/help/capacity-and-admission" />
        </>
    )
}

export default CapacityReport
