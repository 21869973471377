import * as React from 'react'
import { InlineEdit, InlineUpload } from 'uiComponents/input'
import { IOrderItems } from 'orders/schema'
import { usePermission } from 'admin/hocs'
import { useUpdateApfFieldMutation, useUploadApfPhotoMutation } from 'orders/reduxQueries'
import { FieldType } from 'studioAPF/schema'
import { DatePicker } from 'uiComponents/popups/datePickerInput'
import { format } from 'date-fns'
import { delay } from 'utils'
import { EditFieldLoader } from 'uiComponents/loaders'
import { formatFromBoolValue, formatToBoolValue } from './utils'
import { DateFormats, parseDate } from 'utils/dates'
import { ReplaceMessagesFunc } from 'messagesContext'

interface UserInputFieldProps {
    accountSlug: string
    fieldName: string
    placeholder: string
    value: string
    type: FieldType
    item: IOrderItems
    replaceMessages: ReplaceMessagesFunc
    hideMessage: (id: string) => void
    editable?: boolean
    setEditable: React.Dispatch<React.SetStateAction<boolean>>
}

function APFInput(props: UserInputFieldProps) {
    const {
        item,
        placeholder,
        value,
        fieldName,
        type,
        replaceMessages,
        hideMessage,
        accountSlug,
        setEditable,
    } = props
    const [uploadApfPhoto] = useUploadApfPhotoMutation()
    const [updateApfField, { isError, isSuccess, isLoading }] = useUpdateApfFieldMutation()
    const { hasPermission } = usePermission()
    
    const canBeEdited =
        hasPermission('edit_orders', accountSlug) &&
        !!item.barcode &&
        !['refunded', 'refunding'].includes(item.barcode.status)

    React.useEffect(() => {
        const handleMutationResponse = async () => {
            if (isError) {
                replaceMessages('error', 'error', 'Oops, unable update field. Please try again.')
                await delay(4000)
                hideMessage('error')
            } else if (isSuccess) {
                replaceMessages('success', 'success', 'Field successfully updated.')
                await delay(4000)
                hideMessage('success')
            }
        }

        handleMutationResponse()
    }, [isError, isSuccess])

    React.useEffect(() => {
        if (isLoading) {
            setEditable(false)
        } else {
            setEditable(true)
        }
    }, [isLoading])

    const onEditAccept = async (v: string) => {
        const newValue = type === 'bool' ? formatToBoolValue(v) : v
        if (!item.barcode) {
            return
        }

        await updateApfField({
            ticketUuid: item.id,
            fieldName: fieldName,
            fieldValue: newValue,
        })
    }

    return (
        <div>
            {isLoading ? (
                <EditFieldLoader />
            ) : (
                <>
                    {type !== 'image' && type !== 'date' && (
                        <InlineEdit
                            id={item.id + fieldName}
                            value={type === 'bool' ? formatFromBoolValue(value) : value}
                            placeholder={canBeEdited ? placeholder : <>&mdash;</>}
                            onEditAccept={onEditAccept}
                            limitedTextWidth="18em"
                            disabled={!canBeEdited || !props.editable}
                            maskData={false}
                            responsiveWidth
                            disabledOnEmpty
                        />
                    )}
                    {type === 'image' && (
                        <InlineUpload
                            id={item.id + fieldName}
                            value={value}
                            placeholder={canBeEdited ? placeholder : <>&mdash;</>}
                            fileSizeLimit={16}
                            onUpload={async (file: File) =>
                                (await uploadApfPhoto({ file }).unwrap()) ?? { file_id: '', file_name: '' }
                            }
                            onEditAccept={onEditAccept}
                            limitedTextWidth="18em"
                            disabled={!canBeEdited || !props.editable}
                            allowCameraCapture
                        />
                    )}
                    {type === 'date' && (
                        <DatePicker
                            date={parseDate(value)}
                            inlineEdit
                            modalDatePicker
                            onInlineEditAccept={(newDate) => {
                                onEditAccept(format(newDate, 'dd.MM.yyyy'))
                            }}
                            disabled={!canBeEdited || !props.editable}
                            inlineEditDateFormat={DateFormats.LONG_DATE}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default APFInput
