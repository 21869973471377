import React, { useState, ReactNode, useMemo } from 'react'
import styled from 'styled-typed'
import { MessageKind } from 'uiComponents/messages'
import { Text, StatusType } from 'uiComponents/typography'
import { UploadInput } from 'uiComponents/input/upload'
import { DownloadElement } from 'uiComponents/input/downloadComponent'
import { UploadDetails } from 'venue/bookingCodes/bookingCodesService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCheckCircle, faUndoAlt } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/pro-light-svg-icons'
import { faFileTimes } from '@fortawesome/pro-regular-svg-icons'
import { Col, Row } from 'uiComponents/flex'
import { addSeparators, conviousEmail } from 'utils'
import { withFeatures } from 'features'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { BookingCodesService, UploadType } from './bookingCodesService'
import { delay } from 'utils'
import DownloadLink from 'uiComponents/downloadLink'
import { LoginService } from 'http/loginService'
import { ReactTable, TableColumn } from 'uiComponents/table/reactTable'
import { Sorting } from 'uiComponents/table'
import { DateFormats, formatISOString, parseISODate } from 'utils/dates'
import { useAppSelector } from 'store/hooks'

const FileNameAsLink = styled.span`
    color: ${(props) => props.theme.colors.boyBlue};
    text-decoration: underline;
    cursor: pointer;
`

const deletionPossibleFromDate = new Date(2020, 9, 3)

export const uploadItemStatuMap = {
    completed: {
        text: 'Successfully processed',
        style: 'success',
        icon: faCheckCircle,
    },
    error: { text: 'Upload failed', style: 'error', icon: faUndoAlt },
    expired: { text: 'Upload failed', style: 'error', icon: faUndoAlt },
    generating_error: {
        text: 'Code generation failed',
        style: 'error',
        icon: faUndoAlt,
    },
    pending: { text: 'Pending', style: 'processing', icon: faSpinner },
    deleted: { text: 'Codes removed', style: 'info', icon: faFileTimes },
}

interface UploadPageProps {
    accountSlug: string
    uploadType: UploadType
    uploadsHistoryList: UploadDetails[]
    exampleCSVHref: string
    exampleFileName: string
    loading: boolean
    bookingCodesService: BookingCodesService
    backofficeEndpoint: string
    loginService: LoginService
    onUpload: (file: File) => Promise<UploadDetails>
    reloadUploadsHistoryList: () => void
    onUploadError: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasFeature: (feature: string, accountSlug?: string) => boolean
    loadData?: () => void
    count?: number
    sort?: Sorting
}

function UploadPage(props: UploadPageProps) {
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [deleteItemId, setDeleteItemId] = useState<string>('')
    const [warningText, setWarningText] = useState<string | ReactNode>('')
    const [loadingModal, setLoadingModal] = useState<boolean>(false)
    const user = useAppSelector((state) => state.auth.user)
    
    const onDeleteRequest = async (id: string, fileName: string, itemCount: number) => {
        setDeleteItemId(id)
        const genericMessage = `All the codes uploaded with the file "${fileName}" will be removed.`
        setWarningText(genericMessage)
        setShowDeleteDialog(true)
        if (props.uploadType === 'barcodes') {
            const usedBarcodes = await getUsedBarcodes(id)
            const message = usedBarcodes ? (
                <>
                    <div>{genericMessage}</div>
                    <div style={{ marginTop: '.7em' }}>
                        Please note:{' '}
                        <strong>
                            {usedBarcodes} out of {itemCount} barcodes are already used.
                        </strong>
                    </div>
                    <div>
                        A confirmation email will be resent for the orders with the used barcodes, so that customers
                        could download their tickets again.
                    </div>
                </>
            ) : (
                <>
                    <div>{genericMessage}</div>
                    <div style={{ marginTop: '.7em' }}>None of the barcodes are used yet.</div>
                </>
            )
            setWarningText(message)
        }
    }

    const onConfirmDelete = async () => {
        try {
            setLoadingModal(true)
            await props.bookingCodesService.deleteUploadedCodes(props.accountSlug, props.uploadType, deleteItemId)
            props.reloadUploadsHistoryList()
            props.replaceMessages('success', 'success', 'Codes deleted successfully')
            setShowDeleteDialog(false)
            setLoadingModal(false)
            await delay(5000)
            props.removeAllMessages()
        } catch {
            setShowDeleteDialog(false)
            setLoadingModal(false)
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    const getUsedBarcodes = async (id: string) => {
        try {
            setLoadingModal(true)
            const data = await props.bookingCodesService.getUsedBarcodesCount(props.accountSlug, id)
            return data.usedBarcodesCount
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
            setShowDeleteDialog(false)
            return 0
        } finally {
            setLoadingModal(false)
        }
    }

    const downloadLink = (id: string) => {
        const type = props.uploadType === 'barcodes' ? 'barcodes' : 'discounts'
        return `${props.backofficeEndpoint}dashboard_api/codes/export?account_slug=${props.accountSlug}&import_id=${id}&codes_type=${type}`
    }

    const columns = useMemo(
        () =>
            [
                {
                    accessor: 'filename',
                    Header: 'File name',
                    Cell: ({ row: { original }, value }) => (
                        <DownloadLink
                            loginService={props.loginService}
                            downloadEndpoint={downloadLink(original.uploadId)}
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            <FileNameAsLink title={value}>{original.filename}</FileNameAsLink>
                        </DownloadLink>
                    ),
                },
                {
                    accessor: 'createdBy',
                    Header: 'Uploaded by',
                    width: '10em',
                    Cell: ({ value }) => {
                        if (!value) {
                            return '-'
                        }

                        if (conviousEmail(value || '') && !user?.isAdmin) {
                            return 'Convious'
                        }

                        return value
                    },
                },
                {
                    accessor: 'createdAt',
                    Header: 'Uploaded date',
                    Cell: ({ value }) => formatISOString(value, `${DateFormats.LONG_DATE} ${DateFormats.SHORT_TIME}`),
                    width: '11.5em',
                },
                {
                    accessor: 'codeCount',
                    Header: 'Count',
                    align: 'center',
                    disableSortBy: true,
                    Cell: ({ row: { original } }) => {
                        return addSeparators(original.codeCount || original.barcodeCount)
                    },
                    width: '7em',
                },
                {
                    accessor: 'status',
                    Header: 'Status',
                    align: 'left',
                    disableSortBy: true,
                    Cell: ({ row: { original } }) => {
                        return (
                            <Text status={uploadItemStatuMap[original.status].style as StatusType}>
                                <FontAwesomeIcon
                                    icon={uploadItemStatuMap[original.status].icon}
                                    style={{ marginRight: '.5em' }}
                                    className={original.status === 'pending' ? 'fa-pulse' : ''}
                                />
                                {uploadItemStatuMap[original.status].text}
                            </Text>
                        )
                    },
                    width: '19em',
                },
                {
                    accessor: 'id',
                    width: '4em',
                    disableSortBy: true,
                    Cell: ({ row: { original } }) => {
                        const itemCount = original.codeCount || original.barcodeCount
                        const showDeleteIcon =
                            original.status === 'completed' &&
                            props.uploadType !== 'sold_tickets' &&
                            (props.uploadType === 'barcodes' ||
                                parseISODate(original.createdAt) > deletionPossibleFromDate)

                        if (showDeleteIcon) {
                            return (
                                <FontAwesomeIcon
                                    onClick={() => onDeleteRequest(original.uploadId, original.filename, itemCount)}
                                    icon={faTrash}
                                    style={{ fontSize: '1.1em', cursor: 'pointer' }}
                                />
                            )
                        }

                        return null
                    },
                },
            ] as TableColumn<UploadDetails>[],
        [],
    )
    return (
        <>
            {showDeleteDialog && (
                <ConfirmationDialog
                    title="Are you sure?"
                    text={warningText}
                    buttonText="Delete"
                    destructive
                    onCancel={() => setShowDeleteDialog(false)}
                    onConfirm={onConfirmDelete}
                    loading={loadingModal}
                />
            )}
            <div style={{ margin: '1rem 1.25rem 1.5rem 1.25rem' }}>
                <Row>
                    <Col span={6} data-userpilot={`upload-${props.uploadType}`}>
                        <UploadInput
                            type="file"
                            acceptedTypesMsg="CSV"
                            acceptedTypes="text/csv"
                            extensionCheckOnly
                            onUpload={props.onUpload}
                            handleUploadSuccessResponse={props.reloadUploadsHistoryList}
                            handleUploadError={props.onUploadError}
                            style={{ height: '5em' }}
                            uploadArea
                            inputId="upload"
                            fileSizeLimit={16}
                            parseErrorMessage
                            replaceTopMessages={props.replaceMessages}
                            removeAllMessages={props.removeAllMessages}
                        />
                    </Col>
                    <Col span={6}>
                        <DownloadElement
                            exampleCSVHref={props.exampleCSVHref}
                            exampleFileName={props.exampleFileName}
                        />
                    </Col>
                </Row>
            </div>
            <ReactTable
                loading={props.loading}
                elevation={false}
                noResultsRow
                pagination
                data={props.uploadsHistoryList}
                columns={columns}
                size="small"
                loadData={props.loadData}
                totalCount={props.count}
                sort={props.sort}
            />
        </>
    )
}

export default withFeatures(UploadPage)
