import * as React from 'react'
import { History } from 'history'
import { usePrevious } from 'reactUtils'
import NavigationPrompt from 'react-router-navigation-prompt'
import { ConfirmationDialog } from 'uiComponents/popups/confirmationDialog'
import { onNavigateAway } from 'uiComponents/studio/onNavigateAway'
import { MobileDeviceWarning } from 'uiComponents/studio/mobileDeviceWarning'
import Form from './form'
import Header from 'uiComponents/studio/header'
import { Page, Side, Body } from 'uiComponents/studio/pageComponents'
import { PreviewSize, PreviewContainer } from 'uiComponents/studio/preview/components'
import { TemplatePreview } from 'emailTemplates/preview'
import { ArticleService, Locale, Upsell } from 'admin/articleService'
import { EmailTemplatesService } from 'emailTemplates/emailTemplatesService'
import { ImagesServiceContext } from 'http/context'
import { ImageUploadResponse } from 'http/imagesService'
import {
    dummySettings,
    FormItemType,
    TemplateSettings,
    globalFieldList,
    templatesConfig,
    Copy,
    optionalFieldList,
    translationFieldList,
    TemplateSettingsWithVariables,
    MenuStepType,
} from 'emailTemplates/schema'
import cloneDeep from 'lodash/cloneDeep'
import { Message } from 'uiComponents/messages'
import { withNavigation, WithNavigationProps } from 'hocs'
import { delay } from 'utils'
import { Debounce } from 'debounce'
import { isValidUrlIncludingHttp } from 'utils/formFieldChecks'
import { checkVariableErrors } from './copySection'
import { BookingCodesService } from 'venue/bookingCodes/bookingCodesService'
import { SingleSelectOption } from 'uiComponents/input/singleSelect'
import useGetDefaultTaxConfig from 'settings/accountSettings/taxConfigurations/hooks/useGetDefaultTaxConfig'
import { useAppSelector } from 'store/hooks'
import { useMessages } from 'messagesContext'
import { EmailTemplateRouteParams } from 'routes/appRoutes'

interface PreviewPayload {
    settings: TemplateSettings
    upsells: Upsell[]
}

interface StudioProps extends WithNavigationProps<EmailTemplateRouteParams> {
    articleService: ArticleService
    emailTemplatesService: EmailTemplatesService
    bookingCodesService: BookingCodesService
    history: History
}

function Studio(props: StudioProps) {
    const { accountSlug, templateType } = props.match.params
    const didMountRef = React.useRef(false)
    const updateCountRef = React.useRef(0)
    const imagesService = React.useContext(ImagesServiceContext)
    const [previewSize, setPreviewSize] = React.useState<PreviewSize>('desktop')
    const [activeLanguage, setActiveLanguage] = React.useState<string>('en')
    const [defaultLanguage, setDefaultLanguage] = React.useState<string>('en')
    const [templateSettings, setTemplateSettings] = React.useState<TemplateSettingsWithVariables>(dummySettings)
    const [accountLocales, setAccountLocales] = React.useState<Locale[]>([])
    const [discountReservoirOptions, setDiscountReservoirOptions] = React.useState<SingleSelectOption[]>([])
    const [allUpsells, setAllUpsells] = React.useState<Upsell[]>([])
    const [enabledUpsells, setEnabledUpsells] = React.useState<Upsell[]>([])
    const [unusedFields, setUnusedFields] = React.useState<FormItemType[]>([])
    const [markAllErrors, setMarkAllErrors] = React.useState<boolean>(false)
    const [templateHtml, setTemplateHtml] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(true)
    const [previewLoading, setPreviewLoading] = React.useState<boolean>(false)
    const [sendingEmail, setSendingEmail] = React.useState<boolean>(false)
    const [typing, setTyping] = React.useState<boolean>(false)
    const [currentStep, setCurrentStep] = React.useState<MenuStepType>('settings')
    const defaultTaxConfiguration = useGetDefaultTaxConfig(accountSlug)
    const user = useAppSelector((state) => state.auth.user)
    const { hideMessage, removeAllMessages, replaceMessages } = useMessages()

    React.useEffect(() => {
        async function getData() {
            try {
                const step = props.match.params.step || 'settings'
                setCurrentStep(step)
                let upsellsData: Upsell[] = []
                let currentEnabled: Upsell[] = []
                if (templateType === 'pre_event') {
                    const allUpsellsList = await props.articleService.getUpsellsList(accountSlug)
                    upsellsData = allUpsellsList.filter((u) => u.enabled || u.useInEmails)
                    currentEnabled = upsellsData
                        .filter((u) => u.useInEmails)
                        .sort((a, b) => (a.preEventEmailPriority || 0) - (b.preEventEmailPriority || 0))
                }
                const localesDetails = await props.articleService.getAccountLocales(accountSlug)
                const discountReservoirs = await props.bookingCodesService.getDiscountPoolsList(
                    accountSlug,
                    '?page_size=100',
                )
                const settings = await props.emailTemplatesService.getTemplateSettings(
                    accountSlug,
                    templateType,
                )
                const reservoirsOptions = discountReservoirs.entries.map((r) => ({
                    value: r.uuid,
                    name: r.name,
                }))
                reservoirsOptions.unshift({ name: 'No coupons used', value: '' })
                setDiscountReservoirOptions(reservoirsOptions)
                setAccountLocales(localesDetails.locales)
                setDefaultLanguage(localesDetails.defaultLocale || 'en')
                setAllUnusedFields(settings)
                setTemplateSettings({
                    ...settings,
                    subject: mapTranslations(settings.subject, localesDetails.locales),
                    preheader: mapTranslations(settings.preheader, localesDetails.locales),
                    title: mapTranslations(settings.title, localesDetails.locales),
                    paragraphText: mapTranslations(settings.paragraphText, localesDetails.locales),
                    textline: mapTranslations(settings.textline, localesDetails.locales),
                    footerText: mapTranslations(settings.footerText, localesDetails.locales),
                    ctaUrl: mapTranslations(settings.ctaUrl, localesDetails.locales),
                })
                setAllUpsells(upsellsData)
                setEnabledUpsells(currentEnabled)
            } catch {
                replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again later.')
            } finally {
                setLoading(false)
            }
        }

        if (!templatesConfig[templateType]) {
            replaceMessages('server_error', 'error', 'Invalid email template type. Unable to retrieve data.')
        } else {
            getData()
        }
    }, [])

    React.useEffect(() => {
        const tempSettings = removeUnusedFieldCopiesForPreview(cloneDeep(templateSettings))
        if (!loading) {
            updateTemplateHtml(tempSettings, enabledUpsells)
        }
    }, [unusedFields])

    React.useEffect(() => {
        if (typing) {
            return
        }
        const tempSettings = removeUnusedFieldCopiesForPreview(cloneDeep(templateSettings))
        didMountRef.current ? updateTemplateHtml(tempSettings, enabledUpsells) : (didMountRef.current = true)
    }, [activeLanguage, templateSettings, enabledUpsells, typing])

    const prevStep = usePrevious(props.match.params.step)
    React.useEffect(() => {
        const newStep = props.match.params.step
        if (newStep && newStep !== currentStep && prevStep !== newStep) {
            onStepChange(newStep)
        }
    }, [props.match.params.step])

    const updateTemplateHtml = (settings: TemplateSettings, upsells: Upsell[]) => {
        if (!previewLoading) {
            setPreviewLoading(true)
        }
        updateCountRef.current += 1
        const enabledOnlyUpsells = upsells.filter((u) => u.enabled)
        const payload = { settings, upsells: enabledOnlyUpsells }
        getTemplateHtmlLater.trigger(payload)
    }

    const getTemplateHtmlLater = new Debounce(async (payload: PreviewPayload) => await getTemplateHtml(payload), 500)

    const getTemplateHtml = async (payload: PreviewPayload) => {
        try {
            hideMessage('preview_error')
            const settings =
                templateType === 'pre_event'
                    ? {
                          ...payload.settings,
                          upsells: mapUpsellsForPayload(payload.upsells),
                      }
                    : { ...payload.settings }
            const html = await props.emailTemplatesService.getTemplateHtml(
                accountSlug,
                templateType,
                settings,
                activeLanguage,
            )
            setTemplateHtml(html)
        } catch {
            replaceMessages('preview_error', 'error', 'Oops! We could not load the preview. Please try again later.')
        } finally {
            updateCountRef.current -= 1
            if (updateCountRef.current < 1) {
                setPreviewLoading(false)
            }
        }
    }

    const mapUpsellsForPayload = (upsells: Upsell[]) => {
        return upsells
            .filter((u) => !!u.id)
            .map((u) => ({
                uuid: u.id,
                priority: u.preEventEmailPriority || 999,
                image: u.emailImage || '',
            }))
    }

    const setAllUnusedFields = (settings: TemplateSettings) => {
        const fields: FormItemType[] = []
        translationFieldList.forEach((field) => {
            if (optionalFieldList.indexOf(field) > -1 && !settings[field]) {
                fields.push(field)
            }
        })
        setUnusedFields(fields)
    }

    const mapTranslations = (copies: Copy, locales: Locale[]) => {
        const object = {}
        locales.forEach((l) => {
            object[l.code] = copies && copies[l.code] ? copies[l.code] : ''
        })
        return object
    }

    const handleItemChange = (value: string | boolean | Upsell[], item: FormItemType) => {
        removeAllMessages()
        switch (item) {
            case 'defaultLanguage':
                setDefaultLanguage(value as string)
                break
            case 'activeLanguage':
                setActiveLanguage(value as string)
                break
            case 'themeColor':
            case 'backgroundColor':
            case 'signatureEnabled':
            case 'socialIconsEnabled':
            case 'upsellsEnabled':
            case 'vatEnabled':
            case 'requiresOptIn':
            case 'logo':
            case 'agentPhoto':
            case 'discountReservoir':
                updateGlobalSettings(value as string | boolean, item)
                break
            case 'subject':
            case 'preheader':
            case 'title':
            case 'paragraphText':
            case 'textline':
            case 'footerText':
            case 'ctaUrl':
                if (!typing) {
                    setTyping(true)
                }
                updateTranslations(value as string, item)
                break
            case 'upsells':
                setEnabledUpsells(value as Upsell[])
                break
            default:
                return
        }
    }

    const updateTranslations = (value: string | null, item: FormItemType) => {
        if (value && unusedFields.indexOf(item) > -1) {
            setUnusedFields(unusedFields.filter((f) => f !== item))
        }
        const updatedSettings = cloneDeep(templateSettings)
        updatedSettings[item][activeLanguage] = value
        setTemplateSettings(updatedSettings)
    }

    const updateGlobalSettings = (value: string | boolean, item: FormItemType) => {
        const updatedSettings = cloneDeep(templateSettings)
        updatedSettings[item] = value
        setTemplateSettings(updatedSettings)
    }

    const toggleUnusedField = (item: FormItemType, addToUnused: boolean) => {
        const updatedUnusedFields = [...unusedFields]
        addToUnused ? updatedUnusedFields.push(item) : updatedUnusedFields.splice(updatedUnusedFields.indexOf(item), 1)
        setUnusedFields(updatedUnusedFields)
    }

    const onUpload = async (file: File) => {
        return await imagesService.uploadImage(file)
    }

    const onUploadSuccess = (response: ImageUploadResponse, type: 'logo' | 'agentPhoto') => {
        handleItemChange(response.url, type)
    }

    const removeUnusedFields = (settings: TemplateSettings) => {
        const templateFields = globalFieldList.concat(templatesConfig[templateType].customFields)
        Object.keys(settings).forEach((s) => {
            if (templateFields.indexOf(s as FormItemType) === -1) {
                delete settings[s]
            } else if (unusedFields.indexOf(s as FormItemType) > -1) {
                settings[s] = null
            }
        })
        return settings
    }

    const removeUnusedFieldCopiesForPreview = (settings: TemplateSettings) => {
        Object.keys(settings).forEach((s) => {
            if (unusedFields.indexOf(s as FormItemType) > -1) {
                Object.keys(settings[s]).forEach((k) => {
                    settings[s][k] = ''
                })
            }
        })
        return settings
    }

    const onTestEmailSend = async () => {
        if (!checkTranslationsValid(activeLanguage)) {
            return
        }
        try {
            setSendingEmail(true)
            const settings =
                templateType === 'pre_event'
                    ? {
                          ...templateSettings,
                          upsells: mapUpsellsForPayload(enabledUpsells),
                      }
                    : { ...templateSettings }
            if (!user) {
                throw new Error('User not found')
            }

            await props.emailTemplatesService.sendTestEmail(
                accountSlug,
                templateType,
                settings,
                activeLanguage,
                user.username,
            )
            setSendingEmail(false)
            replaceMessages('send_success', 'success', 'Email sent successfully. Please check your inbox.')
            await delay(6000)
            hideMessage('send_success')
        } catch {
            setSendingEmail(false)
            replaceMessages('server_error', 'error', 'Oops! We could not send the test email. Please try again later.')
        }
    }

    const getFieldsToUpdate = () => {
        const fields = removeUnusedFields(cloneDeep(templateSettings))
        if (fields.discountReservoir === '') {
            fields.discountReservoir = null
        }
        if (templateType === 'pre_event') {
            return { ...fields, upsells: mapUpsellsForPayload(enabledUpsells) }
        }
        return fields
    }

    const checkAllCtaUrlsEmpty = (ctaUrlFieldTranslations: Copy) => {
        if (!ctaUrlFieldTranslations) {
            return true
        }
        return !Object.entries(ctaUrlFieldTranslations).find(([k, v]) => !!v)
    }

    const checkTranslationsValid = (langToCheck: string = '') => {
        const fieldsToUpdate = getFieldsToUpdate()
        const allCtaUrlsEmpty = checkAllCtaUrlsEmpty(fieldsToUpdate.ctaUrl)
        return translationFieldList.every((field) => {
            if (!fieldsToUpdate[field]) {
                return true
            }
            const languagesToCheck = !!langToCheck
                ? Object.keys(fieldsToUpdate[field]).filter((lang) => lang === langToCheck)
                : Object.keys(fieldsToUpdate[field])
            return languagesToCheck.every((lang) => {
                const translation = fieldsToUpdate[field][lang]
                const urlInvalid =
                    field === 'ctaUrl' && !allCtaUrlsEmpty && !!translation && !isValidUrlIncludingHttp(translation)
                const variablesValid = !!translation
                    ? !checkVariableErrors(translation, templateSettings.variables)
                    : true
                if ((field !== 'ctaUrl' && !translation) || urlInvalid || !variablesValid) {
                    setActiveLanguage(lang)
                    setMarkAllErrors(true)
                    onStepChange('text')
                    replaceMessages(
                        'validation_error',
                        'error',
                        'Please check all the translations are correctly completed.',
                    )
                    return false
                }
                return true
            })
        })
    }

    const checkUpsellsValid = () => {
        return enabledUpsells
            .filter((u) => !!u.id)
            .every((upsell) => {
                if (upsell.preEventEmailPriority === null || !upsell.emailImage) {
                    setMarkAllErrors(true)
                    onStepChange('settings')
                    replaceMessages(
                        'validation_error',
                        'error',
                        'Please check all the upsells have a set priority and an image.',
                    )
                    return false
                }
                return true
            })
    }

    const onPublish = async () => {
        if (!checkTranslationsValid() || !checkUpsellsValid()) {
            return
        }
        try {
            const fieldsToUpdate = getFieldsToUpdate()
            await props.emailTemplatesService.updateTemplateSettings(
                accountSlug,
                templateType,
                fieldsToUpdate,
            )
            const message: Message = {
                id: 'publish_sucess',
                status: 'success',
                text: `${templatesConfig[templateType].name} template has been updated successfully.`,
                visible: true,
            }
            props.navigation.replaceWithMessages([message], `/account/${accountSlug}/engage/email_templates/home`)
        } catch {
            replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    const onStepChange = (step: MenuStepType) => {
        setCurrentStep(step)
        props.history.push(`/account/${accountSlug}/engage/email_templates/edit/${templateType}/${step}`)
    }

    const onConfirmNavigation = () => {}

    return (
        <>
            <Page id="wizard-page" style={{ position: 'relative', justifyContent: 'center' }}>
                <NavigationPrompt
                    when={(crntLocation, nextLocation) => onNavigateAway(nextLocation, false)}
                    afterConfirm={onConfirmNavigation}
                >
                    {({ onConfirm, onCancel }) => (
                        <ConfirmationDialog
                            title="Do you want to exit without publishing?"
                            text="Watch out! By exiting the Convious Studio, all your changes will be lost."
                            buttonText="Exit"
                            destructive
                            onCancel={onCancel}
                            onConfirm={onConfirm}
                        />
                    )}
                </NavigationPrompt>
                <MobileDeviceWarning
                    history={props.history}
                    returnPath={`/account/${accountSlug}/engage/email_templates/home`}
                />
                <Side>
                    <Form
                        history={props.history}
                        accountSlug={accountSlug}
                        templateType={templateType}
                        defaultLanguage={defaultLanguage}
                        activeLanguage={activeLanguage}
                        accountLocales={accountLocales}
                        allUpsells={allUpsells}
                        enabledUpsells={enabledUpsells}
                        unusedFields={unusedFields}
                        templateSettings={templateSettings}
                        markAllErrors={markAllErrors}
                        currentStep={currentStep}
                        setCurrentStep={(step) => onStepChange(step)}
                        sendingEmail={sendingEmail}
                        loading={loading}
                        discountReservoirOptions={discountReservoirOptions}
                        onFinishTyping={() => setTyping(false)}
                        handleItemChange={handleItemChange}
                        toggleUnusedField={toggleUnusedField}
                        onPublish={onPublish}
                        onTestEmailSend={onTestEmailSend}
                        onUpload={onUpload}
                        onUploadSuccess={onUploadSuccess}
                        replaceMessages={replaceMessages}
                        removeAllMessages={removeAllMessages}
                        defaultTaxConfiguration={defaultTaxConfiguration}
                    />
                </Side>
                <Body>
                    <Header
                        accountSlug={accountSlug}
                        previewSize={previewSize}
                        onPreviewSizeChange={(size: PreviewSize) => setPreviewSize(size)}
                        targetDevice={previewSize}
                        studioType="emailTemplates"
                        onPublish={onPublish}
                        replaceMessages={replaceMessages}
                        removeAllMessages={removeAllMessages}
                    />
                    <PreviewContainer className={previewSize}>
                        <TemplatePreview templateHtml={templateHtml} loading={previewLoading || loading} />
                    </PreviewContainer>
                </Body>
            </Page>
        </>
    )
}

export default withNavigation(Studio)
