import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import { AppServices, navigation } from 'middleware'
import { ConfigReader } from 'utils/confiReader'
import LoginPage from 'auth/loginPage'
import { ForgotPasswordPage } from 'auth/forgotPasswordPage'
import { PasswordResetFinishPage } from 'auth/passwordResetFinishPage'
import SsoSuccessPage from 'auth/ssoSuccessPage'
import CreateAccountPage from 'signUp/createAccountPage'
import AccountDetailsPage from 'signUp/accountDetailsPage'
import LinkAccountPage from 'signUp/linkAccountPage'
import PassTokenValidationPage from 'settings/personalSettings/passTokenValidationPage'
import { withNavigation, WithNavigationProps } from 'hocs'

export const AUTH_ROUTES = {
    PASSWORD_CREATE: '/account/create/:username/:token',
    PASSWORD_FORGOT: '/account/forgotPassword',
    PASSWORD_RESET: '/password/reset/:username/:token',
    SIGN_UP_CREATE: '/sign_up/create_account',
    SIGN_UP_DETAILS: '/sign_up/account_details',
    SSO_AUTH_SUCCESS: '/sso_auth/success',
    SSO_LINK: '/sso_auth/link_account',
    TOKEN_VALIDATION: '/new_user/token_validation',
    LOGIN: '/login',
}

export const ALL_AUTH_ROUTES = Object.values(AUTH_ROUTES)

const AuthRoutes = (props: WithNavigationProps<{ username: string; token: string }>) => {
    const history = useHistory()

    return (
        <>
            <Route
                exact
                path={AUTH_ROUTES.PASSWORD_CREATE}
                render={() => (
                    <PasswordResetFinishPage
                        history={history}
                        login={AppServices.loginService.login}
                        resetPassword={AppServices.passwordResetService.consumeResetToken}
                        username={decodeURIComponent(props.match.params.username)}
                        token={props.match.params.token}
                    />
                )}
            />
            <Route
                path={AUTH_ROUTES.PASSWORD_FORGOT}
                render={() => <ForgotPasswordPage resetPassword={AppServices.passwordResetService.createResetToken} />}
            />
            <Route
                exact
                path={AUTH_ROUTES.PASSWORD_RESET}
                render={() => (
                    <PasswordResetFinishPage
                        history={history}
                        login={AppServices.loginService.login}
                        resetPassword={AppServices.passwordResetService.consumeResetToken}
                        username={decodeURIComponent(props.match.params.username as string)}
                        token={props.match.params.token as string}
                    />
                )}
            />
            <Route
                exact
                path={AUTH_ROUTES.SIGN_UP_CREATE}
                render={() => (
                    <CreateAccountPage
                        history={history}
                        signUpService={AppServices.signUpService}
                        reCaptchaService={AppServices.reCaptchaService}
                        reCaptchaSiteKey={ConfigReader.getRecaptchaSiteKey()}
                        ssoService={AppServices.ssoService}
                    />
                )}
            />
            <Route
                exact
                path={AUTH_ROUTES.SIGN_UP_DETAILS}
                render={() => <AccountDetailsPage history={history} signUpService={AppServices.signUpService} />}
            />
            <Route
                exact
                path={AUTH_ROUTES.SSO_AUTH_SUCCESS}
                render={() => <SsoSuccessPage history={history} ssoService={AppServices.ssoService} />}
            />
            <Route
                exact
                path={AUTH_ROUTES.SSO_LINK}
                render={() => (
                    <LinkAccountPage
                        history={history}
                        login={AppServices.loginService.login}
                        ssoService={AppServices.ssoService}
                    />
                )}
            />
            <Route
                exact
                path={AUTH_ROUTES.TOKEN_VALIDATION}
                render={() => (
                    <PassTokenValidationPage
                        navigation={navigation}
                        match={props.match}
                        loginService={AppServices.loginService}
                        history={history}
                    />
                )}
            />
            <Route
                path={AUTH_ROUTES.LOGIN}
                render={() => (
                    <LoginPage
                        login={AppServices.loginService.login}
                        history={history}
                        ssoService={AppServices.ssoService}
                    />
                )}
            />
        </>
    )
}

export default withNavigation(AuthRoutes)
