import React from 'react'
import { ArticleCrudData } from 'admin/articleService'
import { NavigationTabs } from 'uiComponents/navigation/tabs'
import { usePermission } from 'admin/hocs'

interface ExternalConfigurationSectionProps {
    accountSlug: string
    productData: ArticleCrudData
    onSave: () => void
    onPublish: () => void
    onUnpublish: () => void
    saveBeforeContinue: (action: 'validity' | 'pricing') => void
}

function ExternalConfigurationSection(props: ExternalConfigurationSectionProps) {
    const { hasPermission } = usePermission()

    const pricingIsSet =
        !!props.productData.articleValidationErrors &&
        props.productData.articleValidationErrors.indexOf('pricing_settings_not_configured') === -1

    const validityIsSet =
        !!props.productData.articleValidationErrors &&
        props.productData.articleValidationErrors.indexOf('validity_not_configured') === -1

    const tabs = [
        {
            name: 'Edit Details',
            onClick: () => {},
            current: true,
            disabled: !hasPermission('partner_admin', props.accountSlug),
        },
        {
            name: validityIsSet ? 'Edit Validity' : 'Configure Validity',
            onClick: () => props.saveBeforeContinue('validity'),
            current: false,
            disabled: !hasPermission('view_product_validity', props.accountSlug),
        },
        {
            name: pricingIsSet ? 'Edit Pricing' : 'Configure Pricing',
            onClick: () => props.saveBeforeContinue('pricing'),
            current: false,
            disabled: !hasPermission('view_pricing_settings', props.accountSlug),
        },
    ]

    return (
        <div style={{ marginBottom: '2.5em' }}>
            <NavigationTabs tabs={tabs} />
        </div>
    )
}

export default ExternalConfigurationSection
