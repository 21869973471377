import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import styled from 'styled-typed'

import { State } from 'store'
import { User } from 'auth/state'
import { withFeatures } from 'features'
import { Container, ContainerHeading, ContainerBody, HeadingSectionName } from 'uiComponents/settingsContainer'
import { PageTitle } from 'uiComponents/typography'
import { isConviousAdmin, isPartnerAdmin } from 'utils/useGetPermission'
import { useHasAnyIntegrations } from './integrations/utils'
import Feature from 'features/feature'
import { ContractEditWarning } from './contract/contractEditWarning'

interface AccountSettingsProps extends ReturnType<typeof mapStateToProps>, RouteComponentProps {
    hasFeature: (feature: string, accountslug: string) => boolean
}

const Title = styled(PageTitle)`
    overflow: hidden;
    text-overflow: ellipsis;
`

const AccountSettingsPageContainer: React.FC<AccountSettingsProps> = ({
    accountSlug,
    accounts,
    location,
    isConviousAdmin,
    isPartnerAdmin,
    children,
}) => {
    const activeAccount = accounts.find((x) => x.slug === accountSlug) || accounts[0]
    const hasAnyIntegrations = useHasAnyIntegrations()

    return (
        <div style={{ position: 'relative' }} id="account-settings">
            <Title title={activeAccount.name}>Account Settings - {activeAccount.name}</Title>
            <ContractEditWarning />
            <Container>
                <ContainerHeading>
                    <HeadingSectionName
                        className={classNames({ active: location.pathname.includes('account_info') })}
                        to={`/account/${accountSlug}/settings/account_settings/account_info`}
                    >
                        Account
                    </HeadingSectionName>
                    <Feature name="BookingFees" accountSlug={accountSlug}>
                        <HeadingSectionName
                            id="booking-fees-link"
                            className={classNames({
                                active: location.pathname.includes('fees'),
                            })}
                            to={`/account/${accountSlug}/settings/account_settings/booking_fees`}
                        >
                            Fees
                        </HeadingSectionName>
                    </Feature>
                    <Feature name="TaxConfigurations" accountSlug={accountSlug}>
                        <HeadingSectionName
                            id="tax-configurations-link"
                            className={classNames({
                                active: location.pathname.includes('tax_configurations'),
                            })}
                            to={`/account/${accountSlug}/settings/account_settings/tax_configurations`}
                        >
                            Tax Configurations
                        </HeadingSectionName>
                    </Feature>
                    {isConviousAdmin && (
                        <>
                            <HeadingSectionName
                                id="contract-link"
                                className={classNames({
                                    active: location.pathname.includes('contract'),
                                })}
                                to={`/account/${accountSlug}/settings/account_settings/contract`}
                            >
                                Contract
                            </HeadingSectionName>
                        </>
                    )}
                    {((isPartnerAdmin && hasAnyIntegrations) || isConviousAdmin) && (
                        <HeadingSectionName
                            id="integrations-link"
                            className={classNames({
                                active: location.pathname.includes('integrations'),
                            })}
                            to={`/account/${accountSlug}/settings/account_settings/integrations`}
                        >
                            Integrations
                        </HeadingSectionName>
                    )}
                </ContainerHeading>
                <ContainerBody>{children}</ContainerBody>
            </Container>
        </div>
    )
}

const mapStateToProps = (state: State) => {
    return {
        accountSlug: state.preferences.activeAccount as string,
        accounts: state.auth.user ? state.auth.user.accounts : [],
        isConviousAdmin: isConviousAdmin(state.auth.user as User, state.preferences.activeAccount),
        isPartnerAdmin: isPartnerAdmin(state.auth.user as User, state.preferences.activeAccount),
    }
}

export default withRouter(withFeatures(connect(mapStateToProps)(AccountSettingsPageContainer)))
