import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { State } from 'store'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import PersonalSettingsHeader from './personalSettingsHeader'
import PersonalSettingsContainer from './personalSettingsContainer'
import { UpdateProfileRequest } from 'settings/schema'
import { profileLoaded } from 'settings/actions'
import { History, Location } from 'history'
import { userDataRetrieved } from 'auth/actions'
import { AccountSettingsService } from 'settings/settingsService'
import { PersonalSettingsParams, UpdatedAccountDetails } from 'settings/schema'
import { UserService } from 'auth/userService'
import { LoginService } from 'http/loginService'
import { PasswordResetService } from 'http/passwordResetService'
import { delay } from 'utils'
import { useMessages } from 'messagesContext'

interface PersonalSettingsProps {
    accountSlug: string
    history: History
    accountSettingsService: AccountSettingsService
    userService: UserService
    navigation: Navigation
    match: RouteMatch<PersonalSettingsParams>
    passwordResetService: PasswordResetService
    loginService: LoginService
    location: Location
    welcomePage: boolean
}

const PersonalSettingsPage: React.FC<PersonalSettingsProps> = (props) => {
    const {
        accountSlug,
        history,
        accountSettingsService,
        userService,
        navigation,
        passwordResetService,
        loginService,
        location,
        welcomePage,
    } = props
    const user = useSelector((state: State) => state.auth.user)
    const profile = useSelector((state: State) => state.profile.profile)
    const dispatch = useDispatch()
    const [setPassSuccessful, setSetPassSuccessful] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const { replaceMessages, removeAllMessages } = useMessages()

    React.useEffect(() => {
        const init = async () => {
            const passToken = navigation.query().pass_token
            if (passToken) {
                sessionStorage.setItem('newUserPassToken', passToken)
            }
            const emailToken = navigation.query().email_token
            if (emailToken && !profile?.emailConfirmed) {
                try {
                    const emailConfirmed = await accountSettingsService.confirmEmail(emailToken)
                    if (emailConfirmed && profile) {
                        const updatedProfile = { ...profile, emailConfirmed: true }
                        dispatch(profileLoaded(updatedProfile))
                    }
                } catch (e) {
                    handleEmailConfirmationError(e)
                }
            }
        }
        init()
    }, [navigation, accountSettingsService, profile?.emailConfirmed, dispatch])

    const handleEmailConfirmationError = (e: Error) => {
        if (profile?.emailConfirmed) {
            history.push('/personal_settings')
        } else if (e.message === 'tokenInvalid') {
            replaceMessages(
                'email',
                'error',
                'Token is invalid, please click "resend" under your email address field for a new confirmation email.',
            )
        } else {
            replaceMessages(
                'email',
                'error',
                'We are unable to confirm your email at this time. Please try again later.',
            )
        }
    }

    const resendEmailConfirmation = async () => {
        removeAllMessages()
        try {
            await accountSettingsService.resendConfirmEmail('')
        } catch {
            replaceMessages('unknown', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    const onSaveChanges = async (newDetails: UpdatedAccountDetails, password: string = '') => {
        setLoading(true)
        removeAllMessages()
        if (password) {
            await setPassword(password)
        }
        const { email, firstName, lastName, contactNo, companyRole } = newDetails
        if (email) {
            await updateEmail(email)
        }
        const profileUpdateRequest: UpdateProfileRequest = {
            firstName,
            lastName,
            contactNo,
            companyRole,
        }
        await updatePersonal(profileUpdateRequest)
        const userDetails = await userService.getLoggedInUser()
        dispatch(userDataRetrieved(userDetails.currentUser))
        dispatch(profileLoaded(userDetails.profile))
        if (setPassSuccessful) {
            history.replace('/')
        }
    }

    const setPassword = async (password: string) => {
        try {
            const passToken = navigation.query().pass_token || sessionStorage.getItem('newUserPassToken') || ''
            const result = await passwordResetService.consumeResetToken(passToken, password)
            if (!result) {
                setGeneralErrorMessage('passTokenExpired')
                return
            }

            if (!user) {
                setGeneralErrorMessage('User not found')
                return
            }

            const loginResult = await loginService.login(user.username, password)
            if (loginResult) {
                sessionStorage.removeItem('newUserPassToken')
                setSetPassSuccessful(true)
            } else {
                setGeneralErrorMessage('unknown')
            }
        } catch {
            setGeneralErrorMessage('unknown')
        }
    }

    const updateEmail = async (email: string) => {
        try {
            await accountSettingsService.updateEmail(email, '')
            replaceMessages('success', 'success', 'Your email was updated successfully.')
        } catch (e) {
            setGeneralErrorMessage(e.message)
        }
    }

    const updatePersonal = async (updateRequest: UpdateProfileRequest) => {
        try {
            await accountSettingsService.updateUserProfile(updateRequest)
            replaceMessages('success', 'success', 'Your details were updated successfully.')
            setLoading(false)
            await delay(5000)
            removeAllMessages()
        } catch {
            setLoading(false)
            replaceMessages(
                'unknown',
                'error',
                'Oops! We were not able to update your personal details. Please try again later.',
            )
        }
    }

    const setGeneralErrorMessage = (errorMessage: string) => {
        if (errorMessage === 'passTokenExpired') {
            replaceMessages('pass_token', 'error', 'The link you were given has expired.')
        } else if (errorMessage === 'emailRegistered') {
            replaceMessages('email', 'error', 'Email provided is already registered.')
        } else {
            replaceMessages('unknown', 'error', 'Oops! Something went wrong. Please try again later.')
        }
    }

    const profileDetails = profile || {
        firstName: '',
        lastName: '',
        avatarUrl: '',
        email: '',
        contactNo: '',
        company: { name: '', role: '', industry: '', country: '', url: '' },
        welcomeSeen: false,
        tourSeen: true,
        requiresPasswordChange: false,
        signUp: null,
        emailConfirmed: false,
    }

    const username = (profileDetails.firstName || user?.name || user?.username) ?? 'unknown'

    return (
        <>
            <PersonalSettingsHeader
                username={username}
                avatarUrl={profileDetails.avatarUrl}
                userService={userService}
                accountSettingsService={accountSettingsService}
            />
            <PersonalSettingsContainer
                accountSlug={accountSlug}
                user={user}
                accountSettingsService={accountSettingsService}
                profile={profileDetails}
                onSave={onSaveChanges}
                history={history}
                resendEmail={resendEmailConfirmation}
                location={location}
                welcomePage={welcomePage}
                loading={loading}
                replaceMessages={replaceMessages}
                removeAllMessages={removeAllMessages}
            />
        </>
    )
}

export default withNavigation(PersonalSettingsPage)
