import * as React from 'react'
import styled from 'styled-typed'
import { withNavigation } from 'hocs'
import { match as RouteMatch } from 'react-router-dom'
import { Navigation } from 'navigation'
import { MessageKind } from 'uiComponents/messages'
import { TableLoader } from 'uiComponents/loaders'
import { NotificationsServiceContext } from 'settings/context'
import { NotificationItem, UrlParts } from 'settings/notificationsService'
import { Account } from 'auth/state'
import { ActionButtonA } from 'uiComponents/buttons'
import { differenceInHours, differenceInMinutes } from 'date-fns'
import { format } from 'date-fns'
import { usePermission } from 'admin/hocs'
import { DateFormats, parseISODate } from 'utils/dates'

const ItemWrapper = styled.div`
    position: relative;
    font-size: 0.75em;
    border: 1px solid ${(p) => p.theme.colors.tableRow};
    padding: 1.6em 1.5em 1.6em 2em;
    margin-bottom: 0.7em;

    &.first-new-item {
        margin-bottom: 4em;
    }
`
const LevelLabel = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
`
const LevelMarker = styled.div`
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.status.error};
    margin-right: 0.5em;
`
const AccountName = styled.div`
    color: ${(props) => props.theme.colors.textLight};
    font-size: 0.875rem;
    margin-bottom: 0.5em;
`
const MainMessage = styled.div`
    font-size: 1.1em;
    span {
        font-weight: bold;
    }
`
const AdditionalDataItem = styled.div`
    margin-top: 0.6em;
    margin-right: 9em;
    span {
        color: ${(props) => props.theme.colors.textLight};
    }
`
const PossitionalWrapper = styled.div`
    position: absolute;
    right: 1.5em;
    &.top {
        top: 1.4em;
    }
    &.bottom {
        bottom: 1.4em;
    }
`

interface NotificationsFeedPageProps {
    accountSlug: string
    accounts: Account[]
    allPartners: boolean
    navigation: Navigation
    match: RouteMatch<{}>
    isAdmin: boolean
    onSectionChange: (section: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

function NotificationsFeedPage(props: NotificationsFeedPageProps) {
    const _isMounted = React.useRef<boolean>(false)
    const notificationsService = React.useContext(NotificationsServiceContext)
    const lastSeenDate = React.useRef<string | null>(null)
    const [loading, setLoading] = React.useState<boolean>(true)
    const [notificationsList, setNotificationsList] = React.useState<NotificationItem[]>([])
    const [firstNewItemIndex, setFirstNewItemIndex] = React.useState<number | null>(null)
    const { hasPermission } = usePermission()
    
    async function getData() {
        try {
            setLoading(true)
            const allPartners = !!props.navigation.query().showAllPartners
            let data: NotificationItem[] = []
            if (props.isAdmin && allPartners) {
                data = await notificationsService.getAllNotifications()
            } else {
                data = await notificationsService.getNotificationsForAccount(props.accountSlug)
            }
            setNotificationsList(data)
            setFirstNewItemIndex(getFirstNewItemIndex(data))
        } catch {
            props.replaceMessages('unknown_error', 'error', 'Oops! Something went wrong. Please try again later.')
        } finally {
            setLoading(false)
        }
    }

    const getFirstNewItemIndex = (data: NotificationItem[]) => {
        const item = [...data].reverse().find((i) => {
            return i.createdAt > (lastSeenDate.current || '')
        })
        return item ? data.indexOf(item) : null
    }

    const setLastSeen = () => {
        const storageName = props.isAdmin ? 'notificationsLastSeen' : `${props.accountSlug}_notificationsLastSeen`
        const lastSeen = localStorage.getItem(storageName)
        lastSeenDate.current = lastSeen
        localStorage.setItem(storageName, new Date().toISOString())
    }

    React.useEffect(() => {
        props.onSectionChange('feed')
        setLastSeen()
        _isMounted.current = true
    }, [])

    React.useEffect(() => {
        if (_isMounted.current) {
            getData()
        }
    }, [props.accountSlug, props.allPartners])

    const getTimeStamp = (dateString: string | Date) => {
        const date = parseISODate(dateString)
        const difference = differenceInHours(new Date(), date)
        if (difference < 1) {
            const differenceInMin = differenceInMinutes(new Date(), date)
            return `${differenceInMin}min ago`
        }
        return difference > 24
            ? format(date, `${DateFormats.EXTRA_SHORT_DATE} ${DateFormats.SHORT_TIME}`)
            : `${difference}h ago`
    }

    const getActionUrl = (urlParts: UrlParts) => {
        switch (urlParts.type) {
            case 'codes':
                return !!urlParts.subtype && urlParts.subtype === 'coupons'
                    ? `/account/${urlParts.accountSlug}/venue/codes/coupons/upload/${urlParts.uuid}`
                    : `/account/${urlParts.accountSlug}/venue/codes/tickets/upload/${urlParts.id}`
            case 'products':
                return `/account/${urlParts.accountSlug}/products/validity/${urlParts.uuid}/detail`
            default:
                return '/'
        }
    }

    const showTakeActionButton = (urlParts: UrlParts) => {
        return urlParts.type === 'codes'
            ? hasPermission('access_codes', props.accountSlug)
            : hasPermission('partner_admin', props.accountSlug)
    }

    return (
        <div id="notifications-feed-page">
            {loading && <TableLoader />}
            {!loading && notificationsList.length === 0 && <div>No notifications</div>}
            {!loading &&
                notificationsList.length > 0 &&
                notificationsList.map((item, i) => {
                    const accountName = props.accounts.find((a) => a.slug === item.accountSlug)?.name
                    return (
                        <ItemWrapper
                            key={i}
                            className={
                                i === firstNewItemIndex ? 'first-new-item notifications-item' : 'notifications-item'
                            }
                        >
                            <PossitionalWrapper className="top">{getTimeStamp(item.createdAt)}</PossitionalWrapper>
                            {item.level === 'Alarm' && (
                                <LevelLabel>
                                    <LevelMarker />
                                    Urgent
                                </LevelLabel>
                            )}
                            {props.isAdmin && !!accountName && props.allPartners && (
                                <AccountName>{accountName}</AccountName>
                            )}
                            <MainMessage className="message">
                                {item.metadata.title} <span>{item.metadata.subtitle}</span>
                            </MainMessage>
                            <AdditionalDataItem className="message-details">
                                {item.metadata.text} <span>{item.metadata.footer}</span>
                            </AdditionalDataItem>
                            {item.metadata.urlParts && showTakeActionButton(item.metadata.urlParts) && (
                                <PossitionalWrapper className="bottom">
                                    <ActionButtonA
                                        size="small"
                                        kind="action"
                                        secondary
                                        href={getActionUrl(item.metadata.urlParts)}
                                    >
                                        Take action
                                    </ActionButtonA>
                                </PossitionalWrapper>
                            )}
                        </ItemWrapper>
                    )
                })}
        </div>
    )
}

export default withNavigation(NotificationsFeedPage)
