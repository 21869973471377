import React from 'react'
import { ActionButton, ActionButtonA } from 'uiComponents/buttons'
import { Col } from 'uiComponents/flex'
import { ButtonWrapper } from 'uiComponents/pageElements'
import { usePermission } from 'admin/hocs'

interface FormActionsProps {
    accountSlug: string
    viewMode: 'flat' | 'nested' | ''
}

function FormActions(props: FormActionsProps) {
    const { hasPermission } = usePermission()
    
    return (
        <Col span={12}>
            <ButtonWrapper>
                <ActionButtonA
                    size="large"
                    secondary
                    href={`/account/${props.accountSlug}/products/home/${props.viewMode}`}
                    style={{ marginRight: '1em' }}
                >
                    Cancel
                </ActionButtonA>
                <ActionButton
                    id="save-product"
                    size="large"
                    type="submit"
                    style={{ marginRight: '1.5em' }}
                    disabled={!hasPermission('partner_admin', props.accountSlug)}
                >
                    Save
                </ActionButton>
            </ButtonWrapper>
        </Col>
    )
}

export default FormActions
