import * as React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { PricingService } from '../pricingService'
import { ArticleService } from 'admin/articleService'
import { StatsService } from 'http/statsService'
import ExceptionFormRouter from './exceptionFormRouter'
import ExceptionEdit from './exceptionEdit'
import { withNavigation } from 'hocs'
import { Navigation } from 'navigation'
import { MessageKind } from 'uiComponents/messages'
import { ChannelsService } from 'channels/channelsService'
import { match as RouteMatch } from 'react-router-dom'

interface ProductPricingPageParams {
    accountSlug: string
    id: string
    copy_from: string
}

interface ExceptionDetailPageProps {
    pricingService: PricingService
    articleService: ArticleService
    statsService: StatsService
    channelsService: ChannelsService
    match: RouteMatch<ProductPricingPageParams>
    navigation: Navigation
    setActiveSection: (section: string, header?: string) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    hasPermission: (permission: string, accountSlug: string) => boolean
    className?: string
}

const ExceptionDetailPage: React.FC<ExceptionDetailPageProps> = (props) => {
    const history = useHistory()
    const {
        match,
        navigation,
        pricingService,
        articleService,
        statsService,
        channelsService,
        setActiveSection,
        replaceTopMessages,
        removeAllMessages,
        hasPermission,
        className
    } = props

    const accountSlug = match.params.accountSlug
    const overrideId = match.params.id
    const prototypeId = navigation.query().copy_from

    return (
        <Switch>
            <Route
                path="/account/:accountSlug/products/pricing/exceptions/duplicate"
                render={() => (
                    <ExceptionEdit
                        className={className}
                        key="new"
                        id={prototypeId}
                        prototype={true}
                        accountSlug={accountSlug}
                        history={history}
                        pricingService={pricingService}
                        articleService={articleService}
                        statsService={statsService}
                        channelsService={channelsService}
                        setActiveSection={setActiveSection}
                        replaceTopMessages={replaceTopMessages}
                        removeAllMessages={removeAllMessages}
                        hasPermission={hasPermission}
                    />
                )}
            />
            <Route
                path="/account/:accountSlug/products/pricing/exceptions/create"
                render={() => (
                    <ExceptionFormRouter
                        key="new"
                        className={className}
                        history={history}
                        accountSlug={accountSlug}
                        pricingService={pricingService}
                        articleService={articleService}
                        channelsService={channelsService}
                        statsService={statsService}
                        setActiveSection={setActiveSection}
                        replaceTopMessages={replaceTopMessages}
                        removeAllMessages={removeAllMessages}
                        hasPermission={hasPermission}
                    />
                )}
            />
            <Route
                path="/account/:accountSlug/products/pricing/exceptions/:id"
                render={() => (
                    <ExceptionEdit
                        className={className}
                        key={overrideId}
                        id={overrideId}
                        accountSlug={accountSlug}
                        history={history}
                        pricingService={pricingService}
                        statsService={statsService}
                        channelsService={channelsService}
                        articleService={articleService}
                        setActiveSection={setActiveSection}
                        replaceTopMessages={replaceTopMessages}
                        removeAllMessages={removeAllMessages}
                        hasPermission={hasPermission}
                    />
                )}
            />
        </Switch>
    )
}

export default withNavigation(ExceptionDetailPage)
