import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { History, Location } from 'history'
import { Container, ContainerHeading, FullWidthContainerBody, HeadingSectionName } from 'uiComponents/settingsContainer'
import { Headline } from 'uiComponents/typography'
import { usePermission } from 'admin/hocs'
import { withFeatures } from 'features'
import { OpeningTimesListPage } from './list'
import OpeningTimesForm from './form'
import OverrideForm from './exceptionsForm'
import { useMessages } from 'messagesContext'

interface OpeningTimesPageProps {
    history: History
    accountSlug: string
    location: Location
    hasFeature: (feature: string, accountSlug: string) => boolean
}

function OpeningTimesPage(props: OpeningTimesPageProps) {
    const [header, setHeader] = useState<string>('Opening Times Configuration')
    const { replaceMessages, removeAllMessages } = useMessages()
    const { hasPermission } = usePermission()

    const setActiveHeader = (headerText: string) => {
        setHeader(headerText)
    }

    return (
        <div style={{ position: 'relative' }} id="opening-hours">
            <Headline size={1} style={{ marginBottom: '1.5em' }}>
                {header}
            </Headline>
            <Container>
                <ContainerHeading>
                    <HeadingSectionName
                        className="active"
                        to={`/account/${props.accountSlug}/venue/opening_times/locations`}
                    >
                        Locations
                    </HeadingSectionName>
                </ContainerHeading>
                <Switch location={props.location}>
                    <Route
                        path="/account/:accountSlug/venue/opening_times/locations"
                        render={() => (
                            <FullWidthContainerBody>
                                <OpeningTimesListPage
                                    history={props.history}
                                    setActiveHeader={setActiveHeader}
                                    replaceTopMessages={replaceMessages}
                                    hasPermission={hasPermission}
                                    hasFeature={props.hasFeature}
                                    accountSlug={props.accountSlug}
                                />
                            </FullWidthContainerBody>
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/venue/opening_times/default/:id"
                        exact
                        render={() => (
                            <OpeningTimesForm
                                history={props.history}
                                accountSlug={props.accountSlug}
                                setActiveHeader={setActiveHeader}
                                replaceMessages={replaceMessages}
                                removeAllMessages={removeAllMessages}
                            />
                        )}
                    />
                    <Route
                        path="/account/:accountSlug/venue/opening_times/exception/:id/default/:openingTimesId/"
                        render={() => (
                            <OverrideForm
                                history={props.history}
                                accountSlug={props.accountSlug}
                                setActiveHeader={setActiveHeader}
                                replaceMessages={replaceMessages}
                                removeAllMessages={removeAllMessages}
                            />
                        )}
                    />
                    <Route
                        render={() => <Redirect to={`/account/${props.accountSlug}/venue/opening_times/locations`} />}
                    />
                </Switch>
            </Container>
        </div>
    )
}

export default withFeatures(OpeningTimesPage)
