import { find } from 'lodash'
import { RefundTicket } from './types'
import { ICancelRefundTableRow } from './components/cancelRefundTable/types'

export interface BookingFeeRowData extends ICancelRefundTableRow {
    ticketsUuids: string[]
    selectedTicketsUuids: string[]
    selectedProductsNames: string[]
}

export const getBookingFeesRowsData = (
    rowsData: BookingFeeRowData[],
    ticket: RefundTicket,
    isSelected: boolean,
    productName: string,
) => {
    for (const bookingFee of ticket.bookingFees) {
        const bookingFeeExist = find(rowsData, { id: bookingFee.titleCmsKey })

        if (bookingFeeExist) {
            bookingFeeExist.amount += isSelected ? Number(bookingFee.calculatedAmountInclTax) : 0
            let ticketsUuids = [...bookingFeeExist.ticketsUuids]
            let selectedTicketsUuids = [...bookingFeeExist.selectedTicketsUuids]
            let selectedProductsNames = [...bookingFeeExist.selectedProductsNames]

            ticket.ids.forEach((id) => {
                if (!bookingFeeExist.ticketsUuids.includes(id)) {
                    ticketsUuids.push(id)
                    if (isSelected) {
                        selectedTicketsUuids.push(id)
                        if (!selectedProductsNames.includes(productName)) {
                            selectedProductsNames.push(productName)
                        }
                    }
                }
            })
            bookingFeeExist.ticketsUuids = ticketsUuids
            bookingFeeExist.selectedTicketsUuids = selectedTicketsUuids
            bookingFeeExist.selectedProductsNames = selectedProductsNames
        } else {
            rowsData.push({
                id: bookingFee.titleCmsKey,
                rowType: 'bookingFee',
                name: bookingFee.title,
                description: '1/1 products selected',
                infoTip: 'none',
                amount: isSelected ? Number(bookingFee.calculatedAmountInclTax) : 0,
                selected: true,
                orderItemId: '',
                ticketsUuids: ticket.ids,
                selectedTicketsUuids: isSelected ? ticket.ids : [],
                selectedProductsNames: isSelected ? [productName] : [],
            })
        }
    }
}
