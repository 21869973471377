import React from 'react'

const BaseKnowledgeLink = ({ link }: { link: string }) => {
    return (
        <p className="body1">
            Need help? Take a look at our&nbsp;
            <a target="_blank" href={link} rel="noreferrer" className="bk-link">
                Knowledge Base
            </a>
            .
        </p>
    )
}

export default BaseKnowledgeLink
