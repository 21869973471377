import React, { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-typed'

import { BaseRouteParams } from 'hocs'
import { useHasFeature, useHasFeatureFunc } from 'utils/useHasFeature'
import DiscountHeader from './discountCodes/header'
import DiscountCodesPageTitle from './discountCodes/pageTitle'

const GuidelinesLink = styled.a`
    color: ${(props) => props.theme.colors.boyBlue};
`
export interface BookingNavigationSection {
    path: string
    active: boolean
    shouldShow: boolean
    tabTitle: string
    title: string
    description: string
    secondDescription?: string
    guidelinesSubject?: string
    guidelinesUrl?: string
    tabHeader?: React.ReactNode
}

const Sign = styled.div`
    font-size: 0.5625rem;
    font-weight: 400;
    position: absolute;
    right: -2.3em;
    top: -0.25em;
    color: ${(props) => props.theme.colors.aluminiumShades[5]};
`

export const useBookingNavigation = () => {
    const { pathname, search } = useLocation()
    const { accountSlug } = useParams<BaseRouteParams>()
    const reservationPoolsOn = useHasFeature({ featureName: 'RetailersReservationCodes' })
    const hasFeature = useHasFeatureFunc()

    return useMemo(() => {
        const sections = [
            {
                path: `/account/${accountSlug}/venue/codes/tickets/list/`,
                active: pathname.includes('/tickets'),
                shouldShow: true,
                tabTitle: 'Ticket barcodes',
                title: 'Ticket barcodes',
                description:
                    'Every ticket needs a barcode for the visitor. We can hand out generated barcodes, or you can use your \
        own barcodes. You can upload them here. Visitors will see these barcodes on their tickets in order for \
        them to be scanned at the gates.',
                secondDescription: '',
                guidelinesSubject: 'barcodes',
                guidelinesUrl: 'https://support.convious.com/help/how-to-upload-your-own-barcodes-in-the-control-panel',
            },
            {
                path: `/account/${accountSlug}/venue/codes/reservations/${reservationPoolsOn ? 'list/' : ''}`,
                active: pathname.includes('/reservations'),
                shouldShow: hasFeature('BarcodeCheck'),
                tabTitle: 'Reservation codes',
                title: 'Reservation codes',
                description:
                    'The reservation feature in the checkout checks if a consumer entered a valid code. \
        Here, you can upload those codes in a CSV file that should be used to check if a reservation is valid.',
                secondDescription: (
                    <div>
                        Note: you cannot use upload discount codes here, only codes for reservation.Learn more about
                        reservation codes{' '}
                        <GuidelinesLink
                            target="_blank"
                            href="https://support.convious.com/help/how-to-upload-reservation-codes-in-your-control-panel"
                        >
                            here
                        </GuidelinesLink>
                        .
                    </div>
                ),
                guidelinesUrl: 'https://support.convious.com/help/how-to-upload-reservation-codes-in-your-control-panel'
            },
            {
                path: `/account/${accountSlug}/venue/codes/vouchers/`,
                active: pathname.includes('/vouchers'),
                shouldShow: true,
                tabTitle: 'Vouchers',
                title: 'Vouchers',
                description: `Vouchers are product specific discount codes. Upload a list of discount codes to be used at the time of purchase.
        A customer must enter this code, in order to receive discount on a particular product(s). Codes are case-sensitive.`,
                guidelinesSubject: 'voucher codes',
                guidelinesUrl: 'https://support.convious.com/help/uploading-voucher-codes',
            },
            {
                path: `/account/${accountSlug}/venue/codes/coupons/list`,
                active: pathname.includes('/coupons'),
                shouldShow: true,
                tabTitle: 'Coupons',
                title: 'Coupons',
                description:
                    'Coupons are generic discount codes valid on all products. Upload a list of coupon codes to be used at the time of purchase.',
                secondDescription:
                    'A customer must enter this code, in order to receive a discount on a purchase. Codes are case-sensitive.',
                guidelinesSubject: 'coupon codes',
                guidelinesUrl: 'https://support.convious.com/help/uploading-coupons-codes',
            },
            {
                path: `/account/${accountSlug}/venue/codes/discount`,
                active: pathname.includes('/codes/discount'),
                shouldShow: hasFeature('discountCodes'),
                tabTitle: (
                    <div style={{ display: 'flex', justifyContent: 'center', paddingRight: '1rem' }}>
                        <div>Discount codes</div>
                        <div style={{ position: 'relative' }}>
                            <Sign>New</Sign>
                        </div>
                    </div>
                ),
                title: <DiscountCodesPageTitle />,
                description: 'Generate or create codes to be used at the time of purchase.',
                tabHeader: <DiscountHeader />,
            },
            {
                path: `/account/${accountSlug}/venue/codes/sold_tickets/`,
                active: pathname.includes('/codes/sold_tickets'),
                shouldShow: hasFeature('SeasonPassesUploadFeature'),
                tabTitle: 'Season passes',
                title: 'Season passes',
                description: 'Upload your season passes that were sold externally to later on use them in the Tap app.',
            },
            {
                path: `/account/${accountSlug}/venue/codes/product_ids/`,
                active: pathname.includes('/codes/product_ids'),
                shouldShow: true,
                tabTitle: 'Product IDs',
                title: 'Product IDs',
                description: 'Here you can find the product IDs needed for the code uploads.',
            },
        ] as BookingNavigationSection[]

        return sections.filter((section) => section.shouldShow)
    }, [accountSlug, pathname, search])
}
