import * as React from 'react'
import { ExportQuery } from 'orders/schema'
import { LoginService } from 'http/loginService'
import DownloadLink from 'uiComponents/downloadLink'
import { withFeatures } from 'features'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus/'
import { MessageKind, MessageButton } from 'uiComponents/messages'
import { DateRange } from 'dateRanges'
import { delay } from 'utils'
import { OrdersService } from 'orders/ordersService'
import { LoggingService, ActionEventData } from 'http/loggingService'
import { parseSearch } from 'navigation'
import Feature from 'features/feature'
import cloneDeep from 'lodash/cloneDeep'
import { useAppSelector } from 'store/hooks'

interface ExportButtonProps {
    page: 'transactions' | 'details'
    ordersNumber: number
    ordersQuery: ExportQuery
    backofficeEndpoint: string
    loginService: LoginService
    loggingService: LoggingService
    activeSlug: string
    dateRange: DateRange
    ordersService: OrdersService
    hasFeature: (feature: string, accountSlug: string) => boolean
    getComponentValue: (component: string, accountSlug: string) => any
    replaceMessages: (id: string, status: MessageKind, text: string, actionButton?: MessageButton) => void
    hideMessage: (id: string) => void
}

const ExportMenu: React.FC<ExportButtonProps> = (props) => {
    const user = useAppSelector((state) => state.auth.user)

    const getQueryObject = (path: string) => {
        if (path.includes('refund-details')) {
            const query = cloneDeep(props.ordersQuery)
            query.date_range_type = 'refund'
            return query
        }
        return props.ordersQuery
    }

    const downloadLink = (path: string, format: string, async: boolean = false) => {
        const queryObject = getQueryObject(path)
        const query = Object.keys(queryObject)
            .map((k) => (queryObject[k] ? `${encodeURIComponent(k)}=${encodeURIComponent(props.ordersQuery[k])}` : ''))
            .filter((x) => x !== '')
            .join('&')
        const timezoneOffset = new Date().getTimezoneOffset()
        const params = `${query}&export_format=${format}&timezone_offset=${timezoneOffset}`
        const schedule = async ? 'schedule' : ''
        return `${props.backofficeEndpoint}dashboard_api/orders/${path}${schedule}?${params}`
    }

    const sendAsyncExportRequest = async (path: string, format: string) => {
        try {
            const endpoint = downloadLink(path, format, true)
            await props.ordersService.scheduleAsyncExport(endpoint)
            const message =
                path === 'discounts/export/'
                    ? 'Discount codes export request was placed successfully. An email with be sent to you shortly.'
                    : 'Request was placed successfully. An email with be sent to you shortly.'
            props.replaceMessages('schedule_success', 'success', message)
            await delay(6000)
            props.hideMessage('schedule_success')
        } catch {
            props.replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again.')
        }
    }

    const isOrderNumberUnderLimit = (path: string, format: string) => {
        const ordersLimit = props.getComponentValue('OrdersAsyncExportLimit', props.activeSlug)
        if (ordersLimit && props.ordersNumber > ordersLimit) {
            const messageButton: MessageButton = {
                kind: 'action',
                buttonText: 'Send archive',
                postButtonText: `to ${user?.username}`,
                onClick: () => sendAsyncExportRequest(path, format),
            }
            props.replaceMessages(
                'export_message',
                'warn',
                `You are exceeding the limit of ${ordersLimit} items per export.
    Please adjust the time range or search parameters to limit the result or`,
                messageButton,
            )
            return false
        }
        return true
    }

    const setMessage = async () => {
        props.replaceMessages(
            'export_starting',
            'success',
            'Report generation may take up to 30 seconds and will download automatically.',
        )
        await delay(45000)
        props.hideMessage('export_starting')
    }

    const logDownloadInitiated = (endpoint: string) => {
        const params = parseSearch(`?${endpoint.split('?')[1]}`)
        const eventData: ActionEventData = {
            category: endpoint.indexOf('orders/details') > -1 ? 'orders_details' : 'orders_transactions',
            payload: {
                search: params.search,
                export_format: params.export_format,
                date_from: params.date_from,
                date_to: params.date_to,
                date_range_type: params.date_range_type,
                sort_by: params.sort_by,
                sort_direction: params.sort_direction,
                endpoint,
            },
        }
        props.loggingService.logAction('order_export_requested', eventData)
    }

    return (
        <OnClickMenu kind="default" title="Export" style={{ marginLeft: '1em' }} alignRight>
            <DownloadLink
                loginService={props.loginService}
                downloadEndpoint={downloadLink(`${props.page}/export/`, 'csv')}
                downloadAllowed={() => isOrderNumberUnderLimit(`${props.page}/export/`, 'csv')}
                onDownloadInitiated={logDownloadInitiated}
            >
                <OnClickMenuItem onClick={setMessage}>Export orders .CSV</OnClickMenuItem>
            </DownloadLink>
            <DownloadLink
                loginService={props.loginService}
                downloadEndpoint={downloadLink(`${props.page}/export/`, 'xlsx')}
                downloadAllowed={() => isOrderNumberUnderLimit(`${props.page}/export/`, 'xlsx')}
                onDownloadInitiated={logDownloadInitiated}
            >
                <OnClickMenuItem onClick={setMessage}>Export orders .XLSX</OnClickMenuItem>
            </DownloadLink>
            {props.page === 'transactions' && (
                <>
                    <OnClickMenuItem onClick={() => sendAsyncExportRequest('discounts/export/', 'csv')}>
                        Export discount codes .CSV
                    </OnClickMenuItem>
                    <OnClickMenuItem onClick={() => sendAsyncExportRequest('discounts/export/', 'xlsx')}>
                        Export discount codes .XLSX
                    </OnClickMenuItem>
                </>
            )}
            {props.page === 'details' && (
                <Feature name="OrderRefundExport" accountSlug={props.activeSlug}>
                    <OnClickMenuItem onClick={() => sendAsyncExportRequest('refund-details/export/', 'csv')}>
                        Export refunds .CSV
                    </OnClickMenuItem>
                    <OnClickMenuItem onClick={() => sendAsyncExportRequest('refund-details/export/', 'xlsx')}>
                        Export refunds .XLSX
                    </OnClickMenuItem>
                </Feature>
            )}
        </OnClickMenu>
    )
}

export default withFeatures(ExportMenu)
