/* eslint-disable react/display-name */
import * as React from 'react'
import { Route, match as RouteMatch } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { ReactComponent } from 'reactUtils'
import { Subtract } from 'utility-types'
import { Navigation } from 'navigation'

export interface BaseRouteParams {
    accountSlug: string
}

export interface WithNavigationProps<TRouteParams extends { [K in keyof TRouteParams]?: string | undefined }> {
    navigation: Navigation
    match: RouteMatch<TRouteParams>
}

export function withNavigation<
    TProps extends WithNavigationProps<TRouteParams>,
    TRouteParams extends { [K in keyof TRouteParams]?: string | undefined },
>(Component: ReactComponent<TProps>): ReactComponent<Subtract<TProps, WithNavigationProps<TRouteParams>>> {
    return function (props: TProps) {
        return (
            <Route
                render={({ history, match }) => {
                    return <Component {...props} navigation={new Navigation(history, history.location)} match={match} />
                }}
            />
        )
    }
}

export const useNavigation = () => {
    const history = useHistory()

    return React.useMemo(() => new Navigation(history, history.location), [history, history.location])
}
