import React, { useState, useEffect } from 'react'
import styled from 'styled-typed'
import { usePermission } from 'admin/hocs'
import { ImagesService } from 'http/imagesService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome'
import { Col } from 'uiComponents/flex'
import Infotip from 'uiComponents/infotip'
import { SmallText } from 'uiComponents/typography'
import { MessageKind } from 'uiComponents/messages'
import { ValidationMessage } from 'uiComponents/form/formElements'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { FormFieldComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { FieldType, SelectField } from 'uiComponents/studio/form/fieldComponentSchema'
import { UploadElement } from 'engageTools/studio/form/uploadElement'
import { fontOptions } from 'engageTools/studio/shared'
import { CheckoutConfiguration, OnChangeFunc, EngageToolConfig } from 'engageTools/studio/schema'
import { ComponentsService } from 'engageTools/studio/componentsService'
import cloneDeep from 'lodash/cloneDeep'

interface GeneralSectionProps {
    getConfigValue: (field: FieldType) => string | number | boolean
    section: ConfigurationUnit
    configuration: CheckoutConfiguration
    handleInputChange: OnChangeFunc
    updateWholeConfig: (config: EngageToolConfig) => void
    componentsService: ComponentsService
    imagesService: ImagesService
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    accountSlug: string
    fontsValidation: boolean
    setFontsValidation: (v: boolean) => void
}

const InfoIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.textLight};
`

function GeneralSection(props: GeneralSectionProps) {
    const { section, configuration, handleInputChange, imagesService, getConfigValue, componentsService } = props
    const [showCustomPrimaryFont, setShowCustomPrimaryFont] = useState<boolean>(false)
    const [showCustomSecondaryFont, setShowCustomSecondaryFont] = useState<boolean>(false)
    const [customPrimaryFont, setCustomPrimaryFont] = useState<string>('')
    const [customSecondaryFont, setCustomSecondaryFont] = useState<string>('')
    const [customPrimaryValid, setCustomPrimaryValid] = useState<boolean>(true)
    const [customSecondaryValid, setCustomSecondaryValid] = useState<boolean>(true)
    const { hasPermission } = usePermission()
    const isConviousAdmin = hasPermission('convious_admin', props.accountSlug)

    useEffect(() => {
        if (!!configuration.primaryFont && !fontOptions.find((o) => o.value === configuration.primaryFont)) {
            setCustomPrimaryFont(configuration.primaryFont)
            setShowCustomPrimaryFont(true)
        }
        if (!!configuration.secondaryFont && !fontOptions.find((o) => o.value === configuration.secondaryFont)) {
            setCustomSecondaryFont(configuration.secondaryFont)
            setShowCustomSecondaryFont(true)
        }
        if (!configuration.primaryFont) {
            props.handleInputChange('Roboto', 'primaryFont')
        }
        if (!configuration.secondaryFont) {
            props.handleInputChange('Roboto', 'secondaryFont')
        }
    }, [])

    useEffect(() => {
        if (props.fontsValidation) {
            checkFontValidity()
            props.setFontsValidation(false)
        }
    })

    const checkFontValidity = (): void => {
        if (configuration.primaryFont === 'custom') {
            if (customPrimaryFont) {
                props.handleInputChange(customPrimaryFont, 'primaryFont')
            }
            setCustomPrimaryValid(!!customPrimaryFont)
        }
        if (configuration.secondaryFont === 'custom') {
            setCustomSecondaryValid(!!customSecondaryFont)
            if (customSecondaryFont) {
                props.handleInputChange(customSecondaryFont, 'secondaryFont')
            }
        }
    }

    function handleButtonStyleChange(value: string, path: string) {
        const updatedConfig = cloneDeep(configuration)
        const isGradient = value === 'gradient'
        updatedConfig.isPrimaryColorGradient = isGradient
        updatedConfig.isSecondaryColorGradient = isGradient
        if (isGradient) {
            if (!configuration.primaryColorGradientStop) {
                updatedConfig.primaryColorGradientStop = configuration.primaryColor
            }
            if (!configuration.secondaryColorGradientStop) {
                updatedConfig.secondaryColorGradientStop = configuration.secondaryColor
            }
        }
        props.updateWholeConfig(updatedConfig)
    }

    function getButtonStyleValue() {
        return !!configuration.isPrimaryColorGradient ? 'gradient' : 'solid'
    }

    function handleFontSelectValue(v: string, path: 'primaryFont' | 'secondaryFont') {
        path === 'primaryFont' ? setShowCustomPrimaryFont(v === 'custom') : setShowCustomSecondaryFont(v === 'custom')
        if (v === 'custom') {
            path === 'primaryFont'
                ? props.handleInputChange(customPrimaryFont || 'custom', path)
                : props.handleInputChange(customSecondaryFont || 'custom', path)
        } else {
            props.handleInputChange(v, path)
        }
    }

    function getFontValue(field: SelectField) {
        if (
            field.name === 'primary_font' &&
            !field.availableOptions.find((o) => o.value === configuration.primaryFont)
        ) {
            return !!configuration.primaryFont ? 'custom' : props.getConfigValue(field)
        }
        if (
            field.name === 'secondary_font' &&
            !field.availableOptions.find((o) => o.value === configuration.secondaryFont)
        ) {
            return !!configuration.secondaryFont ? 'custom' : props.getConfigValue(field)
        }
        return props.getConfigValue(field)
    }

    function handleCustomFontValue(v: string, path: 'primaryFontCustomName' | 'secondaryFontCustomName') {
        if (path === 'primaryFontCustomName') {
            setCustomPrimaryFont(v)
            setCustomPrimaryValid(!!v)
            props.handleInputChange(!!v ? v : 'custom', 'primaryFont')
        } else {
            setCustomSecondaryFont(v)
            setCustomSecondaryValid(!!v)
            props.handleInputChange(!!v ? v : 'custom', 'secondaryFont')
        }
    }

    function renderField(field: FieldType) {
        const primaryIsCustom =
            field.name === 'primary_font' &&
            !(field as SelectField).availableOptions.find((o) => o.value === configuration.primaryFont)
        if (
            ((field.name === 'primary_font' || field.name === 'secondary_font') && isConviousAdmin) ||
            (primaryIsCustom && !isConviousAdmin)
        ) {
            if (!(field as SelectField).availableOptions.find((o) => o.value === 'custom')) {
                ;(field as SelectField).availableOptions.push({
                    name: 'Custom',
                    value: 'custom',
                })
            }
        }
        if (field.name === 'primary_font' || field.name === 'secondary_font') {
            return (
                <FormFieldComponent
                    field={field}
                    handleChange={(v: string, path: 'primaryFont' | 'secondaryFont') => handleFontSelectValue(v, path)}
                    value={getFontValue(field as SelectField)}
                    disabled={getFontValue(field as SelectField) === 'custom' && !isConviousAdmin}
                />
            )
        }
        if (field.name === 'custom_font_name_primary' || field.name === 'custom_font_name_secondary') {
            return (
                <FormFieldComponent
                    field={field}
                    handleChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                        path: 'primaryFontCustomName' | 'secondaryFontCustomName',
                    ) => handleCustomFontValue(e.target.value, path)}
                    value={field.name === 'custom_font_name_primary' ? customPrimaryFont : customSecondaryFont}
                    error={
                        (field.name === 'custom_font_name_primary' && !customPrimaryValid) ||
                        (field.name === 'custom_font_name_secondary' && !customSecondaryValid)
                    }
                />
            )
        }
        if (field.name === 'button_style') {
            return (
                <FormFieldComponent
                    field={field}
                    handleChange={handleButtonStyleChange}
                    value={getButtonStyleValue()}
                />
            )
        }
        return (
            <FormFieldComponent
                field={field}
                handleChange={props.handleInputChange}
                value={props.getConfigValue(field)}
            />
        )
    }

    if (section.fields) {
        const isCustomForPrimaryChosen =
            !!configuration.primaryFont && !fontOptions.find((o) => o.value === configuration.primaryFont)
        return (
            <SectionWrapper key={section.name} title={section.name}>
                {section.fields.map((field, j) => {
                    let style = field.span && field.span < 12 ? { display: 'flex', alignItems: 'center' } : {}
                    const labelName = field.label === '' || field.type === 'checkbox' ? null : field.label
                    const isButtonGradient = configuration.isPrimaryColorGradient
                    if (
                        (field.name === 'primary_color_gradient_stop' ||
                            field.name === 'secondary_color_gradient_stop') &&
                        !isButtonGradient
                    ) {
                        return null
                    }
                    if (
                        (field.name === 'custom_font_name_primary' && !showCustomPrimaryFont) ||
                        (field.name === 'custom_font_name_secondary' && !showCustomSecondaryFont)
                    ) {
                        return null
                    }
                    return (
                        <div key={j}>
                            {[
                                'secondary_font',
                                'custom_font',
                                'custom_font_name_primary',
                                'custom_font_name_secondary',
                            ].indexOf(field.name) > -1 && !isConviousAdmin ? (
                                <></>
                            ) : (
                                <FormItem>
                                    {labelName && (
                                        <Col
                                            span={!!field.span && field.span !== 12 ? 12 - field.span : 12}
                                            style={style}
                                        >
                                            <label htmlFor={field.name}>
                                                {labelName}
                                                {field.infotip && (
                                                    <Infotip
                                                        pointer={field.name === 'custom_font' ? 'right' : 'left'}
                                                        maxWidth="16em"
                                                        smallBoxText
                                                    >
                                                        {field.infotip}
                                                    </Infotip>
                                                )}
                                            </label>
                                        </Col>
                                    )}
                                    {field.type === 'upload' ? (
                                        <UploadElement
                                            key={field.name}
                                            field={field}
                                            style={style}
                                            handleChange={handleInputChange}
                                            value={getConfigValue(field)}
                                            imagesService={imagesService}
                                            componentsService={componentsService}
                                            replaceTopMessages={props.replaceTopMessages}
                                            removeAllMessages={props.removeAllMessages}
                                            type={field.name === 'header-logo' ? 'img' : 'icon'}
                                            acceptSvg={field.name === 'icon-url'}
                                        />
                                    ) : (
                                        <Col span={field.span} style={{ ...style }} className="justify-right">
                                            {renderField(field)}
                                        </Col>
                                    )}
                                </FormItem>
                            )}
                            {((field.name === 'custom_font_name_primary' && !customPrimaryValid) ||
                                (field.name === 'custom_font_name_secondary' && !customSecondaryValid)) && (
                                <ValidationMessage className="validation-message-visible">
                                    This field is required if you select the custom font option
                                </ValidationMessage>
                            )}
                            {field.name === 'primary_font' && !isConviousAdmin && isCustomForPrimaryChosen && (
                                <>
                                    <SmallText>
                                        <InfoIcon icon={faInfoCircle as IconProp} style={{ marginRight: '.3em' }} />
                                        Custom font is chosen for your checkout. To edit it, please contact the Convious
                                        team.
                                    </SmallText>
                                </>
                            )}
                        </div>
                    )
                })}
            </SectionWrapper>
        )
    } else {
        return null
    }
}

export default GeneralSection
