import * as React from 'react'
import { LoggingService } from 'http/loggingService'
import { SidebarMenu } from 'sidebar/sidebarMenu'
import { useAppSelector } from 'store/hooks'

interface SidebarProps {
    accountSlug: string
    selfSignUp: string | null
    hasPermission: (permission: string, accountSlug: string) => boolean
    hasFeature: (feature: string, accountSlug?: string) => boolean
    loggingService: LoggingService
}

function Sidebar({ accountSlug, selfSignUp, hasPermission, hasFeature, loggingService }: SidebarProps) {
    const user = useAppSelector((state) => state.auth.user)
    
    return (
        <SidebarMenu
            accountSlug={accountSlug}
            selfSignUp={selfSignUp}
            hasPermission={hasPermission}
            hasFeature={hasFeature}
            loggingService={loggingService}
            user={user}
        />
    )
}

export default Sidebar
