import React from 'react'
import { PageTitle } from 'uiComponents/typography'
import { BookingNavigationSection } from './hooks'

const CodesHeading = ({ activeSection }: { activeSection?: BookingNavigationSection }) => {
    return (
        <>
            <PageTitle style={{ marginBottom: activeSection?.path.includes('/codes/discount') ? '0' : '2rem' }}>{activeSection?.title}</PageTitle>
            {activeSection?.tabHeader}
        </>
    )
}

export default CodesHeading
