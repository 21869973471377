import * as React from 'react'
import { Cell } from 'uiComponents/table'
import { InlineEdit, InlineUpload } from 'uiComponents/input'
import { UserField, OrderDetails, ConfigUserField } from 'orders/schema'
import { OrdersService } from 'orders/ordersService'
import { MessageKind } from 'uiComponents/messages'
import { usePermission } from 'admin/hocs'

interface UserInputFieldProps {
    accountSlug: string
    ordersService: OrdersService
    configField: ConfigUserField
    item: OrderDetails
    maskData: boolean
    updateApfItem: (barcode: string, userField: UserField, value: string) => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

function UserInputField(props: UserInputFieldProps) {
    const { configField, item, updateApfItem, ordersService } = props
    const { hasPermission } = usePermission()
    const userField = item.userFields.find((f) => f.name === configField.name)
    if (!userField) {
        return null
    }

    const onEditAccept = (v: string) => {
        const newValue = userField.type === 'bool' ? formatToBoolValue(v) : v
        updateApfItem(item.barcode, userField, newValue)
    }
    const formatToBoolValue = (v: string) => {
        const yesValues = ['yes', 'y', 'true']
        return yesValues.includes(v.toLowerCase()) ? 'true' : 'false'
    }
    const formatFromBoolValue = (v: string) => {
        return v === null ? v : v === 'true' ? 'Yes' : 'No'
    }

    const canBeEdited =
        hasPermission('edit_orders', props.accountSlug) &&
        !!item.barcode &&
        userField.editable &&
        item.status !== 'refunded' &&
        item.status !== 'refunding'
    const value = userField.type === 'bool' ? formatFromBoolValue(userField.value) : userField.value

    return (
        <Cell title={userField.value} className="user-field">
            {userField.type !== 'image' && (
                <InlineEdit
                    id={item.barcode + userField.name}
                    value={value}
                    placeholder={canBeEdited ? userField.placeholder || <>&mdash;</> : ''}
                    onEditAccept={onEditAccept}
                    limitedTextWidth="13.5em"
                    disabled={!canBeEdited}
                    maskData={props.maskData}
                    maskIcon
                />
            )}
            {userField.type === 'image' && (
                <InlineUpload
                    id={item.barcode + userField.name}
                    value={userField.value}
                    placeholder={canBeEdited ? userField.placeholder || <>&mdash;</> : ''}
                    fileSizeLimit={16}
                    onUpload={async (file: File) => await ordersService.uploadApfPhoto(file)}
                    onEditAccept={onEditAccept}
                    limitedTextWidth="13.5em"
                    disabled={!canBeEdited}
                />
            )}
        </Cell>
    )
}

export default UserInputField
