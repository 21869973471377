import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { delay } from 'utils'
import { Message, MessageKind, MessageButton, Messages } from 'uiComponents/messages'
import { useAppSelector } from 'store/hooks'

export type ReplaceMessagesFunc = (
    id: string,
    status: MessageKind,
    text: string,
    actionButton?: MessageButton,
    scrollToTop?: boolean,
) => void

export interface MessageProps {
    messages: Message[]
    addMessage: (id: string, status: MessageKind, text: string, actionButton?: MessageButton) => void
    hideMessage: (id: string) => void
    replaceMessages: ReplaceMessagesFunc
    removeAllMessages: () => void
}

interface LocationState {
    messages?: Message[]
    keepMessages?: boolean
}

const MessagesContext = createContext<MessageProps | undefined>(undefined)

function appendMessage(messages: Message[], message: Message): Message[] {
    const newMessages = messages.filter((m) => m.id !== message.id)
    newMessages.push(message)
    return newMessages
}

export const MessagesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [messages, setMessages] = useState<Message[]>([])
    const location = useLocation<LocationState>()
    const history = useHistory()
    const reduxMessages = useAppSelector((state) => state.messages.messages)

    useEffect(() => {
        setMessages(reduxMessages)
    }, [reduxMessages])
    
    useEffect(() => {
        const locationState = location.state
        if (locationState && locationState.messages && locationState.messages.length > 0) {
            checkMessagesInLocationState(locationState)
        }
    }, [location])

    const checkMessagesInLocationState = async (locationState: LocationState) => {
        if (!locationState.messages) {
            return
        }

        const toAppend: Message[] = locationState.messages
        const keepMessages = locationState.keepMessages

        setMessages((prevMessages) => toAppend.reduce(appendMessage, prevMessages))

        const newState = { ...locationState }
        delete newState.messages
        delete newState.keepMessages

        history.replace({ ...history.location, state: newState })

        if (!keepMessages) {
            await delay(3000)
            hideAllMessages()
        }
    }

    const addMessage = (id: string, status: MessageKind, text: string, actionButton: MessageButton = null) => {
        setMessages((prevMessages) =>
            appendMessage(prevMessages, {
                id,
                status,
                text,
                visible: true,
                actionButton,
            }),
        )
    }

    const hideMessage = (id: string) => {
        setMessages((prevMessages) =>
            prevMessages.map((message) => (message.id === id ? { ...message, visible: false } : message)),
        )
    }

    const hideAllMessages = () => {
        setMessages((prevMessages) => prevMessages.map((message) => ({ ...message, visible: false })))
    }

    const replaceMessages = (
        id: string,
        status: MessageKind,
        text: string,
        actionButton: MessageButton = null,
        scrollToTop: boolean = false,
    ) => {
        setMessages([])
        addMessage(id, status, text, actionButton)
        if (scrollToTop) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
    }

    const removeAllMessages = () => {
        setMessages([])
    }

    const value: MessageProps = {
        messages,
        addMessage,
        hideMessage,
        replaceMessages,
        removeAllMessages,
    }

    return (
        <MessagesContext.Provider value={value}>
            <Messages />
            {children}
        </MessagesContext.Provider>
    )
}

export const useMessages = (): MessageProps => {
    const context = useContext(MessagesContext)
    if (context === undefined) {
        throw new Error('useMessages must be used within a MessagesProvider')
    }
    return context
}
